import { makeStyles } from "@material-ui/styles";
import { BiFontSize } from "react-icons/bi";

export const useStyles = makeStyles(
  {
    accordion: {
      background: "#FFFFFF",
      borderRadius: "7.51286px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
    },
    marginTop20: {
      marginTop: 20
    },
    buttons: {
      textAlign: 'center',
      marginTop: 14,
      display:'flex'

    },
    backbutton: {
      marginTop: 20,
      background: '#EFEFEF',
      color: '#2196f3',
      fontSize: 12,
      padding: '4px 10px'
    },
    marginTop10: {
      marginTop: 10
    },
    inputFields: {
      margin: '10px 0px 20px',
      display: 'flex',
      alignItems: 'center'
    },
    alignCenter: { textAlign: "center" },
    outbondCalls: {
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontSize: 20,
    },
    table: {
      border: "1px solid darkgrey",
      borderBottom: "1px solid darkgrey"
    },
    textfield: {
      marginTop: 25
    },
    saveButton: {
      marginTop: 20,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      borderRadius: "5px",
      padding: "6px 20px",
      // "&:hover": {
      //   color: "#FFFFFF",
      //   background: " #3A416F",
      // },
      '&:hover': {
        background: " #3A416F",
        color: "#fff",
      }
    },
    tableCell: {
      color: "#8392AB",
      fontSize: 14,
      fontWeight: "bold",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      //lineHeight: 2,
      textAlign: "left",
    },
    tableCellBody: {
      color: "#8392AB",
      fontSize: 12,
      fontWeight: 600,
      fontFamily: "Work Sans",
      fontStyle: "normal",
    },
    divider: {
      background: "#E2E2E2",
    },

    divButtons: {
      marginTop: 31,
    },
    searchButton: {
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      borderRadius: "5px",
      padding: "6px 20px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },

    clearButton: {
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      color: "#8392AB",
      border: "0.791301px solid #8392AB",
      background: "#F5F5F5",
      borderRadius: "5px",
      padding: "6px 20px",
      marginLeft: 20,
    },
    // materialIcons:{color:"red"},
    pendingButton: {
      background: "linear-gradient(135deg, #ff4500 0%, #bf4f51 100%)",
      color: "#FFFFFF",
      borderRadius: 5,
      fontSize: 10,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: "bold",
      height: 15,
      position: "sticky",
      padding: 5,
      marginTop: -11,
      width: 45
    },
    submitButton: {
      marginRight: 15,
      marginTop: 20,
      background: 'linear-gradient(#273da6,#1b90f1)',
      color: ' #fff',
      fontSize: 12,
      padding: '4px 10px'
    },
    name: {
      fontSize: 16,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
      textAlign: 'center'
    },
    providername: {
      // marginTop: 20,
      fontSize: 16,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
    },
    voiceName: {
      // marginTop: 22,
      fontSize: 16,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
    },
    activeButton: {
      background: "linear-gradient(135deg, #98EC2D 0%, #17AD37 100%)",
      color: "#FFFFFF",
      borderRadius: 5,
      fontSize: 10,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: "bold",
      height: 15,
      position: "absolute",
      padding: 5,
      marginTop: -11
    },
    portalUsers: {
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontSize: 16,
    },
    totalUsers: {
      color: "#3A416F",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontSize: 16,
      position: "relative",
    },
    dflex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    outboundButton: {
      background: "#FFFFFF",
      border: "1px solid #00407B",
      borderRadius: 50,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Work Sans',
      padding: 4,
      width: '300px',
      marginRight: 50,
      color: "#00407B",
    },
    recordsFlex: {
      marginTop: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tableCardOne: {
      background: "#FFFFFF",
      borderRadius: "7.51286px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
      marginTop: 15,
    },
    mTop10: { marginTop: 10, },
    mTB10: { margin: "10px 0px 0px" },
    tableHead: {
      color: "#8392AB",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
    },
    tableBody: {
      color: "#8392AB",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      alignItems: "center",
    },
    tabs: {
      background: "#FFFFFF",
      borderRadius: "7.51286px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
    },
    tab: {
      textTransform: "capitalize",
      background: "#FFFFFF",
      // borderBottom: "1px solid #B8B8B8",
      boxSizing: "border-box",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 18,
      color: "#8392AB",
    },
    tabActive: {
      background: "#FFFFFF",
      // borderBottom: "1px solid #B8B8B8",
      boxSizing: "border-box",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      //color: "#00407B",
      textTransform: "capitalize",
    },
    cardstyles: {
      background: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
    },
    tabPad: {
      padding: 0,
      marginTop: 30,
      // margin:"1px solid black"
    },
    addName: {
      color: "#8392AB",
      fontSize: 22,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      marginTop: 25,
    },
    heading: {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: '#F5F5F5',
      marginBottom: '14px'
    },
    addons: {
      color: "#3A416F",
      fontFamily: 'Work Sans',
      fontStyle: "noramal",
      fontWeight: 500,
      fontSize: 20,
    },
    allCalls: {
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontSize: 18,
    },
    actionButton: {
      fontSize: 10,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      textAlign: "center",
      color: "#3A416F",
      height: 20,
      width: 70,
      background: "#F5F5F5",
    },
    depaertmentName: {
      // marginTop: 20,
      fontSize: 16,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
    },
    depaertment: {
      // marginTop: 25,
      fontSize: 16,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
    },
    selctBox: {
      //marginTop: 8,
      color: "#8392AB",

    },
    newPass: {
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: '#8392ab',
      marginTop: 30,
    },
    conPass: {
      marginTop: 10,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: '#8392ab',
    },
    textField: {
      marginTop: 10,
      color: "#8392AB",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,

    },
    label: {
      // marginTop: 20,
      color: "#8392AB",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      marignLeft: 0,
      marginRight: 20
    },
    labelOne: {
      marginTop: 0,
      color: "#8392AB",
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
    },
    grid: {
      margin: 20,
    },
    textArea: {
      width: "100%",
      marginTop: 13,
      background: "#FFFFFF",
      boxSizing: "border-box",
      padding: "10px",
      fontWeight: 500,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      border: '1px solid #E7E7E7',
    },
    errorMessage: {
      color: 'red',
      fontSize: '0.8rem',
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
    },
    mainHeader: {
      fontFamily: 'work sans',
      color: '#565656',
      fontSize: 20,
      fontWeight: 'bold',
    },
    subHeader: {
      fontSize: 14,
      fontFamily: 'work sans',
      marginTop: 6
    }
  },
  { index: 1 }
);
