import React from 'react'
import TicketsInformations from './TicketsInformations/TicketsInformations'

function Index() {

    React.useEffect(() => {
        window.scrollTo(0, 0)
    },[])
  
    
    return (
        <div>
            <TicketsInformations />
        </div>
    )
}

export default Index
