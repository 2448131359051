import React from 'react'
import clsx from 'clsx';
import { connect } from 'react-redux'
import HeaderComponent from '../../components/Header-component/HeaderComponent'
import FooterComponent from '../../components/Footer-component/FooterComponent'
import * as globalPopuphandler from '../../store/Actions/popup-actionCreator'
import SidebarComponent from '../../components/Sidebar-component/Index'
import './layout.css'
import efoneLogo from '../../utils/images/logo-new-2.png'
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import SidebarResponsive from '../../components/SidebarComponent-responsive/SidebarResponsive'
import SidebarPopUpModal from '../../components/SidebarPopUpModel/SidebarPopUpModal';
import SidebarBackdrop from '../../components/SidebarPopUpModel/SidebarBackdrop';
import { useMediaPredicate } from "react-media-hook";

const drawerWidth = 290;

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: 0,
    },
    menuButtonHidden: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
}));

function Layout(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [sidebar, setSidebar] = React.useState(false)
    const biggerThan400 = useMediaPredicate("(width: 993px)");
    const handleDrawerOpen = () => {
        setOpen(false);
    };

    const handleDrawerClose = () => {
        setOpen(true);
    };

    const handleSidebarSection = () => {
        setSidebar(!sidebar)
    }

    return (
        <div className="layout_section">
            {/* pixel 992 */}
            <div className="sidebar_container">
                <SidebarComponent />
            </div>
            <div className="sidebar_container_responsive">
                {
                    sidebar && (
                        <>
                            <SidebarBackdrop handlePopupModal={handleSidebarSection} />
                            <SidebarPopUpModal>
                                <SidebarResponsive handlePopupModal={handleSidebarSection} />
                            </SidebarPopUpModal>
                        </>
                    )
                }
                
            </div>
            <div className="main_body_section">
                <div className="header_section">
                    <HeaderComponent handleSidebarPopupModal={handleSidebarSection} />
                </div>
                <div className="childComponenet">
                    {props.children}
                </div>
                <div className="footer_container">
                    {/* footer container */}
                    <FooterComponent />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        states: state
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearPoPUP: () => dispatch(globalPopuphandler.clearGlobalPopup())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
