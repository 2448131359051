import React from 'react'
import { Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  CardContent,
  Grid, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core'
  import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { useStyles } from './styles'
import SmsTable from './SmsTable/SmsTable';

function SmsUsage(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [smsData, setsmsData] = React.useState<any>([])

    React.useEffect(() => {
        if (props.usageData.smsUsage) {
            setsmsData(props.usageData.smsUsage)
        }
    }, [props.usageData])

    const handleChange = () => {
        setOpen(!open);
    };
    return (
        <div id="smsusage">
            <Accordion onChange={handleChange} style={{marginTop: 20, borderRadius: 10,background: '#FFFFFF',boxShadow: '0 0 0.5em #E2E2E2',}}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Typography className={classes.heading} id='heading2'>SMS Usage</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        <TableContainer>
                            <SmsTable smsData={smsData} />
                        </TableContainer>
                    </CardContent>
                </AccordionDetails>
            </Accordion> 
        </div>
    )
}

export default SmsUsage
