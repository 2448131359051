import { VendorRatesConstants, status } from '../Constants';

export function VendorUsers(state = {}, action: any) {
    switch (action.type) {
        case VendorRatesConstants.SEARCH_VENDOR_REQUEST:
            return {
                ...state,
                search_vendor_status: status.IN_PROGRESS,
                searchVendorList: action.data
            };
        case VendorRatesConstants.SEARCH_VENDOR_SUCCESS:
            return {
                ...state,
                search_vendor_status: status.SUCCESS,
                searchVendorList: action.data
            };
        case VendorRatesConstants.SEARCH_VENDOR_FAILURE:
            return {
                ...state,
                search_vendor_status: status.FAILURE,
                searchVendorList: null
            };
        default:
            return state;
    }
}
