import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(() => ({
    addons: {
        color: '#464646',
        fontSize: 18,
        fontFamily: 'work sans',
        fontWeight: 600,
    },
    dividernew: {
        backgroundColor: '#E2E2E2',
        marginTop: -5,
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
     accordianNoFlex:{
        display: 'block',
        //padding: '10px 0'
    },
    modaldeleteButton: {
        margin: 10,
        padding: '6px 22px',
        background: '#2645AD',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 3,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: 14,
        '&:hover': {
            background: '#2645AD',
            color: '#FFFFFF',
        }
    },
    mL20:{marginLeft:20},
    deltecancelButton: {
        margin: 10,
        padding: '6px 22px',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginLeft: 15,
        background: '#EFEFEF',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#787878',
        fontSize: 14,
    },
    calls: {
        textAlign: 'center',
        padding: 10,
        background: '#4D72F8',
        color: '#fff',
        fontFamily: 'work sans',
        fontSize: 16,
    },
    cardStyles:{
        marginTop: 20,
        borderRadius: 5,
        width:'100%',
        "@media (max-width: 426px)": {
            width:'90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
       
    },
     tableHead:{
        color: '#25282B',
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 600
    },
    tableBody: {
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 500,
        color: '#707070',
    },
    subscribe: {
        background: '#F5FFF6',
        color: '#219653',
        fontSize: 10,
        fontFamily: 'work sans',
        border: '0.75px solid rgba(168, 180, 222, 0.16)',

    },
     subscribeHeading: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        color: "#8392AB",
        textAlign: 'center',
        margin: '15px 0px',
    },
    subscribePopupButtons: {
        margin: '10px 0px',
        textAlign: "center",
    },
    subscribeRadioButtons: {
        display: "block",
        padding: "5px 10px",
        textAlign: 'center',
        //fontSize: 30
    },
    subscribeRadioButton: {
        display: "inline-block",
        //paddingBottom: 0,
        fontSize: "12px",
        },
    yesButton: {
        display: "inline-block",
        verticalAlign: "top",
        background: "#219653",
        border: "0.75px solid rgba(168, 180, 222, 0.16)",
        borderRadius: 3,
        color: "#ffffff",
        fontSize: 8,
        lineHeight: "8px",
        marginRight: 3,
        "&:hover": {
            background: "#219653",
            color: "#ffffff",
        },
    },
    noButton: {
        display: "inline-block",
        verticalAlign: "top",
        background: "#EB5757",
        border: "0.75px solid rgba(168, 180, 222, 0.16)",
        borderRadius: 3,
        color: "#ffffff",
        fontSize: 10,
        lineHeight: "8px",
        marginRight: 3,
        "&:hover": {
            background: "#EB5757",
            color: "#ffffff",
        },
    },
    addonName: {
        textAlign: 'center',
        fontSize:12,
        color: '#8392AB',
        fontFamily: 'work sans',
        fontWeight: 600,
    },
    collapse: {
        fontSize: 14,
    },
    mainHead: {
        fontSize: 12,
        color: '#8392AB',
        fontWeight: 600,
    }
}))