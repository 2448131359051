import React from 'react'

import logo from '../../utils/images/logo-new-2.png'
// import authposter from '../../../utils/images/login-desing-1.png'
import authposter from '../../utils/images/login-desing-1.png'
import { FcGoogle } from 'react-icons/fc'
import './auth.css'
import UpdatePassword from './UpdatePassword'

function Auth() {

    return (
        <div className="auth_container">
            {/* <div className="auth_logo_wrapper">
                <div className="logo_img_container">
                    <img src={logo} alt="logo" />
                </div>
            </div> */}
            <div className="auth_flex_section">
                <div className="auth_forms_wrapper">
                    <div className="auth_forms_container">
                        <UpdatePassword />
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Auth
