import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
  {
    mainHeader: {
      color: '#2546AE',
      //fontWeight: 'bold',
      fontFamily: 'work sans',
      fontWeight: 500,
      fontSize: 18,
    },
    reset: {
      fontFamily: 'work sans',
      color: 'green',
      fontSize: 15,
      marginTop: 10
    },
    errorMessage: {
      color: 'red',
      fontSize: '0.8rem',
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
    },
    error: {
      margin: '0',
      fontSize: ' 0.7rem',
      color: 'red',
      marginLeft: '13px'
    },
    email: {
      fontFamily: 'work sans',
      fontSize: 16,
      marginBottom: 6,
    },
    divFlex: {
      display: 'flex',
      alignItems: 'center',
      marginTop: -10,
    },

    emailText: {
      // marginLeft: 12,
      width: '100%'
    },
    conPass: {
      marginTop: 10,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: '#8392ab',
    },
    textfield: {
      marginTop: 25
    },
    newPass: {
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: '#8392ab',
      marginTop: 30,
    },
    buttons: {
      // textAlign: 'center',
      marginTop: 20,

    },
    submitButton: {
      // marginLeft: 40,
      background: 'linear-gradient(#273da6,#1b90f1)',
      color: ' #fff',
      fontSize: 12,
      padding: '4px 10px',
      width: '25%'
    },
    backbutton: {
      background: '#EFEFEF',
      color: '#2196f3',
      fontSize: 12,
      padding: '4px 10px',
      width: '25%',
      marginLeft: 10,
    }
  })