import { clientsConstants } from '../Constants';
import { clientsServices } from '../Services';

export const clientsActions = {
    clearData,
    clientDetail,
    addClientDetail,
    upadateSyncSettingsDetail,
    getEDetails,
    updateclientDetail,
    getSyncSettings,
    getDevicesList,
    addDevicesList,
    deleteDevice,
    getVerifiedNumbers,
    getBlockedNumbers,
    createBlockedNumbers,
    updateBlockedNumbers,
    deleteBlockedNumbers,
    getTickets,
    getVoiceMailList,
    voiceMailCreate,
    deleteVoiceMail,
    getAddressBookList,
    getCallLogs,
    getSmsLogs,
    getOutboundProviders,
    getSipcredentials,
    getUsage,
    getAccountLogs,
    clinetUpdateDevice,
    addAddressList,
    deleteVerifiedNumber,
    addVerifyNumberList,
    addSendOtpList,
    getSubscriptionAddons,
    addSubscriptionAddons,
    removeSubscriptionAddons,
    getRecomendedAddons,
    getPaymentTransaction,
};

function clearData(){
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.CLEAR_CLIENT_DATA,
            data: null
        }));
    };
};

function getSubscriptionAddons() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.SUBSCRIPTION_ADDONS_REQUEST,
            data: null
        }));
        clientsServices.getSubscriptionAddons()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.SUBSCRIPTION_ADDONS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.SUBSCRIPTION_ADDONS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.SUBSCRIPTION_ADDONS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getPaymentTransaction() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.GET_PAYMENT_TRANSACTION_REQUEST,
            data: null
        }));
        clientsServices.getPaymentTransaction()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.GET_PAYMENT_TRANSACTION_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.GET_PAYMENT_TRANSACTION_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.GET_PAYMENT_TRANSACTION_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function addSubscriptionAddons(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.ADD_SUBSCRIPTION_ADDONS_REQUEST,
            data: null
        }));
        clientsServices.addSubscriptionAddons(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_SUBSCRIPTION_ADDONS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_SUBSCRIPTION_ADDONS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.ADD_SUBSCRIPTION_ADDONS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function removeSubscriptionAddons(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.REMOVE_SUBSCRIPTION_ADDONS_REQUEST,
            data: null
        }));
        clientsServices.removeSubscriptionAddons(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.REMOVE_SUBSCRIPTION_ADDONS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.REMOVE_SUBSCRIPTION_ADDONS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.REMOVE_SUBSCRIPTION_ADDONS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getRecomendedAddons() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.RECOMENDED_ADDONS_REQUEST,
            data: null
        }));
        clientsServices.getRecomendedAddons()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.RECOMENDED_ADDONS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.RECOMENDED_ADDONS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.RECOMENDED_ADDONS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getSyncSettings() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.GET_SYNCSETTINGS_REQUEST,
            data: null
        }));
        clientsServices.getSyncSettings()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.GET_SYNCSETTINGS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.GET_SYNCSETTINGS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.GET_SYNCSETTINGS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function clientDetail() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.CLIENT_DETAIL_REQUEST,
            data: null
        }));
        clientsServices.clientDetail()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.CLIENT_DETAIL_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.CLIENT_DETAIL_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.CLIENT_DETAIL_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function updateclientDetail(data:any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.UPDATE_CLIENT_DETAIL_REQUEST,
            data: null
        }));
        clientsServices.updateclientDetail(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.UPDATE_CLIENT_DETAIL_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.UPDATE_CLIENT_DETAIL_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.UPDATE_CLIENT_DETAIL_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function addClientDetail(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.ADD_CLIENT_DETAIL_REQUEST,
            data: null
        }));
        clientsServices.addClientDetail(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_CLIENT_DETAIL_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_CLIENT_DETAIL_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.ADD_CLIENT_DETAIL_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function upadateSyncSettingsDetail(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.UPDATE_SYNCSETTINGS_REQUEST,
            data: null
        }));
        clientsServices.upadateSyncSettingsDetail(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.UPDATE_SYNCSETTINGS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.UPDATE_SYNCSETTINGS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.UPDATE_SYNCSETTINGS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getEDetails() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.GET_EDETAILS_REQUEST,
            data: null
        }));
        clientsServices.getEDetails()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.GET_EDETAILS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.GET_EDETAILS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.GET_EDETAILS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getDevicesList() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.DEVICES_LIST_REQUEST,
            data: null
        }));
        clientsServices.getDevicesList()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DEVICES_LIST_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DEVICES_LIST_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.DEVICES_LIST_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function addDevicesList(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.ADD_DEVICES_REQUEST,
            data: null
        }));
        clientsServices.addDevicesList(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_DEVICES_SUCCESS,
                            data: response
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_DEVICES_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.ADD_DEVICES_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function addAddressList(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.ADD_DEVICES_REQUEST,
            data: null
        }));
        clientsServices.addAddressList(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADDRESS_BOOK_ADD_SUCCESS,
                            data: response
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADDRESS_BOOK_ADD_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.ADDRESS_BOOK_ADD_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function deleteDevice(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.DELETE_DEVICE_REQUEST,
            data: null
        }));
        clientsServices.deleteDevice(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DELETE_DEVICE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DELETE_DEVICE_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.DELETE_DEVICE_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function clinetUpdateDevice(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.CLINET_UPDATE_DEVICE_REQUEST,
            data: null
        }));
        clientsServices.clinetUpdateDevice(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.CLINET_UPDATE_DEVICE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.CLINET_UPDATE_DEVICE_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.CLINET_UPDATE_DEVICE_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getVerifiedNumbers() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.VERIFIED_NUMBERS_REQUEST,
            data: null
        }));
        clientsServices.getVerifiedNumbers()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.VERIFIED_NUMBERS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.VERIFIED_NUMBERS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.VERIFIED_NUMBERS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function deleteVerifiedNumber(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.DELETE_VERIFIED_NUMBERS_REQUEST,
            data: null
        }));
        clientsServices.deleteVerifiedNumber(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DELETE_VERIFIED_NUMBERS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DELETE_VERIFIED_NUMBERS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.DELETE_VERIFIED_NUMBERS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}
function addSendOtpList(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.ADD_OTP_VERIFIED_NUMBERS_REQUEST,
            data: null
        }));
        clientsServices.addSendOtpList(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_OTP_VERIFIED_NUMBERS_SUCCESS,
                            data: response
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_OTP_VERIFIED_NUMBERS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.ADD_OTP_VERIFIED_NUMBERS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function addVerifyNumberList(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.ADD_VERIFIED_NUMBERS_REQUEST,
            data: null
        }));
        clientsServices.addVerifiedNumber(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_VERIFIED_NUMBERS_SUCCESS,
                            data: response
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADD_VERIFIED_NUMBERS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.ADD_VERIFIED_NUMBERS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function createBlockedNumbers(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.CREATE_BLOCKED_NUMBER_REQUEST,
            data: null
        }));
        clientsServices.createBlockedNumbers(data)
            .then(
                (response:any) => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.CREATE_BLOCKED_NUMBER_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.CREATE_BLOCKED_NUMBER_FAILURE,
                            data: response.message
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.CREATE_BLOCKED_NUMBER_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function updateBlockedNumbers(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.UPDATE_BLOCKED_NUMBER_REQUEST,
            data: null
        }));
        clientsServices.updateBlockedNumbers(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.UPDATE_BLOCKED_NUMBER_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.UPDATE_BLOCKED_NUMBER_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.UPDATE_BLOCKED_NUMBER_FAILURE,
                        data: error
                    }));
                }
            );
    };
}

function deleteBlockedNumbers(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.DELETE_BLOCKED_NUMBER_REQUEST,
            data: null
        }));
        clientsServices.deleteBlockedNumbers(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DELETE_BLOCKED_NUMBER_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DELETE_BLOCKED_NUMBER_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.DELETE_BLOCKED_NUMBER_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getBlockedNumbers() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.BLOCKED_NUMBERS_REQUEST,
            data: null
        }));
        clientsServices.getBlockedNumbers()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.BLOCKED_NUMBERS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.BLOCKED_NUMBERS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.BLOCKED_NUMBERS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getTickets() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.TICKETS_REQUEST,
            data: null
        }));
        clientsServices.getTickets()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.TICKETS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.TICKETS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.TICKETS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getVoiceMailList() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.VOICE_MAIL_LIST_REQUEST,
            data: null
        }));
        clientsServices.getVoiceMailList()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.VOICE_MAIL_LIST_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.VOICE_MAIL_LIST_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.VOICE_MAIL_LIST_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function voiceMailCreate(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.VOICE_MAIL_CREATE_REQUEST,
            data: null
        }));
        clientsServices.voiceMailCreate(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.VOICE_MAIL_CREATE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.VOICE_MAIL_CREATE_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.VOICE_MAIL_CREATE_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function deleteVoiceMail(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.DELETE_VOICE_MAIL_REQUEST,
            data: null
        }));
        clientsServices.deleteVoiceMail(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DELETE_VOICE_MAIL_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.DELETE_VOICE_MAIL_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.DELETE_VOICE_MAIL_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getAddressBookList(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.ADDRESS_BOOK_LIST_REQUEST,
            data: null
        }));
        clientsServices.getAddressBookList(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADDRESS_BOOK_LIST_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ADDRESS_BOOK_LIST_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.ADDRESS_BOOK_LIST_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getCallLogs(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.CALL_LOGS_REQUEST,
            data: null
        }));
        clientsServices.getCallLogs(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.CALL_LOGS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.CALL_LOGS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.CALL_LOGS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getSmsLogs(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.SMS_LOGS_REQUEST,
            data: null
        }));
        clientsServices.getSmsLogs(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.SMS_LOGS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.SMS_LOGS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.SMS_LOGS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getOutboundProviders(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.OUT_BOUND_PROVIDERS_REQUEST,
            data: null
        }));
        clientsServices.getOutboundProviders(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.OUT_BOUND_PROVIDERS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.OUT_BOUND_PROVIDERS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.OUT_BOUND_PROVIDERS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}


function getAccountLogs(data: any) {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.ACCOUNT_LOGS_REQUEST,
            data: null
        }));
        clientsServices.getAccountLogs(data)
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ACCOUNT_LOGS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.ACCOUNT_LOGS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.ACCOUNT_LOGS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getUsage() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.USAGE_REQUEST,
            data: null
        }));
        clientsServices.getUsage()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.USAGE_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.USAGE_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.USAGE_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}

function getSipcredentials() {
    return (dispatch: any) => {
        dispatch(dispatchFunction({
            type: clientsConstants.SIP_CREDENTIALS_REQUEST,
            data: null
        }));
        clientsServices.getSipcredentials()
            .then(
                response => {
                    if (response.status) {
                        dispatch(dispatchFunction({
                            type: clientsConstants.SIP_CREDENTIALS_SUCCESS,
                            data: response.data
                        }));
                    } else {
                        dispatch(dispatchFunction({
                            type: clientsConstants.SIP_CREDENTIALS_FAILURE,
                            data: response
                        }));
                    }
                },
                error => {
                    dispatch(dispatchFunction({
                        type: clientsConstants.SIP_CREDENTIALS_FAILURE,
                        data: error.message
                    }));
                }
            );
    };
}




function dispatchFunction(data: any) {
    // To do: check for refresh / unauthorized error
    return {
        type: data.type,
        data: data.data
    };
}