import { VendorReportsConstants, status } from '../Constants';

export function VendorReportsusers(state = {}, action: any) {
    switch (action.type) {
        case VendorReportsConstants.SEARCH_VENDORREPORTS_REQUEST:
            return {
                ...state,
                search_vendorReport_status: status.IN_PROGRESS,
                searchVendorReportList: action.data
            };
        case VendorReportsConstants.SEARCH_VENDORREPORTS_SUCCESS:
            return {
                ...state,
                search_vendorReport_status: status.SUCCESS,
                searchVendorReportList: action.data
            };
        case VendorReportsConstants.SEARCH_VENDORREPORTS_FAILURE:
            return {
                ...state,
                search_vendorReport_status: status.FAILURE,
                searchVendorReportList: null
            };
        default:
            return state;
    }
}
