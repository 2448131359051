import {  status,AllCallsConstants } from '../Constants';

export function allCallsUsers(state = {}, action: any) {
    switch (action.type) {
        case AllCallsConstants.SEARCH_ALLCALL_REQUEST:
            return {
                ...state,
                search_allCall_status: status.IN_PROGRESS,
                searchAllCallList: action.data
            };
        case AllCallsConstants.SEARCH_ALLCALL_SUCCESS:
            return {
                ...state,
                search_allCall_status: status.SUCCESS,
                searchAllCallList: action.data
            };
        case AllCallsConstants.SEARCH_ALLCALL_FAILURE:
            return {
                ...state,
                search_allCall_status: status.FAILURE,
                searchAllCallList: null
            };
        default:
            return state;
    }
}