import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, CardContent, Divider, Typography } from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { useStyles } from '../styles';
import SubscribedAddons from './SubscribedAddons/SubscribedAddons';
import RecomendedAddons from './RecomendedAddons/RecomendedAddons';
import CrmSubscribedAddons from "./SubscribedAddons/CrmSubscribedAddon"
import CrmRecomendedAddons from "./RecomendedAddons/CrmRecommendedAddon"

function Plans(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleChange = () => {
        setOpen(!open);
    };

    React.useEffect(() => {
        setOpen(true)
    },[props.getSyncSettings?.isCrm])


    return (
        <div id="plans">
            <Accordion 
            expanded={open}
            onChange={handleChange} className={classes.accordion} style={{marginTop: 25, borderRadius: 10,background: '#FFFFFF',boxShadow: '0 0 0.5em #E2E2E2',}}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>Plans</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        {props.getSyncSettings.isCrm ? <CrmSubscribedAddons/> : <SubscribedAddons /> }
                    </CardContent>
                </AccordionDetails>
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                    {props.getSyncSettings.isCrm ? "" : <RecomendedAddons />}
                    </CardContent>
                </AccordionDetails>

            </Accordion>
        </div>
    )
}

export default Plans
