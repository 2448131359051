import {
  Button,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { InputAdornment } from '@material-ui/core';
import React from "react";
import { useStyles } from "./styles";
import { useHistory } from 'react-router-dom'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import './auth.css'
import * as gloalActionCreator from '../../store/Actions/popup-actionCreator'
import { useDispatch  } from 'react-redux'
import authDataServices from '../../components-services/Auth/Auth'

function UpdatePassword() {
    const classes = useStyles();
    const history: any = useHistory();
    const dispatch: any = useDispatch()
    const [showPw1, setshowPw1] = React.useState(false)
    const [showPw2, setshowPw2] = React.useState(false)
    const [showPw3, setshowPw3] = React.useState(false)

    const [passData, setPassData] = React.useState({
      oldPassword: "",
      password: "",
      confirmPassword: ""
    })

    const habdleshowPW3 = () => {
      setshowPw3(!showPw3)
    }
    const habdleshowPW2 = () => {
      setshowPw2(!showPw2)
    }

    const habdleshowPW1 = () => {
      setshowPw1(!showPw1)
    }
    const onChangeEvent = (e: any) => {
      setPassData((prev: any) => {
        return {
          ...prev,
          [e.target.name] : e.target.value
        }
      })
    }

    const onSubmitForm = () => {
      const { oldPassword, confirmPassword, password } = passData
      if (oldPassword === "" && confirmPassword === "" && password === ""){
        dispatch(gloalActionCreator.initglobalPopup("Please fill all the fields", "UPDATE_PASSWORD", "info"))
        return
      }

      if (confirmPassword !== password) {
        dispatch(gloalActionCreator.initglobalPopup("Passwords not matched", "UPDATE_PASSWORD", "info"))
        return
      }

      authDataServices.changePassword("/accounts/update-password", passData).then((res: any) => {
        if(res.success){
          dispatch(gloalActionCreator.initglobalPopup("Passwords changed successfully", "UPDATE_PASSWORD", "success"))
          setPassData((prev: any) => {
            return {
              ...prev,
              oldPassword: "",
              confirmPassword: "",
              password: ""
            }
          })
          history.push("/myaccount")
        }else{
          dispatch(gloalActionCreator.initglobalPopup(res.message, "UPDATE_PASSWORD", "error"))

        }
      }).catch((err: any) => {
        dispatch(gloalActionCreator.initglobalPopup(err.message, "UPDATE_PASSWORD", "info"))
      })
    }

    return (
        <div className="sign_wrapper">
      <div >
        <Typography className={classes.mainHeader} >Change Password</Typography>
        <Typography className={classes.subHeader}  >Please enter new and confirm password</Typography>
      </div>
      <div className="sign_form_container">
        {/* <form > */}
          <div style={{ marginTop: -20 }}>
            <Typography className={classes.newPass}>Old Password:</Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type={showPw1 ? "text" : "password"}
              name="oldPassword"
              value={passData.oldPassword}
              onChange={onChangeEvent}
              placeholder="Enter new password"
              className={classes.textField}
              //error={!errors.password.isValid}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showPw1 ? <FiEye onClick={habdleshowPW1} style={{cursor: 'pointer'}} /> :
                      <FiEyeOff onClick={habdleshowPW1} style={{cursor: 'pointer'}}/>}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ marginTop: -20 }}>
            <Typography className={classes.newPass}>New Password:</Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type={showPw2 ? "text" : "password"}
              name="password"
              value={passData.password}
              onChange={onChangeEvent}
              placeholder="Enter new password"
              className={classes.textField}
              //error={!errors.password.isValid}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showPw2 ? <FiEye onClick={habdleshowPW2} style={{cursor: 'pointer'}} /> :
                      <FiEyeOff onClick={habdleshowPW2} style={{cursor: 'pointer'}}/>}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <Typography className={classes.conPass}>Confirm Password:</Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type={showPw3 ? "text" : "password"}
              name="confirmPassword"
              value={passData.confirmPassword}
              onChange={onChangeEvent}
              placeholder="Re-enter password"
              className={classes.textField}
              //error={!errors.confirmPassword.isValid}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showPw3 ? <FiEye onClick={habdleshowPW3} style={{cursor: 'pointer'}}/> :
                      <FiEyeOff onClick={habdleshowPW3} style={{cursor: 'pointer'}} />}
                  </InputAdornment>
                ),
              }}
            />

            {
            //   !errors.confirmPassword.isValid &&
            //   <p className={classes.errorMessage}>{errors.confirmPassword.message}</p>
            }
          </div>



          <div className={classes.buttons}>
            <Button type="submit" size="small"
              className={classes.submitButton} style={{ width: '50%' }}
              onClick={onSubmitForm}
            >
              Save</Button>
            <Button type="submit" size="small" variant="outlined" className={classes.backbutton}
              onClick={() => history.push('/myaccount')} style={{ width: '50%' }}>Back</Button>
          </div>
         
      </div>

    </div>

    )
}

export default UpdatePassword
