import {
    Card, CardContent, Grid, Table, TableContainer, TableHead, TableRow,
    TableCell, Typography, TableBody, Button,
  } from "@material-ui/core";
  import React from "react";
  import TextareaAutosize from "@material-ui/core/TextareaAutosize";
  import AttachFileIcon from "@material-ui/icons/AttachFile";
  import Radio, { RadioProps } from "@material-ui/core/Radio";
  import RadioGroup from "@material-ui/core/RadioGroup";
  import FormControlLabel from "@material-ui/core/FormControlLabel";
  import FormControl from "@material-ui/core/FormControl";
  import { useStyles } from "./style";
  import { useDispatch } from "react-redux";
  import axiosInstance from '../../environment/axiosInstances'
  import Moment from 'moment'
  import { useHistory } from "react-router-dom";
  import Modal from '@material-ui/core/Modal';
  import * as popupActionCreators from '../../store/Actions/popup-actionCreator'
  import axios from 'axios'
  import { Link } from 'react-router-dom';
  import parse from 'html-react-parser';
  
  
  function EfoneSupport(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [ticketData, setTicketData] = React.useState<any>([]);
    const [selectedFile, setSelectedFile] = React.useState<any>("")
    const [value, setValue] = React.useState<any>("1");
    const [commentData, setCommentData] = React.useState<any>([]);
    const [ticketsModal, setTicketsModal] = React.useState(false);
    const [sendComment, setSendCommentData] = React.useState<any>({
      comment: "",
      internalStatus: 1,
      file: null
    });
    const [loading, setLoading] = React.useState(false)
  
    React.useEffect(() => {
      //getTicketData()
      getCommentData()
    }, [])
  
  
    // const getTicketData = React.useCallback(() => {
    //   setLoading(true);
    //   axiosInstance.get(`/tickets/${props.match.params.id}`).then((res: any) => {
    //     if (res.data.data) {
    //       setTicketData(res.data.data)
    //     } else {
    //       setLoading(false)
    //     }
    //   }).catch((err: any) => {
    //     setLoading(false)
    //   })
    // }, [])
  
    const getCommentData = React.useCallback(() => {
      setLoading(true);
      axiosInstance.get(`/tickets/${props.match.params.id}/comments`).then((res: any) => {
        if (res.data.data) {
          setCommentData(res.data.data)
        } else {
          setLoading(false)
        }
      }).catch((err: any) => {
        setLoading(false)
      })
    }, [])
  
    const onFileChange = (e: any) => {
      const files = e.target.files[0]
      setSelectedFile(files)
    }
  
    const onContentCommentChangeEvent = (e: any) => {
      setSendCommentData((prev: any) => {
        return {
          ...sendComment,
          [e.target.name]: e.target.value,
        }
      })
    }
  
    const handleChangeData = (e: any) => {
      setValue(e.target.value)
      setSendCommentData((prev: any) => {
        return {
          ...sendComment,
          internalStatus: e.target.value === "1" ? 1 : 2,
        }
      })
    }
  
    const onTicketModal = () => {
      setTicketsModal(true);
    }
  
    const handleCancel = () => setTicketsModal(false);
  
    const onsubmitComment = () => {
      let formData = new FormData();
      formData.append('comment', sendComment.comment);
      if (selectedFile) {
        formData.append('file', selectedFile, selectedFile.name);
      }
      //formData.append('internalStatus', sendComment.internalStatus);
      axiosInstance.post(`/tickets/${props.match.params.id}/comments`, formData).then((res: any) => {
        if (res.data.data) {
          dispatch(popupActionCreators.initglobalPopup(res.data.message, "UPDATE_AGENT_SUCCESS", 'success'))
          window.location.reload()
        } else {
          setLoading(false)
          props.popUpAlert(res.data.message, "UPDATE_AGENT_FAIL", "error");
        }
      }).catch((err: any) => {
        setLoading(false)
        dispatch(popupActionCreators.initglobalPopup(err.response.data.message, "UPDATE_AGENT_FAIL", 'error'))
      })
    }
  
    const handleYes = () => {
      axiosInstance.patch(`/tickets/${props.match.params.id}/close`).then((res: any) => {
        if (res.data.data) {
          dispatch(popupActionCreators.initglobalPopup(res.data.message, "UPDATE_AGENT_SUCCESS", 'success'))
          history.push('/tickets')
        } else {
          setLoading(false)
          props.popUpAlert(res.data.message, "UPDATE_AGENT_FAIL", "error");
        }
      }).catch((err: any) => {
        setLoading(false)
        props.popUpAlert(err.response.data.message, "UPDATE_AGENT_FAIL", "error");
      })
    }
  
  
    var dueDate = parseInt(ticketData.dueDate)
    var DueDATE = Moment(dueDate).format('YYYY-MM-DD HH:MM:SS')
    var remainderDate = parseInt(ticketData.remainderDate)
    var RemainderDATE = Moment(remainderDate).format('YYYY-MM-DD HH:MM:SS')
  
    return (
      <div>
        <Card>
          <CardContent>
            <div className={classes.mainDiv}>
              <div className={classes.subDiv}>
                <Typography className={classes.efoneSupport}>
                  eFone Support
                </Typography>
              
              </div>
              <div>
                <Typography className={classes.ticketId}>
                  Ticket Id ({props.match.params.id})
                </Typography>
              </div>
            </div>
            {/* <Grid container className={classes.grid}>
              <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
              <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                <TableContainer className={classes.tableStyle}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Subject
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Client
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Department
                        </TableCell>
                        <TableCell className={classes.tableHead}>Agent</TableCell>
                        <TableCell className={classes.tableHead}>
                          Due Date
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Remainder Date{" "}
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Ticket Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableCell className={classes.tableBody}>{ticketData.subject}</TableCell>
                      <TableCell className={classes.tableBodyOne}>{ticketData.accountId}</TableCell>
                      <TableCell className={classes.tableBodyOne}>{ticketData.departmentId}</TableCell>
                      <TableCell className={classes.tableBodyOne}>{ticketData.agentId}</TableCell>
                      <TableCell className={classes.tableBodyOne}>{DueDATE}</TableCell>
                      <TableCell className={classes.tableBodyOne}>{RemainderDATE}</TableCell>
                      <TableCell className={classes.tableBodyOne}>
                        {ticketData.ticketStatus === 1 ? <div className={classes.pendingButton}>Pending</div> : ticketData.ticketStatus === 2 ? <div className={classes.pendingButton}>Review</div> : ticketData.ticketStatus === 3 ? <div className={classes.closedButton}>Closed</div> : ""}</TableCell>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
             
            </Grid> */}
            <div className={classes.mainDiv}>
              <div className={classes.subDiv}>
                <Typography
                  className={classes.comments}
                  style={{ marginTop: 40 }}
                >
                  Comments
                </Typography>
              </div>
              {/* <div>
                <Typography className={classes.admin}>
                  Admin - 09/08/2021 1:33 PM
                </Typography>
              </div> */}
            </div>
            {commentData.map((item: any) => {
              var createdDate = parseInt(item.createdDate)
              var CREATEDDATE = Moment(createdDate).format('YYYY-MM-DD HH:MM:SS')
              return (
                <div>
                   <Grid container className={classes.grid}>
                    <Grid item xl={2} lg={2} md={2} sm={12} xs={12}></Grid>
                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                  <Typography
                    style={{
                      float: "right",
                      background: "#F5F5F5",
                      width: 250,
                      color: "#8392AB",
                      textAlign: "center",
                      borderRadius: "0px 50px",
                      // marginRight: 196,
                      // marginTop: 40,
                      padding: 6,
                      fontSize: 12,
                      marginBottom: 10,
                      fontWeight:600,
                    }}
                  >
                    Admin - {CREATEDDATE}
                  </Typography>
                    </Grid>
                  </Grid>
                  <div>
                    <Grid container >
                    <Grid item xl={2} lg={2} md={2} sm={12} xs={12}></Grid>
                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Card style={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)" }}>
                          <div className={classes.flexDiv}>
                            <Typography className={classes.newNumber}>
                              {parse(item.comment)}
                              </Typography>
                          </div>
                        </Card>
                        {item.filePath &&
                          <div className={classes.flexDiv} style={{ marginTop: 20, width: 100, height: 60 }}>
                            <img src={item.filePath} alt="" />
                          </div>
                        }
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )
            })}
  
            {ticketData.ticketStatus === 3 ?
              <div>
                <Grid container className={classes.grid}>
                  <Grid item sm={2}></Grid>
                  <Grid item sm={8}>
                    <Typography className={classes.respond} style={{ backgroundColor: "antiquewhite" }}>
                      Notice: This ticket is closed,If you require additional support,please open a new ticket.
                    </Typography>
                  </Grid>
                  <Grid item sm={2}></Grid>
                </Grid>
              </div> :
              <div>
                <Grid container className={classes.grid}>
                  <Grid item sm={2}></Grid>
                  <Grid item sm={8}>
                    <Typography className={classes.respond}>Respond</Typography>
                    <TextareaAutosize
                      minRows={5}
                      maxRows={6}
                      name="comment"
                      onChange={onContentCommentChangeEvent}
                      className={classes.textArea}
                    />
                  </Grid>
                  <Grid item sm={2}></Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 13, }}>
                  <Grid item sm={8}>
                    <label style={{ float: "right" }}>{selectedFile.name}</label>
                  </Grid>
                  <Grid item sm={4}>
                    <input type="file"
                      id="myFile"
                      style={{ display: "none" }}
                      onChange={onFileChange} />
                    <label
                      htmlFor="myFile"
                      className={classes.attachmentButton}
                    >
                      <AttachFileIcon style={{ width: 20, height: 15, marginTop: 0, maxWidth: 28 }} />
                      Add Attachment
                    </label>
                  </Grid>
                </Grid>
              </div>}
  
            {ticketData.ticketStatus === 3 ? "" :
              <div style={{ display: "grid", placeItems: "center" }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    value={value}
                    onChange={handleChangeData}
                    name="customized-radios"
                  >
                    <div style={{ display: "flex" }}>
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Public"
                        color="primary"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Private"
                        color="primary"
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>}
  
            {ticketData.ticketStatus === 3 ?
              <div style={{ display: "grid", placeItems: "center", marginTop: 30 }}>
                <div>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 600,
                      margin: "0px 0px 0px 10px",
                      padding: "3px 12px",
                      backgroundColor: "lightcoral"
                    }}
                  >
                    Re-open Ticket
                  </Button>
                </div>
              </div>
              :
              <div style={{ display: "grid", placeItems: "center", margin: "30px 0" }}>
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.sendButton}
                    onClick={onsubmitComment}
                  >
                    Send Comment
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={onTicketModal}
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 600,
                      margin: "0px 0px 0px 10px",
                      padding: "6px 20px",
                      color:"#8392AB",
                      background:"rgb(244 244 244)",
                    }}
                  >
                    Close tickets
                  </Button>
                </div>
              </div>}
          </CardContent>
        </Card>
        <Modal
          open={ticketsModal}
          //style={{ maxWidth: 400, }}
        >
          <div className={classes.modal}>
            <div className={classes.modalBody}>
              <div className={classes.modalRows}>
                <p style={{ display: "block", textAlign: "center", margin: "0" }}>Are you sure you want to close the ticket?</p>
              </div>
              <div className={classes.modalRows}>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.modalSubmitButton}
                  onClick={handleYes}
                >
                  Yes
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.modalCancelButton}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
  
        </Modal>
      </div>
    );
  }
  
  export default EfoneSupport;
  