import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(() => ({
    divider: {
        backgroundColor: '#E2E2E2',
        '@media (max-width:426px)':{
            marginLeft:-10
        }
    },
    header: {
        color: '#464646',
        fontSize: 19,
        fontFamily: 'work sans',
        fontWeight: 600,
        '@media (max-width:426px)':{
            marginLeft:-10
        }
    },
    search: {
      background: "#2645AD",
      textTransform: "capitalize",
      border: "0.791301px solid #3A416F",
      borderRadius: 3,
      color: "#FFFFFF",
      padding: '5px 30px',
      fontFamily: 'work sans',
      marginRight: 10,
      fontSize: 14,
      "&:hover": {
        background: "#2645AD",
        color: "#FFFFFF",
      },
    },
    clear: {
        background: "#B70909",
        border: "0.791301px solid #8392AB",
         fontFamily: 'work sans',
        borderRadius: 3,
        textTransform: "capitalize",
        padding: '5px 30px',
        fontSize: 14,
        color: '#FFFFFF',
        "&:hover": {
            background: "#B70909",
            color: '#FFFFFF',
        },
    },
}))