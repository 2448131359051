import React from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button,
    Divider,
    CardContent,
    Grid, TextField
} from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { useStyles } from './styles';
import Select from 'react-select';
import { useDispatch } from 'react-redux'
import profileDataServices from '../../../components-services/MyAccountPage/ProfileData'
import * as globalPopupCreator from '../../../store/Actions/popup-actionCreator'

function SMSSettings(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [smsForwardNumber, setsmsForwardNumber] = React.useState<any>("")
    const [forwardEmail, setforwardEmail] = React.useState<any>("")
    const [dropdownCell, setDropdownCell] = React.useState<any>({ value: "", label: "Disabled" })
    const [cellNumberData, setCellNumberData] = React.useState<any>(null)
    const [dataChnaged, setDataChanged] = React.useState<any>(false)

    const handleChange = () => {
        setOpen(!open);
    };


    const onChangeForwardNumber = (e: any) => {
        setDataChanged(true)
        setsmsForwardNumber(e.value)
    }

    const onChangeEmail = (e: any) => {
        setDataChanged(true)
        // if (validateEmailRegex(e.target.value) === false) {
        //     dispatch(globalPopupCreator.initglobalPopup("Please provide valid email", "PROFILE_UPDATE", "info"))
        //     return
        // }
        setforwardEmail(e.target.value)
    }

    const updatedSettings = (data: any) => {
        profileDataServices.updateSetting("/accounts/settings", data).then((res: any) => {
            if (res.success) {
                props.fecthMysettings()
                setDataChanged(false)
                dispatch(globalPopupCreator.initglobalPopup("Updated successfully", "ADDON_DELETED", "success"))
            } else {
                dispatch(globalPopupCreator.initglobalPopup(res.message, "ADDON_DELETED", "error"))
            }
        }).catch((err: any) => {

        })
    }

    const validateEmailRegex = (email: any) => {
        const re: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const onSaveData = () => {
        if (validateEmailRegex(forwardEmail) === false) {
            dispatch(globalPopupCreator.initglobalPopup("Please provide valid email", "PROFILE_UPDATE", "error"))
            return
        }
        updatedSettings({ smsForwardNumber, forwardEmail })
    }

    const getMyNumbers = () => {
        profileDataServices.getMyNumbers('/accounts/mynumbers').then((res: any) => {
            if (res.success) {
                const { efoneNumber, cellNumber, otherNumbers } = res.data;
                otherNumbers.push(cellNumber)
                setCellNumberData(otherNumbers)
            } else {

            }
        }).catch((err: any) => {
        })
    }

    const dropdowndata: any = []
    if (cellNumberData) {
        cellNumberData?.map((item: any) => {
            var object = {
                "value": item.phoneNumber,
                "label": item.phoneNumber
            }
            dropdowndata.push(object)
        })
        dropdowndata.push(dropdownCell)
    }


    React.useEffect(() => {
        getMyNumbers()
    }, [])

    React.useEffect(() => {
        if (props.settings) {
            const { smsForwardNumber, smsForwardEmail } = props.settings;
            setsmsForwardNumber(smsForwardNumber)
            setforwardEmail(smsForwardEmail)
        }
    }, [props.settings])

    return (
        <div id="SMSsettings">
            <Accordion onChange={handleChange} style={{ marginTop: 20, borderRadius: 10, background: '#FFFFFF', boxShadow: '0 0 0.5em #E2E2E2', }}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Sms settings</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent >
                        <Grid container spacing={4} style={{ padding: '0px 14px' }}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.sms}>Sms Forward to cell</Typography>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    value={
                                        dropdowndata.filter((option: any) =>
                                            option.value === smsForwardNumber)
                                    }
                                    placeholder="Caller's Number"
                                    onChange={onChangeForwardNumber}
                                    className={classes.textField}
                                    options={dropdowndata}
                                />
                                {/* <TextField 
                                    variant="outlined" 
                                    //type='number'
                                    size='small' fullWidth  
                                    placeholder="Caller's Number" 
                                    onChange={onChangeForwardNumber}
                                    // name="isForwardSms"
                                    value={smsForwardNumber}
                                    className={classes.textField}
                                /> */}

                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.sms}>Sms Forward to email</Typography>
                                <TextField
                                    variant="outlined"
                                    size='small' fullWidth
                                    placeholder="Caller's Email"
                                    // name="forwardToEmail"
                                    onChange={onChangeEmail}
                                    value={forwardEmail}
                                    className={classes.textField}
                                />
                            </Grid>
                        </Grid>
                        {
                            dataChnaged && (
                                <div style={{ display: 'grid', placeItems: 'center', marginTop: '20px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Button onClick={onSaveData}
                                            style={{ background: '#2645AD', color: '#fff', padding: '5px 16px', }}
                                        >Save</Button>
                                        <Button onClick={() => {
                                            setDataChanged(false)
                                            setsmsForwardNumber(props?.settings?.smsForwardNumber)
                                            // setforwardEmail(props?.settings?.forwardEmail)
                                        }}
                                            style={{ background: '#b0b0b3', fontSize: '12px', color: '#fff', marginLeft: 8 }}
                                        >Cancel</Button>
                                    </div>
                                </div>)
                        }
                    </CardContent>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default SMSSettings

