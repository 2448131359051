import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles';
import TableBodyData from './TableBody'
import Loader from '../../../../components/Loader/Loader'
import '../../style.css'
function StripPayments(props: any) {
    const classes = useStyles();

    return (
        <div>
            <Typography className={classes.header} id="heading2"> Strip Payments</Typography>
            <Divider className={classes.divider} style={{marginTop: '15px'}}/>
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead className={classes.backgroundColor}>
                        <TableRow >
                            <TableCell className={classes.tableHead}>Invoice Number</TableCell>
                            <TableCell className={classes.tableHead}>Amount($)</TableCell>
                            <TableCell className={classes.tableHead}>Created On</TableCell>
                            <TableCell className={classes.tableHead}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                        {
                            <TableBody>
                                {
                                    props.StripPayment.length > 0 && 
                                        props.StripPayment.map((pay: any, i: any) => <TableBodyData rowData={pay} />)    
                                }
                            </TableBody>
                        }
                </Table>
                {
                    props.loading ? <div className="loader_class"> 
                        <Loader />
                    </div> : props.StripPayment.length <= 0 && <p style={{textAlign: 'center'}}>No Data</p>
                }
            </TableContainer>
        </div>
    )
}

export default StripPayments
