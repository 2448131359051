import React from 'react'
import {
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Divider,
    CardContent,
    Tabs,
    Tab,
    Grid, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TextField, Button, MenuItem,
} from '@material-ui/core'
import { useStyles } from '../styles';
import { GoKebabHorizontal } from 'react-icons/go'
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import profileServices from '../../../../components-services/MyAccountPage/ProfileData';
import Backdrop from '../../../../components/Modal/Backdrop'
import Modal from '../../../../components/Modal/Modal'
import EditBlockNumber from './EditBlockNumber';
import { useDispatch } from 'react-redux'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'
import ReactSelect from 'react-select';
import { MdEdit } from 'react-icons/md'
import { AiFillDelete } from 'react-icons/ai'


function TableBody(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const anchorRef: any = React.useRef(null);
    const [deleteModal, setDeleteModal] = React.useState<any>(false);
    const [editModal, setEditModal] = React.useState<any>(false);
    const [delId, setDelID] = React.useState<any>('')

    // const Options = [
    //     { label:1, value:1},
    //     { label:2, value:2}
    // ]


    const { phoneNumber, createdDate, blockCallType, sms, calls, _id } = props.blockNumberData


    const blockNumberDelete = () => {
        profileServices.deleteBlockNumber(`/accounts/blockednumbers/${_id}`)
            .then((res: any) => {
                if (res.success) {
                    onChangeDeleteModal()
                    props.fetchBlockNumbers()
                    dispatch(globalPopupCreator.initglobalPopup("Bloked Number deleted successfully", "SUBSCRIPTION_ADDED", "success"))

                } else {
                    //err
                    //dispatch(globalPopupCreator.initglobalPopup(res.data?.message, "SUBSCRIPTION_ADDED_ERROR", "error"))
                }
            }).catch((err: any) => {
                //err
            })
    }



    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };


    const onChangeDeleteModal = () => {
        setDeleteModal(!deleteModal)

    }

    const onChangeEditModal = () => {
        setEditModal(!editModal)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }



    return (
        <TableRow>
            {/* popupmodal start  */}

            {
                deleteModal && (
                    <>
                        <Backdrop handlePopupModal={onChangeDeleteModal} />
                        <Modal handlePopupModal={onChangeDeleteModal} title="Are you sure you want to Delete ?">
                            <div style={{ textAlign: 'center', width: 500 }} >
                                <Grid container spacing={2}>

                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.modaldeleteButton} onClick={blockNumberDelete}>Delete</Button>
                                            <Button size="medium" className={classes.deltecancelButton} onClick={onChangeDeleteModal}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </>
                )
            }
            {
                editModal && (
                    <>
                        <Backdrop handlePopupModal={onChangeEditModal} />
                        <Modal handlePopupModal={onChangeEditModal} title="Update Block Number">
                            <div style={{ width: 500 }} >
                                <EditBlockNumber handlePopupModal={onChangeEditModal}
                                    id={_id}
                                    data={props.blockNumberData} fetchBlockNumbers={props.fetchBlockNumbers} />
                            </div>
                        </Modal>
                    </>
                )
            }
            {/* popupmodal End  */}

            <TableCell className={classes.tableData}>{phoneNumber}</TableCell>
            <TableCell className={classes.tableData}>
                <div className={classes.buttonDIv}>
                    <Button disabled size="small" variant="outlined" style={{ color: '#fff' }} className={sms ? classes.callButton : classes.smsButton}>
                        sms
                    </Button>
                    <Button disabled size="small" variant="outlined" style={{ color: '#fff' }} className={calls ? classes.callButton : classes.smsButton}>
                        Calls
                    </Button>
                </div>
            </TableCell>
            <TableCell className={classes.tableData}>{calls ? blockCallType === 1 ? "Hangup" : "Voice mail" : ""}</TableCell>
            <TableCell className={classes.tableData}> {new Date(Number(createdDate)).toLocaleString()}</TableCell>
            <TableCell>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}

                >
                    <GoKebabHorizontal style={{ fontSize: 20, color: '#787878' }} />
                </Button>

            </TableCell>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem onClick={onChangeEditModal}>
                                        <div className={classes.dFlex}>
                                            <MdEdit color="#C4C4C4" />
                                            <Typography className={classes.edit}>Edit</Typography>
                                        </div>
                                    </MenuItem>
                                    <MenuItem onClick={onChangeDeleteModal} >
                                        <div className={classes.dFlex}>
                                            <AiFillDelete color="#C4C4C4" />
                                            <Typography className={classes.edit}>Delete</Typography>
                                        </div>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </TableRow>

    )
}

export default TableBody;
