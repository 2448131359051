import React, { createContext } from 'react'
import BillingInformation from './BillingInformation/BillingInformation'
import Plans from './Plans/Plans'
import billingServicesData from '../../components-services/Billing/Billing'
import { connect, useDispatch } from "react-redux";
import { status } from '../../store/Constants';
import { clientsActions } from "../../store/Actions";
import * as popupActionCreators from '../../store/Actions/popup-actionCreator'
export const Dataprovider: any = createContext(null)

function Index(props: any) {
    const dispatch = useDispatch();
    const [subscriptionAddons, setSubscrptionAddons] = React.useState<any>([])
    const [recommendedAddons, setRecomendedAddons] = React.useState<any>([])
    const [getSyncSettings, setgetSyncSettings] = React.useState<any>({})

    const fetchSubscriptionAddons = () => {
        billingServicesData.getSubriptionAddons("/accounts/addons").then((res: any) => {
            if(res.success){
                setSubscrptionAddons(res.data.data)
            }else{

            }
        }).catch((err: any) => {

        })
    }

    const fetchRecommendedAddons = () => {
        billingServicesData.getRecommandedAddons("/accounts/recomended-addons").then((res: any) => {
            if (res.success) {
                setRecomendedAddons(res.data.data)
                //subscribedAddons
            } else {

            }
        }).catch((err: any) => {

        })
    }
    
    React.useEffect(() => {
        fetchSubscriptionAddons()
        dispatch(clientsActions.getSyncSettings());
        fetchRecommendedAddons()
    }, [])

    React.useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    React.useEffect(() => {
        if (props.get_syncsettings_status === status.SUCCESS) { 
            setgetSyncSettings(props.getsyncsettingsData.data)   
        } else if (props.get_syncsettings_status === status.FAILURE) {
          dispatch(popupActionCreators.initglobalPopup(props.getsyncsettingsData, '', 'error'));
        }
      }, [props.get_syncsettings_status]);
    
      console.log("cdcndkjcnk",getSyncSettings)

    return (
        <div>
            {getSyncSettings.isCrm ? "":
            <BillingInformation />}
            <Dataprovider.Provider value={{ fetchSubscriptionAddons, fetchRecommendedAddons, subscriptionAddons, recommendedAddons }}>
                <Plans
                    subscriptionAddons={subscriptionAddons}
                    recommendedAddons={recommendedAddons}
                    getSyncSettings={getSyncSettings}
                    fetchSubscriptionAddons={fetchSubscriptionAddons}
                    fetchRecommendedAddons={fetchRecommendedAddons}
                />
            </Dataprovider.Provider>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    const { get_syncsettings_status, getsyncsettingsData} = state.clientDetailState;
    return {
      get_syncsettings_status,
      getsyncsettingsData,
    };
  }
  
  export default connect(mapStateToProps, null)(Index);

