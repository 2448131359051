import { Button, Grid, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useStyles } from '../styles'
import moment from 'moment'
import { BsArrow90DegDown, BsArrow90DegUp } from 'react-icons/bs'
import { MdCallMissed } from 'react-icons/md'
import { FiTrendingUp } from 'react-icons/fi'
import ReactAudioPlayer from 'react-audio-player';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import RecordVoiceOver from '@mui/icons-material/RecordVoiceOver'
import Moment from 'moment'
import {AiTwotoneDelete} from 'react-icons/ai'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
import axiosInstance from '../../../../environment/axiosInstances'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'
import { useDispatch } from 'react-redux'


function TableBody(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const {id, createdDate, destinationNumber, fullName, number, callType, callRecording, callAction, callResult } = props.rowData
    const [DeleteModal, setDeleteModal] = React.useState(false)
    const [delId, setDelID] = React.useState<any>('')

    

     const deleteRecord = () => {
        axiosInstance.delete(`/users/delete-recording/${id}`).then((res: any) => {
            if (res.data) {
                setDeleteModal(!DeleteModal)
                props.fetchCallLogs(props.paginate.limit, props.paginate.page)
                dispatch(globalPopupCreator.initglobalPopup("Recording deleted sucessfully", "ADDON_DELETED", "success")) 
            } else {
                dispatch(globalPopupCreator.initglobalPopup(res.data?.message, "ADDON_DELETED_FAIL", "error"))
            }
        }).catch((err: any) => {
            dispatch(globalPopupCreator.initglobalPopup(err?.message, "ADDON_DELETED_FAIL", "error"))
        })
    }

    const handlePopupModalDelete = (id: any) => {
        // setDelID(id)
        setDeleteModal(!DeleteModal)
    }
    return (
        <>
                {
                    DeleteModal && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupModalDelete} />
                            <Modal title="Are you sure want you delete this record ?" handlePopupModal={handlePopupModalDelete} >
                                <Grid container spacing={2} style={{ margin: 15 }}>
                                    <div className="add_user_popup" style={{ width: "500px" }}>
                                        <Grid item md={12} style={{ textAlign: 'center' }}>
                                            <div>
                                                <Button size="medium" className={classes.deleteButtonNew} onClick={deleteRecord} >Delete</Button>
                                                <Button size="medium" onClick={handlePopupModalDelete}  className={classes.CancelButtonNew}>Cancel</Button>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Modal>
                        </div>
                    )
                }
                {/* {
                    DeleteModal && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupModalDelete} />
                            <Modal title="Are you sure want you delete this record ?" handlePopupModal={handlePopupModalDelete} >
                                <Grid container spacing={2} style={{ margin: 15 }}>
                                    <div className="add_user_popup" style={{ width: "500px" }}>
                                        <Grid item md={12} style={{ textAlign: 'center' }}>
                                            <div>
                                                <Button size="medium" className={classes.deleteButtonNew} onClick={deleteRecord} >Delete</Button>
                                                <Button size="medium" onClick={handlePopupModalDelete}  className={classes.CancelButtonNew}>Cancel</Button>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Modal>
                        </div>
                    )
                } */}
            <TableRow>
                <TableCell className={classes.tableData}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {callType == 1 && <BsArrow90DegUp color='green' />}
                        {callType == 2 && <MdCallMissed color='red' />}
                        {callType == 3 && <BsArrow90DegDown color='blue' />}
                        {callType === 6 && <FiTrendingUp color="#00A5AF" />}
                        {destinationNumber !== undefined ? <p style={{ margin: '0' }}>{destinationNumber}</p> : ""}
                    </div>

                </TableCell>
                <TableCell className={classes.tableData}>{fullName !== undefined ? fullName : ""}</TableCell>
                {/* <TableCell className={classes.tableData}>{createdDate !== undefined ? moment(Number(createdDate)).format('MMM Do Y, h:mm a') : ""}</TableCell> */}
                <TableCell className={classes.tableData}>{createdDate ? <div>{Moment(Number(createdDate)).format('YYYY-MM-DD HH:MM:SS')}</div> : ""}</TableCell>
                <TableCell className={classes.tableData} style={{textAlign:"center"}}>
                    {
                        props.rowData.recordedFilePath && props.rowData.recordedFilePath !== "" ?
                        props.rowData.isVoiceMail ?
                        
                        <div style={{display:'flex',alignItems: 'center',cursor: 'pointer'}}>
                            <VoicemailIcon style={{ cursor: "pointer", color: "black" }} onClick={() => props.handlechangeRecModal(props.rowData.isVoiceMail, props.rowData.recordedFilePath)} />
                             &nbsp;<AiTwotoneDelete color="red" onClick={handlePopupModalDelete}/>
                        </div> :
                        <div style={{display:'flex',alignItems: 'center',cursor: 'pointer'}}>
                            <RecordVoiceOver style={{ cursor: "pointer", color: "black" }} onClick={() => props.handlechangeRecModal(props.rowData.isVoiceMail, props.rowData.recordedFilePath)} /> 
                            &nbsp;<AiTwotoneDelete color="red" onClick={handlePopupModalDelete}/>
                        </div> : ""
                    }
                </TableCell>
                <TableCell className={classes.tableData}>{callAction !== undefined ? callAction : ""}</TableCell>
                <TableCell className={classes.tableData}>{callResult !== undefined ? callResult : ""}</TableCell>
            </TableRow>
        </>
    )
}

export default TableBody
