import { Button, TextField } from '@material-ui/core'
import React from 'react'
import { useStyles } from "./styles";
import profileDataServices from '../../../components-services/MyAccountPage/ProfileData'
import * as globalPopupCreator from '../../../store/Actions/popup-actionCreator'
import { useDispatch } from 'react-redux'

function AddNumber(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [number, setNumbers] = React.useState<any>({ num: "" })
    const [validateOtpUI, setValidateOtpUI] = React.useState(false)
    const [otp, setOtp] = React.useState<any>("")
    const [isValidotp, setIsValidotp] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false);
    const gobackToNumber = () => {
        setValidateOtpUI(false)
        setOtp("")
    }
    const onChangeData = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            setNumbers((prev: any) => {
                return {
                    num: val.replace(/[^0-9,.]+/g, "")
                }
            })
        }

    }

    const onChangeOTP = (e: any) => {
        setOtp(e.target.value)
    }
    const addNumber = () => {
        setSubmitted(true);
        const errorData = validate(true);
        if (errorData.isValid) {

            profileDataServices.addMyNumbers('/accounts/mynumbers', { phoneNumber: number.num }).then((res: any) => {
                if (res.success) {
                    setValidateOtpUI(true)
                    dispatch(globalPopupCreator.initglobalPopup("OTP sent", "ADDON_DELETED", "success"))
                } else {
                    dispatch(globalPopupCreator.initglobalPopup(res.message, "ADDON_DELETED", "success"))
                }
            }).catch((err: any) => {

            })


        }
    }

    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            newnumber: { ...validData },
        };
        let isValid = true;
        if (submitted) {
            if (number.num === "") {
                console.log("1111")
                retData.newnumber = {
                    isValid: false,
                    message: "Please enter phone number"
                };
                isValid = false;
            }
            else if (number.num.length < 10) {
                retData.newnumber = {
                    isValid: false,
                    message: "Phone Number should be less than 10 digits"
                };
                isValid = false;
            }
            else if (number.num.length > 15) {
                retData.newnumber = {
                    isValid: false,
                    message: "Phone Number should not be greater than 15 digits"
                };
                isValid = false;
            }



        }
        return {
            ...retData,
            isValid
        };
    };

    const validateOTPPost = () => {
        if (otp !== "" && otp.length > 3) {
            profileDataServices.verifyMyNumbersOtp('/accounts/mynumbers/verify-otp', { phoneNumber: number.num, otp: otp }).then((res: any) => {

                if (res.success) {
                    props.getMyNumbers()
                    setNumbers("")
                    setValidateOtpUI(false)
                    setOtp("")
                    setIsValidotp(false)
                    props.popupaddNumberHandler()
                    props.fecthMysettings()
                    dispatch(globalPopupCreator.initglobalPopup("Number Added successfully", "ADDON_DELETED", "success"))
                } else {
                    props.getMyNumbers()
                    dispatch(globalPopupCreator.initglobalPopup(res.message, "ADDON_DELETED", "info"))
                }
            }).catch((err: any) => {

            })

        } else {
            //err
        }
    }
    const errors = validate(submitted);
    if (validateOtpUI) {
        return (
            <div>
                <div>
                    <TextField error={isValidotp} onChange={onChangeOTP} name="otp" value={otp} variant="outlined" label="Enter OTP" size="small" placeholder="Enter OTP" className={classes.textField} />
                </div>
                <div style={{ textAlign: 'center', marginTop: 10 }}>
                    <Button onClick={validateOTPPost} style={{ background: '#2645AD', marginRight: '10px', color: '#FFFFFF', fontSize: 12, padding: '7px 15px' }}>Validate OTP</Button>
                    <Button onClick={gobackToNumber} style={{ background: '#ccc', color: '#585858', fontSize: 12, padding: '7px 15px' }}>Back</Button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className={classes.modalFlex}>
                <div>
                    <TextField onChange={onChangeData} value={number.num} variant="outlined" size="small" placeholder="Number" className={classes.textField}
                        error={!errors.newnumber.isValid}
                    />
                    {
                        !errors.newnumber.isValid &&
                        <p className={classes.errorMessage}>{errors.newnumber.message}</p>
                    }
                </div>
                <Button onClick={addNumber} style={{ background: '#2645AD', marginLeft: 10, color: '#FFFFFF', fontSize: 12, padding: '7px 20px' }}>Add</Button>
            </div>
        </div>
    )
}

export default AddNumber
