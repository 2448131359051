import React from 'react';
import Layout from './container/Layout/Layout'
import { Route, Switch } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import Routes from './services/Routes'
import ProtectedRoutes from './components/protectedRoutes/ProtectedRoutes';
import * as authActionCreators from './store/Actions/Authentication-creator'
import Login from './container/Auth-page/Auth'
import './App.css';
import PopupAlert from './components/Popup-alert/Popup-alert';
import { useSelector } from 'react-redux'
import ProfileDataServices from './components-services/MyAccountPage/ProfileData';
import * as userActionCreators from './store/Actions/Authentication-creator'
import ForgetPassword from './container/ForgetPassword/Auth';
import UpdatePassword from './container/UpdatePassword/Auth'
import LoginAs from './container/LoginAs/LoginAs';

function App(props: any) {
  const { checkAuthStatus } = props;
  const userAuthData: any = useSelector((state: any) => state.authState)
  const dispatch: any = useDispatch()

  React.useLayoutEffect(() => {
      checkAuthStatus()
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if(userAuthData.isAuthenticated){
      dispatch(userActionCreators.authProfileData())
    }
  }, [userAuthData.isAuthenticated])

  return (
    <div className="App">
      <div className="alert_messages">
        <PopupAlert />
      </div>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route exact path="/login-as" component={LoginAs} />
          {/* <Route path="/forgot-password/change-password" component={UpdatePassword} /> */}
        <Layout>
            {
              Routes.map((route: any, i: any) => (
                <ProtectedRoutes path={route?.path} Component={route.Component} routes={route.routes} key={i} />
              ))
            }
        </Layout>
      </Switch>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    authData: state.authState,
    searchData:state.searchState
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    checkAuthStatus: () => dispatch(authActionCreators.initAuthStatus())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
