import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import profileServices from '../../../../components-services/MyAccountPage/ProfileData'
import { useStyles } from '../styles'
import ReactSelect from 'react-select';
import { useDispatch } from 'react-redux'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'

function AddBlockNumber(props: any) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const typeOptions = [
        { label: "Hangup", value: 1 },
        { label: "Voice mail", value: 2 }
    ]
    const [submitted, setSubmitted] = React.useState(false);
    const [addNumber, setAddNumber] = React.useState({
        phoneNumber: "",
        calls: false,
        blockCallType: "",
        sms: false
    })



    const onChangeData = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            setAddNumber((prev: any) => {
                return {
                    ...prev,
                    [e.target.name]: val.replace(/[^0-9,.]+/g, "")
                }
            })
        }

    }

    const selcetType = ((e: any) => {
        setAddNumber((prev: any) => {
            return {
                ...prev,
                blockCallType: e.value
            }
        })
        props.parentcall(e.value)
    })

    const onChangeCheckBox = (e: any) => {
        setAddNumber((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }
        })

    }
    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            newnumber: { ...validData },
        };
        let isValid = true;
        if (submitted) {
            if (addNumber.phoneNumber === "") {
                retData.newnumber = {
                    isValid: false,
                    message: "Please enter phone number"
                };
                isValid = false;
            }
            else if (addNumber.phoneNumber.length < 10) {
                retData.newnumber = {
                    isValid: false,
                    message: "Phone Number should be less than 10 digits"
                };
                isValid = false;
            }
            else if (addNumber.phoneNumber.length > 15) {
                retData.newnumber = {
                    isValid: false,
                    message: "Phone Number should not be greater than 15 digits"
                };
                isValid = false;
            }



        }
        return {
            ...retData,
            isValid
        };
    };


    const postAddNumber = (data: any) => {
        var newData
        if (addNumber.calls) {
            newData = addNumber
        }
        else {
            newData = {
                phoneNumber: addNumber.phoneNumber,
                calls: addNumber.calls,
                sms: addNumber.sms
            }
        }
        setSubmitted(true);
        const errorData = validate(true);
        if (errorData.isValid) {
            if (!addNumber.calls && !addNumber.sms) {
                dispatch(globalPopupCreator.initglobalPopup("Please select block calls or sms", "SUBSCRIPTION_ADDED", "error"))

            }
            else {
                props.postAddNumberData(newData)
            }

        }
    }
    const errors = validate(submitted);
    return (
        <div>
            <Grid container spacing={4} style={{ padding: 10 }}>
                <Grid item md={12} >
                    <Typography className={classes.phoneNumber} >PhoneNumber</Typography>
                    <TextField
                        variant="outlined"
                        size='small'
                        fullWidth

                        placeholder="Enter Phone Number"
                        name='phoneNumber'
                        onChange={onChangeData}
                        value={addNumber.phoneNumber}
                        error={!errors.newnumber.isValid}
                    />
                    {
                        !errors.newnumber.isValid &&
                        <p className={classes.errorMessage}>{errors.newnumber.message}</p>
                    }
                    {/* <Typography className={classes.BlockphoneNumber}>BlockCall Type</Typography>
                    <ReactSelect
                        placeholder="Select BlockCall Type"
                        name="blockCallType"
                        options={typeOptions}
                        onChange={selcetType}
                    /> */}

                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={addNumber.sms} name="sms" onChange={onChangeCheckBox} color="primary" />}
                            label="SMS"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={addNumber.calls} name="calls" onChange={onChangeCheckBox} color="primary" />}
                            label="Calls" />
                    </FormGroup>
                    {
                        addNumber.calls === true ?
                            <div>
                                <Typography className={classes.BlockphoneNumber}>BlockCall Type</Typography>
                                <ReactSelect
                                    placeholder="Select BlockCall Type"
                                    name="blockCallType"
                                    options={typeOptions}
                                    onChange={selcetType}
                                    value={typeOptions.filter((opt: any) => opt.value === addNumber?.blockCallType)}
                                />
                            </div> : ""
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={12} style={{ textAlign: 'center' }}>
                    <div>
                        <Button size="medium" className={classes.modaldeleteButton} onClick={postAddNumber}>Add</Button>
                        <Button size="medium" className={classes.deltecancelButton} onClick={props.handlePopupModal} >
                            Cancel
                        </Button>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}

export default AddBlockNumber
