import { capitalize } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { cursorTo } from "readline";

export const useStyles = makeStyles(
  {
    tabs: {
      background: "#FFFFFF",
      borderRadius: "7.51286px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
      //marginBottom: 15,
      marginTop: 20,
    },
    inputFields: {
      margin: '10px 0px 20px',
      display: 'flex',
      alignItems: 'center'
    },

    marginTB10: {
      margin: "10px 0px",
    },
    marginBottom15: {
      marginBottom: 15,
    },
    marginTop10: {
      marginTop: '10px',
    },
    marginBottom20: {
      marginBottom: '20px',
    },
    marginTop5: {
      marginTop: '5px',
    },
    voicemailTabs: {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: "7.51286px",
      padding: '0 40px',
      marginTop: 50,

    },
    cardstyles: {
      background: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
    },
    cardstyles2: {
      background: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
      maxHeight: 164,
      overflowY: 'auto'
    },
    tabPad: {
      padding: 0,
      marginTop: 30,
    },
    divider: {
      background: "#E2E2E2",
    },
    clientUpdater: {
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontSize: 20,
    },
    clientPrimary: {
      color: "black",
      fontWeight: 500,
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontSize: 16,
      paddingRight: 16,
      paddingLeft: 16
    },
    tableCard: {
      background: "#FFFFFF",
      borderRadius: "10.3033px",
      // border: "1px solid #F5F5F5",
      boxShadow: "0px 17.1721px 23.1824px rgba(0, 0, 0, 0.05)",
      // marginTop: 10,
      overflow: "visible",
    },
    activeButtonefone: {
      background: "linear-gradient(135deg, #98EC2D 0%, #17AD37 100%)",
      color: "#FFFFFF",
      borderRadius: 5,
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      height: 15,
      position: "sticky",
      padding: 5,
      marginTop: -11
    },
    inActiveButton: {
      background: "linear-gradient(135deg, #ff4500 0%, #bf4f51 100%)",
      color: "#FFFFFF",
      borderRadius: 5,
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      height: 15,
      position: "sticky",
      padding: 5,
      marginTop: -11,
      width: 45
    },
    tableCard2: {
      background: "#FFFFFF",
      borderRadius: "10.3033px",
      boxShadow: "0px 17.1721px 23.1824px rgba(0, 0, 0, 0.05)",
      overflow: "visible",
      height: '220px',
      overflowY: 'auto',
      marginTop: 10
    },
    tableCard3: {
      background: "#FFFFFF",
      borderRadius: "10.3033px",
      boxShadow: "0px 17.1721px 23.1824px rgba(0, 0, 0, 0.05)",
      overflow: "visible",
      maxHeight: '400px',
      overflowY: 'auto',
      marginTop: 10
    },
    addonsTableHeading: {
      fontSize: 20,
      fontWeight: 500,
      color: "#3A416F",
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 12,
      paddingBottom: 9,
      borderBottom: "1.31653px solid #F5F5F5",
      fontFamily: "work Sans",
    },
    forwardTableCard: {
      background: "#FFFFFF",
      borderRadius: "7.51286px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
      marginTop: 0,
    },
    table: {
      border: "none",

    },
    tableCell: {
      color: "#8392AB",
      fontSize: 16,
      fontWeight: "bold",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      //lineHeight: 2,
      // alignItems: "center",
      // textAlign: "center",
    },
    tableCellBody: {
      color: "#8392AB",
      fontSize: 12,
      fontWeight: 600,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      // alignItems: "center",
      // textAlign: "center",
    },
    button: {
      background: "#8392AB",
      height: 21,
      width: 70,
      color: "white",
      fontWeight: "bold",
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      "&:hover": {
        background: "#8392AB",
        color: "white",
      },
    },
    activeButton: {
      background: "linear-gradient(135deg, #98EC2D 0%, #17AD37 100%)",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      color: "white",
      fontWeight: "bold",
      height: 21,
      width: 55,
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
    },
    accordion: {
      background: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
      //boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
    },
    accordion2: {
      background: "#FFFFFF",
      borderRadius: "8px",

    },
    tab: {
      // minHeight: 10,
      // borderRadius: 5,
      // height: 30,
      // boxSizing: "border-box",
      // fontFamily: "Work Sans",
      // fontStyle: "normal",
      // fontWeight: 600,
      // fontSize: 16,
      // color: "#FFFFFF",

      textTransform: "capitalize",
      background: "#FFFFFF",
      // borderBottom: "1px solid #B8B8B8",
      boxSizing: "border-box",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 18,
      color: "#8392AB",
      paddingTop: 15,
      paddingBottom: 15,
      maxWidth: 400,

    },
    tabActive: {
      // minHeight: 10,
      // borderRadius: 5,
      // background: '#FAFAFA',
      // boxSizing: 'border-box',
      // fontFamily: 'Work Sans',
      // fontStyle: 'normal',
      // fontWeight: 600,
      // fontSize: 16,
      // color: '#4D72F8',
      // textTransform: 'none',

      background: "#FFFFFF",
      // borderBottom: "1px solid #B8B8B8",
      boxSizing: "border-box",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 18,
      color: "#8392AB",
      textTransform: "capitalize",
    },
    webLogin: {
      color: "#3A416F",
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
    },
    viewNumbers: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 12,
      letterSpacing: -0.381952,
      color: "#8392AB"
    },
    buyNumbers: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 12,
      letterSpacing: -0.381952,
      color: "white",
      backgroundColor: "#219653",
      '&:hover': {
        background: '#4D72F8',
        color: '#FFFFFF',
      }
    },
    audioPlayer: {
      display: "block",
      width: "73%",
      marginLeft: "73px",
      height: 48,
      '&.selected': {
        color: '#0921a9'
      },
      '&:hover': {
        color: '#4D72F8'
      },
    },
    nextTab: { display: 'block' },
    dflex: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' },
    dflex2: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '65%', marginTop: 10 },
    userName: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      // marginTop: '10px',
    },
    PortText: {
      fontSize: 16,
      //marginRight: 20,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      color: "#464646",
      letterSpacing: 0.1,
      textTransform: "none",
      // marginTop: 5,
      // marginLeft: 7,
    },
    deleteButton: {
      margin: 10,
      background: '#4D72F8',
      border: '1px solid #AEAEAE',
      boxSizing: 'border-box',
      borderRadius: 5,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      padding: '6px 22px',
      color: '#FFFFFF',
      fontSize: 14,
      '&:hover': {
        background: '#4D72F8',
        color: '#FFFFFF',
      }
    },
    CancelButton: {
      fontFamily: 'Work Sans',
      fontWeight: 500,
      marginLeft: 15,
      background: '#EFEFEF',
      border: '1px solid #AEAEAE',
      borderRadius: 5,
      color: '#8392AB',
      fontSize: 14,
      padding: '6px 22px',
    },
    mAuto: { margin: '0px auto' },
    userName2: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      marginTop: '6px',
    },
    change: {
      justifyContent: "felx-end",
      float: "right",
      color: "#00407B",
      fontSize: 14,
      fontWeight: 500,
      marginTop: 10,
      marginBottom: 15,
    },
    lastName: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      // marginTop: 8,
      marginRight: 15,
    },
    textArea: {
      width: "100%",
      border: "1px solid #E7E7E7",
      background: "#FFFFFF",
      boxSizing: "border-box",
      padding: "10px",
      fontWeight: "normal",
      fontFamily: "Work Sans",
      fontStyle: "normal",
    },
    selectBox: {
      // marginTop: 6,
      fontSize: 14,
      color: '#8392AB',
    },
    selectBox2: {
      // marginTop: 6,
      fontSize: 14,
      marginBottom: 15,
    },
    textBox2: {
      marginTop: 6,
      marginBottom: 7,
    },
    selectBoxOne: {
      marginTop: 6,
      fontSize: 14,
    },
    details: {
      marginTop: 25,
      color: "#3A416F",
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 22,
      marginBottom: 10,
    },
    disabled: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
      fontSize: 14,
      marginLeft: 15,
    },
    enabled: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
      fontSize: 14,
      marginLeft: 15,
    },
    saveButton: {
      color: 'white',
      border: "1px solid darkgreen",
      borderRadius: 8,
      paddingTop: 8,
      paddingBottom: 8,
      width: "40%",
      boxSizing: "border-box",
      marginBottom: 20,
      backgroundColor: "darkgreen",
    },

    addNewButton: {
      marginTop: 5,
      marginRight: 19,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },

    ringDevices: {
      color: "#4d6289",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 19,
    },
    newButton: {
      float: "right",
      width: 150,
      color: '#00407B',
      paddingTop: 8,
      paddingBottom: 8,
      textTransform: "capitalize",
      border: "1px solid #00407B",
      borderRadius: 50,
      boxSizing: "border-box",
      boxShadow: '0px 2px 15px rgba(104, 109, 224, 0.5)',
    },
    vector: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    text: {
      fontSize: 16,
      fontFamily: 'work sans',
      fontStyle: 'normal',
      fontWeight: 500,
      color: '#464646',
      letterSpacing: 0.208955,
      textTransform: 'none',
      margin: '10',
    },
    customBadge: {
      backgroundColor: "green",
      color: "white"
    },
    textfieldheight: {
      marginTop: 6,
      marginBottom: 7,
      color: '#8392AB',
      background: '#F5F5F5',

    },
    clearButton: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      color: "#8392AB",
      border: "0.791301px solid #8392AB",
      background: "#F5F5F5",
      padding: "6px 20px",
      borderRadius: "4.7478px",
      marginLeft: 10,
      marginTop: 5,
    },
    searchButton: {
      marginTop: 5,
      marginRight: 12,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },
    addticket: {
      marginTop: 5,
      marginRight: 19,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },
    buttons: {
      marginTop: 10,
      float: "right",
    },
    search: {
      background: "#3b4ec5",
      textTransform: "capitalize",
      border: "0.791301px solid #3A416F",
      borderRadius: 5,
      color: "#FFFFFF",
      padding: '5px 30px',
      marginRight: 10,
      fontSize: 14,
      "&:hover": {
        background: "#3A416F",
        color: "#FFFFFF",
      },
    },
    clear: {
      background: "#F5F5F5",
      border: "0.791301px solid #8392AB",
      borderRadius: 5,
      textTransform: "capitalize",
      padding: '5px 30px',
      fontSize: 14,
    },
    totalMinutes: {
      display: "flex",
      alignItems: "center",
      marginTop: 10
    },
    totalCalls: {
      fontSize: 16,
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
    },
    line: {
      marginLeft: 10,
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
    },
    totalSec: {
      marginLeft: 10,
      fontSize: 16,
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
    },
    felxDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    inboundCall: {
      color: "#4d6289",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 19,

    },
    checkBox: {
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
    },
    duration: {
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
    },
    idName: {
      marginTop: 10,
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
    },
    verifiedNum: {
      color: "#3A416F",
    },
    addButton: {
      marginTop: 5,
      marginRight: 19,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },
    addNewBlockButton: {
      marginTop: 5,
      marginRight: 19,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },
    tableHead: {
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "16px",
      // letterSpacing: -0.332482
    },
    tableBody: {
      color: "#3A416F",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 13.7377,
      lineHeight: "19px",
      letterSpacing: -0.379979
    },
    deleteOutlineIcon: {
      color: "#EB5757",
      cursor: "pointer",
      verticalAlign: "bottom",
      marginBottom: -2,
      marginRight: "20px",
      float: "right"
    },
    updateButton: {
      borderRadius: 50,
      border: "1px solid #00407B",
      background: "#FFFFFF",
      color: "#00407B",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "capitalize",
      fontSize: 16,
      padding: 5,
      width: 200,
      boxShadow: '0px 2px 15px rgba(104, 109, 224, 0.5)',
    },
    cancelButton: {
      borderRadius: 50,
      border: "1px solid #8392AB",
      background: "#F5F5F5",
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "capitalize",
      fontSize: 16,
      width: 200,
      padding: 5,
      boxShadow: '0px 2px 15px rgba(104, 109, 224, 0.5)',
    },
    bill: {
      display: "flex",
      alignItems: "start",
      justifyContent: "end",
      marginTop: 15,
    },
    billCycling: {
      color: "#8392AB",
      fontSize: 18,
      lineHeight: "28px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      display: "inline-block"
    },
    number: {
      marginLeft: 8,
      display: "inline-block"
    },
    cadNumber: {
      color: "#3A416F",
      fontSize: 24,
      lineHeight: "28px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      display: "inline-block"
    },
    monthText: {
      color: "#3A416F",
      fontSize: 16,
      lineHeight: "28px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      display: "inline-block",
      margin: 0
    },
    excludingText: {
      color: "#3A416F",
      fontSize: 13,
      lineHeight: "28px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      display: "inline-block",
      margin: 0
    },
    dues: {
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
    },
    package: {
      padding: 15,
      textAlign: "center",
      color: "#252F40",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
    },
    price: {
      color: "#00407B",
      textAlign: "center",
      padding: 15,
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
    },
    paymentMethod: {
      color: "#252F40",
      padding: 16,
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
    },
    paymentDiv: {
      display: "flex",
      alignItems: "center",
    },
    numberDiv: {
      display: "felx",
      alignItems: "center",
    },
    totalAmout: {
      color: "#4d6289",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
    },
    marginTB20: { margin: "20px 0" },
    content: {
      color: "#67748E",
      fontSize: 14,
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
    },
    checkboxDiv: {
      display: "flex",
      alignItems: "center",
    },
    terms: {
      color: "#00407B",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 18,
    },
    filters: {
      color: "#4d6289",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 19,
    },
    smallCard: {
      marginTop: 25,
      borderRadius: "10px 10px 0px 0px",
    },
    divBackground: {
      background: "#00407B",
    },
    callingPlan: {
      color: "#F5F5F5",
      textAlign: "center",
      padding: 18,
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
    },
    northAmerica: {
      textAlign: "center",
      padding: 18,
      color: "#00407B",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 20,
    },
    freeMinutes: {
      textAlign: "center",
      padding: 12,
      color: "#00407B",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 18,
    },
    route: {
      color: "#00407B",
      fontSize: 14,
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
    },
    countryName: {
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      color: "#3A416F",
    },
    active: {
      background: "linear-gradient(135deg, #98EC2D 0%, #17AD37 100%)",
      fontSize: 10,
      color: "#FFFFFF",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: "bold",
      borderRadius: 5,
    },

    voicemailTable: {
      display: 'block',
      // marginTop: 20,
    },
    dlfex: { display: 'flex', alignItems: 'center' },
    voicemailTableInner: {
      border: '1px solid #F5F5F5',
      padding: 0,
    },
    voicemailTableHeader: {
      background: '#F8F8F8',
    },
    default: {
      background: '#F8F8F8',
    },
    defaultInner: {
      padding: 8,
      fontSize: 16,
    },
    tableUpaodButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 20px',
    },
    uploadButton: {
      marginTop: 9,
      marginRight: 19,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },
    VoicEmails: {
      color: '#8392AB',
    },
    inputtable: {
      background: '#F5F5F5',
    },
    closeButton: {
      background: '#B70909',
      color: '#fff',
      borderRadius: 50,
      cursor: "pointer"
    },
    callLogsTabs: {
      background: '#FFFFFF',
      boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.05)',
      borderRadius: 5,
      padding: '10px 20px',

    },
    callLogsTab: {
      textTransform: 'capitalize',
      borderBottom: '0',
      minWidth: 0,
      color: '#8392AB',

    },
    logsTabActive: {
      textTransform: 'capitalize',
      display: 'inline-block',
      color: '#8392AB',


    },
    callLogsIcon: {
      display: 'inline-block',
    },
    callTab: {
      padding: '0 10px',
      borderRight: '1px solid #F5F5F5',

    },
    smsLogsCalender: {
      marginBottom: 15,
      color: '#8392AB',
      background: '#F5F5F5',
    },

    routeNumber: {
      color: '#8392AB',
    },

    mainDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

    },
    subDiv: {
      padding: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    callsApp: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      marginLeft: 4,
    },
    barLine: {
      color: "#8392AB",
      marginLeft: 40,
    },
    iconsflex: {
      display: "flex",
      alignItems: "center",
    },
    modal: {
      display: 'flex',
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%"
    },
    asigngridItem: {
      //display: 'flex',
      alignItems: 'center',
      width: '-webkit-fill-available',
      justifyContent: 'flex-start',
    },
    tablecolor: {
      backgroundColor: "#fbfbfb",
    },
    textDevice: {
      marginBottom: 10,
      color: '#464646',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    modalBody: {
      width: "600px",
      padding: "0px",
      position: "absolute",
      boxShadow: "0px 12.5214px 16.9039px rgb(0 0 0 / 5%)",
      backgroundColor: "#fff",
      borderRadius: "4px"
    },
    modalHeader: {
      display: "flex",
      padding: "20px 16px",
      width: "100%",
      color: "#3A416F",
      fontSize: "24px",
      fontStyle: "normal",
      fontFamily: "work Sans",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      borderBottom: "1px solid #E2E2E2",
      boxSizing: "border-box",
      fontWeight: 500,
    },
    modalRows: {
      paddingTop: 30,
      paddingBottom: 30
    },
    modalAddForm: {
      paddingBottom: 20,
    },
    label: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      marginLeft: 0
    },
    modalAddFormLabel: {
      lineHeight: "40px",
      padding: "20px 16px",
      width: "100%",
      color: "#3A416F",
      fontWeight: 500,
      fontSize: "20px",
    },
    modalAddFormText: {
      lineHeight: "40px",
      margin: 0
    },
    modalSubmitButton: {
      marginTop: 5,
      marginRight: 19,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },

    modalCancelButton: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      color: "#8392AB",
      padding: "5px 20px",
      border: "0.791301px solid #8392AB",
      background: "#F5F5F5",
      borderRadius: "4.7478px",
      marginTop: 5,
      marginRight: 19,
    },
    errorMessage: {
      color: 'red',
      fontSize: '0.8rem',
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
    },
    playAndStop: {
      backgroundColor: "#3A416F",
      padding: "6px 5px",
      fontSize: 26,
      color: "#ffffff",
      borderRadius: 3,
      cursor: "pointer"
    },

    subscribePopupMain: {
      position: "relative",
      float: "right"
    },
    subscribeButton: {
      display: "inline-block",
      background: "#F5FFF6",
      border: "0.75px solid rgba(168, 180, 222, 0.16)",
      borderRadius: 3,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      color: "#219653",
      fontSize: 8,
      lineHeight: "8px",
      marginTop: 0
    },
    subscribePopup: {
      right: 0,
      top: 22,
      width: 160,
      zIndex: 10,
      borderRadius: 2,
      position: "absolute",
      background: "#FFFFFF",
      border: "0.2px solid #F1F1F1",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.06)",
    },
    subscribeHeading: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 13,
      lineHeight: "10px",
      color: "#8392AB",
      display: "block",
      border: "0.6px solid #F5F5F5",
      paddingLeft: 7,
      paddingBottom: 6,
      paddingTop: 7,
    },
    subscribePopupButtons: {
      paddingRight: 10,
      paddingLeft: 10,
      paddingTop: 7,
      paddingBottom: 7,
      display: "block",
      textAlign: "left"
    },
    subscribeRadioButtons: {
      display: "block",
      // textAlign: "center",
      padding: "5px",
    },
    subscribeRadioButton: {
      display: "inline-block",
      paddingBottom: 0,
      fontSize: "12px",
      lineHeight: "12px"
    },
    yesButton: {
      display: "inline-block",
      verticalAlign: "top",
      background: "#219653",
      border: "0.75px solid rgba(168, 180, 222, 0.16)",
      // borderRadius: 3,
      // paddingLeft: 12,
      // paddingRight: 12,
      // paddingTop: 3,
      // paddingBottom: 3,
      padding: 10,
      color: "#ffffff",
      fontSize: 10,
      lineHeight: "8px",
      marginRight: 3,
      "&:hover": {
        background: "#219653",
        color: "#ffffff",
      },
    },
    noButton: {
      display: "inline-block",
      verticalAlign: "top",
      background: "#EB5757",
      border: "0.75px solid rgba(168, 180, 222, 0.16)",
      borderRadius: 3,
      // paddingLeft: 12,
      // paddingRight: 12,
      // paddingTop: 3,
      // paddingBottom: 3,
      padding: 10,
      color: "#ffffff",
      fontSize: 10,
      lineHeight: "8px",
      marginRight: 3,
      "&:hover": {
        background: "#EB5757",
        color: "#ffffff",
      },
    },
    blockCallType: {
      width: 210,
    }
  },
  { index: 1 }
);
