import React from 'react'
import { Card, Grid, Typography, TextField, Button } from '@material-ui/core';
import { useStyles } from './styles'
import ModalPopup from '../../../components/Modal/Modal'
import Backdrop from '../../../components/Modal/Backdrop'
import moment from 'moment'

function CommentBody(props: any) {
    const classes = useStyles();
    const { comment, id, filePath, fileName, ticketId, addedBy, createdDate } = props.rowData;

    const [openModal, setOpenModal] = React.useState<any>(false)
    const handleopenmodal = () => {
        setOpenModal(!openModal)
    }

    const ticketIdData = props.ticketId
    const tokenData = props.tokenData;

    return (

        <Grid item xl={8} lg={8} md={8} sm={12} xs={12} style={{ alignSelf: tokenData && tokenData.accountId === addedBy ? 'flex-end' : 'flex-start' }}>
            <Card
                className={classes.cardRight}
            >
                <div className={classes.textLeft}>
                    {comment}
                </div>
            </Card>
            <Typography className={classes.leftAlign}>{moment(Number(createdDate)).format('YYYY-MM-DD hh:mm A')}</Typography>
            {
                filePath !== null && (
                    <div className={classes.flexDiv} style={{ paddingBottom: '10px', cursor: 'pointer', width: 200, height: 100, }}>
                        <img src={filePath} onClick={handleopenmodal} />
                    </div>
                )
            }
            {
                openModal && (
                    <div >
                        <Backdrop handlePopupModal={handleopenmodal} />
                        <ModalPopup title="" handlePopupModal={handleopenmodal}>
                            {
                                filePath !== null && (
                                    <div className={classes.flexDiv}
                                        style={{ height: "350px", width: "250px" }}
                                    // style={{ paddingBottom: '10px', width: 190, height: 100, objectFit: "contain" }}
                                    >
                                        <img src={filePath} alt={fileName} style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                                    </div>
                                )
                            }

                        </ModalPopup>
                    </div>
                )

            }


        </Grid>

    )
}

export default CommentBody
