import React from "react";
import axiosInstance from '../../environment/axiosInstances'
import { useHistory, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js';
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import * as authenticationCreator from '../../store/Actions/Authentication-creator'
import { connect } from 'react-redux'

import Loading from "../../components/Loader";

import localStorageContent from '../../services/localStorage'
import * as globalPopupHandler from '../../store/Actions/popup-actionCreator'
import './loginas.css';


function LoginAs(props: any) {
    const history = useHistory();
    const { isAuthenticated, authLoading} = props.authState

    const authData = useSelector((state: any) => state.authState)
    
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")

React.useEffect(() => {
    props.initAuthentication({token:token})
}, [])

React.useLayoutEffect(() => {
    if (isAuthenticated) {
        history.push("/myaccount")
    }
    // eslint-disable-next-line
}, [isAuthenticated])
    return (

        <>
            {
                authLoading &&
                    <div className='loginas-loader'>
                    <CircularProgress disableShrink  />
                    </div>
             
            }
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        authState: state.authState
    }
}

const mapDispatchToProps = (dispatch: any) => {
    
    return {

        initAuthentication: (tokenObj: any) => dispatch(authenticationCreator.initAuthLoginAs(tokenObj))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginAs)