export const clientsConstants = {
    CLEAR_CLIENT_DATA: 'CLEAR_CLIENT_DATA',

    CLIENT_DETAIL_REQUEST: 'CLIENT_DETAIL_REQUEST',
    CLIENT_DETAIL_SUCCESS: 'CLIENT_DETAIL_SUCCESS',
    CLIENT_DETAIL_FAILURE: 'CLIENT_DETAIL_FAILURE',

    UPDATE_CLIENT_DETAIL_REQUEST: 'UPDATE_CLIENT_DETAIL_REQUEST',
    UPDATE_CLIENT_DETAIL_SUCCESS: 'UPDATE_CLIENT_DETAIL_SUCCESS',
    UPDATE_CLIENT_DETAIL_FAILURE: 'UPDATE_CLIENT_DETAIL_FAILURE',

    ADD_CLIENT_DETAIL_REQUEST: 'ADD_CLIENT_DETAIL_REQUEST',
    ADD_CLIENT_DETAIL_SUCCESS: 'ADD_CLIENT_DETAIL_SUCCESS',
    ADD_CLIENT_DETAIL_FAILURE: 'ADD_CLIENT_DETAIL_FAILURE',

    GET_EDETAILS_REQUEST: 'GET_EDETAILS_REQUEST',
    GET_EDETAILS_SUCCESS: 'GET_EDETAILS_SUCCESS',
    GET_EDETAILS_FAILURE: 'GET_EDETAILS_FAILURE',

    DEVICES_LIST_REQUEST: 'DEVICES_LIST_REQUEST',
    DEVICES_LIST_SUCCESS: 'DEVICES_LIST_SUCCESS',
    DEVICES_LIST_FAILURE: 'DEVICES_LIST_FAILURE',

    ADD_DEVICES_REQUEST: 'ADD_DEVICES_REQUEST',
    ADD_DEVICES_SUCCESS: 'ADD_DEVICES_SUCCESS',
    ADD_DEVICES_FAILURE: 'ADD_DEVICES_FAILURE',

    DELETE_DEVICE_REQUEST: 'DELETE_DEVICE_REQUEST',
    DELETE_DEVICE_SUCCESS: 'DELETE_DEVICE_SUCCESS',
    DELETE_DEVICE_FAILURE: 'DELETE_DEVICE_FAILURE',

    CLINET_UPDATE_DEVICE_REQUEST: 'CLINET_UPDATE_DEVICE_REQUEST',
    CLINET_UPDATE_DEVICE_SUCCESS: 'CLINET_UPDATE_DEVICE_SUCCESS',
    CLINET_UPDATE_DEVICE_FAILURE: 'CLINET_UPDATE_DEVICE_FAILURE',

    GET_SYNCSETTINGS_REQUEST: 'GET_SYNCSETTINGS_REQUEST',
    GET_SYNCSETTINGS_SUCCESS: 'GET_SYNCSETTINGS_SUCCESS',
    GET_SYNCSETTINGS_FAILURE: 'GET_SYNCSETTINGS_FAILURE',

    UPDATE_SYNCSETTINGS_REQUEST: 'UPDATE_SYNCSETTINGS_REQUEST',
    UPDATE_SYNCSETTINGS_SUCCESS: 'UPDATE_SYNCSETTINGS_SUCCESS',
    UPDATE_SYNCSETTINGS_FAILURE: 'UPDATE_SYNCSETTINGS_FAILURE',


    VERIFIED_NUMBERS_REQUEST: 'VERIFIED_NUMBERS_REQUEST',
    VERIFIED_NUMBERS_SUCCESS: 'VERIFIED_NUMBERS_SUCCESS',
    VERIFIED_NUMBERS_FAILURE: 'VERIFIED_NUMBERS_FAILURE',

    DELETE_VERIFIED_NUMBERS_REQUEST: 'DELETE_VERIFIED_NUMBERS_REQUEST',
    DELETE_VERIFIED_NUMBERS_SUCCESS: 'DELETE_VERIFIED_NUMBERS_SUCCESS',
    DELETE_VERIFIED_NUMBERS_FAILURE: 'DELETE_VERIFIED_NUMBERS_FAILURE',

    ADD_VERIFIED_NUMBERS_REQUEST: 'ADD_VERIFIED_NUMBERS_REQUEST',
    ADD_VERIFIED_NUMBERS_SUCCESS: 'ADD_VERIFIED_NUMBERS_SUCCESS',
    ADD_VERIFIED_NUMBERS_FAILURE: 'ADD_VERIFIED_NUMBERS_FAILURE',

    ADD_OTP_VERIFIED_NUMBERS_REQUEST: 'ADD_OTP_VERIFIED_NUMBERS_REQUEST',
    ADD_OTP_VERIFIED_NUMBERS_SUCCESS: 'ADD_OTP_VERIFIED_NUMBERS_SUCCESS',
    ADD_OTP_VERIFIED_NUMBERS_FAILURE: 'ADD_OTP_VERIFIED_NUMBERS_FAILURE',

    BLOCKED_NUMBERS_REQUEST: 'BLOCKED_NUMBERS_REQUEST',
    BLOCKED_NUMBERS_SUCCESS: 'BLOCKED_NUMBERS_SUCCESS',
    BLOCKED_NUMBERS_FAILURE: 'BLOCKED_NUMBERS_FAILURE',

    TICKETS_REQUEST: 'TICKETS_REQUEST',
    TICKETS_SUCCESS: 'TICKETS_SUCCESS',
    TICKETS_FAILURE: 'TICKETS_FAILURE',

    VOICE_MAIL_LIST_REQUEST: 'VOICE_MAIL_LIST_REQUEST',
    VOICE_MAIL_LIST_SUCCESS: 'VOICE_MAIL_LIST_SUCCESS',
    VOICE_MAIL_LIST_FAILURE: 'VOICE_MAIL_LIST_FAILURE',

    VOICE_MAIL_CREATE_REQUEST: 'VOICE_MAIL_CREATE_REQUEST',
    VOICE_MAIL_CREATE_SUCCESS: 'VOICE_MAIL_CREATE_SUCCESS',
    VOICE_MAIL_CREATE_FAILURE: 'VOICE_MAIL_CREATE_FAILURE',
    
    DELETE_VOICE_MAIL_REQUEST: 'DELETE_VOICE_MAIL_REQUEST',
    DELETE_VOICE_MAIL_SUCCESS: 'DELETE_VOICE_MAIL_SUCCESS',
    DELETE_VOICE_MAIL_FAILURE: 'DELETE_VOICE_MAIL_FAILURE',

    ADDRESS_BOOK_LIST_REQUEST: 'ADDRESS_BOOK_LIST_REQUEST',
    ADDRESS_BOOK_LIST_SUCCESS: 'ADDRESS_BOOK_LIST_SUCCESS',
    ADDRESS_BOOK_LIST_FAILURE: 'ADDRESS_BOOK_LIST_FAILURE',

    ADDRESS_BOOK_ADD_REQUEST: 'ADDRESS_BOOK_ADD_REQUEST',
    ADDRESS_BOOK_ADD_SUCCESS: 'ADDRESS_BOOK_ADD_SUCCESS',
    ADDRESS_BOOK_ADD_FAILURE: 'ADDRESS_BOOK_ADD_FAILURE',

    CALL_LOGS_REQUEST: 'CALL_LOGS_REQUEST',
    CALL_LOGS_SUCCESS: 'CALL_LOGS_SUCCESS',
    CALL_LOGS_FAILURE: 'CALL_LOGS_FAILURE',

    SMS_LOGS_REQUEST: 'SMS_LOGS_REQUEST',
    SMS_LOGS_SUCCESS: 'SMS_LOGS_SUCCESS',
    SMS_LOGS_FAILURE: 'SMS_LOGS_FAILURE',

    OUT_BOUND_PROVIDERS_REQUEST: 'OUT_BOUND_PROVIDERS_REQUEST',
    OUT_BOUND_PROVIDERS_SUCCESS: 'OUT_BOUND_PROVIDERS_SUCCESS',
    OUT_BOUND_PROVIDERS_FAILURE: 'OUT_BOUND_PROVIDERS_FAILURE',


    SEARCH_SMS_LOGS_REQUEST: 'SEARCH_SMS_LOGS_REQUEST',
    SEARCH_SMS_LOGS_SUCCESS: 'SEARCH_SMS_LOGS_SUCCESS',
    SEARCH_SMS_LOGS_FAILURE: 'SEARCH_SMS_LOGS_FAILURE',

    ACCOUNT_LOGS_REQUEST: 'ACCOUNT_LOGS_REQUEST',
    ACCOUNT_LOGS_SUCCESS: 'ACCOUNT_LOGS_SUCCESS',
    ACCOUNT_LOGS_FAILURE: 'ACCOUNT_LOGS_FAILURE',

    SIP_CREDENTIALS_REQUEST: 'SIP_CREDENTIALS_REQUEST',
    SIP_CREDENTIALS_SUCCESS: 'SIP_CREDENTIALS_SUCCESS',
    SIP_CREDENTIALS_FAILURE: 'SIP_CREDENTIALS_FAILURE',

    USAGE_REQUEST: 'USAGE_REQUEST',
    USAGE_SUCCESS: 'USAGE_SUCCESS',
    USAGE_FAILURE: 'USAGE_FAILURE',

    SUBSCRIPTION_ADDONS_REQUEST: 'SUBSCRIPTION_ADDONS_REQUEST',
    SUBSCRIPTION_ADDONS_SUCCESS: 'SUBSCRIPTION_ADDONS_SUCCESS',
    SUBSCRIPTION_ADDONS_FAILURE: 'SUBSCRIPTION_ADDONS_FAILURE',

    ADD_SUBSCRIPTION_ADDONS_REQUEST: 'ADD_SUBSCRIPTION_ADDONS_REQUEST',
    ADD_SUBSCRIPTION_ADDONS_SUCCESS: 'ADD_SUBSCRIPTION_ADDONS_SUCCESS',
    ADD_SUBSCRIPTION_ADDONS_FAILURE: 'ADD_SUBSCRIPTION_ADDONS_FAILURE',

    REMOVE_SUBSCRIPTION_ADDONS_REQUEST: 'REMOVE_SUBSCRIPTION_ADDONS_REQUEST',
    REMOVE_SUBSCRIPTION_ADDONS_SUCCESS: 'REMOVE_SUBSCRIPTION_ADDONS_SUCCESS',
    REMOVE_SUBSCRIPTION_ADDONS_FAILURE: 'REMOVE_SUBSCRIPTION_ADDONS_FAILURE',

    RECOMENDED_ADDONS_REQUEST: 'RECOMENDED_ADDONS_REQUEST',
    RECOMENDED_ADDONS_SUCCESS: 'RECOMENDED_ADDONS_SUCCESS',
    RECOMENDED_ADDONS_FAILURE: 'RECOMENDED_ADDONS_FAILURE',

    ACTIVITY_LOGS_REQUEST: 'ACTIVITY_LOGS_REQUEST',
    ACTIVITY_LOGS_SUCCESS: 'ACTIVITY_LOGS_SUCCESS',
    ACTIVITY_LOGS_FAILURE: 'ACTIVITY_LOGS_FAILURE',

    APP_DEBUG_REQUEST: 'APP_DEBUG_REQUEST',
    APP_DEBUG_SUCCESS: 'APP_DEBUG_SUCCESS',
    APP_DEBUG_FAILURE: 'APP_DEBUG_FAILURE',

    CREATE_BLOCKED_NUMBER_REQUEST: 'CREATE_BLOCKED_NUMBER_REQUEST',
    CREATE_BLOCKED_NUMBER_SUCCESS: 'CREATE_BLOCKED_NUMBER_SUCCESS',
    CREATE_BLOCKED_NUMBER_FAILURE: 'CREATE_BLOCKED_NUMBER_FAILURE',

    UPDATE_BLOCKED_NUMBER_REQUEST: 'UPDATE_BLOCKED_NUMBER_REQUEST',
    UPDATE_BLOCKED_NUMBER_SUCCESS: 'UPDATE_BLOCKED_NUMBER_SUCCESS',
    UPDATE_BLOCKED_NUMBER_FAILURE: 'UPDATE_BLOCKED_NUMBER_FAILURE',


    DELETE_BLOCKED_NUMBER_REQUEST: 'DELETE_BLOCKED_NUMBER_REQUEST',
    DELETE_BLOCKED_NUMBER_SUCCESS: 'DELETE_BLOCKED_NUMBER_SUCCESS',
    DELETE_BLOCKED_NUMBER_FAILURE: 'DELETE_BLOCKED_NUMBER_FAILURE',
    
    GET_PAYMENT_TRANSACTION_REQUEST: 'GET_PAYMENT_TRANSACTION_REQUEST',
    GET_PAYMENT_TRANSACTION_SUCCESS: 'GET_PAYMENT_TRANSACTION_SUCCESS',
    GET_PAYMENT_TRANSACTION_FAILURE: 'GET_PAYMENT_TRANSACTION_FAILURE',
};