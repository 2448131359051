import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import React from 'react'
import ReactSelect from 'react-select'
import { useStyles } from './styles';
import Moment from 'moment'
function TableBody(props: any) {
    const classes = useStyles();
    const { accountPhoneNumber, phoneNumber, smsDirection, textMessage, forwardNumber, createdDt } = props.rowData;
    return (
        <TableRow>
            <TableCell className={classes.tableData}>{accountPhoneNumber ? accountPhoneNumber : ""}</TableCell>
            <TableCell className={classes.tableData}>{phoneNumber ? phoneNumber : ""}</TableCell>
            <TableCell className={classes.tableData}>{forwardNumber ? forwardNumber : ""}</TableCell>
            <TableCell className={classes.tableData}>{smsDirection ? smsDirection === 2 ? "Inbound" : "OutBound" : ""}</TableCell>
            <TableCell className={classes.tableData}>{createdDt ? <div>{Moment(Number(createdDt)).format('YYYY-MM-DD HH:MM:SS')}</div> : ""}</TableCell>
        </TableRow>
    )
}

export default TableBody
