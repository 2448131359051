export const authenticAction = {
    INIT_AUTH: 'INIT_AUTH',
    INIT_AUTH_LOGIN_AS: 'INIT_AUTH_LOGIN_AS',
    AUTH_LOADING: 'AUTH_LOADING',
    INIT_AUTH_PROFILE_DATA: 'INIT_AUTH_PROFILE_DATA',
    INIT_AUTH_PROFILE_DATA_SUCCESS: 'INIT_AUTH_PROFILE_DATA_SUCCESS',
    INIT_AUTH_PROFILE_DATA_FAIL: 'INIT_AUTH_PROFILE_DATA_FAIL',
    INIT_AUTH_SUCCESS: 'INIT_AUTH_SUCCESS',
    INIT_AUTH_FAIL: 'INIT_AUTH_FAIL',
    INIT_AUTH_TIMEOUT: 'INIT_AUTH_TIMEOUT',
    INIT_AUTH_LOGOUT: 'INIT_AUTH_LOGOUT',
    INIT_AUTH_LOGOUT_SUCCESS: 'INIT_AUTH_LOGOUT_SUCCESS',
    INIT_AUTH_LOGOUT_FAIL: 'INIT_AUTH_LOGOUT_FAIL',
    INIT_AUTH_STATUS: 'INIT_AUTH_STATUS',
    INIT_AUTH_REFRESH_TOKEN: 'INIT_AUTH_REFRESH_TOKEN',
    INIT_TOKEN_DATA: 'INIT_TOKEN_DATA',
}

export const globalPopupActions = {
    INIT_GLOBAL_ERROR: 'INIT_GLOBAL_ERROR',
    CLEAR_GLOBAL_ERROR: 'CLEAR_GLOBAL_ERROR'
}

