import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  mainDiv: {
    padding: "10px 0px 20px 0px",
    display: "grid",
    placeItems: "center",
    alignItems: "center",
    borderBottom:"1px solid #e2e2e2"
  },
  subDiv: {
    display: "flex",
    alignItems: "center",
  },
  efoneSupport: {
    color: "#00407B",
    fontSize: 30,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
  },
  ticket: {
    marginLeft: 15,
    color: "#8392AB",
    fontSize: 22,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
  },
  ticketId: {
    color: "#8392AB",
    fontSize: 24,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    margin: "8px 0px 0px 0px",
  },
  tableStyle: {
    background: "#FFFFFF",
    borderRadius: "7.51286px",
    boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
  },
  grid: {
    marginTop: 50,
  },
  tableHead: {
    color: "#8392AB",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
  },
  tableBody: {
    color: "#3A416F",
    fontSize: 12,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
  },
  tableBodyOne: {
    color: "#8392AB",
    fontSize: 15,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
  },
  comments: {
    color: "#00407B",
    fontSize: 24,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
  },
  admin: {
    color: "#A6A6A6",
    fontSize: 16,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
  },
  newNumber: {
    padding: 20,
    fontSize: 16,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#A6A6A6",
  },
  flexDiv: {
    display: "flex",
  },
  order: {
    //padding: 20,
  },
  textArea: {
    width: "100%",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
    border: "none",
    background: "#F8F9FA",
  },
  respond: {
    color: "#8392AB",
    fontSize: 16,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    marginBottom: 10,
  },
  attachmentButton: {
    borderRadius: 50,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
    color: "#8392AB",
    marginBottom: 20,
    fontSize: 14,
    textTransform: "capitalize",
    padding: 10,
    cursor: "pointer",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-evenly",
    width:"46%",
    fontWeight: 600,
  },
 
  closedButton: {
    background: "linear-gradient(135deg, #98EC2D 0%, #17AD37 100%)",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: 10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 15,
    position: "sticky",
    padding: 5,
    marginTop: -11,
    width: 38,
  },
  pendingButton: {
    background: "linear-gradient(135deg, #ff4500 0%, #bf4f51 100%)",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: 10,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 15,
    position: "sticky",
    padding: 5,
    marginTop: -11,
    width: 45
  },
  sendButton: {
    background: "#3A416F",
    borderRadius: 5,
    color: "#FFFFFF",
    textTransform: "capitalize",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    border: "0.791301px solid #3A416F",
    padding: '6px 20px',
    "&:hover": {
      background: "#3A416F",
      olor: "#FFFFFF",
    },
    closeTicket: {
      textTransform: "capitalize",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      padding: '6px 20px',
      border: "0.791301px solid #3A416F",
      background: "#3A416F",
    },
  },
  modal: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  },
  modalBody: {
    width: "400px",
    padding: "0px",
    position: "absolute",
    boxShadow: "0px 12.5214px 16.9039px rgb(0 0 0 / 5%)",
    backgroundColor: "#fff",
    borderRadius: "4px"
  },
  modalRows: {
    paddingTop: 40,
    paddingBottom: 0,
    textAlign: "center"
  },
  modalSubmitButton: {
    margin: "0 0 40px 0",
    marginRight: 10,
    background: "#3A416F",
    border: "0.791301px solid #3A416F ",
    color: "#FFFFFF",
    textTransform: "capitalize",
    fontSize: 14,
    justifyContent: "center",
    borderRadius: "4.7478px",
    "&:hover": {
      color: "#FFFFFF",
      background: "#3A416F",
    },
  },
  modalCancelButton: {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: 14,
    color: "#8392AB",
    border: "0.791301px solid #8392AB",
    background: "#F5F5F5",
    borderRadius: "4.7478px",
    margin: "0 0 40px 0",
  },
});
