import React from 'react'
import { Card, Grid, Button, TextField, Typography } from '@material-ui/core'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ReactSelect from 'react-select'
import ticketsServices from '../../../components-services/Tickets/Tickets';
import { useDispatch } from 'react-redux'
import * as globalPopupalert from '../../../store/Actions/popup-actionCreator'

    
import { useStyles } from '../../Tickets/style';

function AddTicket(props:any) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [departmentList, setDepartmentList] = React.useState<any>([])
    const [departmentListDD, setDepartmentListDD] = React.useState<any>([])
    const [ticket, setTicket] = React.useState({
        subject: "",
        ticketBody: "",
        departmentId: ""
    })

    const getDepartMentList = () => {
        ticketsServices.getDepartmentList("/departments").then((res: any) => {
            if(res.success){
                let data = res.data.data;
                if(data.length > 0){
                    const filterDDArray = data.map((dt: any) => {
                        return { label: dt.departmentName, value: dt.id }
                    })
                    setDepartmentListDD(filterDDArray)
                    setDepartmentList(data)
                }
            }else{

            }
        }).catch((err: any) => {

        })
    }

    const onChangeDepartmentList = (data: any) => {
        setTicket((prev: any) => {
            return {
                ...prev,
                departmentId: data.value
            }
        })
    }

    const onChangeText = (e: any) => {
        setTicket((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const addTicket = (data: any) => {
        if(data.subject === "" || data.ticketBody === "" || data.departmentId === ""){
            //err popup alert //please fill all the fields
            //dispatch
            dispatch(globalPopupalert.initglobalPopup("Please fill all the fields", "ADD_TICKET", 'info'))
            return;
        }
        ticketsServices.addTicket("/tickets", data).then((res: any) => {
            if (res.success) {
                props.fetchTickets()
                props.onChangeAddTicketModal()
                dispatch(globalPopupalert.initglobalPopup("Ticket Added succesfully", "ADD_TICKET", 'success'))
            } else {
                dispatch(globalPopupalert.initglobalPopup(res.message, "ADD_TICKET", 'error'))
            }
        }).catch((err: any) => {
            dispatch(globalPopupalert.initglobalPopup(err.message, "ADD_TICKET", 'error'))
        })
    }

    React.useEffect(() => {
        getDepartMentList()
    }, [])

    return (
        <div>
            <Grid container spacing={2} style={{padding: 15}}>
                <Grid item md={12}>
                    <Typography className={classes.subject}>Subject</Typography>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        fullWidth placeholder='Subject'
                        name="subject"
                        value={ticket.subject}
                        onChange={onChangeText}
                    />
                    <Typography className={classes.comment}>Comment</Typography>
                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={5}
                        placeholder="Message"
                        name="ticketBody"
                        value={ticket.ticketBody}
                        onChange={onChangeText}
                        style={{ width: '100%',marginTop: 10, marginBottom: 10, border: '1px solid gray',borderRadius: 3, }}
                    />
                    <Typography className={classes.dept}>Department</Typography>
                    <ReactSelect 
                        options={departmentListDD}
                        onChange={onChangeDepartmentList}
                        placeholder="Department" 
                        style={{marginTop: 40 }}
                       
                    />
                    <div style={{textAlign: 'center'}}>
                        <Button size="small" 
                            onClick={() => addTicket(ticket)}
                        style={{marginTop: 20,background:"#2645AD",color: '#FFFFFF',padding: '5px 20px',
                                textTransform: 'capitalize',fontFamily: 'work sans',fontSize: 14}}
                    >
                        Submit Ticket
                    </Button>
                    </div>
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default AddTicket
