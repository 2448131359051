import React from 'react'
import MinutesUsage from './MinuteUsage/MinuteUsage'
import SmsUsage from './SmsUsage/SmsUsage'
import logsdataservicesfrom from '../../../components-services/Logs/LogsServices'

function Index(props: any) {
    const [data, setData] = React.useState<any>({})
    const [loading, setloading] = React.useState<any>(false)

    const fetchsUsages = () => {
        setloading(true)
        logsdataservicesfrom.fetchUsageData('/accounts/usage').then((res: any) => {
            if (res.success) {
                setData(res.data.data)
                setloading(false)
            } else {
                setloading(false)
            }
        }).catch((err: any) => {
            setloading(false)
        })
    }

    React.useEffect(() => {
        fetchsUsages()
    }, [])

    return (
        <>
            <MinutesUsage usageData={data}/>
            <SmsUsage usageData={data}/>
        </>
    )
}

export default Index
