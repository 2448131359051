import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    textField: {
        // marginLeft: 30,
        margin: '7px 0px',
        width: '100%'
    },
    updateButton: {
        padding: '6px 40px',
        background: '#2645AD',
        color: '#fff',
        borderRadius: 5,
        textTransform: 'capitalize',
        '&:hover': {
            background: '#2645AD',
            color: '#fff',
        }
    }
}))