import React from 'react'
import { Button, Card, CardContent, Divider, Grid, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useStyles } from './styles'
import Backdrop from "../../../../components/Modal/Backdrop"
import Modal from "../../../../components/Modal/Modal"
import billingDataserives from '../../../../components-services/Billing/Billing'
import { Dataprovider } from '../../Index'
import { useDispatch } from 'react-redux'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'

function TableBody(props: any) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const context: any = React.useContext(Dataprovider)
    const [open, setOPen] = React.useState<any>(false);
    const { addonName, cost, id, subscriptionType} = props.rowData;

    const onchangePopupHandler = () => {
        setOPen(!open)
    }

    const deleteHandler = () => {
        billingDataserives.deleteSubscriptionaddons("/accounts/unsubscribe-addon", { id: id }).then((res: any) => {
            if(res.success){
                context.fetchSubscriptionAddons()
                context.fetchRecommendedAddons()
                onchangePopupHandler()
                dispatch(globalPopupCreator.initglobalPopup("Addon Deleted successfully", "ADDON_DELETED", "success"))
            }else{
                dispatch(globalPopupCreator.initglobalPopup(res.data?.message, "ADDON_DELETED_FAIL", "error"))
            }
        }).catch((err: any) => {
            dispatch(globalPopupCreator.initglobalPopup(err?.message, "ADDON_DELETED_FAIL", "error"))
        })
    }

    return (
        <>
            {
                open && (
                    <>
                        <Backdrop handlePopupModal={onchangePopupHandler} />
                        <Modal title="Are you sure you want to Delete ?" handlePopupModal={onchangePopupHandler} >
                            <div style={{ textAlign: 'center', width: 500 }} >
                                <Grid container spacing={2}>
                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.modaldeleteButton} onClick={deleteHandler}>Delete</Button>
                                            <Button size="medium" className={classes.deltecancelButton} onClick={onchangePopupHandler}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </>
                )
            }
            <TableRow>
                <TableCell className={classes.tableBody}>{addonName}</TableCell>
                {/* <TableCell className={classes.tableBody}>${cost}/M</TableCell> */}
                <TableCell className={classes.tableBody}>${cost}/{ subscriptionType === 'Annual' ? "Y" : "M" }</TableCell>
                <TableCell className={classes.tableBody}>
                    <DeleteOutlineIcon style={{ color: '#EB0000', cursor:"pointer" }} onClick={onchangePopupHandler} />
                </TableCell>
            </TableRow>
        </>
    )
}

export default TableBody
