import { clientsConstants, status } from '../Constants';

export function clients(state = {}, action: any) {
    switch (action.type) {
        case clientsConstants.CLEAR_CLIENT_DATA:
            return {
            };
        case clientsConstants.CLIENT_DETAIL_REQUEST:
            return {
                ...state,
                client_detail_status: status.IN_PROGRESS,
                clientDetailData: action.data
            };
        case clientsConstants.CLIENT_DETAIL_SUCCESS:
            return {
                ...state,
                client_detail_status: status.SUCCESS,
                clientDetailData: action.data
            };
        case clientsConstants.CLIENT_DETAIL_FAILURE:
            return {
                ...state,
                client_detail_status: status.FAILURE,
                clientDetailData: null
            };
//   UPDATE CLIENT
            case clientsConstants.UPDATE_CLIENT_DETAIL_REQUEST:
                return {
                    ...state,
                    update_client_detail_status: status.IN_PROGRESS,
                    updateclientDetailData: action.data
                };
            case clientsConstants.UPDATE_CLIENT_DETAIL_SUCCESS:
                return {
                    ...state,
                    update_client_detail_status: status.SUCCESS,
                    updateclientDetailData: action.data
                };
            case clientsConstants.UPDATE_CLIENT_DETAIL_FAILURE:
                return {
                    ...state,
                    update_client_detail_status: status.FAILURE,
                    updateclientDetailData: null
                };

// Update E911 Details
        case clientsConstants.ADD_CLIENT_DETAIL_REQUEST:
            return {
                ...state,
                add_client_detail_status: status.IN_PROGRESS,
                addClientDetailData: action.data
            };
        case clientsConstants.ADD_CLIENT_DETAIL_SUCCESS:
            return {
                ...state,
                add_client_detail_status: status.SUCCESS,
                addClientDetailData: action.data
            };
        case clientsConstants.ADD_CLIENT_DETAIL_FAILURE:
            return {
                ...state,
                add_client_detail_status: status.FAILURE,
                addClientDetailData: action.data
            };

            // Get E911 Details
            case clientsConstants.GET_EDETAILS_REQUEST:
                return {
                    ...state,
                    get_Edeatils_status: status.IN_PROGRESS,
                    getEDetailData: action.data
                };
            case clientsConstants.GET_EDETAILS_SUCCESS:
                return {
                    ...state,
                    get_Edeatils_status: status.SUCCESS,
                    getEDetailData: action.data
                };
            case clientsConstants.GET_EDETAILS_FAILURE:
                return {
                    ...state,
                    get_Edeatils_status: status.FAILURE,
                    getEDetailData: action.data
                };

                // Otp verifed
            case clientsConstants.ADD_OTP_VERIFIED_NUMBERS_REQUEST:
                return {
                    ...state,
                    add_otp_client_detail_status: status.IN_PROGRESS,
                    addOtpClientDetailData: action.data
                };
            case clientsConstants.ADD_OTP_VERIFIED_NUMBERS_SUCCESS:
                return {
                    ...state,
                    add_otp_client_detail_status: status.SUCCESS,
                    addOtpClientDetailData: action.data
                };
            case clientsConstants.ADD_OTP_VERIFIED_NUMBERS_FAILURE:
                return {
                    ...state,
                    add_otp_client_detail_status: status.FAILURE,
                    addOtpClientDetailData: action.data
                };
                // Get Device list
        case clientsConstants.DEVICES_LIST_REQUEST:
            return {
                ...state,
                devices_list: status.IN_PROGRESS,
                devicesListData: action.data
            };
        case clientsConstants.DEVICES_LIST_SUCCESS:
            return {
                ...state,
                devices_list: status.SUCCESS,
                devicesListData: action.data
            };
        case clientsConstants.DEVICES_LIST_FAILURE:
            return {
                ...state,
                devices_list: status.FAILURE,
                devicesListData: null
            };

            // Add device
        case clientsConstants.ADD_DEVICES_REQUEST:
            return {
                ...state,
                add_devices: status.IN_PROGRESS,
                addDevicesData: action.data
            };
        case clientsConstants.ADD_DEVICES_SUCCESS:
            return {
                ...state,
                add_devices: status.SUCCESS,
                addDevicesData: action.data
            };
        case clientsConstants.ADD_DEVICES_FAILURE:
            return {
                ...state,
                add_devices: status.FAILURE,
                addDevicesData: null
            };

            // dELETE DEVICE
        case clientsConstants.DELETE_DEVICE_REQUEST:
            return {
                ...state,
                delete_device: status.IN_PROGRESS,
                deleteDeviceData: action.data
            };
        case clientsConstants.DELETE_DEVICE_SUCCESS:
            return {
                ...state,
                delete_device: status.SUCCESS,
                deleteDeviceData: action.data
            };
        case clientsConstants.DELETE_DEVICE_FAILURE:
            return {
                ...state,
                delete_device: status.FAILURE,
                deleteDeviceData: null
            };
            
            // Get Sync settings
            case clientsConstants.GET_SYNCSETTINGS_REQUEST:
                return {
                    ...state,
                    get_syncsettings_status: status.IN_PROGRESS,
                    getsyncsettingsData: action.data
                };
            case clientsConstants.GET_SYNCSETTINGS_SUCCESS:
                return {
                    ...state,
                    get_syncsettings_status: status.SUCCESS,
                    getsyncsettingsData: action.data
                };
            case clientsConstants.GET_SYNCSETTINGS_FAILURE:
                return {
                    ...state,
                    get_syncsettings_status: status.FAILURE,
                    getsyncsettingsData: action.data
                };

          // UPDATE Sync settings
            case clientsConstants.UPDATE_SYNCSETTINGS_REQUEST:
                return {
                    ...state,
                    update_syncsettings_status: status.IN_PROGRESS,
                    updatesyncsettingsData: action.data
                };
            case clientsConstants.UPDATE_SYNCSETTINGS_SUCCESS:
                return {
                    ...state,
                    update_syncsettings_status: status.SUCCESS,
                    updatesyncsettingsData: action.data
                };
            case clientsConstants.UPDATE_SYNCSETTINGS_FAILURE:
                return {
                    ...state,
                    update_syncsettings_status: status.FAILURE,
                    updatesyncsettingsData: action.data
                };
            // UPDATE DEVCIE
        case clientsConstants.CLINET_UPDATE_DEVICE_REQUEST:
            return {
                ...state,
                clinet_update_device_status: status.IN_PROGRESS,
                clinetUpdateDeviceData: action.data
            };
        case clientsConstants.CLINET_UPDATE_DEVICE_SUCCESS:
            return {
                ...state,
                clinet_update_device_status: status.SUCCESS,
                clinetUpdateDeviceData: action.data
            };
        case clientsConstants.CLINET_UPDATE_DEVICE_FAILURE:
            return {
                ...state,
                clinet_update_device_status: status.FAILURE,
                clinetUpdateDeviceData: null
            };

              //    PAYMENT TRANSACTION
              case clientsConstants.GET_PAYMENT_TRANSACTION_REQUEST:
                return {
                    ...state,
                    get_payment_transaction_status: status.IN_PROGRESS,
                    getPaymentTransactionData: action.data
                };
            case clientsConstants.GET_PAYMENT_TRANSACTION_SUCCESS:
                return {
                    ...state,
                    get_payment_transaction_status: status.SUCCESS,
                    getPaymentTransactionData: action.data
                };
            case clientsConstants.GET_PAYMENT_TRANSACTION_FAILURE:
                return {
                    ...state,
                    get_payment_transaction_status: status.FAILURE,
                    getPaymentTransactionData: null
                };

            // VERIFIED NUMBER
        case clientsConstants.VERIFIED_NUMBERS_REQUEST:
            return {
                ...state,
                verified_numbers: status.IN_PROGRESS,
                verifiedNumbersData: action.data
            };
        case clientsConstants.VERIFIED_NUMBERS_SUCCESS:
            return {
                ...state,
                verified_numbers: status.SUCCESS,
                verifiedNumbersData: action.data
            };
        case clientsConstants.VERIFIED_NUMBERS_FAILURE:
            return {
                ...state,
                verified_numbers: status.FAILURE,
                verifiedNumbersData: null
            };
        case clientsConstants.DELETE_VERIFIED_NUMBERS_REQUEST:
            return {
                ...state,
                delete_verified_numbers: status.IN_PROGRESS,
                deleteverifiedNumbersData: action.data
            };
        case clientsConstants.DELETE_VERIFIED_NUMBERS_SUCCESS:
            return {
                ...state,
                delete_verified_numbers: status.SUCCESS,
                deleteverifiedNumbersData: action.data
            };
        case clientsConstants.DELETE_VERIFIED_NUMBERS_FAILURE:
            return {
                ...state,
                delete_verified_numbers: status.FAILURE,
                deleteverifiedNumbersData: action.data
            };
        case clientsConstants.ADD_VERIFIED_NUMBERS_REQUEST:
            return {
                ...state,
                add_verifyNumber: status.IN_PROGRESS,
                addVerifyData: action.data
            };
        case clientsConstants.ADD_VERIFIED_NUMBERS_SUCCESS:
            return {
                ...state,
                add_verifyNumber: status.SUCCESS,
                addVerifyData: action.data
            };
        case clientsConstants.ADD_VERIFIED_NUMBERS_FAILURE:
            return {
                ...state,
                add_verifyNumber: status.FAILURE,
                addVerifyData: null
            };
        case clientsConstants.BLOCKED_NUMBERS_REQUEST:
            return {
                ...state,
                blocked_numbers: status.IN_PROGRESS,
                blockedNumbersData: action.data
            };
        case clientsConstants.BLOCKED_NUMBERS_SUCCESS:
            return {
                ...state,
                blocked_numbers: status.SUCCESS,
                blockedNumbersData: action.data
            };
        case clientsConstants.BLOCKED_NUMBERS_FAILURE:
            return {
                ...state,
                blocked_numbers: status.FAILURE,
                blockedNumbersData: null
            };
        case clientsConstants.TICKETS_REQUEST:
            return {
                ...state,
                tickets: status.IN_PROGRESS,
                ticketsData: action.data
            };
        case clientsConstants.TICKETS_SUCCESS:
            return {
                ...state,
                tickets: status.SUCCESS,
                ticketsData: action.data
            };
        case clientsConstants.TICKETS_FAILURE:
            return {
                ...state,
                tickets: status.FAILURE,
                ticketsData: null
            };
        case clientsConstants.VOICE_MAIL_LIST_REQUEST:
            return {
                ...state,
                voice_mail_list: status.IN_PROGRESS,
                voiceMailsListData: action.data
            };
        case clientsConstants.VOICE_MAIL_LIST_SUCCESS:
            return {
                ...state,
                voice_mail_list: status.SUCCESS,
                voiceMailsListData: action.data
            };
        case clientsConstants.VOICE_MAIL_LIST_FAILURE:
            return {
                ...state,
                voice_mail_list: status.FAILURE,
                voiceMailsListData: null
            };
        case clientsConstants.VOICE_MAIL_CREATE_REQUEST:
            return {
                ...state,
                voice_mail_create: status.IN_PROGRESS,
                voiceMailsCreateData: action.data
            };
        case clientsConstants.VOICE_MAIL_CREATE_SUCCESS:
            return {
                ...state,
                voice_mail_create: status.SUCCESS,
                voiceMailsCreateData: action.data
            };
        case clientsConstants.VOICE_MAIL_CREATE_FAILURE:
            return {
                ...state,
                voice_mail_create: status.FAILURE,
                voiceMailsCreateData: null
            };
        case clientsConstants.DELETE_VOICE_MAIL_REQUEST:
            return {
                ...state,
                delete_voice_mails: status.IN_PROGRESS,
                deleteVoiceMailsData: action.data
            };
        case clientsConstants.DELETE_VOICE_MAIL_SUCCESS:
            return {
                ...state,
                delete_voice_mails: status.SUCCESS,
                deleteVoiceMailsData: action.data
            };
        case clientsConstants.DELETE_VOICE_MAIL_FAILURE:
            return {
                ...state,
                delete_voice_mails: status.FAILURE,
                deleteVoiceMailsData: null
            };
        case clientsConstants.ADDRESS_BOOK_LIST_REQUEST:
            return {
                ...state,
                address_book_list: status.IN_PROGRESS,
                addressBookListData: action.data
            };
        case clientsConstants.ADDRESS_BOOK_LIST_SUCCESS:
            return {
                ...state,
                address_book_list: status.SUCCESS,
                addressBookListData: action.data
            };
        case clientsConstants.ADDRESS_BOOK_LIST_FAILURE:
            return {
                ...state,
                address_book_list: status.FAILURE,
                addressBookListData: null
            };
        case clientsConstants.ADDRESS_BOOK_ADD_FAILURE:
            return {
                ...state,
                address_bookadd_list: status.FAILURE,
                addressBookaddData: null
            };
        case clientsConstants.ADDRESS_BOOK_ADD_REQUEST:
            return {
                ...state,
                address_bookadd_list: status.IN_PROGRESS,
                addressBookaddData: action.data
            };
        case clientsConstants.ADDRESS_BOOK_ADD_SUCCESS:
            return {
                ...state,
                address_bookadd_list: status.SUCCESS,
                addressBookaddData: action.data
            };

        case clientsConstants.CALL_LOGS_REQUEST:
            return {
                ...state,
                call_logs: status.IN_PROGRESS,
                callLogsData: action.data
            };
        case clientsConstants.CALL_LOGS_SUCCESS:
            return {
                ...state,
                call_logs: status.SUCCESS,
                callLogsData: action.data
            };
        case clientsConstants.CALL_LOGS_FAILURE:
            return {
                ...state,
                call_logs: status.FAILURE,
                callLogsData: null
            };
        case clientsConstants.SMS_LOGS_REQUEST:
            return {
                ...state,
                sms_logs: status.IN_PROGRESS,
                smsLogsData: action.data
            };
        case clientsConstants.SMS_LOGS_SUCCESS:
            return {
                ...state,
                sms_logs: status.SUCCESS,
                smsLogsData: action.data
            };
        case clientsConstants.SMS_LOGS_FAILURE:
            return {
                ...state,
                sms_logs: status.FAILURE,
                smsLogsData: null
            };
        case clientsConstants.OUT_BOUND_PROVIDERS_REQUEST:
            return {
                ...state,
                out_bound_providers: status.IN_PROGRESS,
                outboundProvidersData: action.data
            };
        case clientsConstants.OUT_BOUND_PROVIDERS_SUCCESS:
            return {
                ...state,
                out_bound_providers: status.SUCCESS,
                outboundProvidersData: action.data
            };
        case clientsConstants.OUT_BOUND_PROVIDERS_FAILURE:
            return {
                ...state,
                out_bound_providers: status.FAILURE,
                outboundProvidersData: null
            };
        case clientsConstants.SEARCH_SMS_LOGS_REQUEST:
            return {
                ...state,
                search_sms_logs: status.IN_PROGRESS,
                searchSmsLogsData: action.data
            };
        case clientsConstants.SEARCH_SMS_LOGS_SUCCESS:
            return {
                ...state,
                search_Sms_logs: status.SUCCESS,
                searchSmsLogsData: action.data
            };
        case clientsConstants.SEARCH_SMS_LOGS_FAILURE:
            return {
                ...state,
                search_sms_logs: status.FAILURE,
                searchSmsLogsData: null
            };
        case clientsConstants.ACCOUNT_LOGS_REQUEST:
            return {
                ...state,
                account_logs_status: status.IN_PROGRESS,
                accountLogsData: action.data
            };
        case clientsConstants.ACCOUNT_LOGS_SUCCESS:
            return {
                ...state,
                account_logs_status: status.SUCCESS,
                accountLogsData: action.data
            };
        case clientsConstants.ACCOUNT_LOGS_FAILURE:
            return {
                ...state,
                account_logs_status: status.FAILURE,
                accountLogsData: null
            };
        case clientsConstants.SIP_CREDENTIALS_REQUEST:
            return {
                ...state,
                sip_credentials_status: status.IN_PROGRESS,
                sipCredentialsData: action.data
            };
        case clientsConstants.SIP_CREDENTIALS_SUCCESS:
            return {
                ...state,
                sip_credentials_status: status.SUCCESS,
                sipCredentialsData: action.data
            };
        case clientsConstants.SIP_CREDENTIALS_FAILURE:
            return {
                ...state,
                sip_credentials_status: status.FAILURE,
                sipCredentialsData: null
            };
        case clientsConstants.USAGE_REQUEST:
            return {
                ...state,
                usage_status: status.IN_PROGRESS,
                usageData: action.data
            };
        case clientsConstants.USAGE_SUCCESS:
            return {
                ...state,
                usage_status: status.SUCCESS,
                usageData: action.data
            };
        case clientsConstants.USAGE_FAILURE:
            return {
                ...state,
                usage_status: status.FAILURE,
                usageData: null
            };
        case clientsConstants.SUBSCRIPTION_ADDONS_REQUEST:
            return {
                ...state,
                subscription_addons_status: status.IN_PROGRESS,
                subscriptionAddonsData: action.data
            };
        case clientsConstants.SUBSCRIPTION_ADDONS_SUCCESS:
            return {
                ...state,
                subscription_addons_status: status.SUCCESS,
                subscriptionAddonsData: action.data
            };
        case clientsConstants.SUBSCRIPTION_ADDONS_FAILURE:
            return {
                ...state,
                subscription_addons_status: status.FAILURE,
                subscriptionAddonsData: null
            };
        case clientsConstants.ADD_SUBSCRIPTION_ADDONS_REQUEST:
            return {
                ...state,
                add_subscription_addons_status: status.IN_PROGRESS,
                addSubscriptionAddonsData: action.data
            };
        case clientsConstants.ADD_SUBSCRIPTION_ADDONS_SUCCESS:
            return {
                ...state,
                add_subscription_addons_status: status.SUCCESS,
                addSubscriptionAddonsData: action.data
            };
        case clientsConstants.ADD_SUBSCRIPTION_ADDONS_FAILURE:
            return {
                ...state,
                add_subscription_addons_status: status.FAILURE,
                addSubscriptionAddonsData: null
            };
        case clientsConstants.REMOVE_SUBSCRIPTION_ADDONS_REQUEST:
            return {
                ...state,
                remove_subscription_addons_status: status.IN_PROGRESS,
                removeSubscriptionAddonsData: action.data
            };
        case clientsConstants.REMOVE_SUBSCRIPTION_ADDONS_SUCCESS:
            return {
                ...state,
                remove_subscription_addons_status: status.SUCCESS,
                removeSubscriptionAddonsData: action.data
            };
        case clientsConstants.REMOVE_SUBSCRIPTION_ADDONS_FAILURE:
            return {
                ...state,
                remove_subscription_addons_status: status.FAILURE,
                removeSubscriptionAddonsData: null
            };
        case clientsConstants.RECOMENDED_ADDONS_REQUEST:
            return {
                ...state,
                recomended_addons_status: status.IN_PROGRESS,
                recomendedAddonsData: action.data
            };
        case clientsConstants.RECOMENDED_ADDONS_SUCCESS:
            return {
                ...state,
                recomended_addons_status: status.SUCCESS,
                recomendedAddonsData: action.data
            };
        case clientsConstants.RECOMENDED_ADDONS_FAILURE:
            return {
                ...state,
                recomended_addons_status: status.FAILURE,
                recomendedAddonsData: null
            };
        case clientsConstants.ACTIVITY_LOGS_REQUEST:
            return {
                ...state,
                activity_logs_status: status.IN_PROGRESS,
                activityLogsData: action.data
            };
        case clientsConstants.ACTIVITY_LOGS_SUCCESS:
            return {
                ...state,
                activity_logs_status: status.SUCCESS,
                activityLogsData: action.data
            };
        case clientsConstants.ACTIVITY_LOGS_FAILURE:
            return {
                ...state,
                activity_logs_status: status.FAILURE,
                activityLogsData: null
            };

        case clientsConstants.APP_DEBUG_REQUEST:
            return {
                ...state,
                appDebug_logs_status: status.IN_PROGRESS,
                appDebugLogsData: action.data
            };
        case clientsConstants.APP_DEBUG_SUCCESS:
            return {
                ...state,
                appDebug_logs_status: status.SUCCESS,
                appDebugLogsData: action.data
            };
        case clientsConstants.APP_DEBUG_FAILURE:
            return {
                ...state,
                appDebug_logs_status: status.FAILURE,
                appDebugLogsData: null
            };
        case clientsConstants.CREATE_BLOCKED_NUMBER_REQUEST:
            return {
                ...state,
                create_blocked_number_status: status.IN_PROGRESS,
                createBlockedNumbersData: action.data
            };
        case clientsConstants.CREATE_BLOCKED_NUMBER_SUCCESS:
            return {
                ...state,
                create_blocked_number_status: status.SUCCESS,
                createBlockedNumbersData: action.data
            };
        case clientsConstants.CREATE_BLOCKED_NUMBER_FAILURE:
            return {
                ...state,
                create_blocked_number_status: status.FAILURE,
                createBlockedNumbersData: action.data
            };
        case clientsConstants.UPDATE_BLOCKED_NUMBER_REQUEST:
            return {
                ...state,
                update_blocked_number_status: status.IN_PROGRESS,
                updateBlockedNumbersData: action.data
            };
        case clientsConstants.UPDATE_BLOCKED_NUMBER_SUCCESS:
            return {
                ...state,
                update_blocked_number_status: status.SUCCESS,
                updateBlockedNumbersData: action.data
            };
        case clientsConstants.UPDATE_BLOCKED_NUMBER_FAILURE:
            return {
                ...state,
                update_blocked_number_status: status.FAILURE,
                updateBlockedNumbersData: null
            };
        case clientsConstants.DELETE_BLOCKED_NUMBER_REQUEST:
            return {
                ...state,
                delete_blocked_number_status: status.IN_PROGRESS,
                deleteBlockedNumbersData: action.data
            };
        case clientsConstants.DELETE_BLOCKED_NUMBER_SUCCESS:
            return {
                ...state,
                delete_blocked_number_status: status.SUCCESS,
                deleteBlockedNumbersData: action.data
            };
        case clientsConstants.DELETE_BLOCKED_NUMBER_FAILURE:
            return {
                ...state,
                delete_blocked_number_status: status.FAILURE,
                deleteBlockedNumbersData: null
            };
        default:
            return state;
    }
}