import React from 'react'
import { Button } from '@material-ui/core'
import './sidebarchildren.css'
import sidebarLinksData from '../../../services/Routes/SidebarLinks'
import { useHistory } from 'react-router-dom'
import Childelement from './Childelement';
import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi'
// import sidebarservices from './sidebarServices/SidebarServivces'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { BiMessageSquareAdd } from 'react-icons/bi'

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';



function SidebarChildren(props: any) {
    const [filteredRoute, setFilteredRoute] = React.useState<any>(null)
    const [crm, setCrm] = React.useState<any>(false)
    const [filterRoute, setFilterRoute] = React.useState<any>(null)
    const [expandTitle, setExpandTitle] = React.useState<any>(true)
    const getsyncsettingsData = useSelector((state: any) => state.clientDetailState)
    var getDetails = getsyncsettingsData.getsyncsettingsData?.data
    const { location } = useHistory();
    React.useEffect(() => {
        var newPath = location.pathname;
        const filteredRoute = sidebarLinksData.find((routes: any) => routes.path === newPath);
        if (filteredRoute) {
            setFilteredRoute(filteredRoute)
            setExpandTitle(filteredRoute.title)
        }
    }, [location.pathname])

    React.useEffect(() => {
        const FilterRoutes = filteredRoute?.routes?.filter((rt: any) => getDetails?.isCrm ? rt.title !== 'Billing info' : rt)
        setFilterRoute(FilterRoutes)
    }, [getDetails])

    const onChangeDepartmentTab = (title: any) => {
        setExpandTitle(!expandTitle)
    }
    return (
        <>
            {
                (filteredRoute !== null && filteredRoute.routes?.length > 0) ?
                    <div className="sidebar_children_section">
                        <Accordion
                            style={{ backgroundColor: '#4D72F8', color: 'white', width: '93%', margin: '0px 0px 10px', borderRadius: '0 20px 20px 0' }}
                            expanded={expandTitle}
                            onChange={onChangeDepartmentTab}
                        >
                            <AccordionSummary
                                expandIcon={expandTitle === filteredRoute?.title ? <BiMinusCircle size={22} color="white" /> : <BiPlusCircle size={22} color="white" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ margin: '0' }}
                            >
                                <p style={{ margin: '0' }}>{filteredRoute !== null && filteredRoute?.title}</p>
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: 'white', color: 'black', padding: 8 }}>
                                <div className="child_sidebar_routes">
                                    {
                                        location.pathname.includes("/billing") ?
                                            filterRoute?.map((route: any, i: any) => {
                                                return <Childelement childRoute={route} route={route} key={i} />
                                            })
                                            :
                                            filteredRoute.routes.map((route: any, i: any) => {
                                                return <Childelement
                                                    childRoute={route}
                                                    route={route} key={i} />
                                            })
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    :
                    null
            }
        </>
    )
}


export default SidebarChildren
