import {  MdSms } from 'react-icons/md'
import { GrMoney } from 'react-icons/gr'
import { IoMdSettings } from 'react-icons/io'
import {  RiUser3Fill } from 'react-icons/ri'
import { FaMoneyCheckAlt,FaAddressCard, FaHistory, FaUserFriends, FaTicketAlt,FaBook } from 'react-icons/fa'
import { AiFillSetting, AiOutlineSync } from 'react-icons/ai'
import { GiSandsOfTime } from "react-icons/gi";
import { BsChatLeftTextFill } from "react-icons/bs";


const SidebarLinks: any = [

    {
        title: 'My Account',
        path: '/myaccount',
        Icon: AiFillSetting,
        color: '',
        IconSize: 25,
        childTitle: '',
        routes: [
            {
                title: 'Profile',
                path: '/',
                Icon: FaUserFriends,
                id: "users",
                IconSize: 21,
                color: '#D9561D',
                routes: []
            },
            {
                title: 'My Numbers',
                path: '/',
                Icon: FaBook,
                id: "Numbers",
                IconSize: 21,
                color: '#038A00',
                routes: []
            },
            {
                title: 'Call Handling',
                path: '/',
                Icon: AiOutlineSync,
                id: "CallHandling",
                IconSize: 21,
                color: '#B76E00',
                routes: []
            },
            {
                title: 'Advanced settings',
                path: '/',
                Icon: IoMdSettings,
                id: "AdvancedSettings",
                IconSize: 21,
                color: '#7900C2',
                routes: []
            },
            {
                title: 'SMS settings',
                path: '/',
                Icon: MdSms,
                id: "SMSsettings",
                IconSize: 21,
                color: '#00AFC7',
                routes: []
            }
        ]
    },
    // {
    //     title: 'Comments',
    //     path: '/comments',
    //     Icon: MdSupervisorAccount,
    //     color: '',
    //     IconSize: 25,
    //     childTitle: 'User & Numbers',
    //     routes: [
    //         {
    //             title: 'Users',
    //             path: '/',
    //             Icon: FaUserFriends,
    //             id: "users",
    //             IconSize: 21,
    //             color: '#D9561D',
    //             routes: []
    //         },
    //         {
    //             title: 'Department',
    //             path: '/',
    //             Icon: HiOfficeBuilding,
    //             IconSize: 21,
    //             id: "departments",
    //             color: '#1F74D7',
    //             routes: []
    //         },
    //         {
    //             title: 'Numbers',
    //             path: '/',
    //             Icon: IoMdKeypad,
    //             color: ' #038A00',
    //             id: "numbers",
    //             IconSize: 21,
    //             routes: []
    //         },
    //         {
    //             title: 'Porting Numbers',
    //             path: '/',
    //             Icon: MdSimCard,
    //             color: '#FF0000',
    //             id: "portingNumbers",
    //             IconSize: 21,
    //             routes: []
    //         },
    //         {
    //             title: 'Desk Phones',
    //             path: '/',
    //             Icon: MdDialerSip,
    //             color: ' #DB00FF',
    //             id: "Deskphone",
    //             IconSize: 21,
    //             routes: []
    //         }
    //     ]
    // },
    {
        title: 'Billing',
        path: '/billing',
        Icon: FaMoneyCheckAlt,
        color: '',
        IconSize: 22,
        childTitle: '',
        routes: [
            {
                title: 'Billing info',
                path: '/',
                Icon: GrMoney,
                id: "billing",
                IconSize: 21,
                color: '#2645AD',
                routes: []
            },
            {
                title: 'Plans',
                path: '/',
                Icon: FaMoneyCheckAlt,
                id: "plans",
                IconSize: 21,
                color: '#06CA6B',
                routes: []
            },
        ]
    },
    {
        path: "/call-logs",
        title: "Logs",
        Icon: FaHistory,
        color: '',
        IconSize: 22,
        childTitle: '',
        routes: [
            {
                title: 'Call Logs',
                path: '/',
                Icon: AiOutlineSync,
                id: "Logs",
                IconSize: 21,
                color: '#2645AD',
                routes: []
            },
            {
                title: 'SMS Logs',
                path: '/',
                Icon: MdSms,
                id: "SMS",
                IconSize: 21,
                color: '#00AFC7',
                routes: []
            },
            {
                title: 'Minutes Usage',
                path: '/',
                Icon: GiSandsOfTime,
                id: "Minutes",
                IconSize: 21,
                color: '#B76E00',
                routes: []
            },
            {
                title: 'SMS Usage',
                path: '/',
                Icon: BsChatLeftTextFill,
                id: 'smsusage',
                IconSize: 21,
                color: '#F80296',
                routes: []
            },
            

        ]
      },
      {
        path: "/tickets",
        title: "Tickets",
        Icon: FaTicketAlt,
        color: '',
        IconSize: 22,
        childTitle: '',
        routes: [
            {
                title: 'Open Tickets',
                path: '/',
                Icon: RiUser3Fill,
                id: "'OpenTickets",
                IconSize: 21,
                color: '#2645AD',
                routes: []
            },
            {
                title: 'Close Tickets',
                path: '/',
                Icon: FaBook,
                id: "CloseTickets",
                IconSize: 21,
                color: '#038A00',
                routes: []
            },
            // {
            //     title: 'My Suggetions',
            //     path: '/',
            //     Icon: MdSms,
            //     id: "MySuggestions",
            //     IconSize: 21,
            //     color: '#00AFC7',
            //     routes: []
            // },
            // {
            //     title: 'Other Suggetions',
            //     path: '/',
            //     Icon: FaAddressCard,
            //     id: "OtherSuggestions",
            //     IconSize: 21,
            //     color: '#AC00BB',
            //     routes: []
            // },
        ]
      },
    {
        path: "/change-password",
        title: "Change Password",
        Icon: FaHistory,
        color: '',
        IconSize: 22,
        childTitle: '',
        routes: []
    },
]

export default SidebarLinks