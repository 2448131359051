import React from 'react'
import {
    Card,
    Typography,
    Paper,
    Divider,
    CardContent,
    Tabs,
    Tab,
    Grid, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button,
} from '@material-ui/core'
import { useStyles } from '../styles';
import { GoKebabHorizontal } from 'react-icons/go'
import profileServices from '../../../../components-services/MyAccountPage/ProfileData';
import TableBodyData from './TableBody';
import Backdrop from '../../../../components/Modal/Backdrop'
import Modal from '../../../../components/Modal/Modal'
import AddBlockNumber from './AddBlockNumber';
import Loader from '../../../../components/Loader/Loader'
import '../../style.css'
import { useDispatch } from 'react-redux';
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'

function BlockNumbers(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState<any>(false);
    const [blockNumberData, setBlockNumberData] = React.useState<any>([]);
    const [AddNumberModal, setAddNumberModal] = React.useState<any>(false);
    const [blockCallType, setBlockCallType] = React.useState<any>('')

    const onChangeAddModal = () => {
        setAddNumberModal(!AddNumberModal)
        if (AddNumberModal) {
            setBlockCallType('')
        }
    }

    const fetchBlockNumbers = () => {
        profileServices.fecthBlockNumberData('/accounts/blockednumbers')
            .then((res: any) => {
                if (res.success) {
                    setBlockNumberData(res.data)
                } else {

                }
            }).catch((err: any) => {
            })
    }
    const handleCallback = (childcall: any) => {
        setBlockCallType(childcall)
    }

    const postAddNumberData = (data: any) => {
        if (data.phoneNumber.length < 10) {
            dispatch(globalPopupCreator.initglobalPopup("Please provide valid Phone Number", "SUBSCRIPTION_ADDED", "info"))
            return
        }
        if (data.phoneNumber === "") {
            dispatch(globalPopupCreator.initglobalPopup("Please enter phone number", "SUBSCRIPTION_ADDED", "info"))
            return
        }
        else if (data.calls && blockCallType === "") {
            dispatch(globalPopupCreator.initglobalPopup("Please provide Block call Type", "SUBSCRIPTION_ADDED", "error"))
            return
        } else if (isNaN(data.phoneNumber)) {
            dispatch(globalPopupCreator.initglobalPopup("Phone Number only characters are not allowed", "SUBSCRIPTION_ADDED", "error"))
            return
        }
        else if (data.phoneNumber.length < 6 && data.phoneNumber.length > 10) {
            dispatch(globalPopupCreator.initglobalPopup("Please provide valid Phone Number", "SUBSCRIPTION_ADDED", "error"))
            return
        }
        // if (data.phoneNumber !== "" && data.phoneNumber.length > 6 && data.phoneNumber.length < 10) {
        setLoading(true)
        profileServices.addBlockNumbers('/accounts/blockednumbers', data)
            .then((res: any) => {
                if (res.success) {
                    setLoading(false)
                    fetchBlockNumbers()
                    onChangeAddModal()
                    dispatch(globalPopupCreator.initglobalPopup("Added Blocked Number successfully", "SUBSCRIPTION_ADDED", "success"))
                } else {
                    setLoading(false)
                    dispatch(globalPopupCreator.initglobalPopup(res.message, "SUBSCRIPTION_ADDED", "error"))
                }
            }).catch((err: any) => {
                setLoading(false)
            })
        // } else {
        //     dispatch(globalPopupCreator.initglobalPopup("Please provide valid Phone Number", "SUBSCRIPTION_ADDED", "error"))
        // }
    }

    React.useEffect(() => {
        fetchBlockNumbers()
    }, [])


    return (
        <div>
            {
                AddNumberModal && (
                    <>
                        <Backdrop handlePopupModal={onChangeAddModal} />
                        <Modal handlePopupModal={onChangeAddModal} title="Add Block Number">
                            <div style={{ width: 600 }} >
                                <AddBlockNumber
                                    postAddNumberData={postAddNumberData}
                                    parentcall={handleCallback}
                                    handlePopupModal={onChangeAddModal}
                                    fetchBlockNumbers={fetchBlockNumbers}
                                />
                            </div>
                        </Modal>
                    </>
                )
            }
            
            <div className={classes.dFlex} style={{width: '76%',marginTop: -38}}>
                <Typography className={classes.blocked} id="heading2">Block Numbers</Typography>
            </div>
            <Divider className={classes.divider} style={{ marginTop: 10,width: "74%" }} />
            <Button size="small" variant='outlined' className={classes.AddNumber} onClick={onChangeAddModal}>
                Add Block Number
            </Button>
            <Card style={{ marginTop: 20,width: '74%' }}>
                <TableContainer>
                    <Table>
                        <TableHead style={{ background: '#F1F1F1' }}>
                            <TableRow>
                                <TableCell className={classes.tableHead}>Numbers</TableCell>
                                <TableCell className={classes.tableHead}>Blocked Type</TableCell>
                                <TableCell className={classes.tableHead}>Call Blocked Type</TableCell>
                                <TableCell className={classes.tableHead}>Created Date</TableCell>
                                <TableCell className={classes.tableHead}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {
                                loading === false && blockNumberData.length > 0 && blockNumberData.map((data: any) => {
                                    return (
                                        <TableBodyData blockNumberData={data} fetchBlockNumbers={fetchBlockNumbers} />
                                    )
                                })

                            }
                        </TableBody>
                    </Table>
                    {
                        loading ? <div className='loader_class'>
                            <Loader />
                        </div> : blockNumberData.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                    }
                </TableContainer>
            </Card>

        </div>
    )
}

export default BlockNumbers
