import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import routes from '../../services/Routes'
import './footercomponent.css'

function FooterComponent() {

    const [title, setTitle] = React.useState('')
    const { location } = useHistory()

    React.useEffect(() => {
        routes.forEach((route: any) => {
            if (route.path === location.pathname) {
                setTitle(route.Title)
            }
        })
    }, [location.pathname])

    return (
        <div className="footer_component_section">
            <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="copyright-text">
                        © 2021, All rights reserved by <a href="https://efone.ca/">eFone</a>
                    </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="footer-links">
                        <a href="https://efone.ca/about">About Us </a>
                        <a href="https://efone.ca/blog">Blog </a>
                        {/* <a href="https://efone.ca/locations">Dealers </a> */}
                    </div>
                </Grid>
            </Grid>


        </div>
    )
}

export default FooterComponent
