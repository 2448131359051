import { addonConstants, status,editaddonConstants } from '../Constants';

export function addonUsers(state = {}, action: any) {
    switch (action.type) {
        case addonConstants.SEARCH_ADDON_REQUEST:
            return {
                ...state,
                search_addon_status: status.IN_PROGRESS,
                searchAddonList: action.data
            };
        case addonConstants.SEARCH_ADDON_SUCCESS:
            return {
                ...state,
                search_addon_status: status.SUCCESS,
                searchAddonList: action.data
            };
        case addonConstants.SEARCH_ADDON_FAILURE:
            return {
                ...state,
                search_addon_status: status.FAILURE,
                searchAddonList: null
            };
        default:
            return state;
    }
}

export function editaddonUsers(state = {}, action: any) {
    switch (action.type) {
        case editaddonConstants.EDIT_ADDON_REQUEST:
            return {
                ...state,
                edit_addon_status: status.IN_PROGRESS,
                editAddonList: action.data
            };
        case editaddonConstants.EDIT_ADDON_SUCCESS:
            return {
                ...state,
                edit_addon_status: status.SUCCESS,
                editAddonList: action.data
            };
        case editaddonConstants.EDIT_ADDON_FAILURE:
            return {
                ...state,
                edit_addon_status: status.FAILURE,
                editAddonList: null
            };
        default:
            return state;
    }
}
