import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(() => ({
    header: {
        color: '#464646',
        fontSize: 19,
        fontFamily: 'work sans',
        fontWeight: 600,
    },
    tableContainer: {
        boxShadow: '0px 0px 1px',
        borderRadius: 2,
        borderColor: '#E2E2E2',
        marginTop: 20
    },
    backgroundColor: {
        background: '#EEEEEE'
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    accordianNoFlex:{
        display: 'block',
        padding: '10px 0'
    },
    tableHead:{
        color: '#25282B',
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 600
    },
    tableBody: {
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 600,
        color: '#707070',
    },
    activeButton: {
        fontWeight: 600,
        textTransform: "capitalize",
        color: "#06CA6B",
        fontFamily: 'work sans',
        fontSize: 16,
    },
    failButton: {
        fontWeight: 600,
        textTransform: "capitalize",
        color: "red",
        fontFamily: 'work sans',
        fontSize: 16,
    },
}))