import axiosInstance from '../../environment/axiosInstances'

class responseServices {

    fetchCallLogs = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.message }
        }
    }

    fetchUsageData = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.message }
        }
    }

}

const Billing = new responseServices()
export default Billing