import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(() => ({
    addons: {
        color: '#464646',
        fontSize: 18,
        fontFamily: 'work sans',
        fontWeight: 600,
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    modaldeleteButton: {
        margin: 10,
        padding: '6px 22px',
        background: '#2645AD',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 3,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: 14,
        '&:hover': {
            background: '#2645AD',
            color: '#FFFFFF',
        }
    },
    mL20:{marginLeft:20},
    deltecancelButton: {
        margin: 10,
        padding: '6px 22px',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginLeft: 15,
        background: '#EFEFEF',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#787878',
        fontSize: 14,
    },
    calls: {
        textAlign: 'center',
        padding: 10,
        background: '#4D72F8',
        color: '#fff',
        fontFamily: 'work sans',
        fontSize: 16,
    },
    cardStyles:{
        marginTop: 20,
        borderRadius: 5,
        width:'100%',
        "@media (max-width: 426px)": {
            width:'90%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    totalCost:{
        background: '#f2f4f6',
        width: '100%',
        margin: '0px 4px',
        padding: '10px',
        display: 'block',
        '@media (max-width:426px)':{
            margin:'0px 10px'
        }
    },
    total:{fontWeight:500,color:'#5c5c5c','@media(max-width:426px)':{
        fontSize: 12
    }},
    cad:{color: '#2645AD', fontSize: 22, marginLeft: 10,fontWeight:600,
    '@media(max-width:426px)':{
        fontSize: 16
    }
},
    subFlex:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight:'20px'
     },
     tableHead:{
        color: '#25282B',
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 600
    },
    tableBody: {
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 500,
        color: '#707070',
    },
    billingButton: {
        float: 'right',
        marginTop: '20px',
        padding: '5px 25px',
        marginRight: '20px',
        fontFamily: 'work sans',
        fontWeight: 600,
        textTransform: 'capitalize',
        background: '#2645AD',
        color: '#FFFFFF',
        '&:hover': {
            background: '#2645AD',
            color: '#FFFFFF',
        }
    }
}))