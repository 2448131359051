import React from 'react'
import './style.css'
import {
    Button,
    Grid,
    Typography,
    TextField,
    Card,
    CardContent,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom'
// import axiosInstance from '../../../environment/axiosInstances'
// import * as popUpActionCreator from "../../../store/Actions/popup-actionCreator";
import { connect } from "react-redux";
import { fontStyle, padding } from '@mui/system';
import { useStyles } from './styles';
import * as globalPopupAlert from '../../store/Actions/popup-actionCreator'
import { useDispatch } from 'react-redux'
import authActionService from '../../components-services/Auth/Auth'
import { InputAdornment } from '@material-ui/core';
import { FiEye, FiEyeOff } from 'react-icons/fi'

function ForgetPassword(props: any) {
    const classes = useStyles();
    const history: any = useHistory();
    const dispatch: any = useDispatch()
    const [showConfirmPw1, setshowConfirmPw1] = React.useState(false)
    const [showConfirmPw2, setshowConfirmPw2] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false);
    const [submittedPW, setSubmittedPW] = React.useState(false);
    const [email, setEmail] = React.useState<any>("")
    const [showUI, setShowUI] = React.useState<any>("email")
    const [otp, setOtp] = React.useState<any>({ OTP: "" })
    const [token, setToken] = React.useState<any>("")
    const [password, setPassword] = React.useState<any>({
        password: "",
        confirmPassword: ""
    })

    const onChangePassword = (e: any) => {
        setPassword((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value)
    }

    const onSubmitForm = () => {
        if (email === "") {
            dispatch(globalPopupAlert.initglobalPopup("Please enter Email", "FORGOT_PASSWORD", "info"))
            return
        }
        authActionService.forgotpassword("/auth/forgot-password", { account: email }).then((res: any) => {
            if (res.success) {
                setShowUI("otp_validation")
                dispatch(globalPopupAlert.initglobalPopup("OTP sent successfully", "FORGOT_PASSWORD", "success"))
            } else {
                dispatch(globalPopupAlert.initglobalPopup(res.message, "FORGOT_PASSWORD", "error"))
            }
        }).catch((err: any) => {
            dispatch(globalPopupAlert.initglobalPopup(err.message, "FORGOT_PASSWORD", "error"))
        })
    }

    const validateEmailOtp = () => {
        // if (otp.length <= 3) {
        //     dispatch(globalPopupAlert.initglobalPopup('Please enter valid OTP', "FORGOT_PASSWORD", "error"))
        //     return
        // }
        // if (otp === "" || email === "") {
        //     dispatch(globalPopupAlert.initglobalPopup('Please fill all the details', "FORGOT_PASSWORD", "error"))
        //     return
        // }
        setSubmitted(true);
        const errorData = validate(true);
        if (errorData.isValid) {
            authActionService.forgotpassword("/auth/verify-otp", { account: email, otp: otp.OTP }).then((res: any) => {
                if (res.success) {
                    const data = res.data.data
                    setShowUI("change_password")
                    setToken(data.resetPasswordToken)
                    dispatch(globalPopupAlert.initglobalPopup("OTP Validated successfully", "FORGOT_PASSWORD", "success"))
                } else {
                    dispatch(globalPopupAlert.initglobalPopup(res.message, "FORGOT_PASSWORD", "error"))
                }
            }).catch((err: any) => {
                dispatch(globalPopupAlert.initglobalPopup(err.message, "FORGOT_PASSWORD", "error"))
            })
        }
    }

    const onSetPassword = () => {
        setSubmittedPW(true);
        const errorDataPW = validatepassword(true);
        if (errorDataPW.isValid) {
            if (password.password !== password.confirmPassword) {
                dispatch(globalPopupAlert.initglobalPopup('Passwords not matched', "FORGOT_PASSWORD", "info"))
                return
            }
            if (token === "") {
                dispatch(globalPopupAlert.initglobalPopup('Please Validate OTP', "FORGOT_PASSWORD", "error"))
                setShowUI("otp_validation")
                return
            }
            authActionService.updatePassword(`/auth/reset-password/?token=${token}`, { password: password.password }).then((res: any) => {
                if (res.success) {
                    history.push('/')
                    dispatch(globalPopupAlert.initglobalPopup("Password Updated successfully", "FORGOT_PASSWORD", "success"))
                } else {
                    dispatch(globalPopupAlert.initglobalPopup(res.message, "FORGOT_PASSWORD", "error"))
                }
            }).catch((err: any) => {
                dispatch(globalPopupAlert.initglobalPopup(err.message, "FORGOT_PASSWORD", "error"))
            })
        }
    }

    const habdleshowConfirmPW1 = () => {
        setshowConfirmPw1(!showConfirmPw1)
    }

    const habdleshowConfirmPW2 = () => {
        setshowConfirmPw2(!showConfirmPw2)
    }
    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            otp: { ...validData },

        };
        let isValid = true;
        if (submitted) {
            if (otp.OTP === "") {
                retData.otp = {
                    isValid: false,
                    message: "Please enter Otp"
                };
                isValid = false;
            }
            else if (otp.OTP.length != 4) {
                retData.otp = {
                    isValid: false,
                    message: "Otp should be of 4 digits"
                };
                isValid = false;
            }

        }
        return {
            ...retData,
            isValid
        };
    };
    const errors = validate(submitted);
    const validatepassword = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            password: { ...validData },
            confirmPassword: { ...validData },
        };
        let isValid = true;
        if (submitted) {
            if (!password.password) {
                retData.password = {
                    isValid: false,
                    message: "Please enter password"
                };
                isValid = false;
            }
            if (password.password != password.confirmPassword) {
                retData.confirmPassword = {
                    isValid: false,
                    message: "Both fields should be same"
                };
                isValid = false;
            }
            if (!password.confirmPassword) {
                retData.confirmPassword = {
                    isValid: false,
                    message: "Please re-enter password"
                };
                isValid = false;
            }
        }
        return {
            ...retData,
            isValid
        };
    };
    const errorspassword = validatepassword(submittedPW);
    return (
        <div className="sign_wrapper">
            <div className='parent_child_wrapper'>
                <div>
                    {
                        showUI === "change_password" ? <Typography className={classes.reset} style={{ marginBottom: 20 }}>Create New password</Typography> : (
                            <Typography className={classes.mainHeader}>Forget Password ?</Typography>
                        )
                    }
                </div>
                {
                    showUI === "email" && <Typography className={classes.reset} style={{ marginBottom: 20 }}>You can Reset your Password here</Typography>
                }
                {
                    showUI === "otp_validation" &&
                    <Typography className={classes.reset} style={{ marginBottom: 20 }}>Please enter OTP</Typography>

                }
                <div className="sign_form_container">
                    {/* <form> */}
                    {
                        showUI === "otp_validation" && (
                            <div>
                                <div className={classes.divFlex}>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        name="otp"
                                        id="otp"
                                        fullWidth
                                        size="small"
                                        className={classes.emailText}
                                        placeholder="Enter OTP"
                                        value={otp.OTP}
                                        onChange={(e: any) => setOtp((prev: any) => {
                                            return {
                                                OTP: e.target.value
                                            }
                                        })}
                                        inputProps={
                                            { maxLength: 4 }
                                        }
                                        error={!errors.otp.isValid}
                                    />
                                </div>
                                {
                                    !errors.otp.isValid &&
                                    <p className={classes.error}>{errors.otp.message}</p>
                                }
                                <div className={classes.buttons}>
                                    <Button type="submit" size="small"
                                        className={classes.submitButton}
                                        onClick={validateEmailOtp}
                                        fullWidth
                                        style={{ width: '40%' }}

                                    >
                                        validate OTP
                                    </Button>
                                    <Button type="submit" size="small" variant="outlined" className={classes.backbutton}
                                        style={{ marginLeft: '25px' }}
                                        onClick={() => setShowUI("email")}>Back</Button>
                                </div>
                            </div>
                        )
                    }
                    {showUI === "email" && (
                        <div>
                            <div className={classes.divFlex}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    name="email"
                                    id="email"
                                    fullWidth
                                    size="small"
                                    className={classes.emailText}
                                    placeholder="Enter your Email"
                                    value={email}
                                    onChange={onChangeEmail}
                                />
                            </div>
                            <div className={classes.buttons}>
                                <Button type="submit" size="small"
                                    className={classes.submitButton}
                                    onClick={onSubmitForm}
                                >
                                    Submit
                                </Button>
                                <Button type="submit" size="small" variant="outlined" className={classes.backbutton}
                                    onClick={() => history.push('/')}>Back</Button>
                            </div>
                        </div>
                    )
                    }
                    {
                        showUI === "change_password" && (
                            <div className="sign_form_container">
                                {/* <form > */}
                                <div style={{ marginTop: -20 }}>
                                    {/* <Typography className={classes.newPass}>New Password:</Typography> */}
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        type={showConfirmPw1 ? "text" : "password"}
                                        name="password"
                                        onChange={onChangePassword}
                                        placeholder="Enter new password"
                                        className={classes.emailText}
                                        style={{ marginTop: '10px' }}

                                        //error={!errors.password.isValid}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    {showConfirmPw1 ? <FiEye onClick={habdleshowConfirmPW1} style={{ cursor: 'pointer' }} /> :
                                                        <FiEyeOff onClick={habdleshowConfirmPW1} style={{ cursor: 'pointer' }} />}
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!errorspassword.password.isValid}
                                    />
                                    {
                                        !errorspassword.password.isValid &&
                                        <p className={classes.errorMessage}>{errorspassword.password.message}</p>
                                    }
                                </div>
                                <div>
                                    {/* <Typography className={classes.conPass}>Confirm Password:</Typography> */}
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        type={showConfirmPw2 ? "text" : "password"}
                                        name="confirmPassword"
                                        onChange={onChangePassword}
                                        placeholder="Re-enter password"
                                        className={classes.textfield}
                                        //error={!errors.confirmPassword.isValid}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    {showConfirmPw2 ? <FiEye onClick={habdleshowConfirmPW2} style={{ cursor: 'pointer' }} /> :
                                                        <FiEyeOff onClick={habdleshowConfirmPW2} style={{ cursor: 'pointer' }} />}
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!errorspassword.confirmPassword.isValid}
                                    />
                                    {
                                        !errorspassword.confirmPassword.isValid &&
                                        <p className={classes.errorMessage}>{errorspassword.confirmPassword.message}</p>
                                    }
                                </div>
                                <div className={classes.buttons} >
                                    <Button type="submit" size="small"
                                        className={classes.submitButton}
                                        onClick={onSetPassword}
                                    >
                                        Save</Button>
                                    <Button type="submit" size="small" variant="outlined" className={classes.backbutton}
                                        onClick={() => setShowUI("otp_validation")} >Back</Button>
                                </div>
                                <div  >

                                </div>
                                {/* </form> */}
                            </div>
                        )
                    }
                    {/* </form> */}
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword
