import { takeEvery } from 'redux-saga/effects'
import { authenticAction } from '../Actions/Actions'
import { init_auth_saga, init_auth_timeout, init_auth_profile_data, init_auth_status, init_refresh_token, init_auth_logout ,init_auth_saga_LoginAs} from './authentication-saga'

export default function* indexSaga(){
    yield takeEvery(authenticAction.INIT_AUTH, init_auth_saga)
    yield takeEvery(authenticAction.INIT_AUTH_LOGIN_AS, init_auth_saga_LoginAs)
    yield takeEvery(authenticAction.INIT_AUTH_TIMEOUT, init_auth_timeout)
    yield takeEvery(authenticAction.INIT_AUTH_PROFILE_DATA, init_auth_profile_data)
    yield takeEvery(authenticAction.INIT_AUTH_STATUS, init_auth_status)
    yield takeEvery(authenticAction.INIT_AUTH_LOGOUT, init_auth_logout)
    yield takeEvery(authenticAction.INIT_AUTH_REFRESH_TOKEN, init_refresh_token)
}