import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    heading:{
        color: "#2645ad",
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'work sans',
        "@media (max-width: 426px)": {
            fontSize: 17,
        },
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    accordianNoFlex:{
        display: 'block',
        padding: '10px 0'
    },
    tabs: {
        padding: 5,
        paddingBottom: 5,
        '@media (max-width:426px)':{
            marginBottom:'-4px'
        }
    },
    tab: {
        minHeight: 10,
        // height: 30,
        borderRadius: 5,
        background: '#2645AD',
        boxSizing: 'border-box',
        fontFamily: 'work sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#FFFFFF',
        textTransform: 'none',
    },
    tabCard:{ 
        borderRadius: 4,background: '#F2F2F2',
        marginLeft: '22px', 
        "@media (max-width: 426px)": {
            width:'90%',
            margin:'8px auto 25px'
        },
 },
    tabActive: {
        minHeight: 10,
        // height: 30,
        borderRadius: 5,
        background: '#F2F2F2',
        boxSizing: 'border-box',
        fontFamily: 'work sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#C9C9C9',
        textTransform: 'none',
    },
    ticketButton: {
        padding: '6px 30px',
        background: '#2645AD',
        color: '#FFFFFF',
        fontSize: 15,
        textTransform: 'capitalize',
        "@media (max-width: 426px)": {
            fontSize: 12,
            padding:'6px 10px'
        },
        '&:hover': {
            background: '#2645AD',
            color: '#FFFFFF',
        }
    },
    dflex:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
    },
    subhead: {
        color: '#464646',
        fontSize: 18,
        fontWeight: 600,
    },
    subheading: {
        color: '#464646',
        fontSize: 18,
        fontWeight: 600,
        marginTop: 20,
    },
    tableContainer: {
        boxShadow: '0px 0px 1px',
        //borderRadius: 0,
        borderColor: '#E2E2E2',
        marginTop: '20px',
    },
    tableHead: {
        fontFamily: 'work sans',
        fontWeight: 500,
        fontSize: 16,
        color: '#25282B',
    },
    tableData:{
        fontFamily: 'work sans',
        fontWeight: 500,
        fontSize: 16,
        color: '#707070',
        //textAlign: 'center'
        alignItems: 'center'
    },
    yesButton: {
        background: '#2645AD',
        padding: '5px 10px',
        fontSize: "12px",
        color: 'white',
        '&:hover': {
            background: '#2645AD',
            padding: '5px 10px',
        }
    },
    noButton: {
        background: '#F2F2F2',
        padding: '5px 10px',
        marginLeft: '10px'
    },
}))