import { adminUsersConstants, status,editadminUsersConstans } from '../Constants';

export function adminUsers(state = {}, action: any) {
    switch (action.type) {
        case adminUsersConstants.SEARCH_AGENT_REQUEST:
            return {
                ...state,
                search_agent_status: status.IN_PROGRESS,
                searchAgentList: action.data
            };
        case adminUsersConstants.SEARCH_AGENT_SUCCESS:
            return {
                ...state,
                search_agent_status: status.SUCCESS,
                searchAgentList: action.data
            };
        case adminUsersConstants.SEARCH_AGENT_FAILURE:
            return {
                ...state,
                search_agent_status: status.FAILURE,
                searchAgentList: null
            };
        default:
            return state;
    }
}


export function editagentUsers(state = {}, action: any) {
    switch (action.type) {
        case editadminUsersConstans.EDIT_AGENT_REQUEST:
            return {
                ...state,
                edit_agent_status: status.IN_PROGRESS,
                editAgentList: action.data
            };
        case editadminUsersConstans.EDIT_AGENT_SUCCESS:
            return {
                ...state,
                edit_agent_status: status.SUCCESS,
                editAgentList: action.data
            };
        case editadminUsersConstans.EDIT_AGENT_FAILURE:
            return {
                ...state,
                edit_agent_status: status.FAILURE,
                editAgentList: null
            };
        default:
            return state;
    }
}