import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers,compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import CombineReducers from './store/indexReducers'
import indexSaga from './store/ReduxSaga/indexSaga'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
const combinedAllReducers = combineReducers(CombineReducers);
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
// const loogerMiddleware = createLogger();
const sagaMiddleWare = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combinedAllReducers, 
  composeEnhancers(applyMiddleware(
    sagaMiddleWare,
    thunkMiddleware,
    // loogerMiddleware
  ))
)
sagaMiddleWare.run(indexSaga)

ReactDOM.render (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
