import React from 'react'
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Paper, Divider, CardContent, Tabs, Tab, Grid, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TextField, Button, } from '@material-ui/core'
import { MdDelete } from 'react-icons/md'
import { useStyles } from '../styles';
import profileServices from '../../../../components-services/MyAccountPage/ProfileData';
import Backdrop from '../../../../components/Modal/Backdrop'
import Modal from '../../../../components/Modal/Modal'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'
import { useDispatch } from 'react-redux'
import Badge from '@mui/material/Badge';
import Moment from 'moment'
function TableBody(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [osData, setOSData] = React.useState<any>([])
    const [checkedStatus, setCheckedStatus] = React.useState<any>(false)
    const [deleteModal, setDeleteModal] = React.useState<any>(false);
    const [osTypeName, setOstypeName] = React.useState<any>("");


    const { name, osType, lastLogin, ringDevice, id, onlineStatus } = props.data;
    React.useEffect(() => {
        switch (osType) {
            case 1:
                setOstypeName("Andriod")
                break;
            case 2:
                setOstypeName("IOS")
                break;
            case 3:
                setOstypeName("Home Phone")
                break;
            case 4:
                setOstypeName("Web Phone")
                break;
            case 5:
                setOstypeName("Web User")
                break;
        }
    }, [props.data])

    const onChangeDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const onChangeRingType = () => {
        setCheckedStatus(!checkedStatus)
        profileServices.updateMyDeviceList(`/devices/${id}`, { ringDevice: !checkedStatus ? 1 : 2 }).then((res) => {
            if (res.success) {
                props.getMyDeviceList()
                dispatch(globalPopupCreator.initglobalPopup("Updated Ring Device successfully", "ADDON_DELETED", "success"))
            } else {
                //err
            }
        }).catch((err: any) => {
            //err
        })
    }

    const onDeleteDeviceList = () => {
        profileServices.deleteMydevice(`/devices/${id}`).then((res) => {
            if (res.success) {
                props.getMyDeviceList()
                onChangeDeleteModal()
                dispatch(globalPopupCreator.initglobalPopup("Delete Device successfully", "ADDON_DELETED", "success"))
            } else {
                //err
            }
        }).catch((err: any) => {
            //err
        })
    }
    var newdata = parseInt(lastLogin)
    var datenew = Moment(newdata).format('YYYY-MM-DD HH:mm:ss')
    React.useEffect(() => {
        if (props.data) {
            if (ringDevice === 1) {
                setCheckedStatus(true)
            } else {
                setCheckedStatus(false)
            }
        }
    }, [props.data])

    return (
        <TableRow>
            {
                deleteModal && (
                    <>
                        <Backdrop handlePopupModal={onChangeDeleteModal} />
                        <Modal handlePopupModal={onChangeDeleteModal} title="Are you sure want to Delete ?">
                            <div style={{ textAlign: 'center', width: 500 }} >
                                <Grid container spacing={2}>
                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.modaldeleteButton} onClick={onDeleteDeviceList}>Delete</Button>
                                            <Button size="medium" className={classes.deltecancelButton} onClick={onChangeDeleteModal}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </>
                )
            }
            <TableCell className={classes.tableData} style={{ width: "50px" }} >
                <Checkbox
                    checked={checkedStatus}
                    onChange={onChangeRingType}
                    color={"primary"}
                    style={{ textAlign: 'center' }}
                    className={classes.tableData}
                />&nbsp;
                {osTypeName}
            </TableCell>
            <TableCell className={classes.tableData} >
                {
                    onlineStatus === true ? <Badge color="success" variant="dot"></Badge> : <Badge color="error" variant="dot"></Badge>
                }&nbsp;&nbsp;
                {name}

            </TableCell>

            <TableCell className={classes.tableData}
            //  style={{ padding: "0px 40px", width: "254px" }}
            >{lastLogin ? datenew : ""}</TableCell>

            <TableCell>
                <MdDelete onClick={onChangeDeleteModal} size={25} color='#C9C9C9' style={{ cursor: 'pointer' }} />
            </TableCell>
        </TableRow>
    )
}

export default TableBody
