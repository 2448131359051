import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    mt30: {
        marginTop: 30
    },
    cardLeft: {
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
        borderRadius: '4px',
        background: '#f5f5f5',
        color: 'white'
    },
    cardRight: {
        background: '#ececec',
        boxShadow: '0px 2px 5px rgb(0 0 0 / 25%)',
        borderRadius: '4px',
        color: 'white'
    },
    flexDiv: {
        display: "flex",
    },
    textLeft: {
        padding: '8px 14px',
        fontSize: 16,
        color: "#333",
    },
    textRight: {
        padding: '8px 14px',
        fontSize: 16,
        color: "#fff",
    },
    leftAlign: {
        color: "#8392AB",
        padding: '10px 0px',
        fontSize: 12,
        fontFamily: 'work sans',
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
    },
    rightAlign: {
        color: "#8392AB",
        padding: '10px 0px',
        fontSize: 12,
        fontFamily: 'work sans',
        textAlign: 'right'
    },
    commentBox: {
        display: 'flex', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', flexDirection: 'column', background: 'white', padding: '10px',
        width: '90%',
        '@media (max-width:426px)': {
            width: '100%',
        }
    }
}))