import Logout from "../components/Logout-component/Logout";
import AddTicket from "../container/AddTicket/AddTicket";
import EfoneSupport from "../container/EfoneSupport/EfoneSupport";
import myAccountPage from "../container/MyAccountPage/Index"
import BillingPage from "../container/BillingPage/Index"
import LogsPage from "../container/LogsPage/Index"
import TicketsPage from '../container/TicketsPage/Index'
import Comments from "../container/TicketsPage/Comments/Comments";
import ChangePassword from '../container/UpdatePassword/Auth'
const routes = [
  {
    path: "/myaccount",
    Title: "My Account",
    Component: myAccountPage,
    routes: [],
  },
  {
    path: "/change-password",
    Title: "Change Password",
    Component: ChangePassword,
    routes: [],
  },
  {
    path: "/billing",
    Title: "Billing",
    Component: BillingPage,
    routes: [],
  },
  {
    path: "/call-logs",
    Title: "Logs",
    Component: LogsPage,
    routes: [],
  },
  {
    path: "/tickets",
    Title: "Tickets",
    Component: TicketsPage,
    routes: [],
  },
  {
    path: "/comments/:ticketid",
    Title: "Tickets",
    Component: Comments,
    routes: [],
  },
  {
    path: "/add-tickets",
    Title: "Tickets",
    Component: AddTicket,
    routes: [],
  },
  {
    path: "/efone-support/:id",
    Title: "Efone Support",
    Component: EfoneSupport,
    routes: [],
  },

  {
    path: "/logout",
    Title: "Logout",
    Component: Logout,
    routes: [],
  },
];

export default routes;
