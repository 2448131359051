import { deviceProvisionConstants, status,editdeviceProvisionConstants } from '../Constants';

export function deviceProvision(state = {}, action: any) {
    switch (action.type) {
        case deviceProvisionConstants.SEARCH_DEVICEPROVISION_REQUEST:
            return {
                ...state,
                search_deviceprovision_status: status.IN_PROGRESS,
                searchProvisionList: action.data
            };
        case deviceProvisionConstants.SEARCH_DEVICEPROVISION_SUCCESS:
            return {
                ...state,
                search_deviceprovision_status: status.SUCCESS,
                searchProvisionList: action.data
            };
        case deviceProvisionConstants.SEARCH_DEVICEPROVISION_FAILURE:
            return {
                ...state,
                search_deviceprovision_status: status.FAILURE,
                searchProvisionList: null
            };
        default:
            return state;
    }
}


export function editDeviceProvision(state = {}, action: any) {
    switch (action.type) {
        case editdeviceProvisionConstants.EDIT_DEVICEPROVISION_REQUEST:
            return {
                ...state,
                edit_deviceProvision_status: status.IN_PROGRESS,
                editDeviceProvisionList: action.data
            };
        case editdeviceProvisionConstants.EDIT_DEVICEPROVISION_SUCCESS:
            return {
                ...state,
                edit_deviceProvision_status: status.SUCCESS,
                editDeviceProvisionList: action.data
            };
        case editdeviceProvisionConstants.EDIT_DEVICEPROVISION_FAILURE:
            return {
                ...state,
                edit_deviceProvision_status: status.FAILURE,
                editDeviceProvisionList: null
            };
        default:
            return state;
    }
}