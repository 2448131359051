import React from 'react'
import CallLogs from './CallLogs/CallLogs'
import SmsLogs from './SmsLogs/SmsLogs'
import Usages from './Minutes_sms_usage/index'

function Index(props: any) {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    },[])
    return (
        <div>
            <CallLogs />
            <SmsLogs />
            <Usages />
        </div>
    )
}

export default Index
