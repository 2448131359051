import { Accordion, AccordionSummary, CardContent, Divider, Typography } from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import React from 'react'
import { useStyles } from './styles'
import { AccordionDetails } from '@mui/material';
import PaymentTransactons from './PaymentTransactons/PaymentTransactons';
import StripPayments from './StripPayments/StripPayments';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import billingDataserives from '../../../components-services/Billing/Billing'

function BillingInformation() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [loading, setloading] = React.useState<any>(false)
    const [isSearching, setIsSearching] = React.useState(false)
    const [searchFilterValue, setSearchFilterValue] = React.useState<any>({
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 59),
        paymentStatus: '',
    })
    const [StripPayment, setStripPayment] = React.useState<any>([])
    const [paginate, setPaginate] = React.useState({
        limit: 5,
        totalDocs: 0,
        page: 1
    })

    const onChangePage = (e: any, value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })
        if (isSearching) {
            SearchFilterFun(searchFilterValue, paginate.limit, value)
        } else {
        fetchStripPayments(paginate.limit, value)
        }

    }

    const onChangeFilterHanlder = (prop: any, value: any) => {
        setSearchFilterValue((prev: any) => {
            return {
                ...prev,
                [prop]: value
            }
        })
    }

    const clearFilter = () => {
        setIsSearching(false)
        fetchStripPayments(paginate.limit, 1)
        setSearchFilterValue({
            startDate: new Date().setHours(0, 0, 0, 0),
            endDate: new Date().setHours(23, 59, 59, 59),
            paymentStatus: ''
        })
    }

    const handleChange = () => {
        setOpen(!open);
    };

    const fetchStripPayments = (limit: any,page: any) => {
        setloading(true)
        billingDataserives.getStripPayments(`/accounts/payment-history?limit=${limit}&page=${page}`)
            .then((res: any) => {
                if (res.success) {
                    console.log('strip___', res.data)
                    setloading(false)
                    setStripPayment(res.data.data)
                    setPaginate((prev: any) => {
                        return {
                            ...prev,
                            totalDocs: res.data.totalDocs
                        }
                    })
                } else {
                    setloading(false)
                }
            }).catch((err: any) => {
                setloading(false)
            })
    }
    const SearchFilterFun = (searchValue: any, limit: any, page: any) => {
        setIsSearching(true)
        if (Object.keys(searchValue).length === 0) {
            return alert('Please enter all fields')
        }
        setIsSearching(true)
        setloading(true)
        let obj: any = {}
        Object.keys(searchValue).forEach((rest: any) => {
            if (searchValue[rest] !== '' && searchValue[rest] !== undefined) {
                obj[rest] = searchValue[rest]
            }
        })
        let queryParams = Object.keys(obj).map((i) => `${i}=${obj[i]}`).join('&')
        let URLS = `/accounts/payment-history?limit=${limit}&page=${page}&${queryParams}`;

        billingDataserives.getStripPayments(URLS).then((res: any) => {
            console.log(res)
            if (res.success) {
                setStripPayment(res.data.data)
                setloading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        totalDocs: res.data.totalDocs
                    }
                })
            } else {
                setloading(false)
            }
        }).catch((err: any) => {
            //err
            setloading(false)
        })
    }

    React.useEffect(() => {
        fetchStripPayments(paginate.limit, paginate.page)
    }, [])

    return (
        <div id="billing">
            <Accordion expanded={open} onChange={handleChange} style={{ borderRadius: 10,background: '#FFFFFF',boxShadow: '0 0 0.5em #E2E2E2',}}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>Billing Information</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        <PaymentTransactons 
                            onChangeFilterHanlder={onChangeFilterHanlder}
                            paginate={paginate}
                            searchFilterValue={searchFilterValue}
                            SearchFilterFun={SearchFilterFun}
                            clearFilter={clearFilter}
                        />
                    </CardContent>
                </AccordionDetails>
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        <StripPayments loading={loading} StripPayment={StripPayment}/>
                        <div className={classes.pagination}>
                            <Stack spacing={2}>
                                <Pagination onChange={onChangePage} count={Math.round(paginate.totalDocs / paginate.limit)} color="primary" variant="outlined" shape="rounded" />
                            </Stack>
                        </div>
                    </CardContent>
                </AccordionDetails >
                   
                        
            
            </Accordion>
        </div>
    )
}

export default BillingInformation
