import {  status,SmsConstants ,editSmsProviderConstants} from '../Constants';

export function SmsUsers(state = {}, action: any) {
    switch (action.type) {
        case SmsConstants.SEARCH_SMS_REQUEST:
            return {
                ...state,
                search_sms_status: status.IN_PROGRESS,
                searchSmsList: action.data
            };
        case SmsConstants.SEARCH_SMS_SUCCESS:
            return {
                ...state,
                search_sms_status: status.SUCCESS,
                searchSmsList: action.data
            };
        case SmsConstants.SEARCH_SMS_FAILURE:
            return {
                ...state,
                search_sms_status: status.FAILURE,
                searchSmsList: null
            };
        default:
            return state;
    }
}


export function editSmsUsers(state = {}, action: any) {
    switch (action.type) {
        case editSmsProviderConstants.EDIT_SMSPROVIDER_REQUEST:
            return {
                ...state,
                edit_sms_status: status.IN_PROGRESS,
                editSmsList: action.data
            };
        case editSmsProviderConstants.EDIT_SMSPROVIDER_SUCCESS:
            return {
                ...state,
                edit_sms_status: status.SUCCESS,
                editSmsList: action.data
            };
        case editSmsProviderConstants.EDIT_SMSPROVIDER_FAILURE:
            return {
                ...state,
                edit_sms_status: status.FAILURE,
                editSmsList: null
            };
        default:
            return state;
    }
}