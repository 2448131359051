import React from 'react'
import { Button, Divider, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import Moment from 'moment'
import { useStyles } from './styles';

function TableBody(props: any) {
    const classes = useStyles();
    const { invoiceNumber, amount, paymentStatus, createdDate  } = props.rowData;

    return (
        <TableRow>
            <TableCell className={classes.tableBody}>{invoiceNumber ? invoiceNumber : ""}</TableCell>
            <TableCell className={classes.tableBody}>{amount}</TableCell>
            <TableCell className={classes.tableBody}>{Moment(Number(createdDate)).format('YYYY-MM-DD HH:MM:SS')}</TableCell>

            <TableCell className={classes.tableBody}>{paymentStatus ? paymentStatus === 1 ? (
                <p className={classes.activeButton}>
                    Success
                </p>
            ) : (
                    <p className={classes.failButton}>
                       Fail
                    </p>
            ) : "" }</TableCell>
        </TableRow>
    )
}

export default TableBody
