import { Button } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox';
import * as authenticationCreators from '../../../store/Actions/Authentication-creator'
import { connect } from 'react-redux'
import './signin.css'
import { Link } from "react-router-dom";
import { TextField } from '@mui/material';
import { InputAdornment } from '@material-ui/core';
import { FiEye, FiEyeOff } from 'react-icons/fi'

const formGroup: any = [
    {
        field: 'email',
        valid: true,
        message: ''
    },
    {
        field: 'password',
        valid: true,
        message: ''
    },
]

function Signin(props: any) {
    const [remember, setRememeber] = React.useState<any>(true)
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
    const { isAuthenticated } = props.authState
    const [showPw, setShowPw] = React.useState(false)

     const habdleshowPW = () => {
        setShowPw(!showPw)
    }

    const history = useHistory()
    //Onchange toggle for remember me checkbox
    const handleChange = () => {
        setRememeber(!remember)
        localStorage.setItem('rememberme', JSON.stringify(!remember))
    }

    //Form validation
    const validateForm = (dataToValidate: any) => {
        let formgroupCopy = [...formGroupData];

        function validateRegexEmail(email: any) {
            // eslint-disable-next-line
            const re: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        if (dataToValidate.property === 'email'){
            if(dataToValidate.value === '' ){
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email'){
                        formG.valid = false;
                        formG.message = 'Email should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            }else if(dataToValidate.value !== '' && !validateRegexEmail(dataToValidate.value)){
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Please provide valid email address'
                    }
                })
                setFormgroup(formgroupCopy)
            }else{
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        
        if (dataToValidate.property === 'password') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = false;
                        formG.message = 'Password should not be empty'
                    }
                })
                setFormgroup(formgroupCopy)
            }else{
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        
    }

    //onchange event for email with validation filed
    const onChangeEmail = (e: any) => {
        setEmail(e.target.value)
        validateForm({ property: 'email', value: e.target.value })
    }

    //onchange event for password with validation filed
    const onChangePassword = (e: any) => {
        setPassword(e.target.value)
        validateForm({ property: 'password', value: e.target.value })
    }

    //onSubmit event to send user credentials to server
    const onSigninSubmit = (e: any) => {
        e.preventDefault()

        validateForm({ property: 'email', value: email })
        validateForm({ property: 'password', value: password })

        const [emails, passwords] = formGroupData;
        if (emails.valid && passwords.valid){
            props.initAuthentication({ username: email, password: password})
        }
    }

    React.useEffect(() => {
        const getRemember: any = localStorage.getItem('rememberme')
        const parseRemem: any = getRemember === undefined ? undefined : JSON.parse(getRemember);
        if (parseRemem === null){
            localStorage.setItem('rememberme', remember)
        }else{
            setRememeber(parseRemem)
        }       
    }, [remember])

    //if user is authenticated login page won't render instead it redirect user to home page
    React.useLayoutEffect(() => {
        if (isAuthenticated) {
            history.push("/myaccount")
        }
        // eslint-disable-next-line
    }, [isAuthenticated])
    
    return (
        <div className="sign_wrapper">
            <div className="sign_header">
                <h4>Sign In</h4>
            </div>
            <div className="sign_form_container">
                <form onSubmit={onSigninSubmit}>
                    <div className="sign_input">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" value={email} onChange={onChangeEmail}/>
                        <p>{formGroupData.map((fileds: any) => {
                            if (fileds.valid === false && fileds.field === 'email'){
                                return fileds.message
                            }else{
                                return ''
                            }
                        })}</p>
                    </div>
                    <div className="sign_input">
                        <label htmlFor="pasword">Password</label>
                        <TextField type={ showPw ? "text" : "password" } name="password" id="pasword" fullWidth size='medium' 
                            variant="standard" value={password} onChange={onChangePassword} 
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start" className="passIcon"> 
                                        {showPw ? <FiEye onClick={habdleshowPW} style={{ cursor: "pointer" }} /> :
                                            <FiEyeOff onClick={habdleshowPW} style={{ cursor: "pointer" }} />}
                                    </InputAdornment>
                                ),
                            }}
                        
                        />
                        <p>{formGroupData.map((fileds: any) => {
                            if (fileds.valid === false && fileds.field === 'password') {
                                return fileds.message
                            }else{
                                return ''
                            }
                            
                        })}</p>
                    </div>
                    <div className="sign_form_action">
                    <Link to={"/forget-password"} style={{ cursor: 'pointer', color: '#8392AB', textDecoration: 'none', }}>Forgot Password ?</Link>
                        <div>
                            <Checkbox
                                checked={remember}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                style={{color: '#2645AD'}}
                            />
                            <p style={{ color: '#2645AD'}}>Remember Me</p>
                        </div>
                    </div>
                    <div className="sign_input_button">
                        <Button type="submit">Login</Button>
                    </div>
                </form>
            </div>
            {/* <div className="sign_footer">
                <p>Don't have an account? <span className="footer_span">Signup here</span></p>
            </div> */}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        authState: state.authState
    }
}


const mapDispatchToProps = (dispatch: any) => {
    
    return {

        initAuthentication: (userData: any) => dispatch(authenticationCreators.initAuth(userData))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Signin)
