import { Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useStyles } from './styles'
import { Dataprovider } from '../../Index'
import TableBodyData from './TableBody'
import axiosInstance from '../../../../environment/axiosInstances'
import { useDispatch }  from 'react-redux'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'


function SubscribedAddons() {
    const classes = useStyles()
    const contextData: any = React.useContext(Dataprovider);
    const [calls, setCalls] = React.useState<any>([])
    const [sms, setSms] = React.useState<any>([])
    const [features, setFeatures] = React.useState<any>([])
    const [totalCostValue, setTotalCost] = React.useState<any>("")
        const dispatch = useDispatch()

    React.useEffect(() => {
        if (contextData.subscriptionAddons){
            const { subscribedAddons, totalCost} = contextData.subscriptionAddons
            if (subscribedAddons && subscribedAddons.length > 0){
                const callsFilter = subscribedAddons.filter((cl: any) => cl.addonType === 'Calls')
                const smsFilter = subscribedAddons.filter((cl: any) => cl.addonType === 'SMS')
                const featuresFilter = subscribedAddons.filter((cl: any) => cl.addonType === "Feature")
                setTotalCost(totalCost)
                setCalls(callsFilter)
                setSms(smsFilter)
                setFeatures(featuresFilter)

            }
        }
    }, [contextData.subscriptionAddons])

    const redirectBilling = () => {
        axiosInstance.get('accounts/manage-billing').then((res: any) => {
        if (res.status === 200 && res.data) {
            const URL = res.data.data.url
            window.location.href = URL
        } else {
            //popup
            dispatch(globalPopupCreator.initglobalPopup(res?.message, '', 'error'));
        }
        }).catch((err: any) => {
            dispatch(globalPopupCreator.initglobalPopup(err?.response.data.message, '', 'error'));
        })
    }


    return (
        <div>
            <Typography className={classes.addons} id="heading2">My Subscribed Addons</Typography>
            <Divider className={classes.divider} style={{marginTop:10}}/>
            <div>
                <Button className={classes.billingButton} onClick={redirectBilling}>Billing</Button>
            </div>
            <Grid container spacing={5}>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                    <Card className={classes.cardStyles} >
                        <TableContainer id="scrollbar">
                            <div className={classes.calls}>Calls</div>
                            <Divider className={classes.divider}/>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Addons</TableCell>
                                        <TableCell className={classes.tableHead}>Cost</TableCell>
                                        <TableCell className={classes.tableHead}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        calls.length > 0 && 
                                        calls.map((cl: any, i: any) => <TableBodyData rowData={cl} />) 
                                        // <p className={classes.mL20}>No Data</p>
                                    }
                                </TableBody>
                            </Table>
                            {
                                // props.loading ? <div className='loader_class'>
                                //     <Loader />
                                // </div> : 
                               calls.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                            }
                        </TableContainer>
                    </Card>
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                     <Card className={classes.cardStyles} >
                        <TableContainer id="scrollbar">
                            <div className={classes.calls}>SMS</div>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Addons</TableCell>
                                        <TableCell className={classes.tableHead}>Cost</TableCell>
                                        <TableCell className={classes.tableHead}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        sms.length > 0 && 
                                        sms.map((cl: any, i: any) => <TableBodyData rowData={cl} />) 
                                    }
                                </TableBody>
                            </Table>
                            {
                               sms.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                            }
                        </TableContainer>
                    </Card>
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                     <Card className={classes.cardStyles} >
                        <TableContainer id="scrollbar">
                            <div className={classes.calls}>Features</div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Addons</TableCell>
                                        <TableCell className={classes.tableHead}>Cost</TableCell>
                                        <TableCell className={classes.tableHead}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        features.length > 0 && 
                                        features.map((cl: any, i: any) => <TableBodyData rowData={cl} />) 
                                    }
                                </TableBody>
                            </Table>
                            {
                               features.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                            }
                        </TableContainer>
                        
                    </Card>
                  
                </Grid>
                 <div className={classes.totalCost}>
                   <div className={classes.subFlex} > 
                        <Typography className={classes.total}>Totall Subscriptons Cost:</Typography>
                        <Typography className={classes.cad} >CAD {totalCostValue && totalCostValue}</Typography>
                                    
                   </div>
                 </div>
            </Grid>
        </div>
    )
}

export default SubscribedAddons
