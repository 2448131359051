import {  status,UsageReportsConstants } from '../Constants';

export function UsageUsers(state = {}, action: any) {
    switch (action.type) {
        case UsageReportsConstants.SEARCH_USAGEREPORTS_REQUEST:
            return {
                ...state,
                search_usage_status: status.IN_PROGRESS,
                searchUsageList: action.data
            };
        case UsageReportsConstants.SEARCH_USAGEREPORTS_SUCCESS:
            return {
                ...state,
                search_usage_status: status.SUCCESS,
                searchUsageList: action.data
            };
        case UsageReportsConstants.SEARCH_USAGEREPORTS_FAILURE:
            return {
                ...state,
                search_usage_status: status.FAILURE,
                searchUsageList: null
            };
        default:
            return state;
    }
}