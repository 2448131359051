import {  status,efoneClientsConstants } from '../Constants';

export function efoneClientsUsers(state = {}, action: any) {
    switch (action.type) {
        case efoneClientsConstants.SEARCH_EFONE_CLIENTS_REQUEST:
            return {
                ...state,
                search_efoneClients_status: status.IN_PROGRESS,
                searchefoneClientList: action.data
            };
        case efoneClientsConstants.SEARCH_EFONE_CLIENTS_SUCCESS:
            return {
                ...state,
                search_efoneClients_status: status.SUCCESS,
                searchefoneClientList: action.data
            };
        case efoneClientsConstants.SEARCH_EFONE_CLIENTS_FAILURE:
            return {
                ...state,
                search_efoneClients_status: status.FAILURE,
                searchefoneClientList: null
            };
        default:
            return state;
    }
}