import axiosInstance from '../../environment/axiosInstances';

class authServicesData {

    forgotpassword = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message}
        }
    }

    forgotpasswordValidateOtp = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    updatePassword = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    changePassword = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.patch(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }
}
const authServices = new authServicesData()
export default authServices