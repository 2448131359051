import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(() => ({
    accordion: {
        marginTop: 20, 
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
    },
    heading:{
        color: "#2645ad",
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'work sans',
        "@media (max-width: 426px)": {
            fontSize: 17,
        },
    },
    pagination: {
      float: 'right',
      margin: '20px 0px'
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    accordianNoFlex:{
        display: 'block',
        padding: '10px 0'
    },
}))