import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { useStyles } from './styles';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Minutestable from './MinutesTable/Minutestable';


function MinuteUsage(props: any) {
    const classes = useStyles();
    const [minutesData, setMinutesData] = React.useState<any>([])
    
    const [open, setOpen] = React.useState(false);
    const handleChange = () => {
        setOpen(!open);
    };

    React.useEffect(() => {
        if (props.usageData.minutesUsage){
            setMinutesData(props.usageData.minutesUsage)
        }
        
    }, [props.usageData])


    return (
        <div id="Minutes">
            <Accordion onChange={handleChange} style={{marginTop: 20, borderRadius: 10,background: '#FFFFFF',boxShadow: '0 0 0.5em #E2E2E2',}}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Typography className={classes.heading} id='heading2'>Minutes Usage</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        <TableContainer>
                            <Minutestable minutesData={minutesData}/>
                        </TableContainer>
                    </CardContent>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default MinuteUsage
