import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import TableBodyData from './TableBody'
import { useStyles } from '../styles'
import Loader from '../../../../../components/Loader/Loader'
function SmsTable(props: any) {
    const classes = useStyles();
    const [data, setData] = React.useState<any>([])
    const [loading, setloading] = React.useState<any>(false)

    React.useEffect(() => {
        setData(props.smsData)
    }, [props])

    return (
        <div>
            <Table>
                <TableHead style={{background: '#F3F6F9'}}>
                    <TableRow>
                        <TableCell className={classes.tableHead}>Route & Country Name</TableCell>
                        <TableCell className={classes.tableHead}>Addon Name</TableCell>
                        <TableCell className={classes.tableHead}>Free Minutes</TableCell>
                        <TableCell className={classes.tableHead}>Remaining Minutes</TableCell>
                        <TableCell className={classes.tableHead}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        loading === false && data.length > 0 && data.map((dts: any) => <TableBodyData rowData={dts}  />)
                    }
                        
                    {/* data.length > 0 ? data.map((dts: any, i: any) => <TableBodyData rowData={dts} key={i} />) : <p style={{textAlign: 'center'}}>No Data</p> */}
                    
                </TableBody>
            </Table>
            {
                loading ? <div className='loader_class'>
                    <Loader />
                </div> : data.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
            }
            <div className={classes.pagination}>
                {/* <Typography>Active Numbers: 478/765</Typography> */}
                {/* <Stack spacing={2}>
                    <Pagination count={10} variant="outlined" shape="rounded" />
                </Stack> */}
            </div>
        </div>
    )
}

export default SmsTable
