import React, { useState, useRef } from "react";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { useStyles } from "./style";

const AudioPlayer = (props: any) => {
    const classes = useStyles();
    const [isPlaying, setIsPlaying] = useState(false);
    const ref: any = useRef(null);
    const toggle = () => {
        if (isPlaying) {
            ref.current && ref.current.pause();
        } else {
            ref.current && ref.current.play();
        }
        setIsPlaying(!isPlaying);
    };
    return (
        <div className="audio-controls" style={{ textAlign: "center" }}>
            {
                !isPlaying &&
                <PlayArrowIcon onClick={toggle} className={classes.playAndStop} />
            }
            {
                isPlaying &&
                <StopIcon onClick={toggle} className={classes.playAndStop} />
            }
            <div style={{ display: "none" }}>
                <audio onAbort={() => setIsPlaying(false)} onEnded={() => setIsPlaying(false)} ref={ref} controls>
                    <source src={props.path}></source>
                </audio>
            </div>
        </div>
    );
}

export default AudioPlayer;
