import React from 'react'
import ParentSidebar from './Sidebar-component'
import SidebarChildren from './SidebarChildren/SidebarChildren'

function Index() {
    return (
        <div className="sideber_wrapper">
            <div className="leftsidebar">
                <ParentSidebar />
            </div>
            <div>
                <SidebarChildren />
            </div>
        </div>
    )
}

export default Index
