import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    accordian: {
        marginTop: 20, borderRadius: 10,background: '#FFFFFF',boxShadow: '0 0 0.5em #E2E2E2',
    },
    heading:{
        color: "#2645ad",
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'work sans'
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    accordianNoFlex:{
        display: 'block'
    },
    tableHead: {
        fontFamily: 'work sans',
        fontWeight: 600,
        fontSize: 16,
        color: '#25282B',
    },
    tableData:{
        fontFamily: 'work sans',
        fontWeight: 600,
        fontSize: 16,
        color: '#707070',
        //textAlign: 'center'
        alignItems: 'center'
    },
    smallCard: {
        background: '#FFFFFF',
        boxShadow: '0px 8.28247px 13.8041px rgba(0, 0, 0, 0.15)',
        borderRadius: 8,
        maxHeight: '140px',
        overflowY: 'auto'
    },
    background: {
        background: '#4D72F8'
    },
    tableInsideHead: {
        fontFamily: 'work sans',
        fontWeight: 600,
        fontSize: 14,
        color: '#FFFFFF',
    },
    tableInsideData:{
        fontFamily: 'work sans',
        fontWeight: 500,
        fontSize: 14,
        color: '#707070',
        //textAlign: 'center'
        alignItems: 'center'
    },
    activeButton: {
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'work sans',
        // background: '#06CA6B',
        color: '#06CA6B',
    },
    inactiveButton: {
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'work sans',
        // background: 'rgb(239, 239, 239)',
        color: 'red',
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0px'

    }
}))