import React from 'react'
import {
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper,
    Divider,
    CardContent,
    Tabs,
    Tab,
    Grid, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button,
} from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
// import Checkbox from '@material-ui/core';
import { useStyles } from './styles';
import ReactSelect from 'react-select'
import { MdDelete } from 'react-icons/md'
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import VectorButton from "../../../assets/icons/VectorButton.png";
import { GoKebabHorizontal } from 'react-icons/go'
//import { MdDelete } from 'react-icons/md'
import { FaUpload } from 'react-icons/fa'
import ReactAudioPlayer from 'react-audio-player';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import DevicesTable from './DevicesTable/DevicesTable';
import BlockNumbers from './BlockNumbers/BlockNumbers';
import profileDataServices from '../../../components-services/MyAccountPage/ProfileData'
import NextUnansweredCalls from './NextUnansweredCalls/NextUnansweredCalls';
import * as globalPopupCreators from '../../../store/Actions/popup-actionCreator'
import { useDispatch } from 'react-redux'
const muiTheme = createMuiTheme({});

function CallHandling(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [settingData, setSettingsData] = React.useState<any>(null)
    const [ringData, setRingData] = React.useState<any>([])
    const [ringDataDD, setRingDataDD] = React.useState<any>([])
    const [selectedRingvalue, setselectedRingvalue] = React.useState<any>(null)
    const [devicesData, setDevicesData] = React.useState<any>([]);
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState("1");
    const handleChange = () => {
        setOpen(!open);
    };

    const onChangeringType = (data: any) => {
        const filterData = ringData.find((res: any) => res.id === data.value)
        if (filterData) {
            setselectedRingvalue({ label: filterData.value, value: filterData.id })
            updateSettings({ noOfRings: Number(filterData.id) })
        }
    }

    const updateSettings = (data: any) => {
        // if (selectedRingvalue){
        profileDataServices.updateSetting("/accounts/settings", data).then((res: any) => {
            if (res.success) {
                props.fecthMysettings()
                dispatch(globalPopupCreators.initglobalPopup("Updated successfully", "UPDATE_SETTINGS", "success"))
            } else {
                dispatch(globalPopupCreators.initglobalPopup(res.message, "UPDATE_SETTINGS_FAIL", "error"))
            }
        }).catch((err: any) => {
            dispatch(globalPopupCreators.initglobalPopup(err.message, "UPDATE_SETTINGS_FAIL", "error"))
        })
        //    }
    }


    React.useEffect(() => {
        if (props.settings) {
            const { devices, ringDuration, noOfRings } = props.settings;
            const filteredRing = ringDuration.find((res: any) => res.id == noOfRings)
            if (filteredRing) {
                setselectedRingvalue({ label: filteredRing.value, value: filteredRing.id })
            }
            setSettingsData(props.settings)
            setRingData(ringDuration)
            setRingDataDD(ringDuration.map((res: any) => {
                return { label: res.value, value: res.id }
            }))

        }
    }, [props.settings])

    const handleTabs = (e: any, newValue: any) => {
        setValue(newValue);
    };

    const getMyDeviceList = () => {
        profileDataServices.getmydevicesList("/devices").then((res: any) => {
            if (res.success) {
                setDevicesData(res.data.data)
            } else {

            }
        }).catch((err: any) => {

        })
    }

    React.useEffect(() => {
        getMyDeviceList()
    }, [])

    return (
        <div id="CallHandling">
            <Accordion onChange={handleChange} style={{ marginTop: 20, borderRadius: 10, background: '#FFFFFF', boxShadow: '0 0 0.5em #E2E2E2', }}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Call handling</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent >
                        <Grid container spacing={4} className={classes.dFlex}>
                            <Grid item xl={8} lg={8} md={6} sm={6} xs={12}>
                                <Typography className={classes.ring} id="heading2">First Ring eFone Device</Typography>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                                <ReactSelect onChange={onChangeringType} value={selectedRingvalue} options={ringDataDD} />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider className={classes.divider} style={{ marginTop: 10 }} />
                </AccordionDetails>
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        <DevicesTable loading={props.settingsLoading} getMyDeviceList={getMyDeviceList} tableData={devicesData}
                            fecthMysettings={props.fecthMysettings} settingData={settingData} />
                    </CardContent>
                </AccordionDetails>
                {/* <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        <NextUnansweredCalls fecthMysettings={props.fecthMysettings} settingData={settingData} />
                    </CardContent>
                </AccordionDetails> */}
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        <BlockNumbers loading={props.settingsLoading} />
                    </CardContent>
                </AccordionDetails>

            </Accordion>
        </div>
    )
}

export default CallHandling
