import { authenticAction } from '../Actions/Actions'


const authState: any = {
    authLoading: false,
    isAuthenticated: false,
    userData: null,
    accessToken: null,
    refreshToken: null,
    tokenData: null
}

export const authenticationReducer = (state = authState, action: any) => {
    switch(action.type){
        case authenticAction.INIT_TOKEN_DATA:
            return {
                ...state,
                tokenData: action.token_data
            }
        case authenticAction.AUTH_LOADING:
            return {
                ...state,
                authLoading: action.status
            }
        case authenticAction.INIT_AUTH_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                userData: action.userData
            }
        case authenticAction.INIT_AUTH_SUCCESS: 
            return {
                ...state,
                isAuthenticated: true,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken
            }
        case authenticAction.INIT_AUTH_LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                accessToken: null,
                refreshToken: null,
                tokenData: null,
                userData: null
            }
        default:
            return state
    }
}

