import { put, delay } from 'redux-saga/effects'
import * as authenticationCreator from '../Actions/Authentication-creator'
import * as globalPopupHandler from '../Actions/popup-actionCreator'
import axiosInstance from '../../environment/axiosInstances'
import localStorageContent from '../../services/localStorage'
import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';

const extractTokenData = (token: any) => {
    if (token) {
        const decodedHeader: any = jwt_decode(token);
        if (decodedHeader?.key) {
            const decryptData2 = (encryptedData: any) => {
                var C = CryptoJS;
                var Key = C.enc.Utf8.parse("oSOhgvKFi2AbgyVwtKMKwFV8pSc5kyxU");
                var IV = C.enc.Utf8.parse("oSOhgvKFi2AbgyVw");
                var decryptedText = C.AES.decrypt(encryptedData, Key, {
                    iv: IV,
                    mode: C.mode.CBC,
                    padding: C.pad.Pkcs7
                });
                return decryptedText.toString(CryptoJS.enc.Utf8);
            }
            const userData = JSON.parse(decryptData2(decodedHeader?.key)) ? JSON.parse(decryptData2(decodedHeader?.key)) : ""
            
            return userData ? userData : null
        }
    }else{
        return null
    }
}

export function* init_auth_saga(action: any): Generator{
    try {
        yield put(authenticationCreator.authLoding(true))
        const authResponse: any = yield axiosInstance.post('/auth/user/login', action.data);
        if (authResponse.data.data && authResponse.status === 200){
            const { access, refresh } = authResponse.data.data;
            const { token } = access;
            
            Cookies.set('access', JSON.stringify(access), { expires: new Date(access.expires) })
            Cookies.set('refresh', JSON.stringify(refresh), { expires: new Date(refresh.expires) })
            localStorageContent.setLocalUserData({ authenticated: true, access: access, refresh: refresh })

            // yield put(authenticationCreator.authSuccess({ accessToken: access, refreshToken: refresh }))
            yield put(authenticationCreator.authSuccess({ accessToken: access, refreshToken: refresh }))
            yield put(globalPopupHandler.initglobalPopup(authResponse.data.message, 'LOGIN_ROUTE', 'success'))
            yield put(authenticationCreator.initAuthTimeout(access))
            yield put(authenticationCreator.authLoding(false))

            const extractToken: any = extractTokenData(token)
            if (extractToken) {
                yield put(authenticationCreator.initAuthToken(extractToken))
            }
        }else{
            yield put(authenticationCreator.authLoding(false))
        }
    } catch (error:any) {
        yield put(authenticationCreator.authLoding(false))
        yield put(globalPopupHandler.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}

export function* init_auth_saga_LoginAs(action: any): Generator{
    try {
        yield put(authenticationCreator.authLoding(true))
        const authResponse: any = yield axiosInstance.post("/auth/login-as", action.data);
        if (authResponse.data.data && authResponse.status === 200){
            const { access, refresh } = authResponse.data.data;
            const { token } = access;
            
            Cookies.set('access', JSON.stringify(access), { expires: new Date(access.expires) })
            Cookies.set('refresh', JSON.stringify(refresh), { expires: new Date(refresh.expires) })
            localStorageContent.setLocalUserData({ authenticated: true, access: access, refresh: refresh })

            // yield put(authenticationCreator.authSuccess({ accessToken: access, refreshToken: refresh }))
            yield put(authenticationCreator.authSuccess({ accessToken: access, refreshToken: refresh }))
            yield put(globalPopupHandler.initglobalPopup(authResponse.data.message, 'LOGIN_ROUTE', 'success'))
            yield put(authenticationCreator.initAuthTimeout(access))
            yield put(authenticationCreator.authLoding(false))
            yield put(authenticationCreator.authProfileData())
            const extractToken: any = extractTokenData(token)
            if (extractToken) {
                yield put(authenticationCreator.initAuthToken(extractToken))
            }
        }else{
            yield put(authenticationCreator.authLoding(false))
        }
    } catch (error:any) {
        yield put(authenticationCreator.authLoding(false))
        yield put(globalPopupHandler.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}

export function* init_auth_profile_data(action: any): Generator{
    try{
        yield put(authenticationCreator.authLoding(true))
        const profileData: any = yield axiosInstance.get('/users')
        if(profileData.data && profileData.status === 200){
            yield put(authenticationCreator.authprofileDataSuccess({ userData: profileData.data.data }))
            yield put(authenticationCreator.authLoding(false))
        }else{
            yield put(globalPopupHandler.initglobalPopup(profileData.data.message, 'AUTH_DATA_FAIL', 'error'))
            yield put(authenticationCreator.authLoding(false))
        }
    }catch(err: any){
        yield put(authenticationCreator.authLoding(false))
        yield put(globalPopupHandler.initglobalPopup(err.message === 'Network Error' ? 'Network Error' : err.response.data.message, 'AUTH_DATA_FAIL', 'error'))
    }
}

export function* init_auth_timeout(action: any): Generator{
    try {
        const rememberMe: any = yield localStorage.getItem('rememberme');
        const ParseRememeber: any = rememberMe !== undefined ? JSON.parse(rememberMe) : null
        const { expires }: any = action.tokenData;
        const timeOut: any = Math.ceil(new Date(expires).getTime() - new Date().getTime());
        yield delay(timeOut)
        if (ParseRememeber){
            yield put(authenticationCreator.initRefreshToken())
        }else{
            yield put(authenticationCreator.initAuthLogout())
        }

    } catch (error) {
    }
}

export function* init_auth_logout(action: any):Generator{
    try {
        yield localStorageContent.setLocalUserData({ authenticated: false, access: null, refresh: null })
        const refreshToken: any = yield Cookies.get('refresh')
        const parseRefresToken = refreshToken !== undefined ? JSON.parse(refreshToken) : undefined;
        if (parseRefresToken !== undefined && parseRefresToken?.token) yield axiosInstance.post('/admin/auth/logout', { refreshToken: parseRefresToken?.token })
    
        yield Cookies.remove('access')
        yield Cookies.remove('refresh')
        yield put(authenticationCreator.initAuthLogoutSuccess())
    } catch (error) {
        
    }
}

export function* init_auth_status(): Generator{
    const accessToken: any = Cookies.get('access');
    const refreshToken: any = Cookies.get('refresh');
    const rememberMe: any = localStorage.getItem('rememberme');

    const accessTokenParse = accessToken !== undefined ? JSON.parse(accessToken) : undefined
    const refreshTokenParse = refreshToken !== undefined ? JSON.parse(refreshToken) : undefined
    const parseRemember = rememberMe !== undefined ? JSON.parse(rememberMe) : undefined;
    const currentTime = new Date();
    if (accessTokenParse !== undefined && refreshTokenParse !== undefined){
        const { expires, token } = accessTokenParse
        if (currentTime > new Date(expires)){
            yield put(authenticationCreator.initAuthLogout())
        }else{
            yield put(authenticationCreator.authSuccess({ accessToken: accessTokenParse, refreshToken: refreshTokenParse }))
            yield put(authenticationCreator.initAuthTimeout(accessTokenParse))
            const extractToken: any = extractTokenData(token)
            if (extractToken){
                yield put(authenticationCreator.initAuthToken(extractToken))
            }
        }
    } else if (accessTokenParse === undefined && parseRemember !== undefined && parseRemember === true){
        yield put(authenticationCreator.initRefreshToken())
    }else{
        yield put(authenticationCreator.initAuthLogout())
    }
}


export function* init_refresh_token(): Generator{
    try {
        const refreshToken: any = yield Cookies.get('refresh')
        const parseRefreshData: any = refreshToken !== undefined ? JSON.parse(refreshToken) : undefined;

        if(parseRefreshData){
            const responseTokenData: any = yield axiosInstance.post('/admin/auth/refresh-tokens', { refreshToken: parseRefreshData?.token });

            if (responseTokenData.data.data){
                const { access, refresh } = responseTokenData.data.data;
                const { token } = access;
                yield Cookies.remove('access')
                yield Cookies.remove('refresh')
                yield Cookies.set('access', JSON.stringify(access), { expires: new Date(access.expires) })
                yield Cookies.set('refresh', JSON.stringify(refresh), { expires: new Date(refresh.expires) })
                yield localStorageContent.setLocalUserData({ authenticated: true, access: access, refresh: refresh })
                yield put(authenticationCreator.authSuccess({ accessToken: access, refreshToken: refresh }))
                yield put(authenticationCreator.initAuthTimeout(access))
                const extractToken: any = extractTokenData(token)
                if (extractToken) {
                    yield put(authenticationCreator.initAuthToken(extractToken))
                }
            }else{
                yield put(authenticationCreator.initAuthLogout())
               
            }
        }else{
            yield put(authenticationCreator.initAuthLogout())
            
        }
    } catch (error) {
        yield put(authenticationCreator.initAuthLogout())

    }
}