import {
  Button,
  Divider,
  Grid,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactSelect from "react-select";
import React from "react";
import { useStyles } from "./style";
import { useHistory } from "react-router-dom";
import * as popUpActionCreator from "../../store/Actions/popup-actionCreator";
import axiosInstance from '../../environment/axiosInstances'
import { connect } from "react-redux";

function AddTicket(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState<any>(true);
  const [departmentList, setDepartmentList] = React.useState<any>([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [ticketData, setTicketData] = React.useState({
    subject: "",
    departmentId: "",
    ticketBody: "",
  })

  const getDepartmentList = React.useCallback(() => {
    setLoading(true);
    axiosInstance.get('/admin/departments').then((res: any) => {
      if (res.data.data) {
        setDepartmentList(res.data.data)
      } else {
        setLoading(false)
      }
    }).catch((err: any) => {
      setLoading(false)
    })
  }, [])


  React.useEffect(() => {
    getDepartmentList()
  }, [])


  var DepartmentList: any = [];
  departmentList.map((item: any) => {
    var object = {
      "value": item.id,
      "label": item.departmentName
    }
    DepartmentList.push(object)
  })



  const onChangeOpen = () => {
    setOpen(!open);
  };

  const onContentChangeEvent = (e: any) => {
    setTicketData((prev) => {
      return {
        ...ticketData,
        [e.target.name]: e.target.value,
      }
    })
  }


  const onContentDepartmentChangeEvent = (e: any) => {
    setTicketData((prev) => {
      return {
        ...ticketData,
        departmentId: e.value,
      }
    })
  }


  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      subject: { ...validData },
      departmentId: { ...validData },
      ticketBody: { ...validData },
    };
    let isValid = true;
    if (submitted) {
      if (!ticketData.subject) {
        retData.subject = {
          isValid: false,
          message: "Please enter subject"
        };
        isValid = false;
      }
      if (!ticketData.departmentId) {
        retData.departmentId = {
          isValid: false,
          message: "Please enter department id"
        };
        isValid = false;
      }
      if (!ticketData.ticketBody) {
        retData.ticketBody = {
          isValid: false,
          message: "Please enter ticket body"
        };
        isValid = false;
      }
    }
    return {
      ...retData,
      isValid
    };
  };

  const onSubmitForm = () => {
    setSubmitted(true);
    const errorData = validate(true);
    if (errorData.isValid) {
      axiosInstance.post('/tickets', ticketData).then((res: any) => {
        if (res.data.data) {
          props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
          setTicketData({
            subject: "",
            departmentId: "",
            ticketBody: "",
          })
          history.push('/tickets')
        } else {
          setLoading(false)
          props.popUpAlert(res.data.message, "UPDATE_AGENT_FAIL", "error");
        }
      }).catch((err: any) => {
        setLoading(false)
        props.popUpAlert(err.response.data.message, "UPDATE_AGENT_FAIL", "error");
      })
    }
  }

  const errors = validate(submitted);

  return (
    <div>
      <Accordion
        className={classes.accordion}
        expanded={open}
        onChange={onChangeOpen}
        style={{ marginTop: 30 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.summary}>Add Name - Ticket</Typography>
        </AccordionSummary>
        <Divider className={classes.divider} />
        <AccordionDetails>
            <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={8} lg={8} md={10} sm={11} xs={12}>
                  <Grid container className={classes.inputFields}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={12} style={{textAlign:"center"}}>
                      <Typography className={classes.subject}>Subject</Typography>
                    </Grid>
                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                    <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="subject"
                    onChange={onContentChangeEvent}
                    placeholder="Subject"
                    className={classes.selctBox}
                    error={!errors.subject.isValid}
                  />
                  {
                    !errors.subject.isValid &&
                    <p style={{ color: 'red', fontSize: '0.8rem', fontWeight: 500, marginTop: 0, marginBottom: 0, }}>
                      {errors.subject.message}
                    </p>
                  }
                    </Grid>
                  </Grid>
                  <Grid container className={classes.inputFields}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={12} style={{textAlign:"center"}}>
                      <Typography className={classes.subject}>Message</Typography>
                    </Grid>
                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                    <TextareaAutosize
                    minRows={4}
                    maxRows={5}
                    placeholder="Enter Message Here"
                    onChange={onContentChangeEvent}
                    name="ticketBody"
                    className={classes.note}
                  />
                  {
                    !errors.ticketBody.isValid &&
                    <p style={{ color: 'red', fontSize: '0.8rem', fontWeight: 500, marginTop: 0, marginBottom: 0, }}>
                      {errors.ticketBody.message}
                    </p>
                  }
                    </Grid>
                  </Grid>
                  <Grid container className={classes.inputFields}>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={12} style={{textAlign:"center"}}>
                       <Typography className={classes.subject}>Department</Typography>
                    </Grid>
                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                    <ReactSelect
                    placeholder="Department"
                    className={classes.selctBox}
                    options={DepartmentList}
                    name="departmentId"
                    onChange={onContentDepartmentChangeEvent}
                  />
                  {
                    !errors.departmentId.isValid &&
                    <p style={{ color: 'red', fontSize: '0.8rem', fontWeight: 500, marginTop: 0, marginBottom: 0, }}>
                      {errors.departmentId.message}
                    </p>
                  }
                    </Grid>
                  </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item md={12} style={{ textAlign: "center" }}>
              <Button
                size="small"
                variant="outlined"
                className={classes.searchButton}
                onClick={onSubmitForm}
              >
                Save
              </Button>
              <Button
                size="small"
                variant="outlined"
                className={classes.clearButton}
                onClick={() => history.push("/tickets")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
  }
}

export default connect(null, mapDispatchToProps)(AddTicket)

