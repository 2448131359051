import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    accordian: {
        marginTop: 20, borderRadius: 10,background: '#FFFFFF',boxShadow: '0 0 0.5em #E2E2E2',
    },
    heading:{
        color: "#2645ad",
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'work sans'
    },
    pagination: {
        float: 'right',
        margin: '15px 0px'

    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    accordianNoFlex:{
        display: 'block'
    },
    search: {
      background: "#2645AD",
      textTransform: "capitalize",
      border: "0.791301px solid #3A416F",
      borderRadius: 3,
      color: "#FFFFFF",
      padding: '5px 30px',
      fontFamily: 'work sans',
      marginRight: 10,
      fontSize: 14,
      "&:hover": {
        background: "#2645AD",
        color: "#FFFFFF",
      },
    },
    clear: {
        background: "#B70909",
        border: "0.791301px solid #8392AB",
         fontFamily: 'work sans',
        borderRadius: 3,
        textTransform: "capitalize",
        padding: '5px 30px',
        fontSize: 14,
        color: '#FFFFFF',
        "&:hover": {
            background: "#B70909",
            color: '#FFFFFF',
        },
    },
    tableHead: {
        fontFamily: 'work sans',
        fontWeight: 600,
        fontSize: 16,
        color: '#25282B',
    },
    tableData:{
        fontFamily: 'work sans',
        fontWeight: 600,
        fontSize: 16,
        color: '#707070',
        //textAlign: 'center'
        alignItems: 'center'
    },
    calls: {
      fontSize: 17,
      fontFamily: 'work sans',
      fontWeight: 500,
      color: '#707070',
      marginLeft: 16,
      '@media (max-width:426px)':{
       fontSize:14
      }  
    }
}))