import { authenticAction } from './Actions'

export const initAuth = (authData: any) => {
    return {
        type: authenticAction.INIT_AUTH,
        data: authData
    }
}

export const initAuthLoginAs = (authData: any) => {
    return {
        type: authenticAction.INIT_AUTH_LOGIN_AS,
        data: authData
    }
}

export const authLoding = (status: any) => {
    return {
        type: authenticAction.AUTH_LOADING,
        status: status
    }
}

export const authProfileData = () => {
    return {
        type: authenticAction.INIT_AUTH_PROFILE_DATA,
    }
}

export const authprofileDataSuccess = (action: any) => {
    return {
        type: authenticAction.INIT_AUTH_PROFILE_DATA_SUCCESS,
        userData: action.userData
    }
}
 
export const authSuccess = (authResponse: any) => {
    return {
        type: authenticAction.INIT_AUTH_SUCCESS,
        accessToken: authResponse.accessToken,
        refreshToken: authResponse.refreshToken
    }
}

export const initAuthToken = (tokenData: any) => {
    return {
        type: authenticAction.INIT_TOKEN_DATA,
        token_data: tokenData
    }
}


export const initAuthTimeout = (tokenData: any) => {
    return {
        type: authenticAction.INIT_AUTH_TIMEOUT,
        tokenData: tokenData
    }
}

export const initAuthLogout = () => {
    return {
        type: authenticAction.INIT_AUTH_LOGOUT
    }
}

export const initAuthLogoutSuccess = () => {
    return {
        type: authenticAction.INIT_AUTH_LOGOUT_SUCCESS
    }
}

export const initAuthStatus = () => {
    return {
        type: authenticAction.INIT_AUTH_STATUS
    }
}

export const initRefreshToken = () => {
    return {
        type: authenticAction.INIT_AUTH_REFRESH_TOKEN
    }
}