import axiosInstance from '../../environment/axiosInstances';

class ticketServices {

     fecthTickets = async (URL: any) => {
        try{
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200){
                return { success: true, data: response.data }
            }else{
                return { success: false, message: response?.message }
            }
        }catch(err: any){
            return { success: false, message: err.response.data.message  }
        }
    }
    
    getDepartmentList = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    addTicket = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 201) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message  }
        }
    }

    updateTicket = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.patch(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message  }
        }
    }


    //comments
    fetchComments = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.message }
        }
    }

    addComment = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message  }
        }
    }

}
const ticketsServices = new ticketServices()
export default ticketsServices