import {  status,OutBoundConstants } from '../Constants';

export function outboundUsers(state = {}, action: any) {
    switch (action.type) {
        case OutBoundConstants.SEARCH_OUTBOUND_REQUEST:
            return {
                ...state,
                search_outbound_status: status.IN_PROGRESS,
                searchOutBoundList: action.data
            };
        case OutBoundConstants.SEARCH_OUTBOUND_SUCCESS:
            return {
                ...state,
                search_outbound_status: status.SUCCESS,
                searchOutBoundList: action.data
            };
        case OutBoundConstants.SEARCH_OUTBOUND_FAILURE:
            return {
                ...state,
                search_outbound_status: status.FAILURE,
                searchOutBoundList: null
            };
        default:
            return state;
    }
}