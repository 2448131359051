import { Card, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useStyles } from './styles'
import { Dataprovider } from '../../Index'
import TableBodyData from './TableBody'

function RecomendedAddons() {
    const classes = useStyles()
    const contextData: any = React.useContext(Dataprovider);
    const [calls, setCalls] = React.useState<any>([])
    const [sms, setSms] = React.useState<any>([])
    const [features, setFeatures] = React.useState<any>([])

    React.useEffect(() => {
        if (contextData.recommendedAddons){
            const { callAddons, smsAddons, featureAddons } = contextData.recommendedAddons;
            // if (callAddons && callAddons.length > 0){
            setCalls(callAddons ? callAddons : [])
            // }
            // if (smsAddons && smsAddons.length > 0) {
            setSms(smsAddons ? smsAddons : [])
            // }
            // if (featureAddons && featureAddons.length > 0) {
            setFeatures(featureAddons ? featureAddons : [])
            // }
        }
    }, [contextData])

    return (
         <div>
            <Typography className={classes.addons} id="heading2">Recomended Addons</Typography>
            <Divider className={classes.divider} style={{marginTop:10}}/>
            <Grid container spacing={5}>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                    <Card className={classes.cardStyles} >
                        <TableContainer id="scrollbar">
                            <div className={classes.calls}>Calls</div>
                            <Divider className={classes.divider}/>
                            <Table  >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Addons</TableCell>
                                        <TableCell className={classes.tableHead}>Cost</TableCell>
                                        <TableCell className={classes.tableHead}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {
                                        calls.length > 0 &&
                                            calls.map((cl: any, i: any) => <TableBodyData rowData={cl} />)
                                    }
                                </TableBody>
                            </Table>
                            {
                                calls.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                            }
                        </TableContainer>
                    </Card>
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                     <Card className={classes.cardStyles}>
                        <TableContainer id="scrollbar">
                            <div className={classes.calls}>SMS</div>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Addons</TableCell>
                                        <TableCell className={classes.tableHead}>Cost</TableCell>
                                        <TableCell className={classes.tableHead}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        sms.length > 0 &&
                                            sms.map((cl: any, i: any) => <TableBodyData rowData={cl} />)
                                    }
                                </TableBody>
                            </Table>
                            {
                                sms.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                            }
                        </TableContainer>
                    </Card>
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                     <Card className={classes.cardStyles} >
                        <TableContainer id="scrollbar">
                            <div className={classes.calls}>Features</div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Addons</TableCell>
                                        <TableCell className={classes.tableHead}>Cost</TableCell>
                                        <TableCell className={classes.tableHead}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        features.length > 0 &&
                                        features.map((cl: any, i: any) => <TableBodyData rowData={cl} />)
                                    }
                                </TableBody>
                            </Table>
                            {
                                features.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                            }
                        </TableContainer>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default RecomendedAddons
