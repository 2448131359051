import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
    {
        cardstyles: {
            background: "#FFFFFF",
            borderRadius: "8px",
            boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
          },
          clientUpdater: {
            color: "#3A416F",
            fontWeight: 500,
            fontFamily: "work Sans",
            fontStyle: "normal",
            fontSize: 20,
          },
          divider: {
            background: "#E2E2E2",
          },
          dflex:{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%'},
          tableCard: {
            background: "#FFFFFF",
            borderRadius: "10.3033px",
            border: "1px solid #F5F5F5",
            boxShadow: "0px 17.1721px 23.1824px rgba(0, 0, 0, 0.05)",
            marginTop: 10,
            overflow: "visible"
          },
          addButton:{
            // marginTop: 20,
            background: " #3A416F",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            textTransform: "capitalize",
            fontSize: 12,
            marginRight: 22,
           
            borderRadius: "4.7478px",
            "&:hover": {
              color: "#FFFFFF",
              background: " #3A416F",
            },
          },
          subject: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontSize: '16px',
            fontWeight: 500,
            marginBottom: 10
          },
          comment: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontSize: '16px',
            marginTop: '10px',
            fontWeight: 500,
          },
          dept: {
              fontFamily: "Work Sans",
            fontStyle: "normal",
            fontSize: '16px',
            marginBottom: 10,
            fontWeight: 500,
          }
    },
    
    
);