import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { useStyles } from './styles'
import profileDataservices from '../../../components-services/MyAccountPage/ProfileData'
import { useSelector } from 'react-redux'
import * as globalPopupCreator from '../../../store/Actions/popup-actionCreator';
import { useDispatch }  from 'react-redux'
import Loader from '../../../components/Loader/Loader'
import CircularProgress from '@mui/material/CircularProgress';


function EditEmergencyAddress(props: any) {
    const classes = useStyles();
     const dispatch = useDispatch()
    const userAuthData = useSelector((state: any) => state.authState)
    const [loading, setLoading] = React.useState<any>(false)
    const [updatedData, setUpdatedData] = React.useState<any>({
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        streetNumber: "",
        streetName: "",
        country: "",
        postalCode: "",
        province: "",
        accountId: ""
    });
    const [eDetails, setEDetails] = React.useState<any>({});

    React.useEffect(() => {
        if (userAuthData.userData){
            const { id } = userAuthData.userData
            setUpdatedData(() => {
                return {
                    ...updatedData,
                    accountId: id
                }
            });
        }
    }, [userAuthData.userData])
    
    const getE911details = () => {
        setLoading(true)
        profileDataservices.gete911details("/accounts/e911").then((res: any) => {
            if(res.success){
                const {address,city,country,firstName,lastName,postalCode,province,streetName,streetNumber} = res.data;
                setUpdatedData((prev: any) => {
                    return {
                        ...prev,
                        firstName: firstName,
                        lastName: lastName,
                        address: address,
                        city: city,
                        streetNumber: streetNumber,
                        streetName: streetName,
                        country: country,
                        postalCode: postalCode,
                        province: province,
                        //accountId: accountId
                    }
                })
                setLoading(false)
            }else{
                setLoading(false)
            }
        }).catch((err: any) => {
            setLoading(false)
        })
    }

    React.useEffect(() => {
        getE911details()
    },[])

    const updateE911Details = (data: any) => {
        setLoading(true)
        profileDataservices.postE911Details("/accounts/e911", data).then((res: any) => {
            if (res.success) {
                //setEDetails(res.data)
                getE911details()
                props.popupHandlerE91Details()
                setLoading(false)
                props.popupHandlerE91Details()
                dispatch(globalPopupCreator.initglobalPopup("e911 Details updated successfully", "SUBSCRIPTION_ADDED", "success"))
            } else {
                setLoading(false)
                dispatch(globalPopupCreator.initglobalPopup(res.message, "SUBSCRIPTION_ADDED", "error"))
            }
        }).catch((err: any) => {
            setLoading(false)
        })
    }

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then((results) => {
                var myResults = results[0]
                var city = ""
                //var state = ""
                var country = ""
                var postalCode = ""
                var streetNumber = ""
                var streetName = ""
                var province = ""

                getLatLng(myResults).then((latLong) => {
                    myResults.address_components.map((item) => {
                        if (item.types.includes("locality")) {
                            city = item.long_name
                        }
                        // if (item.types.includes("administrative_area_level_1")) {
                        //   state = item.long_name
                        // }
                        if (item.types.includes("country")) {
                            country = item.long_name
                        }
                        if (item.types.includes("postal_code")) {
                            postalCode = item.long_name
                        }
                        if (item.types.includes("street_number")) {
                            streetNumber = item.long_name
                        }
                        if (item.types.includes("route")) {
                            streetName = item.short_name
                        }
                        if (item.types.includes("administrative_area_level_1")) {
                            province = item.short_name
                        }

                    })
                    setUpdatedData(() => {
                        return {
                            ...updatedData,
                            address: myResults.formatted_address,
                            city: city,
                            streetNumber: streetNumber,
                            streetName: streetName,
                            country: country,
                            postalCode: postalCode,
                            province: province
                        }
                    });
                })
            })
            .catch(error => console.error('Error', error));
    };

    const handleChange = (data: any) => {
        setUpdatedData(() => {
            return {
                ...updatedData,
                address: data
            }
        });
    };

    const onchangeData= (e: any) => {
        setUpdatedData(() => {
            return {
                ...updatedData,
                [e.target.name]: e.target.value
            }
        });
    }

    return (
    <>
        {
            loading ? <div style={{display: 'grid', alignItems: 'center',marginLeft: '170px',marginTop: '10px'}}>
                        <CircularProgress disableShrink  />
                    </div>:(
                    <div>
                        <Grid container spacing={4} style={{margin: '1px 0px'}}>
                            <Grid item md={10}>
                                    <TextField 
                                        variant="outlined"
                                        size='small'
                                        name="firstName"
                                        fullWidth
                                        placeholder='First Name'
                                        value={updatedData.firstName}
                                        onChange={onchangeData}
                                        label="First Name" 
                                    />
                                    <TextField 
                                        variant="outlined"
                                        size='small'
                                        name="lastName"
                                        value={updatedData.lastName}
                                        fullWidth
                                        placeholder='Last Name'
                                        style={{margin: '16px 0px 0px'}}
                                        label="Last Name" 
                                        onChange={onchangeData} 
                                    />
                                    <PlacesAutocomplete
                                        value={eDetails.address ? eDetails.address : updatedData.address}
                                        onChange={handleChange}
                                        onSelect={handleSelect}
                                        
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
                                            <div>
                                                {/* <input
                                            style={{
                                            width: "95%", padding: 10, color: '#8392AB', position: 'relative',
                                            borderRadius: ' 4px', borderColor: 'rgba(0, 0, 0, 0.23)', fontFamily: "sans-serif",
                                            border: "1px solid #3f51b5"
                                            }}
                                            {...getInputProps({
                                            placeholder: 'Search address',
                                            className: 'location-search-input',
                                            })}
                                        /> */}
                                                <TextField
                                                    style={{marginTop: '12px'}}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    {...getInputProps({
                                                        placeholder: 'Search address',
                                                        className: 'location-search-input',
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map((suggestion: any) => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div style={{ display: "flex", border: "1px solid #d4d5d9", padding: 14 }} >
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    <TextField
                                        style={{margin: '16px 0px 0px'}} 
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        name='streetNumber'
                                        placeholder='Street Number'
                                        value={updatedData.streetNumber}
                                        label="Street Number"
                                        onChange={onchangeData}   
                                    />
                                    <TextField
                                        style={{margin: '16px 0px 0px'}} 
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        name="streetName"
                                        value={updatedData.streetName}
                                        placeholder='Street Name'
                                        label="Street Name"
                                        onChange={onchangeData}   
                                    />
                                    <TextField
                                        style={{margin: '16px 0px 0px'}} 
                                        variant="outlined"
                                        size='small'
                                        value={updatedData.city}
                                        fullWidth
                                        name='city'
                                        placeholder='City'
                                        label="City"
                                        onChange={onchangeData}    
                                    />
                                    <TextField
                                        style={{margin: '16px 0px 0px'}} 
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        name="province"
                                        value={updatedData.province}
                                        placeholder='Province'
                                        label="Province"
                                        onChange={onchangeData}   
                                    />
                                    <TextField
                                        style={{margin: '16px 0px 0px'}} 
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        name="postalCode"
                                        value={updatedData.postalCode}
                                        placeholder='Postal Code'
                                        label="Postal Code"
                                        onChange={onchangeData}   
                                    />
                                    <TextField
                                        style={{margin: '16px 0px 0px'}} 
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        placeholder='Country'
                                        name="country"
                                        value={updatedData.country}
                                        label="Country"
                                        onChange={onchangeData}   
                                    />
                                    <div style={{textAlign: 'center',margin: '10px 0px'}}>
                                        <Button onClick={() => updateE911Details(updatedData)} 
                                            variant='outlined' size="small" className={classes.updateButton}
                                        >
                                            Update
                                        </Button>
                                    </div>
                            </Grid>
                            
                        </Grid> 
                    </div>
            )
        }
    </>
    )
}

export default EditEmergencyAddress
