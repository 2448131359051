import {  status,departmentNameConstants,editDepartmentUsersConstans } from '../Constants';

export function departmentUsers(state = {}, action: any) {
    switch (action.type) {
        case departmentNameConstants.SEARCH_DEPARTMENT_REQUEST:
            return {
                ...state,
                search_department_status: status.IN_PROGRESS,
                searchDepartmentList: action.data
            };
        case departmentNameConstants.SEARCH_DEPARTMENT_SUCCESS:
            return {
                ...state,
                search_department_status: status.SUCCESS,
                searchDepartmentList: action.data
            };
        case departmentNameConstants.SEARCH_DEPARTMENT_FAILURE:
            return {
                ...state,
                search_department_status: status.FAILURE,
                searchDepartmentList: null
            };
        default:
            return state;
    }
}

export function editDepartmentUsers(state = {}, action: any) {
    switch (action.type) {
        case editDepartmentUsersConstans.EDIT_DEPARTMENT_REQUEST:
            return {
                ...state,
                edit_department_status: status.IN_PROGRESS,
                editDepartmentList: action.data
            };
        case editDepartmentUsersConstans.EDIT_DEPARTMENT_SUCCESS:
            return {
                ...state,
                edit_department_status: status.SUCCESS,
                editDepartmentList: action.data
            };
        case editDepartmentUsersConstans.EDIT_DEPARTMENT_FAILURE:
            return {
                ...state,
                edit_department_status: status.FAILURE,
                editDepartmentList: null
            };
        default:
            return state;
    }
}