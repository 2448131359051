import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { useStyles } from '../styles'
import './style.css'
function TableBody(props: any) {
    const classes = useStyles();

    const { addonName, freeSms, countries, remainingSms, status } = props.rowData

    return (
        <TableRow key={props.key}>
            <TableCell>
                
                 <Card className={classes.smallCard} >
                     <div id="scrollbar2">
                    <table className='tables' style={{textDecoration: 'none'}} >
                            <tr className='heads' style={{textDecoration: 'none'}}>
                                <th className='tdth'>Route</th>
                                <th className='tdth'>Name</th>
                            </tr>
                            {
                                countries.length > 0 && countries.map((ct: any) => (
                                    <tr>
                                        <td className='tdth'>{ct.id}</td>
                                        <td className='tdth'>{ct.name}</td>
                                    </tr>
                                ))
                            }
                        </table>
                        </div>
                </Card> 
            </TableCell>
            <TableCell className={classes.tableData}>{addonName !== undefined ? addonName : ""}</TableCell>
            <TableCell className={classes.tableData}>{freeSms !== undefined ? freeSms : ""}</TableCell>
            <TableCell className={classes.tableData}>{remainingSms !== undefined ? remainingSms : ""}</TableCell>
            <TableCell>
                {
                    status !== undefined ? (
                        status ? (
                            <p className={classes.activeButton}>
                                Active
                            </p>
                        ) : (
                            <p className={classes.inactiveButton}>
                                In Active
                            </p>
                        )
                    ) : ""
                }
            </TableCell>
        </TableRow>
    )
}

export default TableBody
