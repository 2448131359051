
import {
    TableRow, TableCell, IconButton
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useStyles } from '../../styles';
import { BsFillChatFill } from 'react-icons/bs'
import Moment from 'moment'
function TableBody(props: any) {
    const classes = useStyles()
    const history = useHistory()
    const { id, createdDate, subject, ticketStatus, departmentId } = props.rowData;

    return (

        <TableRow>
            {/* <TableCell className={classes.tableData}>{id ? id : ""}</TableCell> */}
            <TableCell className={classes.tableData}>{subject ? subject : ""}</TableCell>
            <TableCell className={classes.tableData}>{createdDate ? <div>{Moment(Number(createdDate)).format('YYYY-MM-DD HH:MM:SS')}</div> : ""}</TableCell>
            {/* <TableCell className={classes.tableData}>{new Date(Number(createdDate)).toLocaleString()}</TableCell> */}
            <TableCell className={classes.tableData}>
                {ticketStatus === 1 &&
                    <>
                        <p style={{ color: 'red' }}>Pending</p>
                    </>
                }
                {ticketStatus === 2 &&
                    <>
                        Review
                    </>
                }
                {ticketStatus === 3 &&
                    <>
                        <p style={{ color: 'green' }}>Closed</p>
                    </>
                }
            </TableCell>

            <TableCell className={classes.tableData}>{departmentId ? departmentId?.departmentName : ""}</TableCell>
            <TableCell className={classes.tableData}>
                <div style={{ display: 'flex' }}>
                    <div>
                        <IconButton onClick={() => history.push({ pathname: `comments/${id}`, state: { ticketType: 'closed' } })}
                            style={{ width: 28, height: 28, borderRadius: 4, padding: 7, background: '#2645AD' }}
                        >
                            <BsFillChatFill color='#FFFFFF' />
                        </IconButton>
                    </div>
                    <div>
                        <IconButton>
                            {/* <MdOutlineCancelPresentation /> */}
                        </IconButton>
                    </div>
                </div>
            </TableCell>
        </TableRow>

    )
}

export default TableBody
