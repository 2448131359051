import {
    Accordion, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Typography
} from '@material-ui/core';
import { AccordionDetails } from '@mui/material'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import ReactSelect from 'react-select'
import React from 'react'
import { useStyles } from './styles';
import SearchFilters from './SearchFilters';
import LogsTable from './LogsTable/LogsTable';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi'
import LogsServicesData from '../../../components-services/Logs/LogsServices'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function CallLogs(props: any) {
    const classes = useStyles();
    const [loading, setloading] = React.useState<any>(false)
    const [callLogsData, setCallLogsData] = React.useState<any>([])
    const [isSearching, setIsSearching] = React.useState(false)
    const [searchFilterValue, setSearchFilterValue] = React.useState<any>({
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 59),
        number: '',
        callStatus: '',
        callType: '',
        callError: '',
        osType: ''
    })
    const [paginate, setPaginate] = React.useState({
        limit: 5,
        totalDocs: 0,
        page: 1,
        totalPages: 0
    })

    const [open, setOpen] = React.useState(true);
    const handleChange = () => {
        setOpen(!open);
    };

    const onChangeFilterHanlder = (prop: any, value: any) => {
        setSearchFilterValue((prev: any) => {
            return {
                ...prev,
                [prop]: value
            }
        })
    }

    const clearFilter = () => {
        setIsSearching(false)
        fetchCallLogs(paginate.limit, 1)
        setSearchFilterValue({
            startDate: new Date().setHours(0, 0, 0, 0),
            endDate: new Date().setHours(23, 59, 59, 59),
            number: '',
            callStatus: '',
            callType: '',
            callError: '',
            osType: ''
        })
    }

    const SearchFilterFun = (searchValue: any, limit: any, page: any) => {
        setIsSearching(true)
        if (Object.keys(searchValue).length === 0) {
            return alert('Please enter all fields')
        }
        setIsSearching(true)
        setloading(true)
        let obj: any = {}
        Object.keys(searchValue).forEach((rest: any) => {
            if (rest === 'callType') {
                obj[rest] = searchValue[rest]
                return
            }
            if (searchValue[rest] !== '' && searchValue[rest] !== undefined) {
                obj[rest] = searchValue[rest]
            }
        })
        let queryParams = Object.keys(obj).map((i) => `${i}=${obj[i]}`).join('&')
        let URLS = `/users/calls?limit=${limit}&page=${page}&${queryParams}`;
        if (searchFilterValue.number !== "" || searchFilterValue.startDate || searchFilterValue.endDate) {
            LogsServicesData.fetchCallLogs(URLS).then((res: any) => {
                if (res.success) {
                    setCallLogsData(res.data.data)
                    setloading(false)
                    setPaginate((prev: any) => {
                        return {
                            ...prev,
                            totalDocs: res.data.totalDocs,
                            totalPages: res.data.totalPages
                        }
                    })
                } else {
                    setloading(false)
                }
            }).catch((err: any) => {
                //err
                setloading(false)
            })
        } else {
            fetchCallLogs(paginate.limit, paginate.page)
        }

    }

    const fetchCallLogs = (limit: Number, page: Number) => {
        setloading(true)
        LogsServicesData.fetchCallLogs(`/users/calls?limit=${limit}&page=${page}`).then((res: any) => {
            if (res.success) {
                setCallLogsData(res.data.data)
                setloading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        totalDocs: res.data.totalDocs,
                        totalPages: res.data.totalPages
                    }
                })
            } else {
                setloading(false)
            }
        }).catch((err: any) => {
            //err
            setloading(false)
        })
    }

    const onChangePage = (e: any, value: any) => {

        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })
        if (isSearching) {
            SearchFilterFun(searchFilterValue, paginate.limit, value)
        } else {
            fetchCallLogs(paginate.limit, value)
        }

    }

    React.useEffect(() => {
        fetchCallLogs(paginate.limit, paginate.page)
    }, [])



    // React.useEffect(() => {
    //     fetchCallLogs()
    // }, [paginate])

    return (
        <div id="Logs">
            <Accordion expanded={open} onChange={handleChange} style={{ marginTop: 20, borderRadius: 10, background: '#FFFFFF', boxShadow: '0 0 0.5em #E2E2E2', }}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading} id='heading2'>Call logs</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        <SearchFilters
                            onChangeFilterHanlder={onChangeFilterHanlder}
                            paginate={paginate}
                            searchFilterValue={searchFilterValue}
                            SearchFilterFun={SearchFilterFun}
                            clearFilter={clearFilter}
                        />
                    </CardContent>
                </AccordionDetails>
                {/* <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                        <Grid container>
                            <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                            <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
                                <Card style={{ width: "100%",  padding: 8,}}>
                                    <div className={classes.mainDiv}>
                                        <div className={classes.subDiv}>
                                            <FiArrowUp style={{color: 'red'}} />
                                            <Typography className={classes.callsApp}>
                                                Calls from app
                                            </Typography>
                                            <Typography className={classes.barLine}>|</Typography>
                                        </div>
                                        <div className={classes.subDiv}>
                                            <FiArrowUp style={{ color: "yellow" }}/>
                                            <Typography className={classes.callsApp}>
                                                Redirect Calls
                                            </Typography>
                                            <Typography className={classes.barLine}>|</Typography>
                                        </div>
                                        <div className={classes.subDiv}>
                                            <FiArrowDown style={{ color: "#0316BF" }}/>
                                            <Typography className={classes.callsApp}>
                                                Calls to app
                                            </Typography>
                                            <Typography className={classes.barLine}>|</Typography>
                                        </div>
                                        <div className={classes.subDiv}>
                                            <FiArrowDown  style={{ color: "#AF0000" }} />
                                            <Typography className={classes.callsApp}>
                                                Voice mode calls
                                            </Typography>
                                            <Typography className={classes.barLine}>|</Typography>
                                        </div>
                                        <div className={classes.subDiv}>
                                            <FiArrowDown style={{color:"#04B400"}}/>
                                            <Typography className={classes.callsApp}>
                                                FWD calls
                                            </Typography>
                                        </div>
                                    </div>
                                    
                                </Card>
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
                        </Grid>
                    </CardContent>
                </AccordionDetails> */}
                <AccordionDetails className={classes.accordianNoFlex}>
                    <Typography className={classes.calls}>Total Number of Calls : {paginate.totalDocs}</Typography>
                    <CardContent>
                        <Card style={{ marginTop: -1 }}>
                            <TableContainer>
                                <LogsTable loading={loading} callLogsData={callLogsData} fetchCallLogs={fetchCallLogs} paginate={paginate} />
                            </TableContainer>

                        </Card>
                        {/* {
                            res.data.data === "" ? */}

                        <div className={classes.pagination}>
                            {callLogsData?.length > 0 ?
                                <Stack spacing={2}>
                                    <Pagination color="primary" onChange={onChangePage} count={paginate.totalPages} variant="outlined" shape="rounded" />
                                </Stack>
                                : ""}

                        </div>
                        {/* : <p>No Data found</p>
                        } */}
                    </CardContent>
                </AccordionDetails>

            </Accordion>
        </div>
    )
}

export default CallLogs
