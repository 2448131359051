import axiosInstance from "../../environment/axiosInstances";

export const clientsServices = {
    clientDetail,
    addClientDetail,
    getEDetails,
    getSyncSettings,
    updateclientDetail,
    getDevicesList,
    getVerifiedNumbers,
    getBlockedNumbers,
    createBlockedNumbers,
    updateBlockedNumbers,
    deleteBlockedNumbers,
    getTickets,
    getVoiceMailList,
    voiceMailCreate,
    deleteVoiceMail,
    getAddressBookList,
    getCallLogs,
    getSmsLogs,
    deleteDevice,
    getOutboundProviders,
    addDevicesList,
    getSipcredentials,
    getUsage,
    getAccountLogs,
    addAddressList,
    clinetUpdateDevice,
    deleteVerifiedNumber,
    addVerifiedNumber,
    addSendOtpList,
    getSubscriptionAddons,
    addSubscriptionAddons,
    removeSubscriptionAddons,
    getRecomendedAddons,
    upadateSyncSettingsDetail,
    getPaymentTransaction
}

function getSubscriptionAddons() {
    return axiosInstance.get(`/accounts/addons`);
}

function addSubscriptionAddons(data: any) {
    return axiosInstance.post(`/accounts/subscribe-addon`, data);
}

function removeSubscriptionAddons(data: any) {
    return axiosInstance.patch(`/accounts/unsubscribe-addon`, data);
}

function getRecomendedAddons() {
    return axiosInstance.get(`/accounts/recomended-addons`);
}

function clientDetail() {
    return axiosInstance.get(`/users`);
}

function getSyncSettings() {
    return axiosInstance.get(`/accounts/syncsettings`);
}

function upadateSyncSettingsDetail(data:any) {
    return axiosInstance.patch(`/accounts/settings`,data);
}


function getEDetails() {
    return axiosInstance.get(`/accounts/e911`);
}

function updateclientDetail(data:any) {
    return axiosInstance.patch(`/users`,data);
}


function addClientDetail(data: any) {
    return axiosInstance.patch(`/accounts/e911`, data);
}

function getDevicesList() {
    return axiosInstance.get(`/devices`);
}

function addDevicesList(data: any) {
    const id = data.id;
    delete data.id;
    return axiosInstance.post(`/admin/efone-clients/${id}/devices`, data);
}

function addAddressList(data: any) {
    const id = data.id;
    delete data.id;
    return axiosInstance.post(`/admin/efone-clients/${id}/addressbook`, data);
}


function deleteDevice(data: any) {
    return axiosInstance.delete(`/devices/${data.deviceId}`);
}

function deleteBlockedNumbers(data: any) {
    return axiosInstance.delete(`/accounts/blockednumbers/${data.blockedNumberId}`);
}

function clinetUpdateDevice(data: any) {
    const deviceId = data.deviceId;
    delete data.deviceId;
    return axiosInstance.patch(`/devices/${deviceId}`, data);
}

function getVerifiedNumbers() {
    return axiosInstance.get(`/accounts/mynumbers`);
}

function addVerifiedNumber(data: any) {
    return axiosInstance.post(`/accounts/mynumbers`, data);
}

function addSendOtpList (data: any) {
    return axiosInstance.post(`accounts/mynumbers/verify-otp`, data);
}

function deleteVerifiedNumber(data: any) {
    return axiosInstance.delete(`/accounts/mynumbers/${data.verifiedNumberId}`);
}

function getBlockedNumbers() {
    return axiosInstance.get(`/accounts/blockednumbers`);
}

function createBlockedNumbers(data: any) {
    return axiosInstance.post(`/accounts/blockednumbers`, data);
}

function updateBlockedNumbers(data: any) {
    var sendData={
        phoneNumber :data.phoneNumber,
        calls : data.calls,
        sms :data.sms
    }
    const id = data._id;
    return axiosInstance.patch(`/accounts/blockednumbers/${id}`, sendData);
}


function getTickets() {
    return axiosInstance.get(`/tickets`);
}

function getVoiceMailList() {
    return axiosInstance.get(`/voicemails`);
}

function voiceMailCreate(data: any) {
    return axiosInstance.post(`/voicemails`, data.data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

function deleteVoiceMail(data: any) {
    return axiosInstance.delete(`/voicemails/${data.voicemailId}`);
}

function getAddressBookList(data: any) {
    return axiosInstance.get(`/admin/efone-clients/${data.id}/addressbook`);
}

function getCallLogs(data: any) {
    if (data.callFormData) {
        return axiosInstance.get(`/users/calls?limit=50&page=${data.pageLimit}&${data.callFormData.callNumber}=${data.callFormData.callSearchKey}&${data.callFormData.callFromDateData}=${data.callFormData.callFromDate}&${data.callFormData.remainderDateData}=${data.callFormData.remainderDate}&${data.callFormData.providerNameData}=${data.callFormData.providerName}&${data.callFormData.callTypeData}=${data.callFormData.callType}&${data.callFormData.callErrorData}=${data.callFormData.callError}&${data.callFormData.deviceTypeData}=${data.callFormData.deviceType}&${data.callFormData.callStatusData}=${data.callFormData.callStatus}`);
    }
    else {
        return axiosInstance.get(`/users/calls?limit=50&page=${data}`);
    }
}

function getSmsLogs(data: any) {
    if (data.callFormData) {
        return axiosInstance.get(`/users/sms?limit=50&page=${data.pageLimit}&${data.callFormData.id}=${data.callFormData.SearchKey}&${data.callFormData.messageStatusData}=${data.callFormData.messageStatus}&${data.callFormData.smsDirectionData}=${data.callFormData.smsDirection}`);
    }
    else {
        return axiosInstance.get(`/users/sms?limit=50&page=${data}`);
    }
}

function getOutboundProviders(data: any) {
    return axiosInstance.get(`admin/outbound-providers`);
}


function getAccountLogs(data: any) {
    if (data.accountSearchLogs) {
        return axiosInstance.get(`/admin/efone-clients/${data.id}/account-logs?type=${data.accountSearchLogs.type}`);
    }
    else {
        return axiosInstance.get(`/admin/efone-clients/${data.id}/account-logs`);
    }
}

function getSipcredentials() {
    return axiosInstance.get(`/admin/efone-clients/sip-credentials`);
}

function getPaymentTransaction() {
    return axiosInstance.get(`/accounts/payment-history?limit=100`);
}

function getUsage() {
    return axiosInstance.get(`/accounts/usage`);
}


