import {  status,OutBoundCallLogsConstants } from '../Constants';

export function outboundCallLogsUsers(state = {}, action: any) {
    switch (action.type) {
        case OutBoundCallLogsConstants.SEARCH_OUTBOUNDCALLLOGS_REQUEST:
            return {
                ...state,
                search_outboundcallLogs_status: status.IN_PROGRESS,
                searchOutBoundcallLogsList: action.data
            };
        case OutBoundCallLogsConstants.SEARCH_OUTBOUNDCALLLOGS_SUCCESS:
            return {
                ...state,
                search_outboundcallLogs_status: status.SUCCESS,
                searchOutBoundcallLogsList: action.data
            };
        case OutBoundCallLogsConstants.SEARCH_OUTBOUNDCALLLOGS_FAILURE:
            return {
                ...state,
                search_outboundcallLogs_status: status.FAILURE,
                searchOutBoundcallLogsList: null
            };
        default:
            return state;
    }
}