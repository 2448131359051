import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    heading:{
        color: "#2645ad",
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'work sans',
        "@media (max-width: 426px)": {
            fontSize: 17,
        },
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    accordianNoFlex:{
        display: 'block',
        padding: '10px 0'
    },
    ring: {
        color: '#464646',
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 500,
    },
    fexDiv: {
        display: 'flex',
        justifyContent: 'spece-between',
    },
    sms: {
        color: '#707070',
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 500,
        marginBottom: 10,
        "@media (max-width: 426px)": {
            fontSize: 15,
        },
       
    },
    textField: {
        fontSize: 16,
        fontFamily: 'work sans',
        color: '#707070',
        fontWeight: 500,
    }
}))