import React from 'react'
import {
    Card, CardContent, Grid, Typography, Switch, Divider, RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core'
import avtar from "../../../assets/icons/avtar.jpg";
import { useStyles } from "../styles";
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux'
import DatePicker from "react-datepicker";
import ReactSelect from "react-select"
import { FaUserEdit } from 'react-icons/fa'
import EmergencyIcon from "../../../assets/icons/emergency.png";
import Modal from '../../../components/Modal/Modal'
import Backdrop from '../../../components/Modal/Backdrop'
import EditProfile from './EditProfile';
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import profileDataServices from '../../../components-services/MyAccountPage/ProfileData'
import EditEmergencyAddress from './EditEmergencyAddress';
import * as globalPopupcreator from '../../../store/Actions/popup-actionCreator'
import { useDispatch } from 'react-redux'
import Skeleton from '../../../components/skeleton-component/Skeleton'

const generatHours: any = [];
for (let i = 0; i < 24; i++) {
    let hourData = { value: i + 1, label: `${i + 1} Hour` };
    generatHours.push(hourData);
}

function Profile(props: any) {
    const classes = useStyles();
    const dipatch = useDispatch()

    const [loading, setLoading] = React.useState(false)
    const [openEditUserDetailsModal, setopenEditUserDetailsModal] = React.useState(false)
    const [openE91EmergencyAddresModal, setOpenE91EmergencyMOdal] = React.useState(false)
    const [profileData, setProfileData] = React.useState<any>(null)
    const [settings, setSettings] = React.useState<any>(null)
    const [dndState, setDndState] = React.useState<any>(false)
    const [dndValue, setDndValue] = React.useState<any>("1")
    const [startDate, setStartDate] = React.useState<any>();
    const [selectedHour, setSelectedHour] = React.useState<any>("");
    const [open, setOpen] = React.useState(true);
    const handleChange = () => {
        setOpen(!open);
    };

    const authData = useSelector((state: any) => state.authState)

    const dndhandleChange = () => {
        setDndState(!dndState)
        if (dndState) {
            updateSettings({ dndStatus: !dndState })
        }
        // updateSettings({ dndStatus: !dndState })
    }

    const handleChangeRadioGroup = (event: any) => {
        setDndValue(event.target.value)

        if (event.target.value == 3) {

            const dndDatetime = { dndTime: 'null', dndOptions: '3', dndStatus: dndState };
            updateSettings(dndDatetime);
            //  updateSettings({ dndStatus: !dndState })
        }
    }

    const onChangeDateandTime = (date: any) => {
        const dndDatetime = { dndTime: `${Date.parse(new Date(date).toISOString())}`, dndOptions: '1', dndStatus: dndState };
        updateSettings(dndDatetime);
        setStartDate(date);
    };

    const onhandleChangeHour = (selectedHour: any) => {
        const updatedHour = new Date().setTime(
            new Date().getTime() + selectedHour.value * 60 * 60 * 1000
        );
        const updatedDNDHourtime = { dndTime: `${Date.parse(new Date(updatedHour).toISOString())}`, dndOptions: '2', dndStatus: dndState };
        updateSettings(updatedDNDHourtime);
        setSelectedHour(selectedHour);
    }

    const updateSettings = (data: any) => {

        profileDataServices.updateSetting("/accounts/settings", data).then((res: any) => {
            if (res.success) {
                props.fecthMysettings()
                dipatch(globalPopupcreator.initglobalPopup("Updated successfully", "PROFILE_UPDATED", "success"))
            } else {
                dipatch(globalPopupcreator.initglobalPopup(res.message, "PROFILE_UPDATED_FAIL", "error"))
            }
        }).catch((err: any) => {

        })

    }

    const popupHandlerUserDetails = () => {
        setopenEditUserDetailsModal(!openEditUserDetailsModal)
    }

    const popupHandlerE91Details = () => {
        setOpenE91EmergencyMOdal(!openE91EmergencyAddresModal)
    }

    React.useEffect(() => {
        if (authData.userData) {
            setProfileData(authData.userData)
        }
        setLoading(authData.authLoading)

    }, [authData.userData, authData.authLoading])

    React.useEffect(() => {
        if (props.settings) {
            const { dndStatus, dndOptions, dndTime } = props.settings
            if (dndOptions !== undefined) {
                setDndValue(`${dndOptions}`)
            }
            setSettings(props.settings)
            setDndState(dndStatus)
            if (dndTime !== 'null') {
                if (new Date(Number(dndTime)) > new Date()) {
                    setStartDate(new Date(Number(dndTime)))
                }

            }
            // else {
            //     setStartDate("")
            // }
        }
    }, [props.settings])

    return (
        <div id="users">
            {/* popupmodal start  */}
            {
                openEditUserDetailsModal && (
                    <>
                        <Backdrop handlePopupModal={popupHandlerUserDetails} />
                        <Modal title="Edit Profile" handlePopupModal={popupHandlerUserDetails}>
                            <div style={{ width: 400 }}>

                            </div>
                            <EditProfile popupHandlerUserDetails={popupHandlerUserDetails} />
                        </Modal>
                    </>
                )
            }
            {/* popupmodal End  */}
            {/* popupmodal start  */}
            {
                openE91EmergencyAddresModal && (
                    <>
                        <Backdrop handlePopupModal={popupHandlerE91Details} />
                        <Modal title="e911 Details " handlePopupModal={popupHandlerE91Details}>
                            <div style={{ width: 400 }}>
                                <EditEmergencyAddress popupHandlerE91Details={popupHandlerE91Details} />
                            </div>

                        </Modal>
                    </>
                )
            }
            {/* popupmodal End  */}
            <Accordion expanded={open} onChange={handleChange} style={{ marginTop: 20, borderRadius: 10, background: '#FFFFFF', boxShadow: '0 0 0.5em #E2E2E2', }}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Profile</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>

                    <div className={classes.cardContent}>
                        <Grid container spacing={2}>

                            {/* profile section  */}
                            <Grid item xl={5} lg={5} md={5} sm={4} xs={12} className={classes.prelative}>
                                <CardContent className={classes.cardContent1}>
                                    <img className={classes.profile} alt="Remy Sharp" src={avtar} />
                                </CardContent>
                                <CardContent>
                                    {
                                        loading ? (
                                            <div style={{ display: 'grid', placeItems: "center" }}>
                                                <Skeleton width="100px" height="15px" margin="0" />
                                                <Skeleton width="200px" height="15px" />
                                            </div>
                                        ) : (
                                            <div className={classes.dFlex} >
                                                <div>
                                                    <div>
                                                        <Typography className={classes.head1}>
                                                            {profileData && profileData?.firstName + " " + profileData?.lastName}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography className={classes.head1}>
                                                            {profileData && profileData?.email}
                                                        </Typography>
                                                        {/* <FaUserEdit
                                                color="rgb(133 133 133)"
                                                size={20}
                                                style={{ marginLeft: '15px', cursor: 'pointer' }}
                                                onClick={popupHandlerUserDetails}
                                             
                                            /> */}
                                                    </div>
                                                </div>
                                                <div>
                                                    <FaUserEdit
                                                        color="rgb(133 133 133)"
                                                        size={20}
                                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                        onClick={popupHandlerUserDetails}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                </CardContent>
                                <CardContent>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            variant="outlined"
                                            className={classes.button1}
                                            startIcon={<img src={EmergencyIcon} alt="emergency" />}
                                            onClick={popupHandlerE91Details}
                                        >
                                            e911 Emergency Address
                                        </Button>
                                    </div>
                                </CardContent>

                            </Grid>
                            <Grid item xl={2} lg={2} md={1} sm={1} xs={12} ></Grid>
                            {/* DND section */}
                            <Grid item xl={5} lg={5} md={6} sm={7} xs={12} style={{ padding: '0px' }} >
                                <Card className={classes.doNotDisturbCard} >
                                    <CardContent className={classes.cardContent}>
                                        <Typography className={classes.cardColor}>
                                            Do Not Disturb
                                        </Typography>
                                        <Switch
                                            className={classes.switchColor}
                                            checked={dndState}
                                            onChange={dndhandleChange}
                                        />
                                    </CardContent>
                                    {
                                        dndState && (
                                            <>
                                                <Divider className={classes.divider} />
                                                <CardContent className={classes.dndCardContent}>
                                                    <RadioGroup
                                                        aria-label="gender"
                                                        name="gender1"
                                                        value={dndValue}
                                                        onChange={handleChangeRadioGroup}
                                                    >
                                                        <div className={classes.dnd_flex}>
                                                            <FormControlLabel
                                                                value="1"
                                                                className={classes.radioLabel}
                                                                control={<Radio />}
                                                                label={
                                                                    <span >
                                                                        DND upto date and time
                                                                    </span>
                                                                }
                                                            />
                                                            <div className="date_and_time_picker_section">
                                                                {dndValue === "1" && (
                                                                    <DatePicker
                                                                        selected={startDate}
                                                                        onChange={onChangeDateandTime}
                                                                        timeInputLabel="Time:"
                                                                        dateFormat="MM/dd/yyyy h:mm aa"
                                                                        showTimeInput
                                                                        //className="date_and_time_pickers"
                                                                        className={classes.datePicker}

                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={classes.dnd_flex}>
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio />}
                                                                className={classes.radioLabel}
                                                                label={
                                                                    <span >
                                                                        DND for{" "}
                                                                        {selectedHour
                                                                            ? `${selectedHour.label}s`
                                                                            : `__ hours`}
                                                                    </span>
                                                                }
                                                            />
                                                            {dndValue === "2" && (
                                                                <div className="select_hours_section_s">
                                                                    <ReactSelect
                                                                        placeholder={"Select Hours"}
                                                                        menuPortalTarget={document.body}
                                                                        className={classes.hourPicker}
                                                                        styles={{
                                                                            menuPortal: (base: any) => ({
                                                                                ...base,
                                                                                zIndex: 9999,
                                                                            }),
                                                                        }}
                                                                        value={selectedHour}
                                                                        onChange={onhandleChangeHour}
                                                                        options={generatHours}
                                                                    />

                                                                    <div style={{ width: "180px" }}></div>
                                                                    {startDate ?
                                                                        <div className={classes.timeDate}>
                                                                            {startDate && new Date(Number(startDate)).toLocaleString()}
                                                                        </div> : ""}

                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className={classes.dnd_flex}>
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio />}
                                                                className={classes.radioLabel}
                                                                label={

                                                                    <span>
                                                                        DND Untill trun on
                                                                    </span>
                                                                }
                                                            />
                                                        </div>
                                                    </RadioGroup>
                                                </CardContent>
                                            </>
                                        )
                                    }
                                </Card>
                                {/* <Card className={classes.outBondCard} > */}

                                <div
                                //className={classes.divFlex}
                                >
                                    {/* <div className={classes.balanceValue}>
                                            <Typography className={classes.balance} >Balance</Typography>
                                            <Divider className={classes.divider2} orientation="vertical" />
                                            <Typography className={classes.countBalance} >$ {settings && settings.balance}</Typography>
                                        </div> */}

                                    {/* <div style={{textAlign: 'center'}}>
                                            <Typography className={classes.balance} style={{textAlign: 'center'}}>Created on</Typography>
                                            <Typography className={classes.balance} style={{textAlign: 'center'}}>25.06.2019</Typography>
                                        </div> */}
                                </div>

                                {/* </Card> */}
                            </Grid>
                        </Grid>
                    </div>

                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default Profile
