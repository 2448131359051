import { whiteListConstants, status } from '../Constants';

export function WhiteListUsers(state = {}, action: any) {
    switch (action.type) {
        case whiteListConstants.SEARCH_WHITELIST_REQUEST:
            return {
                ...state,
                search_white_status: status.IN_PROGRESS,
                searchWhiteList: action.data
            };
        case whiteListConstants.SEARCH_WHITELIST_SUCCESS:
            return {
                ...state,
                search_white_status: status.SUCCESS,
                searchWhiteList: action.data
            };
        case whiteListConstants.SEARCH_WHITELIST_FAILURE:
            return {
                ...state,
                search_white_status: status.FAILURE,
                searchWhiteList: null
            };
        default:
            return state;
    }
}
