import React from 'react'
import { useHistory } from 'react-router-dom'
import { RiSettings5Fill, RiNotification2Fill } from 'react-icons/ri'
import Account from '@material-ui/icons/AccountCircle';
import routes from '../../services/Routes'
import './headercomponent.css'
import Tooltip from '@material-ui/core/Tooltip';
import {Typography,Button,Grid} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Moment from 'moment'
import { BsPeopleFill } from "react-icons/bs";
import { alpha } from '@material-ui/core/styles'
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FaUserCircle } from 'react-icons/fa'
import { MdVpnKey, MdDialpad } from 'react-icons/md'
import { RiLogoutCircleLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { IoNotificationsSharp } from 'react-icons/io5'
import { GiHamburgerMenu } from 'react-icons/gi'
import * as authActionCreators from '../../store/Actions/Authentication-creator'
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: any) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: 'rgb(77, 114, 248);',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
                
            },
        },
    },
}))(MenuItem);

function HeaderComponent(props: any) {
    const [title, setTitle] = React.useState('')
    const history = useHistory();
    const dispatch = useDispatch()
    const [user, setUser] = React.useState<any>({})
    const [data, setData] = React.useState<any>({})
    const { location } = useHistory()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };  

      const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    React.useEffect(() => {
        routes.forEach((route: any) => {
            if (route.path === location.pathname) {
                setTitle(route.Title)
            }
        })
    }, [location.pathname])

    React.useLayoutEffect(() => {
      var data = localStorage.getItem("user")
      var newData = data ? JSON.parse(data) : ""
      setUser(newData)
    }, [])

    let token :any = null;

    if (user?.access){
        token = Object.keys(user).length ? user.access.token : null; 
    }

    React.useEffect(() => {
        if (token) {
            const decodedHeader: any = jwt_decode(token);
            if (decodedHeader?.key) {
                const decryptData2 = (encryptedData: any) => {
                    var C = CryptoJS;
                    var Key = C.enc.Utf8.parse("oSOhgvKFi2AbgyVwtKMKwFV8pSc5kyxU");
                    var IV = C.enc.Utf8.parse("oSOhgvKFi2AbgyVw");
                    var decryptedText = C.AES.decrypt(encryptedData, Key, {
                        iv: IV,
                        mode: C.mode.CBC,
                        padding: C.pad.Pkcs7
                    });
                    return decryptedText.toString(CryptoJS.enc.Utf8);
                }
                const userData = JSON.parse(decryptData2(decodedHeader?.key)) ? JSON.parse(decryptData2(decodedHeader?.key)) : ""
                setData(userData)
            }
          }
      }, [token])

    var date = new Date()
    var dataa = Moment(date).format('DD/MM/YYYY HH:MM')

    React.useEffect(() => {

    }, [])

    return (
        <div className="header_component_section">
            <div className="side_header">
                <div>
                    <div className='main_header_title_main'>
                        <p className="main_header_title">{title}</p>
                    </div>
                    <div className="main_header_hamberger" onClick={props.handleSidebarPopupModal}>
                        <GiHamburgerMenu size={22} style={{color:'#67748e'}} />
                    </div>
                </div>
                <div>
                    <div className="user_profile_section"  >
                        <Account style={{ color: '#67748E', cursor: 'pointer' }} onClick={handleClick} />
                        <p>{data.firstName}</p>
                    </div>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}

                    >
                        <StyledMenuItem
                            onClick={() => history.push('/change-password')}
                        >
                            <ListItemIcon>
                                <MdVpnKey size={20} />
                            </ListItemIcon>
                            <ListItemText primary="Change Password" />
                        </StyledMenuItem>
                        <StyledMenuItem
                            onClick={() => dispatch(authActionCreators.initAuthLogout())}
                        >
                            <ListItemIcon>
                                <RiLogoutCircleLine size={20} />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </StyledMenuItem>
                    </StyledMenu>
                </div>
            </div>
        </div>
    )
}

export default HeaderComponent
