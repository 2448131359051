import { Card,
 Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  CardContent,
  Grid, Checkbox, Button} from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import addIcon from "../../../assets/icons/addIcon.png";
import { GiSevenPointedStar } from 'react-icons/gi'
import React, { useState } from 'react'
import { useStyles } from "./styles";
import profileDataServices from '../../../components-services/MyAccountPage/ProfileData'
import NumberBody from './NumberBody';
import Modal from '../../../components/Modal/Modal'
import Backdrop from '../../../components/Modal/Backdrop'
import AddNumber from './AddNumber'
import { useDispatch } from 'react-redux'
import axiosInstance from '../../../environment/axiosInstances'
import CircularProgress from '@material-ui/core/CircularProgress';


function MyNumbers(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [efoneNumberData, setEfoneNumberData] = React.useState<any>(null)
    const [loading, setLoading] = React.useState<any>(false);
    const [cellNumberData, setCellNumberData] = React.useState<any>(null)
    const [othersNumberData, setOtherNumbersData] = React.useState<any>([])
    const [popupaddNumber, setpopupaddNumber] = React.useState(false)
    const [tempNumber,setTempNumber] = React.useState<any>(null)
    const [portModal,setPortModal] = React.useState<any>(false)

    const [portData, setPortData] = React.useState<any>({
            phoneNumber: "",
            portStatusDate: "",
            tempNumber: "",
            status: "",
    })


    const handleChange = () => {
        setOpen(!open);
    };

    const getMyNumbers = () => {
        profileDataServices.getMyNumbers('/accounts/mynumbers').then((res: any) => {
            if(res.success){
                const { efoneNumber, cellNumber, otherNumbers,tempNumber } = res.data;

                // if (efoneNumber !== "" || efoneNumber){
                    setEfoneNumberData(efoneNumber)
                // }
                // if (cellNumber !== null){
                    setCellNumberData(cellNumber)
                // }
                // if (otherNumbers.length > 0){
                    setOtherNumbersData(otherNumbers)
                // }
                    setTempNumber(tempNumber)
                //dispatch(globalPopupCreator.initglobalPopup("Addon Number successfully", "ADDON_DELETED", "success"))
            }else{

            }
        }).catch((err: any) => {

        })
    }

    const portStatus = () => {
        setLoading(true)
        axiosInstance.post('accounts/port-status',portData).then((res: any) => {
        if (res?.data) {
            setLoading(false)
            setPortData(res.data.data)
        } else {
            setLoading(false)
        }
        }).catch((err: any) => {
            setLoading(false)
        })
    }

    // React.useEffect(() => {
    //     portStatus()
    // },[])


    
    const popupaddNumberHandler = () => {
        setpopupaddNumber(!popupaddNumber)
    }

    const popupPortModalHandler = () => {
        portStatus()
        setPortModal(!portModal)
    }

    React.useEffect(() => {
        getMyNumbers()
    },[])

    
    return (
        <div  id ="Numbers">
            {
                popupaddNumber && (
                    <>
                        <Backdrop handlePopupModal={popupaddNumberHandler} />
                        <Modal title="Add Number" handlePopupModal={popupaddNumberHandler} >
                                <AddNumber 
                                getMyNumbers={getMyNumbers} 
                                fecthMysettings={props.fecthMysettings}
                                popupaddNumberHandler={popupaddNumberHandler} 
                            />
                        </Modal>
                    </>
                )
            }
            {
                portModal && (
                    <>
                        <Backdrop handlePopupModal={popupPortModalHandler} />
                        <Modal title="Port Status" handlePopupModal={popupPortModalHandler} >
                            <div style={{ width: '350px', padding: '0px 10px' }}>
                                {loading ?
                                    <div style={{ marginLeft: "135px", width: "70%" }}>
                                        <CircularProgress />
                                    </div>
                                    :
                                    <>
                                        <Grid container spacing={2} style={{ marginTop: "20px" }}>
                                            <Grid item md={6}>
                                                <Typography className={classes.PortText}>Phone Number:</Typography>

                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography className={classes.PortText}>{portData?.phoneNumber}</Typography>

                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} style={{ marginTop: "20px" }}>
                                            <Grid item md={6}>
                                                <Typography className={classes.PortText}>PortStatusDate:</Typography>

                                            </Grid>
                                            <Grid item md={6}>
                                            <Typography className={classes.PortText}>{portData?.portStatusDate ? <div>{new Date(Number(portData.portStatusDate)).toLocaleString()}</div> : ""}</Typography>

                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} style={{ marginTop: "20px" }}>
                                            <Grid item md={6}>
                                                <Typography className={classes.PortText}>TempNumber:</Typography>

                                            </Grid>
                                            <Grid item md={6}>
                                            <Typography className={classes.PortText}>{portData?.tempNumber}</Typography> 

                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} style={{ marginTop: "20px" }}>
                                            <Grid item md={6}>
                                                <Typography className={classes.PortText}>PortStatus:</Typography>

                                            </Grid>
                                            <Grid item md={6}>
                                            <Typography className={classes.PortText}>{portData?.status}</Typography> 

                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            </div >
                           
                            
                        </Modal>
                    </>
                )
            }

           {/* <Card className={classes.card}> */}
               <Accordion onChange={handleChange} style={{marginTop: 20, borderRadius: 10,background: '#FFFFFF',boxShadow: '0 0 0.5em #E2E2E2',}}>
                    <AccordionSummary
                        expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                            <Typography className={classes.heading}>My Numbers</Typography>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <AccordionDetails className={classes.accordianNoFlex}>
                        <CardContent className={classes.paddingGrid}>
                             <Grid container spacing={4}>
                                 <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Card className={classes.numCard}>
                                        <CardContent>
                                            <Typography className={classes.textName}> My eFone Number</Typography>
                                        </CardContent>  
                                        <Divider className={classes.divider} />
                                            <div style={{ overflowY: "auto", maxHeight: "200px" }}>
                                                <CardContent >
                                                    <div className={classes.vector}  > 
                                                    {
                                                        tempNumber ? <div><GiSevenPointedStar className={classes.iconWidth} color='gray'/></div> : 
                                                        <div><GiSevenPointedStar className={classes.iconWidth} color='#4D72F8'/></div>
                                                    }
                                                        {/* <GiSevenPointedStar className={classes.iconWidth} color='gray'/> */}
                                                        <Typography className={classes.efoneNumber}>{efoneNumberData ? efoneNumberData : ""}</Typography>
                                                        {/* <div>
                                                            <Button className={classes.portStatus}variant='outlined' size='small' onClick={portStatus}
                                                            >Port Status</Button>
                                                        </div> */}
                                                        {
                                                           tempNumber &&  
                                                           <div>
                                                             <Typography onClick={popupPortModalHandler} style={{ color: "blue", marginLeft: 20, cursor: "pointer" }}>Port Status</Typography>
                                                            </div>
                                                          
                                                        }
                                                        
                                                    </div>
                                                    {tempNumber&&
                                                        <div style={{display: 'flex',alignItems: 'center'}}>
                                                            <GiSevenPointedStar className={classes.iconWidth} color='#4D72F8'/>
                                                            <Typography className={classes.tempNumber}>{tempNumber ? tempNumber : ""} ({tempNumber ? 'tempNumber': ""})</Typography>  
                                                        </div>
                                                    }

                                                </CardContent>
                                            </div>
                                            
                                       
                                        <Divider className={classes.divider} />
                                    </Card>
                                    {/* <CardContent>
                                        <div className={classes.vector}>
                                            <Checkbox 
                                                //checked={recordAllCalls} 
                                                color={"primary"} 
                                            />
                                            <Typography className={classes.text}>
                                            Record all Calls
                                            </Typography>
                                        </div>
                                    </CardContent> */}
                                 </Grid>
                                 <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Card className={classes.numCard}>
                                        <CardContent>
                                            <div className={classes.textFlex}>
                                                    <Typography className={classes.textName}>
                                                        My cell Numbers
                                                    </Typography>
                                                    <img
                                                        alt="addIcon"
                                                        src={addIcon}
                                                        onClick={popupaddNumberHandler}
                                                        style={{cursor: 'pointer'}}
                                                    />
                                                   
                                                  
                                            </div>
                                        </CardContent>
                                        <Divider className={classes.divider} />
                                        <CardContent>
                                            {
                                                (cellNumberData && othersNumberData.length === 0 && Object.keys(cellNumberData).length  === 0) && <p style={{ margin: '10px 0', textAlign: 'center'}}>No Data</p>
                                            }
        
                                            {(cellNumberData && Object.keys(cellNumberData).length > 0) && <NumberBody  fecthMysettings={props.fecthMysettings} primary={true} getMyNumbers={getMyNumbers} cellNumberData={cellNumberData} />}
                                            {
                                                othersNumberData.length > 0 ? (
                                                    <>
                                                        {
                                                            othersNumberData.map((dt: any, key: any) => <NumberBody fecthMysettings={props.fecthMysettings} primary={false} getMyNumbers={getMyNumbers} cellNumberData={dt} key={key} />)
                                                        }
                                                    </>
                                                ) : ""
                                            }
                                        </CardContent>
                                    </Card>

                                 </Grid>

                             </Grid>    
                        </CardContent>
                    </AccordionDetails>
               </Accordion>

           {/* </Card> */}
        </div>
    )
}

export default MyNumbers
