import axios from 'axios'
import React from 'react'
// const { NODE_ENV } = process.env;
import localStorageContent from '../services/localStorage';
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';

const getToken = () => {
    const localData = localStorageContent.getLocalUserData()
    if (localData === undefined) {
        return ''
    } else if (localData.access === null) {
        return ''
    } else {
        return localData.access.token
    }
}
console.log('_process.env.REACT_APPAPI_URL',process.env.REACT_APP_API_URL)

const URL = axios.create({
    //baseURL: 'http://143.198.45.154:8080/v1',
    baseURL: process.env.REACT_APP_API_URL

})

var local_user = localStorage.getItem("user")
var current_user = local_user ? JSON.parse(local_user) : {}
const token = Object.keys(current_user).length && current_user.access ? current_user.access.token : null;
if (token) {
    const decodedHeader: any = jwt_decode(token);
    if (decodedHeader?.key) {
        const decryptData2 = (encryptedData: any) => {
            var C = CryptoJS;
            var Key = C.enc.Utf8.parse("oSOhgvKFi2AbgyVwtKMKwFV8pSc5kyxU");
            var IV = C.enc.Utf8.parse("oSOhgvKFi2AbgyVw");
            var decryptedText = C.AES.decrypt(encryptedData, Key, {
                iv: IV,
                mode: C.mode.CBC,
                padding: C.pad.Pkcs7
            });
            return decryptedText.toString(CryptoJS.enc.Utf8);
        }
        var userData = JSON.parse(decryptData2(decodedHeader?.key)) ? JSON.parse(decryptData2(decodedHeader?.key)) : ""
    }
}

export const userjhhData = {
    main: userData,
}

URL.interceptors.request.use(
    async config => {
        config.headers = {
            'Authorization': `Bearer ${getToken()}`
        }
        return config
    },
    error => {
        if (error.response.status === 401) {
            //place your reentry code

        } else if (error.response.status === 402) {

        }
        Promise.reject(error)
    }
)

export default URL