import axiosInstance from '../../environment/axiosInstances'

class responseServices {

    getStripPayments = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.message }
        }
    }

    getSubriptionAddons = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.message }
        }
    }

    deleteSubscriptionaddons = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.patch(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.message }
        }
    }
   
    subscribeAddon = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.message }
        }
    }

    getRecommandedAddons = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.message }
        }
    }

}

const profileServices = new responseServices()
export default profileServices