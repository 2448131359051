import { phoneNumberConstants, status ,phoneNumberAssignedConstants,phoneNumberCancelledConstants,phoneNumberPortConstants,editphoneNumberConstants} from '../Constants';

export function phoneNumberUsers(state = {}, action: any) {
    switch (action.type) {
        case phoneNumberConstants.SEARCH_PHONENUMBER_REQUEST:
            return {
                ...state,
                search_phoneNumber_status: status.IN_PROGRESS,
                searchPhoneNumberList: action.data
            };
        case phoneNumberConstants.SEARCH_PHONENUMBER_SUCCESS:
            return {
                ...state,
                search_phoneNumber_status: status.SUCCESS,
                searchPhoneNumberList: action.data
            };
        case phoneNumberConstants.SEARCH_PHONENUMBER_FAILURE:
            return {
                ...state,
                search_phoneNumber_status: status.FAILURE,
                searchPhoneNumberList: null
            };
        default:
            return state;
    }
}

export function phoneNumberUsersAssigned(state = {}, action: any) {
    switch (action.type) {
        case phoneNumberAssignedConstants.SEARCH_PHONENUMBERASSIGNED_REQUEST:
            return {
                ...state,
                search_phoneNumberAssigned_status: status.IN_PROGRESS,
                searchPhoneNumberAssignedList: action.data
            };
        case phoneNumberAssignedConstants.SEARCH_PHONENUMBERASSIGNED_SUCCESS:
            return {
                ...state,
                search_phoneNumberAssigned_status: status.SUCCESS,
                searchPhoneNumberAssignedList: action.data
            };
        case phoneNumberAssignedConstants.SEARCH_PHONENUMBERASSIGNED_FAILURE:
            return {
                ...state,
                search_phoneNumberAssigned_status: status.FAILURE,
                searchPhoneNumberAssignedList: null
            };
        default:
            return state;
    }
}

export function phoneNumberUsersCancelled(state = {}, action: any) {
    switch (action.type) {
        case phoneNumberCancelledConstants.SEARCH_PHONENUMBERCANCELLED_REQUEST:
            return {
                ...state,
                search_phoneNumberCancelled_status: status.IN_PROGRESS,
                searchPhoneNumberCancelledList: action.data
            };
        case phoneNumberCancelledConstants.SEARCH_PHONENUMBERCANCELLED_SUCCESS:
            return {
                ...state,
                search_phoneNumberCancelled_status: status.SUCCESS,
                searchPhoneNumberCancelledList: action.data
            };
        case phoneNumberCancelledConstants.SEARCH_PHONENUMBERCANCELLED_FAILURE:
            return {
                ...state,
                search_phoneNumberCancelled_status: status.FAILURE,
                searchPhoneNumberCancelledList: null
            };
        default:
            return state;
    }
}


export function phoneNumberUsersPort(state = {}, action: any) {
    switch (action.type) {
        case phoneNumberPortConstants.SEARCH_PHONENUMBERPORT_REQUEST:
            return {
                ...state,
                search_phoneNumberPort_status: status.IN_PROGRESS,
                searchPhoneNumberPortList: action.data
            };
        case phoneNumberPortConstants.SEARCH_PHONENUMBERPORT_SUCCESS:
            return {
                ...state,
                search_phoneNumberPort_status: status.SUCCESS,
                searchPhoneNumberPortList: action.data
            };
        case phoneNumberPortConstants.SEARCH_PHONENUMBERPORT_FAILURE:
            return {
                ...state,
                search_phoneNumberPort_status: status.FAILURE,
                searchPhoneNumberPortList: null
            };
        default:
            return state;
    }
}


export function editphoneNumberUsers(state = {}, action: any) {
    switch (action.type) {
        case editphoneNumberConstants.EDIT_PHONENUMBER_REQUEST:
            return {
                ...state,
                edit_phoneNumber_status: status.IN_PROGRESS,
                editPhoneNumberList: action.data
            };
        case editphoneNumberConstants.EDIT_PHONENUMBER_SUCCESS:
            return {
                ...state,
                edit_phoneNumber_status: status.SUCCESS,
                editPhoneNumberList: action.data
            };
        case editphoneNumberConstants.EDIT_PHONENUMBER_FAILURE:
            return {
                ...state,
                edit_phoneNumber_status: status.FAILURE,
                editPhoneNumberList: null
            };
        default:
            return state;
    }
}

