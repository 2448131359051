import React from 'react'
import {
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Tooltip,
    Paper,
    Divider,
    CardContent,
    Tabs,
    Tab,
    Grid, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button,
} from '@material-ui/core'
import { GiSevenPointedStar } from 'react-icons/gi'
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import TableBodyData from './TableBody'
import { useStyles } from '../styles';
import '../../style.css'
import AudioPlayer from './AudioPlayer';
import { MdDelete } from 'react-icons/md'
import Backdrop from '../../../../components/Modal/Backdrop'
import Modal from '../../../../components/Modal/Modal'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'
import { useDispatch } from 'react-redux'
import Loader from '../../../../components/Loader/Loader'
import profileDataServices from '../../../../components-services/MyAccountPage/ProfileData'
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import { FaUpload } from 'react-icons/fa'
const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "13px",
                color: "white",
                fontFamily: "Work Sans",
                fontWeight: 400,
                padding: '6px 16px',
                borderRadius: "0px 10px 0px 0px",
                backgroundColor: "#2645ad",
                top: 6,
                position: 'relative',
                left: -10,
                lineHeight: 'auto',
                maxWidth: 'auto'
            }
        }
    }
});

function DevicesTable(props: any) {
    const [myNumbers, setMyNumbers] = React.useState<any>(null)
    const dispatch = useDispatch()
    const [srcss, setSrcss] = React.useState<any>('')
    const [vaidatemail, setValidateEmail] = React.useState<any>({ vaild: true, message: "" })
    const [changesEmail, setChangedEmail] = React.useState<any>(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState<any>(false);
    const [delId, setDelId] = React.useState('')
    const [selectedVoiceMail, setSelectedVoiceMail] = React.useState<any>(null)
    const [isforwardtoemail, setisforwardtoemail] = React.useState<any>(false)
    const [forwardEmail, setForwardEmail] = React.useState<any>("")
    const [fileName, setFileName] = React.useState('')
    const [loading, setLoading] = React.useState<any>(false)
    const [loadNum, setLoadNum] = React.useState<any>(false)
    const [voiceMailsList, setvoiceMailsList] = React.useState([]);
    const [voicemailfile, setVoicemailFile] = React.useState(false);
    const [arrayNumbers, setArrayNumbers] = React.useState<any>([])
    const [voiceMailDetails, setVoiceDetails] = React.useState<any>([])
    const [voiceMailDetailsDD, setVoiceDetailsDD] = React.useState<any>([])
    const [selectedRedirectNumber, setSelectedRedirectNumber] = React.useState<any>(null)
    const classes = useStyles();
    const audioFileInput: any = React.createRef();
    const getMyNumbers = () => {
        profileDataServices.getMyNumbers('/accounts/mynumbers').then((res: any) => {
            if (res.success) {
                setMyNumbers(res.data)
            } else {

            }
        }).catch((err: any) => {

        })
    }
    const getVoicemailDetails = () => {
        profileDataServices.getVoicemailList("/voicemails").then((res: any) => {
            if (res.success) {
                let voiceData = []
                if (res.data.length > 0) {
                    voiceData = res.data.filter((dt: any) => dt.id !== "U")
                }
                setVoiceDetailsDD(voiceData.length > 0 ? voiceData.map((dt: any) => {
                    return { label: dt.name, value: dt.id }
                }) : [])
                setvoiceMailsList(voiceData);
                setVoiceDetails(res.data)
                setValidateEmail({ valid: true, message: '' })

            } else {

            }
        }).catch((err: any) => {

        })
    }
    React.useEffect(() => {
        getMyNumbers()
        getVoicemailDetails()
    }, [])
    const handleVoiceMail = () => {
        setVoicemailFile(!voicemailfile)

    }
    React.useEffect(() => {
        if (myNumbers) {
            let data = []
            if (myNumbers.otherNumbers && myNumbers.otherNumbers.length > 0) {
                data = myNumbers.otherNumbers
            }
            if (myNumbers.cellNumber && Object.keys(myNumbers.cellNumber).length >= 2) {
                data.push(myNumbers.cellNumber)
            }

            setArrayNumbers(data)
        }
    }, [myNumbers])
    const updateSetting = (data: any) => {
        setLoading(true)
        profileDataServices.updateSetting("/accounts/settings", data).then((res: any) => {
            if (res.success) {
                // props.fecthMysettings()
                setLoading(false)
                setChangedEmail(false)
                dispatch(globalPopupCreator.initglobalPopup("Updated successfully", "ADDON_DELETED", "success"))
            } else {
                setLoading(false)
            }
        }).catch((err: any) => {
            setLoading(false)
        })
    }
    const onChangeRadioNumber = (data: any) => {
        setSelectedRedirectNumber(data)
        setVoicemailFile(false)
        // updateSetting({ redirectTo: 2, redirectNumber: data })
        setLoadNum(true)
        profileDataServices.updateSetting("/accounts/settings", { redirectTo: 2, redirectNumber: data }).then((res: any) => {
            if (res.success) {
                // props.fecthMysettings()
                setLoadNum(false)
                setChangedEmail(false)
                dispatch(globalPopupCreator.initglobalPopup("Updated successfully", "ADDON_DELETED", "success"))
            } else {
                setLoadNum(false)
            }
        }).catch((err: any) => {
            setLoadNum(false)
        })
    }

    const onSelectForwardNumber = (data: any) => {
        setVoicemailFile(false)
        updateSetting({ redirectTo: 2, redirectNumber: data.phoneNumber })
    }
    const validateEmailRegex = (email: any) => {
        const re: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    React.useEffect(() => {

        if (props.settingData) {
            const { voicemailGreetingId, redirectTo, redirectNumber, forwardToEmail, forwardEmail } = props.settingData;
            if (redirectNumber && redirectNumber !== null && redirectNumber !== "") {
                setSelectedRedirectNumber(redirectNumber)
            }
            if (redirectTo === 1) {
                setVoicemailFile(true)
            } else {
                setVoicemailFile(false)
            }
            if (forwardToEmail && forwardEmail) {
                setisforwardtoemail(forwardToEmail)
                if (!validateEmailRegex(forwardEmail)) {
                    setValidateEmail({ valid: false, message: 'please enter valid email' })
                }
                setForwardEmail(forwardEmail)
            }
            setisforwardtoemail(forwardToEmail)
            if (!validateEmailRegex(forwardEmail)) {
                setValidateEmail({ valid: false, message: 'please enter valid email' })
            }
            setForwardEmail(forwardEmail)
            const filteredData = voiceMailDetails.find((vc: any) => vc.id === voicemailGreetingId)

            if (filteredData) {
                setSrcss(filteredData.path)
                setSelectedVoiceMail(filteredData?.id)
            }
        }
    }, [props.settingData, voiceMailDetails])
    const uploadFileClick = () => {
        if (audioFileInput.current) {
            audioFileInput.current.click()
        }
    }
    const onPostDataToServer = (fileBuffer: any, fileName: any) => {
        let formData = new FormData()
        formData.append('file', fileBuffer)

        profileDataServices.createVoicemail("/voicemails", formData).then((res: any) => {
            if (res.success) {
                setSrcss('')
                // setVoiceDetailsDD(res.data.length > 0 ? res.data.map((dt: any) => {
                //     return { label: dt.name, value: dt.id }
                // }) : [])
                // setVoiceDetails(res.data)

                updateSetting({ redirectTo: 1, voicemailGreetingId: res.data.data.id })
                getVoicemailDetails()
                dispatch(globalPopupCreator.initglobalPopup("File Uploaded successfully", "SUBSCRIPTION_ADDED", "success"))
            } else {
                dispatch(globalPopupCreator.initglobalPopup(res.message, "SUBSCRIPTION_ADDED", "error"))
            }
        }).catch((err: any) => {

        })
    }
    const onChangeDeleteModal = (id: any) => {
        setDeleteModal(!deleteModal)
        setDelId(id)
    }
    const onChangeEmail = (e: any) => {
        // forwardEmail, setForwardEmail]
        setForwardEmail(e.target.value)
        setChangedEmail(true)
    }
    const onChangeIsforwardEmail = () => {
        setisforwardtoemail(!isforwardtoemail)
        if (!isforwardtoemail) {
            updateSetting({ forwardToEmail: !isforwardtoemail, forwardEmail: forwardEmail })
        } else {
            updateSetting({ forwardToEmail: !isforwardtoemail })
        }
    }
    const onChangeAudioFile = (e: any) => {
        const fileData = e.target.files;
        var file: any = fileData[0]
        let reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setSrcss(reader.result)
        };

        setFileName(fileData[0]?.name)
        onPostDataToServer(file, fileData[0]?.name)
    }
    const onChangeAudioSelect = (data: any, id: any) => {
        setSelectedVoiceMail(id)
        const filterredData: any = voiceMailDetails.find((dts: any) => dts.id === data.value)

        if (filterredData) {
            setSelectedVoiceMail({ label: filterredData?.name, value: filterredData?.id })
            setSrcss(filterredData?.path)
            updateSetting({ redirectTo: 1, voicemailGreetingId: filterredData.id })
        }
        updateSetting({ redirectTo: 1, voicemailGreetingId: id })
    }
    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {

            email: { ...validData },

        };
        let isValid = true;
        if (submitted) {

            if (!forwardEmail.email) {
                retData.email = {
                    isValid: false,
                    message: "Please enter email"
                };
                isValid = false;
            }
            else if (forwardEmail.email !== '' && !validateEmailRegex(forwardEmail.email)) {

                retData.email = {
                    isValid: false,
                    message: "Please enter valid email"
                };
                isValid = false;
            }

        }
        return {
            ...retData,
            isValid
        };
    };

    const updateForwardEmail = () => {
        if (validateEmailRegex(forwardEmail)) {
            updateSetting({ forwardEmail: forwardEmail })
        } else {
            setSubmitted(true);
            const errorData = validate(true);
            dispatch(globalPopupCreator.initglobalPopup("Please enter valid email", "UPDATE_EMAIL", "info"))
            // if (errorData.isValid) {
            //     dispatch(globalPopupCreator.initglobalPopup("Please Enter valid EMAIL", "UPDATE_EMAIL", "info"))
            // }
        }
    }
    const deleteVoicemail = (id: any) => {
        if (selectedVoiceMail) {
            profileDataServices.deleteVoiceMail(`/voicemails/${id}`).then((res: any) => {
                if (res.success) {

                    getVoicemailDetails()
                    setDeleteModal(!deleteModal)
                    setSelectedVoiceMail(null)
                    dispatch(globalPopupCreator.initglobalPopup("Deleted file successfully", "ADDON_DELETED", "success"))
                } else {

                }
            }).catch((err: any) => {

            })
        }
    }

    return (
        <div>
            {

                deleteModal && (
                    <>
                        <Backdrop handlePopupModal={onChangeDeleteModal} />
                        <Modal handlePopupModal={onChangeDeleteModal} title="Are you sure you want to Delete ?">
                            <div style={{ textAlign: 'center', width: 500 }} >
                                <Grid container spacing={2}>

                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.modaldeleteButton} onClick={() => deleteVoicemail(delId)}>Delete</Button>
                                            <Button size="medium" className={classes.deltecancelButton} onClick={onChangeDeleteModal}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </>
                )

            }
            <Card style={{ width: "74%", marginTop: "-39px" }}>
                <TableContainer>
                    <Table >
                        {/* <TableHead style={{ background: '#F1F1F1' }}>
                            <TableRow>
                                <TableCell className={classes.tableHead}><TableHead>Type</TableHead></TableCell>
                                <TableCell className={classes.tableHead}><TableHead>Device Name</TableHead></TableCell>
                                <TableCell className={classes.tableHead}><TableHead>Last Connected</TableHead></TableCell>
                                <TableCell className={classes.tableHead}><TableHead>Ring this Device</TableHead></TableCell>
                                <TableCell className={classes.tableHead}><TableHead>Action</TableHead></TableCell>
                            </TableRow>
                        </TableHead> */}
                        <TableBody>
                            {
                                props.loading === false && props.tableData.length > 0 && props.tableData.map((dt: any, inx: any) => <TableBodyData getMyDeviceList={props.getMyDeviceList} data={dt} />)
                            }

                            {
                                // props.loading ? <div className='loader_class'>
                                //     <Loader />
                                // </div> :
                                props.tableData.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                            }

                            <TableRow>
                                <TableCell className={classes.tableHead}>
                                    <Typography className={classes.ring} id="heading2">Forward</Typography></TableCell>

                                <TableCell className={classes.tableHead}>

                                </TableCell>
                                <TableCell className={classes.tableHead}></TableCell>
                                <TableCell className={classes.tableHead}></TableCell>
                            </TableRow>

                            {/* <TableRow>
                                <TableCell className={classes.tableHead}>Number</TableCell>

                                <TableCell className={classes.tableHead}>
                                    Created On
                                </TableCell>
                                <TableCell className={classes.tableHead}>Action</TableCell>
                            </TableRow> */}


                            {
                                arrayNumbers.length > 0 &&
                                arrayNumbers.map((element: any, index: any) => {
                                    // var data = parseInt(element.createdDate)
                                    // var dataa = Moment(data).format('YYYY-MM-DD HH:mm:ss')
                                    return (
                                        <>
                                            <TableRow key={element.phoneNumber}>

                                                <TableCell className={classes.tableData}>
                                                    <div style={{ display: "flex" }}>
                                                        <Radio color="primary"
                                                            value={element.phoneNumber}
                                                            name="phoneNumber"
                                                            checked={voicemailfile ? false : selectedRedirectNumber === element.phoneNumber}
                                                            onChange={() => onChangeRadioNumber(element.phoneNumber)}
                                                        // onClick={() => { onSelectForwardNumber(element) }}
                                                        />
                                                        <div className={selectedRedirectNumber && selectedRedirectNumber === element.phoneNumber && !voicemailfile ? classes.activeText : classes.notActiveText}>{element.phoneNumber}</div>
                                                    </div>
                                                </TableCell>

                                                <TableCell className={classes.tableData}>
                                                    {/* {element.createdDate ? dataa : ""} */}
                                                </TableCell>
                                                <TableCell className={classes.tableHead}></TableCell>
                                                <TableCell className={classes.tableData}>
                                                    {/* <Button onClick={() => onhandleDeleteModal(element._id)}>
                                  <Icon icon={delete16Filled} style={{ fontSize: 18 }} />
                                </Button> */}
                                                </TableCell>

                                            </TableRow>

                                        </>
                                    )
                                }
                                )}

                            {
                                arrayNumbers.length === 0 &&
                                <TableRow>
                                    <TableCell className={classes.tableData} colSpan={5} align="center">
                                        There is no data
                                    </TableCell>
                                </TableRow>
                            }


                            <TableRow >
                                <TableCell className={classes.tableHead}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Radio
                                            color="primary"
                                            checked={voicemailfile}
                                            onClick={handleVoiceMail}

                                        />&nbsp;
                                        <Typography className={classes.ring} id="heading2">VoiceMail</Typography>
                                    </div>
                                </TableCell>

                                <TableCell className={classes.tableHead}>

                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    {/* <Button onClick={() => onhandleDeleteModal(element._id)}>
                              <Icon icon={delete16Filled} style={{ fontSize: 18 }} />
                            </Button> */}
                                </TableCell>
                                <TableCell className={classes.tableHead}></TableCell>
                            </TableRow>
                            {voicemailfile &&
                                <>

                                    {/* <TableRow >
                                        <TableCell className={classes.tableHead}>
                                            Voicemail
                                        </TableCell>
                                        <TableCell className={classes.tableHead}>Play</TableCell>
                                        <TableCell className={classes.tableHead}>Action</TableCell>
                                    </TableRow> */}
                                    {
                                        voiceMailsList.map((element: any) => (
                                            element.id != "U" ?
                                                <TableRow key={element.id}>
                                                    <TableCell className={classes.tableData}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Radio

                                                                color="primary"
                                                                checked={selectedVoiceMail === element.id}
                                                                onChange={(e: any) => onChangeAudioSelect(e, element.id)}
                                                            />
                                                            {element.name}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableData}  >
                                                        {
                                                            element.path &&
                                                            <AudioPlayer path={element.path} />
                                                        }
                                                    </TableCell>
                                                    <TableCell className={classes.tableData}>
                                                        <Button onClick={() => onChangeDeleteModal(element.id)}>
                                                            {element.name === "Default (Enable)" || element.name === "Disable Voicemail" ? "" :
                                                                <MdDelete style={{ fontSize: 18 }} />}
                                                        </Button>

                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}></TableCell>
                                                </TableRow>
                                                : ""
                                        ))}


                                    <TableRow >
                                        <TableCell className={classes.tableData} >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Checkbox
                                                    checked={isforwardtoemail}
                                                    onChange={onChangeIsforwardEmail}
                                                    color={"primary"}
                                                />
                                                <Typography className={classes.text}>
                                                    Forward to email
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableData}>
                                            <>
                                                {
                                                    isforwardtoemail && (
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <TextField variant="outlined" fullWidth onChange={onChangeEmail} value={forwardEmail} size="small" className={classes.textField}
                                                                placeholder="Enter email id"
                                                                style={{ marginLeft: "0px", width: "100%" }}

                                                            />

                                                            {changesEmail && forwardEmail !== "" && (
                                                                <>
                                                                    <div className={classes.frwdBtns}>

                                                                        <AiFillCheckCircle size={25}
                                                                            style={{ cursor: "pointer", marginLeft: "6px" }}
                                                                            color="#149a01" onClick={updateForwardEmail} />
                                                                        <AiFillCloseCircle
                                                                            onClick={() => {
                                                                                setChangedEmail(false)
                                                                                setForwardEmail(props.settingData?.forwardEmail)

                                                                            }}
                                                                            size={25}
                                                                            style={{ cursor: "pointer" }}
                                                                            color="#E91C1C"
                                                                        />

                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )
                                                }

                                            </>
                                        </TableCell>
                                        <TableCell className={classes.tableData}>
                                            <>
                                                <Button variant="outlined" size="small"
                                                    className={classes.uploadButton}
                                                    onClick={uploadFileClick}
                                                    startIcon={<FaUpload style={{ color: '#707070', fontSize: 12 }} />}
                                                >
                                                    Upload
                                                </Button>
                                                <div style={{ display: 'none' }}>
                                                    <input type="file" ref={audioFileInput} onChange={onChangeAudioFile} />
                                                </div>
                                            </>
                                        </TableCell>
                                        <TableCell className={classes.tableHead}></TableCell>
                                    </TableRow>
                                </>

                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>

        </div>

    )
}

export default DevicesTable
