import React from 'react'
import { Accordion, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { useStyles } from '../styles'
import TableBodyData from './TableBody'
import Loader from '../../../../components/Loader/Loader'
import '../../style.css'
import ReactAudioPlayer from 'react-audio-player';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import RecordVoiceOver from '@mui/icons-material/RecordVoiceOver'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'

function LogsTable(props: any) {
    const data = props.callLogsData;
    const classes = useStyles();
    const [recordingModal, setRecordingModal] = React.useState(false)
    const [recording, setRecording] = React.useState(false)
    const [filePath, setFilePath] = React.useState("")
    const handlechangeRecModal = (rec: any, path: any) => {
      setRecordingModal(!recordingModal)
      setRecording(rec)
      setFilePath(path)
    }

    return (
        <div>
        {
        recordingModal && (
          <div style={{ width: "500px" }}>
            <Backdrop handlePopupModal={handlechangeRecModal} />
            <Modal
              title={recording ? "VoiceMail" : "Recording"}
              handlePopupModal={handlechangeRecModal}

            // breakLine={false}
            >
              <div style={{ width: "500px", height: "31%" }}>
                <Grid container spacing={2} style={{ marginBottom: '15px' ,marginTop:10}}>
                  <ReactAudioPlayer
                    controls
                    className={classes.audioPlayer}
                    src={filePath}
                  // playing={srcss}
                  />
                </Grid>
              </div>
            </Modal>
          </div>
        )
       }
            <Table>
                <TableHead style={{background: '#F1F1F1'}}>
                    <TableRow>
                        <TableCell className={classes.tableHead}>Number</TableCell>
                        <TableCell className={classes.tableHead}>Name</TableCell>
                        <TableCell className={classes.tableHead}>Date/Time</TableCell>
                        <TableCell className={classes.tableHead}>CallRecording</TableCell>
                        <TableCell className={classes.tableHead}>Action</TableCell>
                        <TableCell className={classes.tableHead}>Result</TableCell>
                    </TableRow>
                </TableHead>
                {
                    <TableBody>

                        {
                            props.loading === false && data.length > 0 && data.map((dt: any) => <TableBodyData rowData={dt} fetchCallLogs={props.fetchCallLogs} paginate={props.paginate}  handlechangeRecModal={handlechangeRecModal}/>) 
                        }
                    </TableBody>
                }
                
            </Table>
            {
                props.loading ? <div className='loader_class'>
                    <Loader />
                </div> : data.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
            }
        </div>
    )
}

export default LogsTable
