import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import React from 'react'
import ReactSelect from 'react-select'
import { useStyles } from './styles';
import SearchFilter from './SearchFilter'
import TableBodyData from './TableBody'
import logsservicesData from '../../../components-services/Logs/LogsServices'
import Loader from '../../../components/Loader/Loader'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import '../style.css'

function SmsLogs(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [loading, setloading] = React.useState<any>(false)
    const [callSMSData, setSMSLogsData] = React.useState<any>([])
    const [isSearching, setIsSearching] = React.useState(false)
    const [searchFilterValue, setSearchFilterValue] = React.useState<any>({
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 59),
        toNumber: '',
        smsDirection: '',
        messageStatus: ''
    })
    const [paginate, setPaginate] = React.useState({
        limit: 5,
        totalDocs: 0,
        page: 1
    })

    const onChangeFilterHanlder = (prop: any, value: any) => {
        setSearchFilterValue((prev: any) => {
            return {
                ...prev,
                [prop]: value
            }
        })
    }

    const handleChange = () => {
        setOpen(!open);
    };

    const clearFilter = () => {
        setIsSearching(false)
        fetchSMSLogs(paginate.limit, 1)
        setSearchFilterValue({
            startDate: new Date().setHours(0, 0, 0, 0),
            endDate: new Date().setHours(23, 59, 59, 59),
            toNumber: '',
            smsDirection: '',
            messageStatus: ''
        })
    }



    const SearchFilterFun = (searchValue: any, limit: any, page: any) => {
        if (Object.keys(searchValue).length === 0) {
            return alert('Please enter all fields')
        }
        setIsSearching(true)
        let obj: any = {}
        Object.keys(searchValue).forEach((rest: any) => {
            if (searchValue[rest] !== '' && searchValue[rest] !== undefined) {
                obj[rest] = searchValue[rest]
            }
        })
        let queryParams = Object.keys(obj).map((i) => `${i}=${obj[i]}`).join('&')
        let URLS = `/users/sms?limit=${limit}&page=${page}&${queryParams}`

        setloading(true)
        logsservicesData.fetchCallLogs(URLS).then((res: any) => {
            if (res.success) {
                setSMSLogsData(res.data.data)
                setloading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        totalDocs: res.data.totalDocs
                    }
                })
            } else {
                setloading(false)
            }
        }).catch((err: any) => {
            //err
            setloading(false)
        })
    }

    const fetchSMSLogs = (limit: Number, page: Number) => {
        setloading(true)
        logsservicesData.fetchCallLogs(`/users/sms?limit=${limit}&page=${page}`).then((res: any) => {
            if (res.success) {
                setSMSLogsData(res.data.data)
                setloading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        totalDocs: res.data.totalDocs
                    }
                })
            } else {
                setloading(false)
            }
        }).catch((err: any) => {
            //err
            setloading(false)
        })
    }

    const onChangePage = (e: any, value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })

        //
        if (isSearching) {
            SearchFilterFun(searchFilterValue, paginate.limit, value)
        } else {
            fetchSMSLogs(paginate.limit, value)

        }
    }

    React.useEffect(() => {
        fetchSMSLogs(paginate.limit, paginate.page)
    }, [])

    return (
        <div id="SMS">
            <Accordion onChange={handleChange} style={{ marginTop: 20, borderRadius: 10, background: '#FFFFFF', boxShadow: '0 0 0.5em #E2E2E2', }}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading} id='heading2'>SMS logs</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <SearchFilter
                        onChangeFilterHanlder={onChangeFilterHanlder}
                        paginate={paginate}
                        searchFilterValue={searchFilterValue}
                        SearchFilterFun={SearchFilterFun}
                        clearFilter={clearFilter}
                    />
                </AccordionDetails>
                <AccordionDetails className={classes.accordianNoFlex}>
                    <Typography className={classes.calls}>Total Number of SMS logs : {paginate.totalDocs}</Typography>
                    <CardContent>
                        <Card style={{ marginTop: -1 }}>
                            <TableContainer>
                                <Table>
                                    <TableHead style={{ background: '#F1F1F1' }}>
                                        <TableRow>
                                            <TableCell className={classes.tableHead}>From Number</TableCell>
                                            <TableCell className={classes.tableHead}>To Number</TableCell>
                                            <TableCell className={classes.tableHead}>Forward Number</TableCell>
                                            <TableCell className={classes.tableHead}>Type</TableCell>
                                            <TableCell className={classes.tableHead}>Date/Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {/* <TableBody> */}
                                    {
                                        <TableBody>
                                            {
                                                loading === false && callSMSData.length > 0 && callSMSData.map((dt: any) => <TableBodyData rowData={dt} />)
                                            }
                                        </TableBody>
                                    }
                                    {/* </TableBody> */}
                                </Table>
                                {
                                    loading ? <div className='loader_class'>
                                        <Loader />
                                    </div> : callSMSData.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                                }
                            </TableContainer>
                        </Card>
                        <div className={classes.pagination}>
                            <Stack spacing={2}>
                                <Pagination onChange={onChangePage} count={Math.round(paginate.totalDocs / paginate.limit)} color="primary" variant="outlined" shape="rounded" />
                            </Stack>
                        </div>
                    </CardContent>
                </AccordionDetails>

            </Accordion>
        </div>
    )
}

export default SmsLogs
