import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
  {
    accordion: {
      background: "#FFFFFF",
      borderRadius: "7.51286px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
      marginBottom:20,
    },
  
    divider: {
      background: "#E2E2E2",
    },
    space: {
      marginTop: 25,
    },
    TotalUsers:{
      color: "#3A416F",
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontSize: 18,
  },
    inputFields:{
      margin: '10px 0px 20px',
      display: 'flex',
      alignItems:'center'
    },
    divButtons: {
      marginTop: 8,
      marginLeft: 80,
    },
    searchButton: {
      // marginBottom: 20,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding:"6px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },
    addButton:{
      // marginTop: 20,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      marginLeft: 22,
      marginBottom:-40,
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },
    clearButton: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      color: "#8392AB",
      border: "0.791301px solid #8392AB",
      background: "#F5F5F5",
      padding:"6px 20px",
      borderRadius: "4.7478px",
      marginLeft: 22,
      // marginBottom: 20,
    },
    summary: {
      // marginTop: 10,
      color: "#3A416F",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 22  ,
    },
    tableCard: {
      background: "#FFFFFF",
      borderRadius: "7.51286px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
      marginTop: 15,
      width:"70%"
    },
    tableHead: {
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
    },
    tableBody: {
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      alignItems: "center",
    },
    closedButton: {
      background: "linear-gradient(135deg, #98EC2D 0%, #17AD37 100%)",
      color: "#FFFFFF",
      borderRadius: 5,
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      height: 15,
      position:"sticky",
      padding:5,
      marginTop:-11,
      width:38,
    },
    pendingButton: {
      background: "linear-gradient(135deg, #ff4500 0%, #bf4f51 100%)",
      color: "#FFFFFF",
      borderRadius: 5,
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      height: 15,
      position:"sticky",
      padding:5,
      marginTop:-11,
      width:45
    },
    actionButton: {
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      textAlign: "center",
      color: "#3A416F",
      height: 20,
      width: 70,
      background: "#F5F5F5",
    },
    name: {
      // marginTop: 20,
      fontSize: 16,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
    },
    subject: {
      fontSize: 16,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
    },
    agent: {
      // marginTop: 18,
      fontSize: 16,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
    },
    selctBox: {
      // marginTop: 8,
      color: "#8392AB",
    },
    label: {
      // marginTop: 10,
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
    },
    grid: {
      marginBottom: 20,
    },
    ticketType: {
      // marginTop: 25,
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 15,
    },
    note: {
      width: "100%",
      borderRadius: 3,
      // marginTop: 8,
      fontWeight: 400,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      color: "black",
      fontSize: 14,
    },
  },
  { index: 1 }
);
