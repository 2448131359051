import { Button, TextField } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles';
import { useSelector } from 'react-redux'
import profileDataServices from '../../../components-services/MyAccountPage/ProfileData'
import * as userActionCreators from '../../../store/Actions/Authentication-creator'
import * as popAlertActionCreator from '../../../store/Actions/popup-actionCreator'

import { useDispatch  } from 'react-redux'
function EditProfile(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()

    const userAuthData = useSelector((state: any) => state.authState)
    const [userData, setUserData] = React.useState<any>(null)
    React.useEffect(() => {
        if(userAuthData.userData){
            setUserData(userAuthData.userData)
        }
    }, [userAuthData.userData])

    const onChangeData = (e: any) => {
        setUserData((prev: any) =>{
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const updateData = () =>{
        if (userData){
            profileDataServices.updateProfileDetails('/users', { firstName: userData.firstName, lastName: userData.lastName }).then((res: any) => {
                if(res.success){
                    dispatch(userActionCreators.authprofileDataSuccess({ userData: userData }))
                    props.popupHandlerUserDetails()
                    dispatch(popAlertActionCreator.initglobalPopup('Profile updated success', 'UPDATE_USER_ROUTE_SUCCESS', 'success'))
                }else{
                    //err
                    dispatch(popAlertActionCreator.initglobalPopup(res.message, 'UPDATE_USER_ROUTE_FAIL', 'error'))
                }
            }).catch((err: any) => {
                dispatch(popAlertActionCreator.initglobalPopup(err.message, 'UPDATE_USER_ROUTE_FAIL', 'error'))

                //err
            })
        }
    }

    return (
        <div style={{padding: 10}}>
            <div>
                <TextField onChange={onChangeData} name="firstName" value={userData?.firstName} variant="outlined" label="First Name" size="small" placeholder="First Name" className={classes.textField}/>    
            </div>
            <div>
                <TextField onChange={onChangeData} name="lastName" value={userData?.lastName} variant="outlined" label="Last Name" size="small" placeholder="Last Name" className={classes.textField}/>   
            </div>
            {/* <div>
                <TextField disabled onChange={onChangeData} name="email" value={userData?.email} variant="outlined" label="Email" size="small" placeholder="Email" className={classes.textField}/>    
            </div> */}
            <div style={{textAlign: 'center'}}>
                <Button onClick={updateData} style={{background: '#2645AD',color: '#FFFFFF',fontSize: 12,margin: '10px 0px'}}>Update</Button>
            </div>
        </div>
    )
}

export default EditProfile
