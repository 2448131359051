import React from 'react'
import './skeleton.css'
function Skeleton(props: any) {
    const { width, height, borders, padding, margin, background } = props;

    const skeleTonStyle = {
        width: width === undefined || null ? '' : width,
        height: height === undefined || null ? '' : height,
        borderRadius: borders === undefined || null ? '' : borders,
        padding: padding === undefined || null ? '' : padding,
        margin: margin === undefined || null ? '' : margin,
        backgroundColor: background === undefined || null ? '' : props.background,
    }

    return <div style={skeleTonStyle} className="skeleton loading"></div>
}

export default Skeleton
