import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as authActionCreators from '../../store/Actions/Authentication-creator'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import './lagout.css'

function Logout(props: any) {
    const history: any = useHistory();

    if(props.authData.isAuthenticated === false){
        history.push('/')
    }

    React.useLayoutEffect(() => {
        props.loguserout()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="logout_section">
            <div className="logout_animation">
                <CircularProgress />
                <div className="exit_icon">
                    <ExitToAppIcon />
                </div>
                <p>Logging user out.....!!</p>
            </div>
        </div>
    )
}

const mapStateToprops = (state: any) => {
    return {
        authData: state.authState
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loguserout: () => dispatch(authActionCreators.initAuthLogout())
    }
}

export default connect(mapStateToprops, mapDispatchToProps)(Logout)
