

import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';

class localStorageData{
    setLocalUserData(userData: any){
        localStorage.setItem('user', JSON.stringify(userData))
    }

    getLocalUserData(){
        const localUser: any = localStorage.getItem('user')
        const parseUserData = JSON.parse(localUser);
        return parseUserData;
    }

}

const localStorageContent = new localStorageData()

export default localStorageContent;