
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core'
import { useStyles } from '../../styles';
import TableBodyData from './TableBody'
import Loader from '../../../../components/Loader/Loader'
import '../style.css'

function ClosedTicketTable(props: any) {
    const classes = useStyles();
    const data = props.closedTickets;
    return (
        <div id="CloseTickets">
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead style={{ background: '#F2F2F2' }}>
                        <TableRow>
                            {/* <TableCell className={classes.tableHead}>Ticket ID</TableCell> */}
                             <TableCell className={classes.tableHead}>Subject</TableCell>
                            <TableCell className={classes.tableHead}>Create Date</TableCell>
                            <TableCell className={classes.tableHead}>Ticket Status</TableCell>
                           
                            <TableCell className={classes.tableHead}>Department</TableCell>
                            <TableCell className={classes.tableHead}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        <TableBody>    
                            {
                                props.loadingTickets === false && data.length > 0 && data.map((dt: any) => <TableBodyData  rowData={dt} />) 
                            }
                        </TableBody>    
                    }

                </Table>
                {
                    props.loadingTickets ? <div className='loader_class'>
                        <Loader />
                    </div> : data.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                }
            </TableContainer>
        </div>
    )
}

export default ClosedTicketTable
