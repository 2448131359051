import { ticketConstants, status } from '../Constants';

export function ticketUsers(state = {}, action: any) {
    switch (action.type) {
        case ticketConstants.SEARCH_TICKET_REQUEST:
            return {
                ...state,
                search_ticket_status: status.IN_PROGRESS,
                searchTicketList: action.data
            };
        case ticketConstants.SEARCH_TICKET_SUCCESS:
            return {
                ...state,
                search_ticket_status: status.SUCCESS,
                searchTicketList: action.data
            };
        case ticketConstants.SEARCH_TICKET_FAILURE:
            return {
                ...state,
                search_ticket_status: status.FAILURE,
                searchTicketList: null
            };
        default:
            return state;
    }
}
