export const efoneClientsConstants = {
    SEARCH_EFONE_CLIENTS_REQUEST: 'SEARCH_EFONE_CLIENTS_REQUEST',
    SEARCH_EFONE_CLIENTS_SUCCESS: 'SEARCH_EFONE_CLIENTS_SUCCESS',
    SEARCH_EFONE_CLIENTS_FAILURE: 'SEARCH_EFONE_CLIENTS_FAILURE',
};


export const adminUsersConstants = {
    SEARCH_AGENT_REQUEST: 'SEARCH_AGENT_REQUEST',
    SEARCH_AGENT_SUCCESS: 'SEARCH_AGENT_SUCCESS',
    SEARCH_AGENT_FAILURE: 'SEARCH_AGENT_FAILURE',
};

export const editadminUsersConstans = {
    EDIT_AGENT_REQUEST: 'EDIT_AGENT_REQUEST',
    EDIT_AGENT_SUCCESS: 'EDIT_AGENT_SUCCESS',
    EDIT_AGENT_FAILURE: 'EDIT_AGENT_FAILURE',
}

export const departmentNameConstants = {
    SEARCH_DEPARTMENT_REQUEST: 'SEARCH_DEPARTMENT_REQUEST',
    SEARCH_DEPARTMENT_SUCCESS: 'SEARCH_DEPARTMENT_SUCCESS',
    SEARCH_DEPARTMENT_FAILURE: 'SEARCH_DEPARTMENT_FAILURE',
};

export const editDepartmentUsersConstans = {
    EDIT_DEPARTMENT_REQUEST: 'EDIT_DEPARTMENT_REQUEST',
    EDIT_DEPARTMENT_SUCCESS: 'EDIT_DEPARTMENT_SUCCESS',
    EDIT_DEPARTMENT_FAILURE: 'EDIT_DEPARTMENT_FAILURE',
}

export const phoneNumberConstants = {
    SEARCH_PHONENUMBER_REQUEST: 'SEARCH_PHONENUMBER_REQUEST',
    SEARCH_PHONENUMBER_SUCCESS: 'SEARCH_PHONENUMBER_SUCCESS',
    SEARCH_PHONENUMBER_FAILURE: 'SEARCH_PHONENUMBER_FAILURE',
}

export const phoneNumberAssignedConstants = {
    SEARCH_PHONENUMBERASSIGNED_REQUEST: 'SEARCH_PHONENUMBERASSIGNED_REQUEST',
    SEARCH_PHONENUMBERASSIGNED_SUCCESS: 'SEARCH_PHONENUMBERASSIGNED_SUCCESS',
    SEARCH_PHONENUMBERASSIGNED_FAILURE: 'SEARCH_PHONENUMBERASSIGNED_FAILURE',
}

export const phoneNumberCancelledConstants = {
    SEARCH_PHONENUMBERCANCELLED_REQUEST: 'SEARCH_PHONENUMBERCANCELLED_REQUEST',
    SEARCH_PHONENUMBERCANCELLED_SUCCESS: 'SEARCH_PHONENUMBERCANCELLED_SUCCESS',
    SEARCH_PHONENUMBERCANCELLED_FAILURE: 'SEARCH_PHONENUMBERCANCELLED_FAILURE',
}

export const phoneNumberPortConstants = {
    SEARCH_PHONENUMBERPORT_REQUEST: 'SEARCH_PHONENUMBERPORT_REQUEST',
    SEARCH_PHONENUMBERPORT_SUCCESS: 'SEARCH_PHONENUMBERPORT_SUCCESS',
    SEARCH_PHONENUMBERPORT_FAILURE: 'SEARCH_PHONENUMBERPORT_FAILURE',
}

export const editphoneNumberConstants = {
    EDIT_PHONENUMBER_REQUEST: 'EDIT_PHONENUMBER_REQUEST',
    EDIT_PHONENUMBER_SUCCESS: 'EDIT_PHONENUMBER_SUCCESS',
    EDIT_PHONENUMBER_FAILURE: 'EDIT_PHONENUMBER_FAILURE',
}

export const addonConstants = {
    SEARCH_ADDON_REQUEST: 'SEARCH_ADDON_REQUEST',
    SEARCH_ADDON_SUCCESS: 'SEARCH_ADDON_SUCCESS',
    SEARCH_ADDON_FAILURE: 'SEARCH_ADDON_FAILURE',
}

export const editaddonConstants = {
    EDIT_ADDON_REQUEST: 'EDIT_ADDON_REQUEST',
    EDIT_ADDON_SUCCESS: 'EDIT_ADDON_SUCCESS',
    EDIT_ADDON_FAILURE: 'EDIT_ADDON_FAILURE',
}

export const ticketConstants = {
    SEARCH_TICKET_REQUEST: 'SEARCH_TICKET_REQUEST',
    SEARCH_TICKET_SUCCESS: 'SEARCH_TICKET_SUCCESS',
    SEARCH_TICKET_FAILURE: 'SEARCH_TICKET_FAILURE',
}

export const whiteListConstants = {
    SEARCH_WHITELIST_REQUEST: 'SEARCH_WHITELIST_REQUEST',
    SEARCH_WHITELIST_SUCCESS: 'SEARCH_WHITELIST_SUCCESS',
    SEARCH_WHITELIST_FAILURE: 'SEARCH_WHITELIST_FAILURE',
}

export const OutBoundConstants = {
    SEARCH_OUTBOUND_REQUEST: 'SEARCH_OUTBOUND_REQUEST',
    SEARCH_OUTBOUND_SUCCESS: 'SEARCH_OUTBOUND_SUCCESS',
    SEARCH_OUTBOUND_FAILURE: 'SEARCH_OUTBOUND_FAILURE',
}

export const OutBoundCallLogsConstants = {
    SEARCH_OUTBOUNDCALLLOGS_REQUEST: 'SEARCH_OUTBOUNDCALLLOGS_REQUEST',
    SEARCH_OUTBOUNDCALLLOGS_SUCCESS: 'SEARCH_OUTBOUNDCALLLOGS_SUCCESS',
    SEARCH_OUTBOUNDCALLLOGS_FAILURE: 'SEARCH_OUTBOUNDCALLLOGS_FAILURE',
}

export const InBoundConstants = {
    SEARCH_INBOUND_REQUEST: 'SEARCH_INBOUND_REQUEST',
    SEARCH_INBOUND_SUCCESS: 'SEARCH_INBOUND_SUCCESS',
    SEARCH_INBOUND_FAILURE: 'SEARCH_INBOUND_FAILURE',
}

export const InBoundGetIpDataConstants = {
    SEARCH_INBOUNDGETIPDATA_REQUEST: 'SEARCH_INBOUNDGETIPDATA_REQUEST',
    SEARCH_INBOUNDGETIPDATA_SUCCESS: 'SEARCH_INBOUNDGETIPDATA_SUCCESS',
    SEARCH_INBOUNDGETIPDATA_FAILURE: 'SEARCH_INBOUNDGETIPDATA_FAILURE',
}

export const SmsConstants = {
    SEARCH_SMS_REQUEST: 'SEARCH_SMS_REQUEST',
    SEARCH_SMS_SUCCESS: 'SEARCH_SMS_SUCCESS',
    SEARCH_SMS_FAILURE: 'SEARCH_SMS_FAILURE',
}

export const editSmsProviderConstants = {
    EDIT_SMSPROVIDER_REQUEST: 'EDIT_SMSPROVIDER_REQUEST',
    EDIT_SMSPROVIDER_SUCCESS: 'EDIT_SMSPROVIDER_SUCCESS',
    EDIT_SMSPROVIDER_FAILURE: 'EDIT_SMSPROVIDER_FAILURE',
}

export const VendorRatesConstants = {
    SEARCH_VENDOR_REQUEST: 'SEARCH_VENDOR_REQUEST',
    SEARCH_VENDOR_SUCCESS: 'SEARCH_VENDOR_SUCCESS',
    SEARCH_VENDOR_FAILURE: 'SEARCH_VENDOR_FAILURE',
}

export const AllCallsConstants = {
    SEARCH_ALLCALL_REQUEST: 'SEARCH_ALLCALL_REQUEST',
    SEARCH_ALLCALL_SUCCESS: 'SEARCH_ALLCALL_SUCCESS',
    SEARCH_ALLCALL_FAILURE: 'SEARCH_ALLCALL_FAILURE',
}

export const UsageReportsConstants = {
    SEARCH_USAGEREPORTS_REQUEST: 'SEARCH_USAGEREPORTS_REQUEST',
    SEARCH_USAGEREPORTS_SUCCESS: 'SEARCH_USAGEREPORTS_SUCCESS',
    SEARCH_USAGEREPORTS_FAILURE: 'SEARCH_USAGEREPORTS_FAILURE',
}

export const SmsReportsConstants = {
    SEARCH_SMSREPORTS_REQUEST: 'SEARCH_SMSREPORTS_REQUEST',
    SEARCH_SMSREPORTS_SUCCESS: 'SEARCH_SMSREPORTS_SUCCESS',
    SEARCH_SMSREPORTS_FAILURE: 'SEARCH_SMSREPORTS_FAILURE',
}

export const VendorReportsConstants = {
    SEARCH_VENDORREPORTS_REQUEST: 'SEARCH_VENDORREPORTS_REQUEST',
    SEARCH_VENDORREPORTS_SUCCESS: 'SEARCH_VENDORREPORTS_SUCCESS',
    SEARCH_VENDORREPORTS_FAILURE: 'SEARCH_VENDORREPORTS_FAILURE',
}


export const CallSimulationConstants = {
    SEARCH_CALLSIMULATION_REQUEST: 'SEARCH_CALLSIMULATION_REQUEST',
    SEARCH_CALLSIMULATION_SUCCESS: 'SEARCH_CALLSIMULATION_SUCCESS',
    SEARCH_CALLSIMULATION_FAILURE: 'SEARCH_CALLSIMULATION_FAILURE',
}


export const deviceProvisionConstants = {
    SEARCH_DEVICEPROVISION_REQUEST: 'SEARCH_DEVICEPROVISION_REQUEST',
    SEARCH_DEVICEPROVISION_SUCCESS: 'SEARCH_DEVICEPROVISION_SUCCESS',
    SEARCH_DEVICEPROVISION_FAILURE: 'SEARCH_DEVICEPROVISION_FAILURE',
};

export const editdeviceProvisionConstants = {
    EDIT_DEVICEPROVISION_REQUEST: 'EDIT_DEVICEPROVISION_REQUEST',
    EDIT_DEVICEPROVISION_SUCCESS: 'EDIT_DEVICEPROVISION_SUCCESS',
    EDIT_DEVICEPROVISION_FAILURE: 'EDIT_DEVICEPROVISION_FAILURE',
};

export const profileConstants = {
    SEARCH_PROFILE_REQUEST: 'SEARCH_PROFILE_REQUEST',
    SEARCH_PROFILE_SUCCESS: 'SEARCH_PROFILE_SUCCESS',
    SEARCH_PROFILE_FAILURE: 'SEARCH_PROFILE_FAILURE',
};