import { authenticationReducer } from './Reducers/authReducer'
import { globalPopupReducerData } from './Reducers/PopupReducer';
import {efoneClientsUsers} from "./Reducers/efoneClients.reducers";
import { adminUsers } from './Reducers/adminUsers.reducers';
import {editagentUsers} from "./Reducers/adminUsers.reducers"
import { departmentUsers } from './Reducers/departmentUsers.reducers';
import {editDepartmentUsers} from "./Reducers/departmentUsers.reducers"
import { addonUsers } from "./Reducers/addonUser.reducers"
import { phoneNumberUsers } from "./Reducers/phoneNumber.reducers";
import {editphoneNumberUsers} from "./Reducers/phoneNumber.reducers"
import {phoneNumberUsersAssigned} from "./Reducers/phoneNumber.reducers"
import {phoneNumberUsersCancelled} from "./Reducers/phoneNumber.reducers"
import {phoneNumberUsersPort} from "./Reducers/phoneNumber.reducers"
import { clients } from "./Reducers/clients.reducers"
import {ticketUsers} from "./Reducers/ticketUser.reducers"
import {WhiteListUsers} from './Reducers/whiteList.reducers'
import {outboundUsers} from "./Reducers/outBound.reducer"
import {inboundUsers} from "./Reducers/inBound.reducer"
import {inboundUsersGetIp} from "./Reducers/inBound.reducer"
import {SmsUsers} from "./Reducers/smsList.reducers"
import {editSmsUsers} from "./Reducers/smsList.reducers"
import {VendorUsers} from "./Reducers/vendor.reducers"
import {allCallsUsers} from "./Reducers/allCallUser.reducers"
import {UsageUsers} from "./Reducers/Usagereport.reducers"
import {editaddonUsers} from "./Reducers/addonUser.reducers"
import {SmsReportsUsers} from "./Reducers/smsReport.reducers"
import {VendorReportsusers} from "./Reducers/VendorReports.reducers"
import {callSimulationUsers} from "./Reducers/callSimulation.reducers"
import { orderNumber } from "./Reducers/orderNumber.reducers"
import {outboundCallLogsUsers} from  "./Reducers/outboundCallLogs.reducers"
import {deviceProvision} from "./Reducers/deviceProvision.reducers"
import {editDeviceProvision} from "./Reducers/deviceProvision.reducers"

const combineReducers = {
    authState: authenticationReducer,
    errorState: globalPopupReducerData,
    efoneClientsUsers,
    adminUsersState: adminUsers,
    editagentUsers,
    departmentUsersState: departmentUsers,
    editDepartmentUsers,
    phoneNumberUsers,
    phoneNumberUsersAssigned,
    phoneNumberUsersCancelled,
    phoneNumberUsersPort,
    editphoneNumberUsers,
    addonUsers,
    clientDetailState: clients,
    ticketUsers,
    WhiteListUsers,
    outboundUsers,
    outboundCallLogsUsers,
    inboundUsers,
    inboundUsersGetIp,
    SmsUsers,
    editSmsUsers,
    VendorUsers,
    allCallsUsers,
    UsageUsers,
    editaddonUsers,
    SmsReportsUsers,
    VendorReportsusers,
    callSimulationUsers,
    orderNumber,
    deviceProvision,
    editDeviceProvision,
}

export default combineReducers;