import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    heading:{
        color: "#2645ad",
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'work sans',
        "@media (max-width: 426px)": {
            fontSize: 17,
        },
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    accordianNoFlex:{
        display: 'block',
        padding: '10px 0'
    },
    callId: {
        color: '#707070',
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 500,
        marginBottom: 10,
        "@media (max-width: 426px)": {
            fontSize: 15,
        },
    },
    vector: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '10px 0px 0px 0px',
    },
    text: {
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#464646',
        //margin: '10',
        fontFamily: 'work sans'
    },
    ring: {
        color: '#303E67',
        fontSize: 18,
        fontFamily: 'work sans',
        fontWeight: 500,
        "@media (max-width: 426px)": {
            fontSize: 15,
        },
    },
    fexDiv: {
        display: 'flex',
        justifyContent: 'spece-between',
    },
    outbound: {
        marginBottom: 10,
        color: '#707070',
        fontSize: 16,
        fontFamily: 'work sans',
        fontWeight: 500,
    },
}))