import { makeStyles } from "@material-ui/core/styles";
import { padding } from "@mui/system";

export const useStyles = makeStyles(() => ({
    heading: {
        color: "#2645ad",
        fontSize: 20,
        fontWeight: 600,
        letterSpacing: 0.208955,
        textTransform: 'none',
        fontFamily: 'work sans',
        "@media (max-width: 426px)": {
            fontSize: 17,
        },
    },
    divider: {
        backgroundColor: '#E2E2E2',

    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
    },
    accordianNoFlex: {
        display: 'block',
        "@media (max-width: 426px)": {
            paddingBottom: 0
        },
    },
    tabCard: {
        background: '#f2f2f2',
        textAlign: 'center',
        width: '90%',
        margin: '20px 0px 10px',
        '@media (max-width:426px)': {
            width: '100%'
        }
    },
    tableHead: {
        fontFamily: 'work sans',
        fontWeight: 600,
        fontSize: 16,
        color: '#25282B',
    },
    tableData: {
        fontFamily: 'work sans',
        fontWeight: 600,
        fontSize: 16,
        color: '#707070',
        //textAlign: 'center'
        alignItems: 'center'
    },
    ring: {
        color: '#464646',
        fontSize: 19,
        fontFamily: 'work sans',
        fontWeight: 600,
    },
    fexDiv: {
        display: 'flex',
        justifyContent: 'spece-between',
    },
    tab: {
        minHeight: 10,
        // height: 30,
        borderRadius: 5,
        background: '#2645AD',
        boxSizing: 'border-box',
        fontFamily: 'work sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#FFFFFF',
        textTransform: 'none',
    },
    tabActive: {
        minHeight: 10,
        // height: 30,
        borderRadius: 5,
        background: '#F2F2F2',
        boxSizing: 'border-box',
        fontFamily: 'work sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#C9C9C9',
        textTransform: 'none',
    },
    tabs: {
        padding: 5,
        paddingBottom: 5,
    },
    voiceMail: {
        color: '#464646',
        fontSize: '18px',
        fontFamily: 'work sans',
        fontWeight: 600,
    },
    selectBox: {
        marginTop: 10,
    },
    vector: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'relative',
        '@media (max-width:426px)': {
            display: 'block',
            marginLeft: '-8px'
        }
    },
    iconWidth: {
        margin: '10px 4px 10px 14px',
        height: '20px',
        width: '20px',
        color: '#4D72F8'
    },
    active: {
        margin: 10,
        height: '20px',
        width: '20px',
        color: '#4D72F8'
    },
    notActive: {
        margin: 10,
        height: '20px',
        width: '20px',
        color: '#C4C4C4'
    },
    textWidth: {
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#2645AD',
        margin: 10,
        fontFamily: 'work sans'
    },
    activeText: {
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#2645AD',
        margin: 10,
        fontFamily: 'work sans',
        '@media (max-width:426px)': {
            fontSize: '14px',
            margin: '10px 10px 10px 0px'
        }
    },
    notActiveText: {
        fontSize: 18,
        fontWeight: 600,
        color: '#C4C4C4',
        margin: 10,
        fontFamily: 'work sans',
        '@media (max-width:426px)': {
            fontSize: '14px',
            margin: '10px 10px 10px 0px'
        }
    },
    audioFlex: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        '@media (max-width:426px)': {
            display: 'block',
            textAlign: 'right',
            marginTop: '-14px',
        }
    },
    text: {
        fontSize: 14,
        fontWeight: 500,
        color: '#707070',
        '@media (max-width:426px)': {
            display: 'inline-block',
        }
    },
    textField: {
        marginLeft: 30,
        width: '63%',
        '@media (max-width:426px)': {
            width: '90%',
            marginBottom: 20,
            marginLeft: 12,
        }
    },
    frwdBtns: {
        display: 'inline-flex',
        '@media(max-width:426px)': {
            width: '100%',
            textAlign: 'center'
        }

    },
    flexButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    uploadButton: {
        color: '#707070',
        fontFamily: 'work sans',
        background: '#EEEEEE',
        borderRadius: 4,
        border: '1px solid #CDCDCD',
        fontSize: 12,
        height: 27,
    },
    audioPlayer: {
        display: "block",
        width: "75%",
        marginTop: 35,
        height: 45,
        "@media (max-width:426px)": {
            marginTop: 0,
            width: '100%',
        },
        '&.selected': {
            color: '#0921a9'
        },
        '&:hover': {
            color: '#4D72F8'
        },

    },
    deleteButton: {
        marginTop: 35,
        fontSize: 14,
        fontFamily: 'work sans',
        fontWeight: 500,
        textTransform: 'capitalize',
        color: '#707070',
        background: '#EEEEEE',
        border: '1px solid #CDCDCD',
        "@media (max-width:426px)": {
            marginTop: 10,
            marginLeft: 4
        },
    },
    modaldeleteButton: {
        margin: 10,
        padding: '6px 22px',
        background: '#2645AD',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 3,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: 14,
        '&:hover': {
            background: '#2645AD',
            color: '#FFFFFF',
        }
    },
    deltecancelButton: {
        margin: 10,
        padding: '6px 22px',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginLeft: 15,
        background: '#EFEFEF',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#787878',
        fontSize: 14,
    },
    blocked: {
        marginLeft: '40%',
        color: '#464646',
        float: 'left',
        fontWeight: 600,
        fontFamily: 'work sans',
        fontSize: 19,
    },
    buttonDIv: {
        display: 'flex',
        '@media (max-width:426px)': {
            justifyContent: 'space-between'
        }
    },
    smsButton: {
        background: '#E2E2E2',
        fontSize: 12,
        fontWeight: 500,
        fontFamily: 'work sans',
        color: 'rgb(120, 120, 120)',
        marginLeft: 5,
    },
    frwdNum: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 10
    },
    callButton: {
        fontSize: 12,
        fontWeight: 500,
        marginLeft: 5,
        fontFamily: 'work sans',
        background: '#2645AD',
        color: '#fff',
        '&:hover': {
            background: '#2645AD',
            color: '#fff',
        },
        '&:active': {
            color: '#fff'
        }
    },
    AddNumber: {
        background: '#2645AD',
        marginTop: 10,
        marginLeft: '60%',
        color: '#fff',  
        margin: '0px 30px 0px',
        textTransform: 'capitalize',
        fontSize: 14,
        "@media (max-width: 426px)": {
            margin: '0px',
            fontSize: 12,
        },
        '&:hover': {
            background: '#2645AD',
            color: '#fff',
        }
        //padding: '6px 22px'
    },
    phoneNumber: {
        margin: '0px 0px 8px'
    },
    BlockphoneNumber: {
        margin: '8px 0px'
    },
    active_class: {
        color: '#2645AD'
    },
    not_active: {
        color: '#C9C9C9'
    },
    textColorred: {
        color: '#E91C1C',
        margin: '30px 0px 0px',
        fontFamily: 'work sans',
        fontWeight: 500,
    },
    dFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    edit: {
        color: '#707070',
        marginLeft: '10px',
        fontWeight: 500,
        fontFamily: 'work sans',
    },
    pointer: {
        cursor: 'pointer'
    },
    tootip: {
        fontSize: "13px",
        color: "white",
        fontFamily: "Work Sans",
        fontWeight: 400,
        padding: '6px 16px',
        borderRadius: "0px 10px 0px 0px",
        backgroundColor: "#2645ad",
        top: 6,
        position: 'relative',
        left: -10,
        lineHeight: 'auto',
        maxWidth: 'auto'
    }
}))