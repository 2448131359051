import {  status,SmsReportsConstants } from '../Constants';

export function SmsReportsUsers(state = {}, action: any) {
    switch (action.type) {
        case SmsReportsConstants.SEARCH_SMSREPORTS_REQUEST:
            return {
                ...state,
                search_smsReport_status: status.IN_PROGRESS,
                searchSmsReportList: action.data
            };
        case SmsReportsConstants.SEARCH_SMSREPORTS_SUCCESS:
            return {
                ...state,
                search_smsReport_status: status.SUCCESS,
                searchSmsReportList: action.data
            };
        case SmsReportsConstants.SEARCH_SMSREPORTS_FAILURE:
            return {
                ...state,
                search_smsReport_status: status.FAILURE,
                searchSmsReportList: null
            };
        default:
            return state;
    }
}