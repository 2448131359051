import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLessOutlined';
import ExpandMore from '@material-ui/icons/NavigateNextOutlined';
import { v4 } from 'uuid';
import { Link, animateScroll as scroll } from 'react-scroll'
import { useHistory, withRouter } from 'react-router-dom'
import React from 'react'
import './sidebaritem.css'


function Sidebaritem(props: any) {
    const { title, path, Icon, IconSize, routes, color } = props;
    const [open, setOpen] = React.useState(false);
    const [active, setActive] = React.useState(false)
    const { location, push } = useHistory();

    React.useEffect(() => {
        if (location.pathname === path) {
            setOpen(true)
            setActive(true)
        } else {
            setActive(false)
        }
    }, [location.pathname])

    const handleClick = () => {
        setOpen(!open);
        push(path)
        props.handlePopupModal()
    };

    const handleChildClick = (path: any) => {
        push(path)
        if (routes.length > 0) {
            setOpen(true);
            setActive(true)
        }
    }


    React.useEffect(() => {
        return () => {
            setActive(false)
        }
    }, [])
    return (
        <div className="sidebar_list_item">
            <ListItem button onClick={handleClick} style={{ boxShadow: `${active ? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' : 0}`, padding: '10px', borderRadius: '5px' }}>
                <ListItemIcon>
                    <div className={`${active && "logoactive"}`} style={{ display: 'grid', placeItems: 'center', padding: '3px', borderRadius: '8px', boxShadow: '0 1px 3px#ccc' }}>
                        <Icon size={IconSize} />
                    </div>
                </ListItemIcon>
                <ListItemText primary={title} className={`side_MuiListItemText-root ${active && "sidebarActive"}`} style={{ marginLeft: '-15px' }} />
                {(open && routes.length > 0) ? <ExpandLess style={{ color: 'grey' }} /> : <ExpandMore style={{ color: 'grey' }} />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {
                    routes.map((route: any) => {
                        const childActive = location.pathname === route.path;
                        // if (route.status)
                            return (
                                <Link
                                    activeClass="active"
                                    to={route.id}
                                    spy={true}
                                    smooth={true}
                                    offset={-140}
                                    duration={1000}   
                                    style={{marginLeft: '15px', display: 'block'}}                             
                                >
                                    <List component="div" disablePadding key={v4()}>
                                        <ListItem button>
                                            <ListItemIcon style={{position:'relative',left:18}}>
                                                <div className={`${childActive && "logoactive"}`} style={{ display: 'grid', placeItems: 'center', padding: '3px', borderRadius: '8px', boxShadow: '0 1px 3px#ccc' }}>
                                                    {/* <icon.Icon size={icon.size} /> */}
                                                    <route.Icon color={route.color} />
                                                </div>
                                            </ListItemIcon>
                                            <ListItemText primary={route.title} />
                                        </ListItem>
                                    </List>
                                </Link>
                            )
                    })
                }
            </Collapse>
        </div>
    )
}

export default withRouter(Sidebaritem)
