import { Button, Checkbox, FormControlLabel, Card, FormGroup, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import profileServices from '../../../../components-services/MyAccountPage/ProfileData';
import { useStyles } from '../styles'
import ReactSelect from 'react-select';
import { useDispatch } from 'react-redux'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'

function EditBlockNumber(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [selectedBlockCallType, setSelectedBlockCallType] = React.useState<any>(null);
    const [submitted, setSubmitted] = React.useState(false);
    const typeOptions = [
        { label: "Hangup", value: 1 },
        { label: "Voice mail", value: 2 }
    ]
    const [addNumber, setAddNumber] = React.useState({
        phoneNumber: "",
        calls: false,
        blockCallType: "",
        sms: false
    })

    const onChangeData = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            setAddNumber((prev: any) => {
                return {
                    ...prev,
                    [e.target.name]: val.replace(/[^0-9,.]+/g, "")
                }
            })
        }

    }
    const selcetType = ((e: any) => {
        const filterBlockType = typeOptions.find((dt: any) => dt.value == e.value)
        if (filterBlockType) {
            setSelectedBlockCallType(filterBlockType)
        }
        setAddNumber((prev: any) => {
            return {
                ...prev,
                blockCallType: e.value
            }
        })
    })

    const onChangeCheckBox = (e: any) => {
        setAddNumber((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }
        })
    }




    const patchBlockNumber = () => {
        if (addNumber.phoneNumber !== "" && addNumber.phoneNumber.length < 6 && addNumber.phoneNumber.length > 10) {
            dispatch(globalPopupCreator.initglobalPopup("Please provide valid Phone Number", "SUBSCRIPTION_ADDED", "error"))
            return
        }
        else if (addNumber.blockCallType === "") {
            dispatch(globalPopupCreator.initglobalPopup("Please provide Block call Type", "SUBSCRIPTION_ADDED", "info"))
            return
        }
        setSubmitted(true);
        const errorData = validate(true);
        if (errorData.isValid) {
            if (!addNumber.calls && !addNumber.sms) {
                dispatch(globalPopupCreator.initglobalPopup("Please select block calls or sms", "SUBSCRIPTION_ADDED", "error"))

            }
            else {
                profileServices.updateBlockNumber(`/accounts/blockednumbers/${props.id}`, addNumber)
                    .then((res) => {
                        if (res.success) {
                            props.handlePopupModal()
                            props.fetchBlockNumbers()
                            dispatch(globalPopupCreator.initglobalPopup("Updated Bloked Number successfully", "SUBSCRIPTION_ADDED", "success"))
                        } else {
                            //err
                        }
                    }).catch((err: any) => {
                        //err
                    })
            }

        }

    }
    React.useEffect(() => {
        const filterBlockType = typeOptions.find((dt: any) => dt.value == props.data.blockCallType)
        if (filterBlockType) {
            setSelectedBlockCallType(filterBlockType)
        }
        setAddNumber((prev: any) => {
            return {
                ...prev,
                phoneNumber: props.data.phoneNumber,
                calls: props.data.calls,
                blockCallType: props.data.blockCallType,
                sms: props.data.sms
            }
        })
    }, [props.data])
    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            newnumber: { ...validData },
        };
        let isValid = true;
        if (submitted) {
            if (addNumber.phoneNumber === "") {
                retData.newnumber = {
                    isValid: false,
                    message: "Please enter phone number"
                };
                isValid = false;
            }
            else if (addNumber.phoneNumber.length < 10) {
                retData.newnumber = {
                    isValid: false,
                    message: "Phone Number should be less than 10 digits"
                };
                isValid = false;
            }
            else if (addNumber.phoneNumber.length > 15) {
                retData.newnumber = {
                    isValid: false,
                    message: "Phone Number should not be greater than 15 digits"
                };
                isValid = false;
            }



        }
        return {
            ...retData,
            isValid
        };
    };
    const errors = validate(submitted);
    return (
        <div>

            <Grid container spacing={4} style={{ padding: 10 }}>
                <Grid item md={12} >
                    <Typography className={classes.phoneNumber} >PhoneNumber</Typography>
                    <TextField
                        variant="outlined"
                        size='small'
                        fullWidth
                        value={addNumber.phoneNumber}
                        placeholder="Enter Phone Number"
                        name='phoneNumber'
                        onChange={onChangeData}
                        error={!errors.newnumber.isValid}
                    />
                    {
                        !errors.newnumber.isValid &&
                        <p className={classes.errorMessage}>{errors.newnumber.message}</p>
                    }
                    {/* <Typography className={classes.phoneNumber} style={{marginTop: 8}}>BlockCall Type</Typography>
                    <ReactSelect
                        variant="outlined"
                        size='small'
                        fullWidth
                        value={selectedBlockCallType}
                        name="blockCallType"
                        options={typeOptions}
                        onChange={selcetType}
                    /> */}

                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={addNumber.sms} name="sms" onChange={onChangeCheckBox} color="primary" />}
                            label="SMS"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={addNumber.calls} name="calls" onChange={onChangeCheckBox} color="primary" />}
                            label="Calls" />
                    </FormGroup>
                    {/* {
                        addNumber.sms === true ? 
                        <div>
                            <Typography className={classes.BlockphoneNumber}>BlockCall Type</Typography>
                            <ReactSelect
                                placeholder="Select BlockCall Type"
                                name="blockCallType"
                                options={typeOptions}
                                onChange={selcetType}
                            />
                        </div> : ""
                    } */}
                    {
                        addNumber.calls === true ?
                            <div>
                                <Typography className={classes.BlockphoneNumber}>BlockCall Type</Typography>
                                <ReactSelect
                                    placeholder="Select BlockCall Type"
                                    name="blockCallType"
                                    options={typeOptions}
                                    onChange={selcetType}
                                    value={
                                        typeOptions.filter((option: any) =>
                                            option.value === addNumber.blockCallType)
                                    }

                                />
                            </div> : ""
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={12} style={{ textAlign: 'center' }}>
                    <div>
                        <Button size="medium" className={classes.modaldeleteButton} onClick={patchBlockNumber}>Update</Button>
                        <Button size="medium" className={classes.deltecancelButton} onClick={props.handlePopupModal} >
                            Cancel
                        </Button>
                    </div>
                </Grid>
            </Grid>


        </div>
    )
}

export default EditBlockNumber
