import {  status,InBoundConstants,InBoundGetIpDataConstants } from '../Constants';

export function inboundUsers(state = {}, action: any) {
    switch (action.type) {
        case InBoundConstants.SEARCH_INBOUND_REQUEST:
            return {
                ...state,
                search_inbound_status: status.IN_PROGRESS,
                searchInBoundList: action.data
            };
        case InBoundConstants.SEARCH_INBOUND_SUCCESS:
            return {
                ...state,
                search_inbound_status: status.SUCCESS,
                searchInBoundList: action.data
            };
        case InBoundConstants.SEARCH_INBOUND_FAILURE:
            return {
                ...state,
                search_inbound_status: status.FAILURE,
                searchInBoundList: null
            };
        default:
            return state;
    }
}

export function inboundUsersGetIp(state = {}, action: any) {
    switch (action.type) {
        case InBoundGetIpDataConstants.SEARCH_INBOUNDGETIPDATA_REQUEST:
            return {
                ...state,
                search_inboundgetIp_status: status.IN_PROGRESS,
                searchInBoundgetipList: action.data
            };
        case InBoundGetIpDataConstants.SEARCH_INBOUNDGETIPDATA_SUCCESS:
            return {
                ...state,
                search_inboundgetIp_status: status.SUCCESS,
                searchInBoundgetipList: action.data
            };
        case InBoundGetIpDataConstants.SEARCH_INBOUNDGETIPDATA_FAILURE:
            return {
                ...state,
                search_inboundgetIp_status: status.FAILURE,
                searchInBoundgetipList: null
            };
        default:
            return state;
    }
}