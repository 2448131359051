import React from 'react'
import {
    Accordion, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Typography,Tooltip
} from '@material-ui/core';
import { useStyles } from './styles';
import ReactSelect from 'react-select'
import Moment from 'moment'
const searchOption1: any = [{ label: 'Number', value: 'number' }]
const searchoption3: any = [{ label: 'All', value: '' }, { label: 'Incoming calls', value: 1 }, { label: 'Outgoing Calls', value: 2 }]
const searchoption2: any = [{ label: 'All', value: 'All' }, { label: 'Answered', value: true }, { label: 'UnAnswered', value: false }]



function SearchFilters(props: any) {
    const classes = useStyles();
    const [searchValue1, setSearchValue1] = React.useState<any>(null)
    const [searchValue2, setSearchValue2] = React.useState<any>(null)
    const [searchValue3, setSearchValue3] = React.useState<any>(null)
    const [searchValue4, setSearchValue4] = React.useState<any>(null)
    const [searchValue5, setSearchValue5] = React.useState<any>(null)


    const onChangeHandler = (data: any, propertyName: any) => {
        switch (propertyName) {
            case "search_op1":
                setSearchValue1(data)
                props.onChangeFilterHanlder("number", "")
                break;
            case "number":
                props.onChangeFilterHanlder("number", data.target.value)
                break;
            case "startDate":
                props.onChangeFilterHanlder("startDate", new Date(data.target.value).setHours(0, 0, 0, 0))
                break;
            case "endDate":
                props.onChangeFilterHanlder("endDate", new Date(data.target.value).setHours(23, 59, 59, 59))
                break;
            case "search_op2":
                setSearchValue2(data)
                props.onChangeFilterHanlder("callStatus", data.value)
                break;
            case "search_op3":
                setSearchValue3(data)
                props.onChangeFilterHanlder("callType", data.value)
                break;
            case "search_op4":
                setSearchValue4(data)
                props.onChangeFilterHanlder("callError", data.value)
                break;
            case "search_op5":
                setSearchValue5(data)
                props.onChangeFilterHanlder("osType", data.value)
                break;
        }
    }

    const onSearchHandler = () => {
        props.SearchFilterFun(props.searchFilterValue, props.paginate.limit, props.paginate.page)
    }

    const displayDates = (dateString: any) => {
        const dateNow = new Date(dateString);
        const year = dateNow.getFullYear();
        const monthWithOffset = dateNow.getMonth() + 1;
        const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
        const date = dateNow.getDate().toString().length < 2 ? `0${dateNow.getDate()}` : dateNow.getDate();
        const materialDateInput = `${year}-${month}-${date}`;
        return materialDateInput
    }

    const clearSearchFilter = () => {
        setSearchValue1(null)
        setSearchValue2(null)
        setSearchValue3(null)
        setSearchValue4(null)
        setSearchValue5(null)


        props.clearFilter()
    }
    var futurdate = new Date()
    var dataaa = Moment(futurdate).format('YYYY-MM-DD')
    return (
        <>
            <Grid container spacing={2} style={{}}>
                {/* <Grid item md={3}>
            <div>
                <Grid container spacing={2}>
                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ReactSelect 
                            value={searchValue1}
                            options={searchOption1} 
                            onChange={(dt) => onChangeHandler(dt,'search_op1')} 
                            placeholder="Select Field" />
                    </Grid> */}

                <Grid item xl={3} lg={2} md={4} sm={6} xs={12}>
                    <TextField
                        placeholder="Enter Number"
                        onChange={(dt) => onChangeHandler(dt, 'number')}
                        value={props.searchFilterValue.number}
                        variant="outlined"
                        size='small'
                        fullWidth
                    />
                </Grid>

                <Grid item xl={3} lg={2} md={4} sm={6} xs={12}>
                    <Tooltip title="Start date">
                        <TextField
                            variant="outlined" fullWidth size="small"
                            onChange={(dt: any) => onChangeHandler(dt, 'startDate')}
                            value={displayDates(props.searchFilterValue.startDate)}
                            type="date"
                            inputProps={{
                                max: dataaa
                            }}
                        />
                    </Tooltip>
                </Grid>

                <Grid item xl={3} lg={2} md={4} sm={6} xs={12}>
                    <Tooltip title="End date">
                        <TextField
                            variant="outlined" fullWidth size="small"
                            onChange={(dt: any) => onChangeHandler(dt, 'endDate')}
                            value={displayDates(props.searchFilterValue.endDate)}
                            type="date"
                            inputProps={{
                                max: dataaa
                            }}
                        />
                    </Tooltip>

                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <ReactSelect
                        onChange={(dt: any) => onChangeHandler(dt, 'search_op3')}
                        options={searchoption3}
                        value={searchValue3}
                        placeholder="Select Call Type" />
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div style={{ textAlign: "center", display: "flex" }}>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.search}
                            onClick={onSearchHandler}
                        >
                            Search
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={clearSearchFilter}
                            className={classes.clear}
                        >
                            Clear
                        </Button>
                    </div>
                </Grid>

            </Grid>

            {/* <div style={{ marginTop: 15 }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div style={{ textAlign: "center" }}>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.search}
                            onClick={onSearchHandler}
                        >
                            Search
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={clearSearchFilter}
                            className={classes.clear}
                        >
                            Clear
                        </Button>
                    </div>
                </Grid>
            </div> */}
        </>
    )
}

export default SearchFilters
