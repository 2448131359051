import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  cardContent: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "4px 12px",
  },
  gridItem: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContent1: {
    //display: "flex",
    //alignItems: "center",
    textAlign: 'center',
    marginTop: -40,
    "@media (max-width: 426px)": {
     margin:0,
     padding:0
    },
  },
  timeDate: {
    border: '1px solid lightgray',
    marginTop: 5,
    padding: 5,
    borderRadius: 5,
    "@media (max-width: 426px)": {
      marginLeft: 31,
  },
  },
 
  profile: {
    width: 150,
    height: 150,
    background: 'white',
    
   
  },
  heading:{
    color: "#2645ad",
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.208955,
    textTransform: 'none',
    "@media (max-width: 426px)": {
      fontSize: 17,
  },
  },
  accordianNoFlex:{
      display: 'block',
      padding: '10px 0'
  },
  head1: {
    fontSize: "18px",
    color: "#565656",
    fontWeight: 800,
  },
  headSmall: {
    fontSize: "14px",
    margin: 5,
    fontWeight: 600,
    color: "#565656",
   
  },
  align: {
    textAlign: "center",
  },
  dFlex:{
    display:"flex",justifyContent:"center",alignItems:"center",
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 800
  },
  gmail: {
    fontSize: "14px",
    margin: 5,
    fontWeight: 600,
    color: "rgb(177 177 177)",
  },
  userEdit: {
    width: 25,
    marginLeft: 20,
  },
  button: {
    margin: 10,
    fontSize: 14,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: 0.208955,
    textTransform: "none",
    textAlign: "center",
    borderRadius: 25,
    padding:"4px 20px",
    color: "#4D72F8",
    backgroundColor: "#FFFFFF",
    borderColor: "#4D72F8",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: "#FFFFFF",
    },
  },
  button1: {
    //margin: 10,
    fontSize: 14,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: 0.208955,
    textTransform: "none",
    textAlign: "center",
    borderRadius: 25,
    padding:"4px 20px",
    color: "#E91C1C",
    backgroundColor: "#FFFFFF",
    borderColor: "#E91C1C",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: "#FFFFFF",
    },
  },
  grid: {
    width: "100%",
  },
  doNotDisturbCard: {
    padding: 5,
    borderRadius: 10,
    background: "#FFFFFF",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
  },
  textfield: {
    marginTop: 15,
    color:'#9A9A9A', 
    fontFamily: "Work Sans",
    fontStyle: "normal",
  },
  submitbutton:{
    margin:15,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing:  0.208955,
    
    textAlign: "center",
    borderRadius: 4,
    color: "#787878",
    backgroundColor: "#EFEFEF",


  },
  outBondCard: {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    background: "#FFFFFF",
    marginTop: 25,
    padding: 10,
    //marginBottom: 60,
    width:'50%',
    '@media (max-width:426px)':{
      width:'100%',
      padding: 6,
    }
  },
  switchColor: {
    color: "#E91C1C",
  },
  cardColor: {
    color: "#4D72F8",
    fontFamily: "work Sans",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 600,
  },
  prelative: {
    position: "relative",
    top: 20,
    "@media (max-width: 426px)": {
      top: 0,
    },
  },
  divider: {
    margin: 5,
    backgroundColor: "#E2E2E2",
  },
  divider2: {
    '@media (max-width:426px)':{
      height: 30,
    backgroundColor: "#c2c2c2",
    }
  },
  dndCardContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "18px",
    
  },
  dndBullet: {
    fontSize: "12px",
    margin: 10,
  },
  dndText: {
    fontSize: "14px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#707070",
    letterSpacing: "0.02em",
    textTransform: "none",
  },
  departmentNameText: {
    fontSize: 16,
    //marginRight: 20,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#464646",
    letterSpacing: 0.1,
    textTransform: "none",
    // marginTop: 5,
    // marginLeft: 7,
  },
  editIcon: {
    color: "#4D72F8",
    marginLeft: 5,
  },
  dnd_flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    '@media (max-width:426px)':{
      display:'block'
    }
  },
  radioLabel: {
    fontFamily: 'work sans',
    color: '#707070',
    fontWeight: 500,
    //fontSize: 14,
  },
  balance: {
    fontFamily: 'work sans',
    color: '#707070',
    fontWeight: 500,
    fontSize: 16,
  },
  divFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 50px',
  },
  blncCard:{
    display: 'flex',
    alignItems:'center',
    justifyContent: 'space-evenly',
    background:'#f2f2f2',
    padding:10,
    margin:10
  },
  datePicker: {
    height: '30px',
    borderRadius: '3px',
    border:'2px solid #ccc',
    '&:focus':{
      border:'1px solid #ccc'
    },
    '@media (max-width:426px)':{
      width:'100%',
      height: '35px',
      marginLeft: '32px'
    }
  },
  hourPicker:{
    '@media (max-width:426px)':{
      marginLeft: '32px'
    }
  },
  countBalance: {
    fontSize: 20,
    color: '#464646',
    fontFamily: 'work sans',
    fontWeight: 500,
  },
  balanceValue:{
    textAlign:'center',
    '@media (max-width:426px)':{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      background: '#f3f3f3',
      padding: '10px 0px',
      borderRadius: 6,
    }
  }
}));
