import React from 'react'

import logo from '../../utils/images/logo-new-2.png'
import authposter from '../../utils/images/login-desing-1.png'
import { FcGoogle } from 'react-icons/fc'
import './Auth.css'
//import ForgetPassword from '../../components/Header-component/ForgotPassword/ForgotPassword'
import ForgetPassword from '../../container/ForgetPassword/ForgetPassword'


function Auth() {

    return (
        <div className="auth_container">
            <div className="auth_logo_wrapper">
                <div className="logo_img_container">
                    <img src={logo} alt="logo" />
                </div>
            </div>
            <div className="auth_flex_section">
                <div className="auth_poster">
                    <img src={authposter} alt="authposter" />
                </div>
                <div className="auth_forms_wrapper">
                    <div className="auth_forms_container">
                        <ForgetPassword />
                    </div>
                </div>
            </div>
            <div className="auth_footer_section">
                <div className="auth_footer_desc">
                    <p>copy rights @2021 all rights reserved with <span className="auth_footer_bold">eFone</span></p>
                </div>
                {/* <div className="auth_footer_google_oauth">
                    <div className="google_oauth_wrapper">
                        <FcGoogle size={30}/>
                        <p>Sign in with Google</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Auth
