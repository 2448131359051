import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    heading:{
        color: "#2645ad",
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'work sans'
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    pagination: {
      marginTop: 20,
      float: 'right',
       marginBottom: 20,

        

    },
    accordianNoFlex:{
        display: 'block'
    },
    audioPlayer: {
      display: "block",
      width: "73%",
      marginLeft: "73px",
      height: 48,
      '&.selected': {
        color: '#0921a9'
      },
      '&:hover': {
        color: '#4D72F8'
      },
    },
    
    deleteCallButton: {
        color: '#fff',
        background: '#B70909',
        fontWeight: 500,
        fontFamily: 'work sans',
        textTransform: 'capitalize',
        '&:hover': {
            color: '#fff',
            background: '#B70909',
        }
    },
    search: {
      background: "#2645AD",
      textTransform: "capitalize",
      border: "0.791301px solid #3A416F",
      borderRadius: 3,
      color: "#FFFFFF",
      padding: '5px 30px',
      fontFamily: 'work sans',
      marginRight: 10,
      fontSize: 14,
      "&:hover": {
        background: "#2645AD",
        color: "#FFFFFF",
      },
    },
    deleteButtonNew: {
      margin: 10,
      background: '#4D72F8',
      border: '1px solid #AEAEAE',
      boxSizing: 'border-box',
      borderRadius: 5,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      padding: '6px 22px',
      color: '#FFFFFF',
      fontSize: 14,
      '&:hover': {
        background: '#4D72F8',
        color: '#FFFFFF',
      }
    },
     CancelButtonNew: {
      fontFamily: 'Work Sans',
      fontWeight: 500,
      marginLeft: 15,
      background: '#EFEFEF',
      border: '1px solid #AEAEAE',
      borderRadius: 5,
      color: '#8392AB',
      fontSize: 14,
      padding: '6px 22px',
    },
    clear: {
        background: "#B70909",
        border: "0.791301px solid #8392AB",
         fontFamily: 'work sans',
        borderRadius: 3,
        textTransform: "capitalize",
        padding: '5px 30px',
        fontSize: 14,
        color: '#FFFFFF',
        "&:hover": {
            background: "#B70909",
            color: '#FFFFFF',
        },
    },
    tableHead: {
        fontFamily: 'work sans',
        fontWeight: 600,
        fontSize: 16,
        color: '#25282B',
    },
    tableData:{
        fontFamily: 'work sans',
        fontWeight: 600,
        fontSize: 16,
        color: '#707070',
        //textAlign: 'center'
        alignItems: 'center'
    },
     mainDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      overflowX:'scroll'
    },
     subDiv: {
      padding: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
     callsApp: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      marginLeft: 4,
    },
    barLine: {
      color: "#8392AB",
      marginLeft: 40,
    },
    calls: {
      fontSize: 17,
      fontFamily: 'work sans',
      fontWeight: 500,
      color: '#707070',
      marginLeft: 16,
      '@media (max-width:426px)':{
        fontSize:14
       }  
    }
}))