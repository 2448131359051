import axiosInstance from '../../environment/axiosInstances'

class mySettingsServices{
    fecthMysettings = async (URL: any) => {
        try{
            const mySettings: any = await axiosInstance.get(URL)
            if(mySettings.data && mySettings.status === 200){
                return { success: true, data: mySettings.data }
            }else{
                return { success: false, message: mySettings?.data.message }
            }
        }catch(err: any){
            return { success: false, message: err.response.data.message }
        }
    }

    updateSetting = async (URL: any, data: any) => {
        try {
            const mySettings: any = await axiosInstance.patch(URL, data)
            if (mySettings.data && mySettings.status === 200) {
                return { success: true, data: mySettings.data }
            } else {
                return { success: false, message: mySettings?.data.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    updateProfileDetails = async (URL: any, data: any) => {
        try{
            const myprofile: any = await axiosInstance.patch(URL, data)
            if(myprofile.data && myprofile.status === 200){
                return { success: true, data: myprofile.data }
            }else{
                return { success: false, message: myprofile?.data.message }
            }
        }catch(err: any){
            return { success: false, message: err.response.data.message }
        }
    }

    //BlockNumber

    fecthBlockNumberData = async (URL: any) => {
        try{
            const response: any = await axiosInstance.get(URL)
            if(response.data.data && response.status === 200){
                return { success: true, data: response.data.data }
            }else{
                return { success: false, message: response?.data.message }
            }
        }catch(err: any){
            return { success: false, message: err.response.data.message }
        }
    }

    addBlockNumbers = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 201) {
                return { success: true, data: response.data.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    deleteBlockNumber = async (URL: any) => {
        try {
            const response: any = await axiosInstance.delete(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    updateBlockNumber = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.patch(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    //End BlockNumbers

    //mynumbers
    getMyNumbers = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data.data }
            } else {
                return { success: false, message: response?.data.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    addMyNumbers = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 201) {
                return { success: true, data: response.data.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    updateMyNumber = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.patch(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    
    verifyMyNumbersOtp = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message}
        }
    }

    updateMynumberPrimary = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.patch(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    deleteMynumber = async (URL: any) => {
        try {
            const response: any = await axiosInstance.delete(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data.message }
            }
        } catch (err: any) {
            return { success: false, message:  err.response.data.message}
        }
    }


    //devices
   getmydevicesList = async(URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    updateMyDeviceList = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.patch(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    deleteMydevice = async (URL: any) => {
        try {
            const response: any = await axiosInstance.delete(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message}
        }
    }

    //voice 
    getVoicemailList = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    createVoicemail = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.post(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message:err.response.data.message }
        }
    }

    getVoicemailDetails = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    deleteVoiceMail = async (URL: any) => {
        try {
            const response: any = await axiosInstance.delete(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }

    //e911 detaiks
    
    gete911details = async (URL: any) => {
        try {
            const response: any = await axiosInstance.get(URL)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message}
        }
    }

    postE911Details = async (URL: any, data: any) => {
        try {
            const response: any = await axiosInstance.patch(URL, data)
            if (response.data && response.status === 200) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response?.data?.message }
            }
        } catch (err: any) {
            return { success: false, message: err.response.data.message }
        }
    }
    
}

const profileServices = new mySettingsServices()
export default profileServices