import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableBody, Tooltip, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import React from 'react'
import ReactSelect from 'react-select'
import { useStyles } from './styles';
import Moment from 'moment'
const searchOption1: any = [{ label: 'Number', value: 'number' }]
const searchoption2: any = [{ label: 'All', value: 'All' }, { label: 'OutBound', value: '1' }, { label: 'inBound', value: '2' }]
const searchoption3: any = [{ label: 'All', value: 'All' }, { label: 'Delivered', value: '1' }, { label: 'Failed', value: '2' }]


function SearchFilter(props: any) {
    const classes = useStyles();
    const [searchValue1, setSearchValue1] = React.useState<any>(null)
    const [searchValue2, setSearchValue2] = React.useState<any>(null)
    const [searchValue3, setSearchValue3] = React.useState<any>(null)

    const onChangeHandler = (data: any, propertyName: any) => {
        switch (propertyName) {
            case "search_op1":
                setSearchValue1(data)
                props.onChangeFilterHanlder("fromNumber", "")
                // setSearchFilterValue((prev: any) => {
                //     return {
                //         ...prev,
                //         fromNumber: ""
                //     }
                // })
                break;
            case "number":
                props.onChangeFilterHanlder("toNumber", data.target.value)
                // setSearchFilterValue((prev: any) => {
                //     return {
                //         ...prev,
                //         fromNumber: data.target.value
                //     }
                // })
                break;
            case "startDate":
                props.onChangeFilterHanlder("startDate", new Date(data.target.value).setHours(0, 0, 0, 0))

                // setSearchFilterValue((prev: any) => {
                //     return {
                //         ...prev,
                //         startDate: new Date(data.target.value).setHours(0,0,0,0)
                //     }
                // })
                break;
            case "endDate":
                props.onChangeFilterHanlder("endDate", new Date(data.target.value).setHours(23, 59, 59, 59))

                // setSearchFilterValue((prev: any) => {
                //     return {
                //         ...prev,
                //         endDate: new Date(data.target.value).setHours(23,59, 59, 59)
                //     }
                // })
                break;
            case "search_op2":
                setSearchValue2(data)
                props.onChangeFilterHanlder("smsDirection", data.value)

                // setSearchFilterValue((prev: any) => {
                //     return {
                //         ...prev,
                //         smsDirection: data.value
                //     }
                // })
                break;
            case "search_op3":
                setSearchValue3(data)
                props.onChangeFilterHanlder("messageStatus", data.value)

                // setSearchFilterValue((prev: any) => {
                //     return {
                //         ...prev,
                //         messageStatus: data.value
                //     }
                // })
                break;
        }
    }

    const displayDates = (dateString: any) => {
        const dateNow = new Date(dateString);
        const year = dateNow.getFullYear();
        const monthWithOffset = dateNow.getMonth() + 1;
        const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
        const date = dateNow.getDate().toString().length < 2 ? `0${dateNow.getDate()}` : dateNow.getDate();
        const materialDateInput = `${year}-${month}-${date}`;
        return materialDateInput
    }

    const onClearSearchFilter = () => {
        setSearchValue1(null)
        setSearchValue2(null)
        setSearchValue3(null)
        props.clearFilter()
    }

    const onSearchHandler = () => {
        props.SearchFilterFun(props.searchFilterValue, props.paginate.limit, props.paginate.page)
    }
    var futurdate = new Date()
    var dataaa = Moment(futurdate).format('YYYY-MM-DD')
    return (
        <CardContent>
            <div>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    {/* <Grid item md={3}>
                    <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                            <ReactSelect 
                                placeholder="Select Field" 
                                options={searchOption1}
                                value={searchValue1}
                                onChange={(dt: any) => onChangeHandler(dt,'search_op1') }
                            />
                        </Grid> */}
                    <Grid item xl={3} lg={2} md={4} sm={6} xs={12}>
                        <TextField
                            value={props.searchFilterValue.toNumber}
                            placeholder="Enter Number"
                            onChange={(e: any) => onChangeHandler(e, "number")}
                            variant="outlined"
                            size='small'
                            fullWidth
                        />
                    </Grid>
                    {/* <Grid item md={3}>
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ReactSelect 
                            options={searchoption2}
                            value={searchValue2}
                            placeholder="Outbound" 
                            onChange={(e: any) => onChangeHandler(e, "search_op2")}
                        />
                    </Grid> */}
                    {/* <Grid item md={3}>
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ReactSelect 
                            placeholder="Delivered" 
                            options={searchoption3}
                            value={searchValue3}
                            onChange={(e: any) => onChangeHandler(e, "search_op3")}
                        />
                    </Grid> */}

                    <Grid item xl={3} lg={2} md={4} sm={6} xs={12}>
                        <Tooltip title="Start date">
                            <TextField
                                size="small"
                                variant="outlined"
                                placeholder="dd-mm-yyyy"
                                fullWidth
                                type="date"
                                value={displayDates(props.searchFilterValue.startDate)}
                                onChange={(dt: any) => onChangeHandler(dt, 'startDate')}
                                name="callFromDate"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: dataaa
                                }}
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xl={3} lg={2} md={4} sm={6} xs={12}>
                        <Tooltip title="End date">
                            <TextField
                                size="small"
                                variant="outlined"
                                placeholder="dd-mm-yyyy"
                                fullWidth
                                type="date"
                                value={displayDates(props.searchFilterValue.endDate)}
                                onChange={(dt: any) => onChangeHandler(dt, 'endDate')}
                                name="callFromDate"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: dataaa
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xl={3} lg={3} md={12} sm={6} xs={12}>
                        <div style={{ textAlign: "center", display: 'flex' }}>
                            <Button
                                size="small"
                                variant="outlined"
                                className={classes.search}
                                onClick={onSearchHandler}
                            >
                                Search
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                className={classes.clear}
                                onClick={onClearSearchFilter}
                            >
                                Clear
                            </Button>
                        </div>
                    </Grid>

                </Grid>

            </div>

        </CardContent>
    )
}

export default SearchFilter
