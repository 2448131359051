import { Button, Divider, Grid, TextField, Typography, Tooltip } from '@material-ui/core'
import ReactSelect from 'react-select'
import React from 'react'
import { useStyles } from './styles'

const searchoption: any = [{ label: 'Success', value: 1 }, { label: 'Failed', value: 2 }]

function PaymentTransactons(props: any) {
    const classes = useStyles();
    const [searchValue1, setSearchValue1] = React.useState<any>(null)

    const onChangeHandler = (data: any, propertyName: any) => {
        switch (propertyName) {
            case "search_op1":
                setSearchValue1(data)
                props.onChangeFilterHanlder("paymentStatus", data.value)
                break;
            case "startDate":
                props.onChangeFilterHanlder("startDate", new Date(data.target.value).setHours(0, 0, 0, 0))
                break;
            case "endDate":
                props.onChangeFilterHanlder("endDate", new Date(data.target.value).setHours(23, 59, 59, 59))
                break;
        }
    }

    const displayDates = (dateString: any) => {
        const dateNow = new Date(dateString);
        const year = dateNow.getFullYear();
        const monthWithOffset = dateNow.getMonth() + 1;
        const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
        const date = dateNow.getDate().toString().length < 2 ? `0${dateNow.getDate()}` : dateNow.getDate();
        const materialDateInput = `${year}-${month}-${date}`;
        return materialDateInput
    }

    const onSearchHandler = () => {
        props.SearchFilterFun(props.searchFilterValue, props.paginate.limit, props.paginate.page)
    }

    const clearSearchFilter = () => {
        setSearchValue1(null)
        props.clearFilter()
    }

    return (
        <div>
            <Typography className={classes.header} id="heading2">Payment Transactons</Typography>
            <Divider className={classes.divider} style={{ marginTop: 10 }} />
            <Grid container spacing={3} style={{ marginTop: 10 }}>
                <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                    <ReactSelect
                        options={searchoption}
                        value={searchValue1}
                        onChange={(dt) => onChangeHandler(dt, 'search_op1')}
                        placeholder="Select status" />
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                    <Tooltip title="Start date">
                        <TextField
                            variant="outlined" fullWidth size="small"
                            onChange={(dt: any) => onChangeHandler(dt, 'startDate')}
                            value={displayDates(props.searchFilterValue.startDate)}
                            type="date"
                        />
                    </Tooltip>
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                    <Tooltip title="End date">
                        <TextField
                            variant="outlined" fullWidth size="small"
                            onChange={(dt: any) => onChangeHandler(dt, 'endDate')}
                            value={displayDates(props.searchFilterValue.endDate)}
                            type="date"
                        />
                    </Tooltip>
                </Grid>
                <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                    <div style={{ textAlign: "center", display: "flex" }}>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.search}
                            onClick={onSearchHandler}
                        >
                            Search
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.clear}
                            onClick={clearSearchFilter}
                        >
                            Clear
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default PaymentTransactons
