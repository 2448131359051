import { TextField, Button } from '@material-ui/core';
import React from 'react'
import { useStyles } from './styles'
import { IoMdSend } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { GrAttachment } from 'react-icons/gr'
import { useHistory, useParams } from 'react-router-dom'
import ticketDataServices from '../../../components-services/Tickets/Tickets'
import CommentBody from './CommentBody'
import ModalPopup from '../../../components/Modal/Modal'
import Backdrop from '../../../components/Modal/Backdrop'
import ticketsDataService from '../../../components-services/Tickets/Tickets'
import { useDispatch } from 'react-redux'
import * as globalPopupCreator from '../../../store/Actions/popup-actionCreator'
import CircularProgress from '@material-ui/core/CircularProgress';
function Comments(props: any) {
    const classes = useStyles();
    const params: any = useParams();
    const history: any = useHistory();
    const dispatch = useDispatch()
    const urlState = history.location.state;
    const [ticketData, setTicketsData] = React.useState<any>([]);
    const [ticketId, setTicketId] = React.useState<any>("")
    const [selectedFile, setSelectedFile] = React.useState<any>("")
    const [comment, setComment] = React.useState<any>("")
    const [tokenData, setTokenData] = React.useState<any>(null)
    const [previewImage, setpreviewImage] = React.useState<any>(false)
    const [filePath, setfilePath] = React.useState<any>(null)
    const [subject, setSubject] = React.useState<any>("")
    const [ticketBody, setTicketBody] = React.useState<any>("")
    const audioFileInput: any = React.createRef();
    const userAuthData: any = useSelector((state: any) => state.authState)
    const [submitted, setSubmitted] = React.useState(false);
    const [ticketdatabyId, setTicketDataById] = React.useState({})
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        if (userAuthData.tokenData !== null) {
            setTokenData(userAuthData.tokenData)
        }
    }, [userAuthData.tokenData])


    const fetchTickets = (id: any) => {
        ticketDataServices.fecthTickets(`/tickets/${id}/comments`).then((res: any) => {
            if (res.success) {
                const data: any = res.data.data;
                setTicketsData(data);
            } else {

            }
        }).catch((err: any) => {

        })
    }

    const postTicket = (data: any) => {
        ticketDataServices.addComment(`/tickets/${ticketId}/comments`, data).then((res: any) => {
            if (res.success) {
                fetchTickets(ticketId)
                setComment("")
                setpreviewImage(false)
            } else {

            }
        }).catch((err: any) => {

        })
    }


    const onChangeAudioFile = (e: any) => {
        setLoading(true)
        setpreviewImage(!previewImage)
        const files = e.target.files[0];
        const path = URL.createObjectURL(files)
        setfilePath(path)
        setSelectedFile(files)
        setLoading(false)
    }

    const sendComment = () => {
        setLoading(true)
        let formData = new FormData();
        formData.append('comment', comment);
        if (selectedFile) {
            setSubmitted(true);
            setLoading(false)
            const errorData = validate(true);
            if (errorData.isValid) {
                formData.append('file', selectedFile, selectedFile.name);
                postTicket(formData)
                setSelectedFile("")
            }
        } else {
            postTicket(formData)
            setLoading(false)
        }
    }

    const onChangeComment = (e: any) => {
        setComment(e.target.value)
    }

    React.useEffect(() => {
        if (params.ticketid) {
            fetchTickets(params.ticketid)
            setTicketId(params.ticketid)
        }
    }, [params.ticketid])

    const handlePreviewModal = () => {
        setpreviewImage(!previewImage)
    }
    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            comment: { ...validData },
        };
        let isValid = true;
        if (submitted) {
            if (comment === "") {
                console.log("1111")
                retData.comment = {
                    isValid: false,
                    message: "Please enter comment"
                };
                isValid = false;
            }
            else if (comment.length < 0) {
                retData.comment = {
                    isValid: false,
                    message: "Please enter comment"
                };
                isValid = false;
            }



        }
        return {
            ...retData,
            isValid
        };
    };

    const errors = validate(submitted);
    const confirmEdit = () => {
        ticketsDataService.updateTicket(`/tickets/${ticketId}`, { subject, ticketBody, departmentId: ticketId, ticketStatus: 3 }).then((res: any) => {
            if (res.success) {
                history.push('/tickets')
                dispatch(globalPopupCreator.initglobalPopup("Ticket Closed successfully", "ADDON_DELETED", "success"))
            } else {
                dispatch(globalPopupCreator.initglobalPopup(res.data?.message, "ADDON_DELETED_FAIL", "error"))
            }
        }).catch((err: any) => {
            dispatch(globalPopupCreator.initglobalPopup(err?.message, "ADDON_DELETED_FAIL", "error"))
        })
    }
    React.useEffect(() => {

        ticketsDataService.fecthTickets(`/tickets/my-tickets?id=${ticketId}`).then((res: any) => {
            if (res.success) {
                const data = res.data.data;
                const filterData = data.filter((dt: any) => dt.id === ticketId)
                const myobj = filterData[0]
                setSubject(myobj.subject)
                setTicketBody(myobj.ticketBody)
                setTicketDataById(myobj)


            } else {

            }
        }).catch((err: any) => {

        })

    }, [ticketId])
    return (
        <>
            <div>
                {
                    previewImage && (
                        <>
                            <Backdrop handlePopupModal={handlePreviewModal} />
                            <ModalPopup handlePopupModal={handlePreviewModal} >
                                {
                                    loading ? <CircularProgress /> :
                                        filePath !== null && (
                                            <>
                                                <div className={classes.flexDiv}
                                                    style={{ height: "350px", width: "250px" }}
                                                // style={{ paddingBottom: '10px', width: 190, height: 100, objectFit: "contain" }}
                                                >

                                                    <img src={filePath} style={{ width: '100%', height: '100%', objectFit: "contain" }} />

                                                </div>
                                                <div style={{ marginTop: '10px', width: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ width: '100%', margin: '0 3px' }}>
                                                        {/* <input style={{ width: '100%', padding: '10px' }} /> */}
                                                        <TextField onChange={onChangeComment} value={comment} fullWidth multiline variant="outlined"
                                                            error={!errors.comment.isValid}
                                                        />
                                                        {
                                                            !errors.comment.isValid &&
                                                            <p className={classes.errorMessage}>{errors.comment.message}</p>
                                                        }
                                                    </div>
                                                    <div style={{ margin: '0 3px', display: 'flex', alignItems: 'center' }}>
                                                        {/* <div onClick={uploadFileClick} style={{ cursor: 'pointer', margin: '0 5px', padding: '10px', borderRadius: '50%', background: '#577bff', display: 'grid', placeItems: 'center' }}>
                                                <GrAttachment size={25} />
                                            </div> */}
                                                        <div onClick={sendComment} style={{ cursor: 'pointer', margin: '0 5px', padding: '10px', borderRadius: '50%', background: '#577bff', display: 'grid', placeItems: 'center' }}>
                                                            <IoMdSend size={28} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                }
                            </ModalPopup>
                        </>
                    )
                }
            </div>
            <div style={{ display: 'grid', placeItems: 'center' }}>
                <div className={classes.commentBox}>
                    {
                        ticketData.map((dt: any) => <CommentBody tokenData={tokenData} ticketId={ticketId} rowData={dt} />)
                    }
                    {
                        urlState && (
                            urlState.ticketType === 'open' ? (
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '100%', margin: '0 3px' }}>
                                        {/* <input style={{ width: '100%', padding: '10px' }} /> */}
                                        <TextField onChange={onChangeComment} value={comment} fullWidth multiline variant="outlined" />
                                    </div>
                                    <div style={{ margin: '0 3px', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ cursor: 'pointer', margin: '0 5px', padding: '10px', borderRadius: '50%', background: '#577bff', display: 'grid', placeItems: 'center' }}>
                                            <input type="file"
                                                id="myFile"
                                                style={{ display: "none", cursor: "pointer" }}
                                                onChange={onChangeAudioFile} />
                                            <label
                                                htmlFor="myFile"
                                            >
                                                <GrAttachment size={25} style={{ cursor: "pointer" }} />
                                            </label>
                                        </div>
                                        <div onClick={sendComment} style={{ cursor: 'pointer', margin: '0 5px', padding: '10px', borderRadius: '50%', background: '#577bff', display: 'grid', placeItems: 'center' }}>
                                            <IoMdSend size={28} />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ textAlign: 'center', color: 'red', fontWeight: 600 }}>
                                    <p>Ticket has been closed</p>
                                </div>
                            )
                        )
                    }



                </div>
                <div style={{ display: 'flext', alignItems: 'center', justifyContent: 'space-beetween' }}>
                    <Button variant='outlined'
                        style={{ marginTop: '20px', boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px', backgroundColor: "red", color: "white", fontFamily: "Work Sans" }}
                        onClick={confirmEdit}
                    >Close Ticket
                    </Button>
                    <Button variant='outlined' style={{ marginTop: '20px', marginLeft: "10px", boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px', fontFamily: "Work Sans" }} onClick={() => history.push('/tickets')}>Back</Button>
                </div>
            </div>
        </>
    )
}

export default Comments
