import React from 'react'
import {
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Divider,
    CardContent,
    Grid, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { useStyles } from './styles';
import ReactSelect from 'react-select'
import { MdDelete } from 'react-icons/md'
import profileDataServices from '../../../components-services/MyAccountPage/ProfileData'
import { useDispatch } from 'react-redux'
import * as globalPopUpAlert from '../../../store/Actions/popup-actionCreator'

function AdvancedSettings(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [callerNameOptionsData, setCallerNameOptionsData] = React.useState<any>([])
    const [callerNumberOptionsData, setCallerNumberOptionsData] = React.useState<any>([])
    const [callerNameOptionsDD, setCallerNameOptionsDD] = React.useState<any>([])
    const [callerNumberOptionsDD, setCallerNumberOptionsDD] = React.useState<any>([])
    const [selectedCallerName, setSelectedCalledName] = React.useState<any>(null)
    const [selectedCallerNumber, setSelectedCalledNumber] = React.useState<any>(null)
    const [obRecording, setObRecording] = React.useState(false)
    const [ibRecording, setIbRecording] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const handleChange = () => {
        setOpen(!open);
    };

    const updateSetting = (data: any) => {
        profileDataServices.updateSetting("/accounts/settings", data).then((res: any) => {
            if (res.success) {
                props.fecthMysettings()
                dispatch(globalPopUpAlert.initglobalPopup("Updated settings successfully", "UPDATE_SETTING", "success"))
            } else {
                dispatch(globalPopUpAlert.initglobalPopup(res.message, "UPDATE_SETTING", "error"))
            }
        }).catch((err: any) => {
            dispatch(globalPopUpAlert.initglobalPopup(err.message, "UPDATE_SETTING", "error"))
        })
    }

    const onChangeCallerIdNumber = (data: any) => {
        const filterData: any = callerNumberOptionsData.find((num: any) => num.id === data.value)
        if (filterData) {
            setSelectedCalledNumber({ label: filterData.value, value: filterData.id })
            // updateSetting({ callerIdNumberOption: Number(filterData.id), callerIdNumber: filterData.value  })
            updateSetting({ callerIdNumberOption: Number(filterData.id) })

        }
    }
    const onChangeCallerIdName = (data: any) => {
        const filterData: any = callerNameOptionsData.find((num: any) => num.id === data.value)
        if (filterData) {
            setSelectedCalledName({ label: filterData.value, value: filterData.id })
            // updateSetting({ callerIdNameOption: Number(filterData.id), callerIdName: filterData.value })
            updateSetting({ callerIdNameOption: Number(filterData.id) })

        }
    }
    const handleChangeRecording = (e: any) => {
        updateSetting({ [e.target.name]: e.target.checked })
        if (e.target.name === "obCallRecording") {
            setObRecording(e.target.checked)
        }
        else if (e.target.name === "ibCallRecording") {
            setIbRecording(e.target.checked)
        }
        // setObRecording(obCallRecording)
        // setIbRecording(ibCallRecording)
    }

    React.useEffect(() => {
        if (props.settings) {
            const {
                callerNameOptions,
                callerNumberOptions,
                callerIdNameOption,
                callerIdNumberOption,
                obCallRecording,
                ibCallRecording
            } = props.settings

            if (callerNameOptions && callerNameOptions.length > 0) {
                setCallerNameOptionsData(callerNameOptions)
                const selectName: any = callerNameOptions.find((cno: any) => cno.id == callerIdNameOption)
                if (selectName) {
                    setSelectedCalledName({ label: selectName.value, value: selectName.id })
                }
                setCallerNameOptionsDD(callerNameOptions.map((cnn: any) => { return { label: cnn.value, value: cnn.id } }))

            }
            if (callerNumberOptions && callerNumberOptions.length > 0) {
                setCallerNumberOptionsData(callerNumberOptions)
                const selectNumber: any = callerNameOptions.find((cno: any) => cno.id == callerIdNumberOption)
                if (selectNumber) {
                    setSelectedCalledNumber({ label: selectNumber.value, value: selectNumber.id })
                }
                setCallerNumberOptionsDD(callerNumberOptions.map((cn: any) => { return { label: cn.value, value: cn.id } }))
            }
            setObRecording(obCallRecording)
            setIbRecording(ibCallRecording)

        }
    }, [props.settings])

    return (
        <div id="AdvancedSettings">
            <Accordion onChange={handleChange} style={{ marginTop: 25, borderRadius: 10, background: '#FFFFFF', boxShadow: '0 0 0.5em #E2E2E2', }}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Advanced settings</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent >
                        <Typography className={classes.ring}>Inbound Call Settings</Typography>
                        <Divider className={classes.divider} style={{ marginTop: 10 }} />
                        <div className={classes.vector}>
                            <Checkbox
                                color={"primary"}
                                checked={ibRecording}
                                onChange={handleChangeRecording}
                                name="ibCallRecording"
                            />
                            <Typography className={classes.text}>
                                Call Recording
                            </Typography>
                        </div>
                    </CardContent>
                    <CardContent >
                        <Typography className={classes.ring}>Outbound Call Settings</Typography>
                        <Divider className={classes.divider} style={{ marginTop: 10 }} />
                        <div className={classes.vector}>
                            <Checkbox
                                color={"primary"}
                                checked={obRecording}
                                name="obCallRecording"
                                onChange={handleChangeRecording}
                            />
                            <Typography className={classes.text}>
                                Call Recording
                            </Typography>
                        </div>
                        <Grid container spacing={4} style={{ padding: 14 }}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.callId}> Caller Id Number : </Typography>
                                <ReactSelect
                                    placeholder="Caller's Number"
                                    options={callerNumberOptionsDD}
                                    value={selectedCallerNumber}
                                    onChange={onChangeCallerIdNumber}
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography className={classes.callId}>Caller Id Name : </Typography>
                                <ReactSelect
                                    value={selectedCallerName}
                                    placeholder="Caller's Name"
                                    options={callerNameOptionsDD}
                                    onChange={onChangeCallerIdName}
                                />
                            </Grid>
                        </Grid>

                    </CardContent>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default AdvancedSettings



