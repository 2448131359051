import React from 'react'
import { Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import './sidebarelement.css'
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "13px",
                color: "white",
                fontFamily: "Work Sans",
                fontWeight: 400,
                padding: '6px 16px',
                borderRadius: "0px 10px 0px 0px",
                backgroundColor: "#2645ad",
                top: 6,
                position: 'relative',
                left: -10,
                lineHeight: 'auto',
                maxWidth: 'auto'
            }
        }
    }
});


function SidebarElement(props: any) {
    const { title, Icon, IconSize, path } = props.sidebar;
    const [name, setName] = React.useState('')
    const history = useHistory()
    const { location } = useHistory();

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <Tooltip title={title} placement="right-start" className="tooltip" style={{ top: 10 }}>
                <div className={path === location.pathname ? "sidebar_element_section_active" : "sidebar_element_section"}
                            onClick={() => history.push(path)}>
                    <Icon size={IconSize} color="#4D72F8" />
                </div>
                </Tooltip>
            </MuiThemeProvider>
        </>
    )
}

export default SidebarElement
