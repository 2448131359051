import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    card: {
        marginTop: 25,
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
        //overflow: 'unset',
    },
    heading: {
        color: "#2645AD",
        fontSize: 20,
        fontWeight: 600,
        fontFamily: 'work sans',
        "@media (max-width: 426px)": {
            fontSize: 17
        },
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
    },
    PortText: {
        fontSize: 16,
        //marginRight: 20,
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 600,
        color: "#464646",
        letterSpacing: 0.1,
        textTransform: "none",
        // marginTop: 5,
        //marginLeft: 15,
    },
    divider: {
        backgroundColor: '#E2E2E2',
    },
    textFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    numCard: {
        borderRadius: '5px',
        marginTop: 10,

    },
    accordianNoFlex: {
        display: 'block'
    },
    paddingGrid: {
        paddingTop: 0,
    },
    textName: {
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#707070',
        fontFamily: 'Work Sans'
    },
    vector: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    portStatus: {
        textTransform: 'capitalize',
        fontSize: 16,
        marginLeft: 40,
        //height: 25,
        //padding: '4px 20px',
        textAlign: 'center',
        fontWeight: 500,
        fontFamily: 'work sans'
    },
    modalFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalFlex2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width:426px)': {
            display: 'block'
        }
    },
    editIcon: {
        fontSize: 18,
        marginLeft: 8,
        color: '#2645AD',
        cursor: 'pointer'
    },
    textField: {
        // marginLeft: 30,
        margin: '7px 0',
        width: '100%'
    },
    iconWidth: {
        margin: 10,
        height: '20px',
        width: '20px',
        cursor: 'pointer'
    },
    newIcon: {
        cursor: 'pointer',
        margin: 10,
        height: '20px',
        width: '20px',
        color: '#C4C4C4'
    },
    iconWidthOne: {
        margin: 10,
        height: '20px',
        width: '20px',
        cursor: 'pointer',
        textAlign: 'center'
    },
    primary: {
        marginTop: '-14px',
        marginLeft: -5,
        fontSize: '10px',
        color: '#464646',
        fontFamily: 'work sans',
        fontWeight: 500,
    },
    textWidth: {
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#8392AB',
        fontFamily: 'work sans',
        marginLeft: 8,
        //marginBottom: 30,
    },
    tempNumber: {
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#4D72F8',
        fontFamily: 'work sans',
        marginLeft: 8,
        //marginBottom: 30,
    },
    tempNumberActive: {
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        color: 'gray',
        fontFamily: 'work sans',
        marginLeft: 8,
        //marginBottom: 30,
    },
    efoneNumber: {
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        color: 'gray',
        fontFamily: 'work sans',
        marginLeft: 8,
    },
    textWidthOne: {
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#8392AB',
        fontFamily: 'work sans',
        //margin: 10,
        marginBottom: 30,
        //marginLeft: -6
    },
    text: {
        fontSize: 16,
        fontFamily: 'work sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#464646',
        letterSpacing: 0.208955,
        textTransform: 'none',
        margin: '10',
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    modaldeleteButton: {
        margin: 10,
        padding: '6px 22px',
        background: '#2645AD',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 3,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: 14,
        '&:hover': {
            background: '#2645AD',
            color: '#FFFFFF',
        }
    },
    deltecancelButton: {
        margin: 10,
        padding: '6px 22px',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginLeft: 15,
        background: '#EFEFEF',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#787878',
        fontSize: 14,
    },
    deleteIcon: {
        color: '#C9C9C9',
        fontSize: 18,
        cursor: 'pointer',
        marginLeft: '10px'
    }
}))