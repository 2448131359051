import React from 'react'
import { Card,
 Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  CardContent,
  Grid, Tabs, Tab, Button} from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import { useStyles } from '../styles';
import OpenTicketTable from './OpenTicketTable/OpenTicketTable';
import ClosedTicketTable from './ClosedTicketTable/ClosedTicketTable';
import Backdrop from '../../../components/Modal/Backdrop'
import Modal from '../../../components/Modal/Modal'
import AddTicket from './AddTicket';
import ticketsDataServices from '../../../components-services/Tickets/Tickets'  

function TicketsInformations() {
    const classes = useStyles();
    const [value, setValue] = React.useState("1");
    const [open, setOpen] = React.useState(true);
    const [loadingTickets, setLoadingTickets] = React.useState<any>(false)
    const [openTickets, setOpenTickets] = React.useState<any>([])
    const [closedTickets, setClosedTickets] = React.useState<any>([])

    const [addTicketModal, setAddTicketModal] = React.useState(false)
    const handleChange = () => {
        setOpen(!open);
    };
    const handleTabs = (e: any, newValue: any) => {
        setValue(newValue);
    };

    const onChangeAddTicketModal = () => {
        setAddTicketModal(!addTicketModal);
    }

    const fetchTickets = () => {
        setLoadingTickets(true)
        ticketsDataServices.fecthTickets("/tickets/my-tickets").then((res: any) => {
            if(res.success){
                const data = res.data.data;
                if(data.length > 0){
                    const filterOpenTickets = data.filter((dt: any) => dt.ticketStatus === 1)
                    const filterCloseTickets = data.filter((dt: any) => dt.ticketStatus === 3)
                    setOpenTickets(filterOpenTickets)
                    setClosedTickets(filterCloseTickets)
                }
                setLoadingTickets(false)
            }else{
                setLoadingTickets(false)
            }
        }).catch((err: any) => {
            setLoadingTickets(false)
        })
    }

    React.useEffect(() => {
        fetchTickets()
    }, [])

    return (
        <div>
            {
                addTicketModal && (
                    <>
                        <Backdrop handlePopupModal={onChangeAddTicketModal} />
                        <Modal handlePopupModal={onChangeAddTicketModal} title="Add Ticket">
                            <div style={{width: 500 }} >
                                <AddTicket fetchTickets={fetchTickets} onChangeAddTicketModal={onChangeAddTicketModal} />
                            </div>
                        </Modal>
                    </>
                )
            }
            <Accordion expanded={open}  onChange={handleChange} style={{marginTop: 25, borderRadius: 10,background: '#FFFFFF',boxShadow: '0 0 0.5em #E2E2E2',}}>
                 <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Tickets Informations</Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                <AccordionDetails className={classes.accordianNoFlex}>
                    <CardContent>
                         <TabContext value={value} >
                                <Grid container spacing={5}>
                                   <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                    
                                    <Card className={classes.tabCard} style={{width: '40%'}}>
                                        <Tabs
                                            className={classes.tabs}
                                            value={value}
                                            onChange={handleTabs}
                                            variant="fullWidth"
                                            TabIndicatorProps={{
                                                style: {
                                                    height: "0px",
                                                },
                                            }}
                                            >
                                            <Tab
                                                label="Tickets"
                                                value="1"
                                                className={value === "1" ? classes.tab : classes.tabActive}
                                            />
                                            {/* <Tab
                                                label="Suggestions"
                                                value="2"
                                                className={value === "2" ? classes.tab : classes.tabActive}
                                            /> */}
                                        </Tabs>
                                      </Card>
                                      
                                    </Grid>
                                    </Grid>
                                    <TabPanel value="1">
                                        <div className={classes.dflex}>
                                            <Typography className={classes.subhead} id="heading2">Open Ticket</Typography>
                                            <Button className={classes.ticketButton} size="medium"
                                                onClick={onChangeAddTicketModal}
                                            >Add New Ticket</Button> 
                                        </div>
                                        <Divider className={classes.divider} style={{marginTop:10}}/>
                                        <div>
                                            <OpenTicketTable fetchTickets={fetchTickets} loadingTickets={loadingTickets} openTickets={openTickets} />
                                        </div>

                                        <div>
                                            <Typography className={classes.subheading} id="heading2">Closed Ticket</Typography>
                                            <Divider className={classes.divider} style={{marginTop:10}}/>
                                            <ClosedTicketTable loadingTickets={loadingTickets} closedTickets={closedTickets} />
                                        </div>
                                    </TabPanel>

                                    {/* <TabPanel value='2'>
                                        <div className={classes.dflex}>
                                            <Typography className={classes.subhead} id="heading2">My Suggestions</Typography>
                                            <Button className={classes.ticketButton} size="medium">Add New Suggestions</Button> 
                                        </div>
                                        <Divider className={classes.divider} style={{marginTop:20}}/>
                                        <div>
                                            <MySuggestionsTable />
                                        </div>
                                        <div style={{marginTop: 20}}>
                                            <Typography className={classes.subhead} id="heading2">Other Suggestions</Typography>
                                            <Divider className={classes.divider} style={{marginTop:10}}/>
                                        </div>
                                        <div>
                                            <OtherSuggestions />
                                        </div>
                                    </TabPanel> */}
                                </TabContext>
                    </CardContent>
                </AccordionDetails>
            </Accordion>
            
        </div>
    )
}

export default TicketsInformations
