import React from 'react'
import './Childelement.css'
import { Link, animateScroll as scroll } from 'react-scroll'
import { useDispatch, useSelector } from "react-redux";

function Childelement(props: any) {
    const { Icon, IconSize, title, color, id } = props.childRoute
    const [closed, setClosed] = React.useState(false)

    const scrollToTop = () => {
        scroll.scrollToTop(100);
    };

   

    return (
        <div className="child_element_component" >
            <div className="child_element_icon">
                <Icon color={color} size={IconSize} onClick={scrollToTop} />
            </div>
            <div className="child_element_title">
                <Link
                    activeClass="active"
                    to={id}
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={1000}
                    onClick={scrollToTop}
                >
                    {title}
                </Link>
            </div>
        </div>
    )
}

export default Childelement
