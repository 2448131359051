import {
    Typography,
    Button,
    TextField,
    Checkbox,
    Grid
} from '@material-ui/core'
import VectorButton from "../../../assets/icons/VectorButton.png";
import { AiFillEdit } from 'react-icons/ai'
import React from 'react'
import { useStyles } from "./styles";
import { MdDelete } from "react-icons/md"
import profileDataServices from '../../../components-services/MyAccountPage/ProfileData'
import Modal from '../../../components/Modal/Modal'
import Backdrop from '../../../components/Modal/Backdrop'
import * as globalPopupCreator from '../../../store/Actions/popup-actionCreator'
import { useDispatch } from 'react-redux'
import { GiSevenPointedStar } from 'react-icons/gi'

function NumberBody(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [cellData, setCellData] = React.useState<any>(null);
    const [editModal, setEditModal] = React.useState<any>(false);
    const [deleteModal, setDeleteModal] = React.useState<any>(false);
    const [changedNumber, setChangedNumber] = React.useState<any>("")
    const [oldNumber, setOldNumber] = React.useState<any>("")
    const [validateEditNumber, setValidateSetNumber] = React.useState<any>(false);
    const [makePrimaryModal, setMakePrimaryModal] = React.useState(false)
    const [otp, setOtp] = React.useState<any>("")
    const [isPrimary, setIsPrimary] = React.useState<any>(false)
    const [dataChnaged, setDataChanged] = React.useState<any>(false)

    React.useEffect(() => {
        if (props?.cellNumberData) {
            setCellData(props?.cellNumberData)
        }
    }, [props?.cellNumberData])

    React.useEffect(() => {
        if (props.primary) {
            setChangedNumber(cellData?.phoneNumber ? cellData?.phoneNumber : "")

        }
    }, [cellData])

    const onChangeOtp = (e: any) => {
        setOtp(e.target.value)
    }

    // const onChangeNumber = (e: any) => {
    //     setDataChanged(true)
    //     setChangedNumber(e.target.value)
    // }

    const onChangeNumber = (e: any) => {

        const val = e.target.value
        if (val !== " ") {
            setChangedNumber(val.replace(/[^0-9,.]+/g, ""))
            setDataChanged(true)
        }

    }

    const onChangeIsPrimary = () => {
        setIsPrimary(!isPrimary)
    }

    const HandlemakePrimaryModal = () => {
        setMakePrimaryModal(!makePrimaryModal)
    }

    const onChangeEditModal = () => {
        setEditModal(!editModal)
        setValidateSetNumber(false)
    }

    const onChangeDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const editNumberDatapost = () => {
        if (cellData.id !== undefined || cellData.id !== null) {
            profileDataServices.updateMyNumber(`/accounts/mynumbers/${cellData.id}`, { phoneNumber: changedNumber.replace(/[^0-9,.]+/g, "") }).then((res: any) => {
                if (res.success) {
                    setDataChanged(false)
                    setOldNumber(changedNumber)
                    setValidateSetNumber(true)
                    dispatch(globalPopupCreator.initglobalPopup('OTP sent', "ADDON_DELETED", "success"))
                } else {
                    // props.getMyNumbers()
                    dispatch(globalPopupCreator.initglobalPopup(res.message, "ADDON_DELETED", "error"))
                }
            }).catch((err: any) => {
                dispatch(globalPopupCreator.initglobalPopup(err.message, "ADDON_DELETED", "error"))
            })
        }
        else if (isNaN(cellData.phoneNumber)) {
            dispatch(globalPopupCreator.initglobalPopup('characters not allowed', "ADDON_DELETED", "error"))
            return
        }
    }

    const deleteMyNumberData = () => {
        if (cellData.id !== undefined || cellData.id !== null) {
            profileDataServices.deleteMynumber(`/accounts/mynumbers/${cellData.id}`).then((res: any) => {
                if (res.success) {
                    setDeleteModal(!deleteModal)
                    props.getMyNumbers()
                    props.fecthMysettings()
                    dispatch(globalPopupCreator.initglobalPopup("Delete Number successfully", "ADDON_DELETED", "success"))
                } else {
                    dispatch(globalPopupCreator.initglobalPopup(res.message, "ADDON_DELETED", "success"))
                }
            }).catch((err: any) => {

            })
        }
    }

    const validateEditOtp = () => {
        if (otp !== "") {
            profileDataServices.verifyMyNumbersOtp(`/accounts/mynumbers/verify-otp`, { phoneNumber: changedNumber, otp: otp, id: cellData.id }).then((res: any) => {
                if (res.success) {
                    setEditModal(!editModal)
                    props.getMyNumbers()
                    setOtp("")
                    setValidateSetNumber(false)
                    props.fecthMysettings()
                    dispatch(globalPopupCreator.initglobalPopup("Updated Number successfully", "ADDON_DELETED", "success"))
                } else {
                    props.getMyNumbers()
                    dispatch(globalPopupCreator.initglobalPopup(res.message, "ADDON_DELETED", "error"))
                }
            }).catch((err: any) => {
                dispatch(globalPopupCreator.initglobalPopup(err.message, "ADDON_DELETED", "error"))
            })
        } else {
            dispatch(globalPopupCreator.initglobalPopup("Please provide OTP", "ADDON_DELETED", "error"))
        }
    }

    const makeAsPrimaryNumber = () => {
        if (props.cellNumberData) {
            profileDataServices.updateMynumberPrimary(`/accounts/mynumbers/${cellData?.id}/primary`, { isPrimary: true }).then((res: any) => {
                if (res.success) {
                    // setEditModal(!editModal)
                    HandlemakePrimaryModal()
                    props.getMyNumbers()
                    dispatch(globalPopupCreator.initglobalPopup("Updated Number as Primary", "ADDON_DELETED", "success"))
                } else {
                    props.getMyNumbers()
                }
            }).catch((err: any) => {

            })
        }
    }

    const gobackToNumber = () => {
        //setDataChanged(false)
        setValidateSetNumber(false)
        setOtp("")
    }

    return (
        <div className={classes.vector} >
            {
                makePrimaryModal && (
                    <>
                        <Backdrop handlePopupModal={HandlemakePrimaryModal} />
                        <Modal handlePopupModal={HandlemakePrimaryModal} title="Are you sure want to make this number primary?">
                            <div style={{ textAlign: 'center', width: 500 }} >
                                <Grid container spacing={2}>
                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.modaldeleteButton} onClick={makeAsPrimaryNumber}>Yes</Button>
                                            <Button size="medium" className={classes.deltecancelButton} onClick={HandlemakePrimaryModal}>
                                                No
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </>
                )
            }
            {
                deleteModal && (
                    <>
                        <Backdrop handlePopupModal={onChangeDeleteModal} />
                        <Modal handlePopupModal={onChangeDeleteModal} title="Are you sure want to Delete ?">
                            <div style={{ textAlign: 'center', width: 500 }} >
                                <Grid container spacing={2}>
                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.modaldeleteButton} onClick={deleteMyNumberData}>Delete</Button>
                                            <Button size="medium" className={classes.deltecancelButton} onClick={onChangeDeleteModal}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </>
                )
            }
            {
                editModal && (
                    <>
                        <Backdrop handlePopupModal={onChangeEditModal} />
                        <Modal handlePopupModal={onChangeEditModal} title="Update Number">
                            {
                                validateEditNumber ? (
                                    <div>
                                        <div>
                                            <TextField onChange={onChangeOtp} name="otp" value={otp} variant="outlined" label="Enter OTP" size="small" placeholder="Enter OTP" className={classes.textField} />
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <Button onClick={validateEditOtp} style={{ background: '#2645AD', marginRight: '10px', color: '#FFFFFF', fontSize: 12, padding: '7px 15px' }}>Validate OTP</Button>
                                            <Button onClick={gobackToNumber} style={{ background: '#ccc', color: '#585858', fontSize: 12, padding: '7px 15px' }}>Back</Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={classes.modalFlex2}>
                                        <div style={{ textAlign: 'center' }}>
                                            <TextField onChange={onChangeNumber} name="number" value={changedNumber} variant="outlined" size="small" placeholder="Enter Number"
                                                className={classes.textField} />
                                            {/* <div style={{display: 'grid', placeItems: 'center', marginTop: '10px'}}>
                                                    <Button onClick={editNumberDatapost} 
                                                        style={{ background: '#2645AD', color: '#FFFFFF', 
                                                        fontSize: 12, padding: '8px 20px' }}>
                                                        Edit Number
                                                    </Button>
                                                </div> */}

                                            {dataChnaged && oldNumber !== changedNumber && (
                                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                                    <Button onClick={editNumberDatapost} size='small'
                                                        style={{
                                                            background: '#2645AD', color: '#FFFFFF',
                                                            fontSize: 12, padding: '6px 20px'
                                                        }}>
                                                        Edit Number
                                                    </Button>
                                                    <Button size='small' variant='outlined' style={{
                                                        fontSize: 12, padding: '6px 10px', marginLeft: 5
                                                    }}
                                                        onClick={() => {
                                                            setDataChanged(false)
                                                            setChangedNumber(cellData?.phoneNumber ? cellData?.phoneNumber : "")
                                                            setOldNumber(cellData?.phoneNumber ? cellData?.phoneNumber : "")
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            }


                        </Modal>
                    </>
                )
            }
            <div>
                {
                    props.primary === false ? (
                        <div>
                            {/* <img
                                className={classes.iconWidth}
                                style={{cursor: 'pointer'}}
                                onClick={HandlemakePrimaryModal}
                                alt="vector"
                                src={VectorButton}
                            /> */}
                            <GiSevenPointedStar className={classes.newIcon} onClick={HandlemakePrimaryModal} />

                        </div>
                    ) : (
                        <div>
                            {/* <img
                                    className={classes.iconWidth}
                                    alt="vector"
                                    src={VectorButton}
                                /> */}
                            <GiSevenPointedStar className={classes.iconWidthOne} color='#4D72F8' />
                            <Typography className={classes.primary}>(Primary)</Typography>

                        </div>


                    )
                }

            </div>
            <Typography className={classes.textWidth}>{cellData ? cellData?.phoneNumber : ""}</Typography>
            {
                props.primary && <AiFillEdit className={classes.editIcon} onClick={onChangeEditModal} />
            }

            {
                props.primary === false && (
                    <MdDelete className={classes.deleteIcon} onClick={onChangeDeleteModal} />
                )
            }
        </div>
    )
}

export default NumberBody
