import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary,Button, Card, CardContent, Divider, Grid, Radio, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useStyles } from './styles'
import Backdrop from "../../../../components/Modal/Backdrop"
import Modal from "../../../../components/Modal/Modal"
import billingDataserives from '../../../../components-services/Billing/Billing'
import { Dataprovider } from '../../Index'
import { useDispatch }  from 'react-redux'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

function TableBody(props: any) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const context: any = React.useContext(Dataprovider)
    const [openSubscribe, setOpensubscribe] = React.useState<any>(false)
    const [open, setOpen] = React.useState(false)

     const handleChange = () => {
        setOpen(!open);
    };

    
    const [subscriptionType, setSubscrionType] = React.useState<any>(1)

    const { addonName, addonCost, id, addonAnnualCost, annualPricePerMonth } = props.rowData;

    const onChangeSubscribeAddon = () => {
        setOpensubscribe(!openSubscribe)
    }

    const onChangeSubsScriptionAddon = (value: any) => {
        setSubscrionType(value)
    }

    //subscriptionType = should be 1 if monthly 2 if yearly
    const subcribeAddon = () => {
        billingDataserives.subscribeAddon("/accounts/subscribe-addon", { id: id, subscriptionType: subscriptionType }).then((res: any) => {
            if(res.success){
                context.fetchSubscriptionAddons()
                context.fetchRecommendedAddons()
                onChangeSubscribeAddon()
                setSubscrionType(1)
                dispatch(globalPopupCreator.initglobalPopup("Subscription Added successfully", "SUBSCRIPTION_ADDED", "success"))
            }else{
                dispatch(globalPopupCreator.initglobalPopup(res.data?.message, "SUBSCRIPTION_ADDED_ERROR", "error"))
            }
        }).catch((err: any) => {
            dispatch(globalPopupCreator.initglobalPopup(err?.message, "SUBSCRIPTION_ADDED_ERROR", "error"))
        })
    }

    

    return (
        <>
        {
                openSubscribe && (
                    <>
                        <Backdrop handlePopupModal={onChangeSubscribeAddon} />
                        <Modal title="Subscribe Addon" handlePopupModal={onChangeSubscribeAddon} >
                            <div style={{width: 250}}>
                                <div className={classes.subscribeHeading}>Are you sure want to subscribe?</div>
                                {/* <Accordion expanded={open} onChange={handleChange} >
                                    <AccordionSummary
                                     expandIcon={open ? <FiMinusCircle color="#2645ad" className={classes.collapse} /> : <FiPlusCircle color="#2645ad" className={classes.collapse} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.mainHead}>World Pack</Typography>
                                    </AccordionSummary>
                                    <Divider className={classes.dividernew} />
                                    <AccordionDetails className={classes.accordianNoFlex}>
                                        <div>
                                            <Typography style={{fontSize: 12}}>Make 100 Calls</Typography>
                                            <Typography style={{fontSize: 12}}> 100 Minutes call to pakistan</Typography>
                                            <Typography style={{fontSize: 12}}> All pakistan mobiles and landlines</Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion> */}
                                
                                <p style={{}} className={classes.addonName}>{addonName}  ${subscriptionType === 1 ? annualPricePerMonth : addonCost}</p>
                                 <div className={classes.subscribeRadioButtons}>
                                    <div className={classes.subscribeRadioButton}>
                                        <Radio  
                                            checked={subscriptionType === 1}
                                            onClick={() => onChangeSubsScriptionAddon(1)}
                                            color="primary" className="subscribe-radio" 
                                        /> Month
                                    </div>
                                    <div className={classes.subscribeRadioButton}>
                                        <Radio  
                                            checked={subscriptionType === 2}
                                            onClick={() => onChangeSubsScriptionAddon(2)}
                                            color="primary" className="subscribe-radio" style={{fontSize: 30}} />
                                        Year
                                    </div>
                                    <div className={classes.subscribePopupButtons}>
                                        <Button className={classes.yesButton} onClick={subcribeAddon}>
                                            Yes
                                        </Button>
                                        <Button className={classes.noButton} onClick={onChangeSubscribeAddon}>
                                          No
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </>
                )
        }
        <TableRow>
            <TableCell className={classes.tableBody}>{addonName}</TableCell>
            <TableCell className={classes.tableBody}>${subscriptionType === 1 ? annualPricePerMonth : addonCost}</TableCell>
            <TableCell className={classes.tableBody}>
                <Button onClick={onChangeSubscribeAddon} className={classes.subscribe}>subscribe</Button>
            </TableCell>
        </TableRow>
        </>
    )
}

export default TableBody
