import React from 'react'
import {
    Grid, TableRow, TableCell, Button, IconButton
} from '@material-ui/core'
import { useStyles } from '../../styles';
import { RiChatOffFill } from 'react-icons/ri'
import { BsFillChatFill } from 'react-icons/bs'
import Modal from '../../../../components/Modal/Modal'
import Backdrop from '../../../../components/Modal/Backdrop'
import ticketsDataService from '../../../../components-services/Tickets/Tickets'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as globalPopupCreator from '../../../../store/Actions/popup-actionCreator'
import Moment from 'moment'
function TableBody(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()
    const [editModal, setEditModal] = React.useState<any>(false)

    const { id, createdDate, subject, ticketStatus, ticketBody, departmentId } = props.rowData;

    const onChangeEditModal = () => {
        setEditModal(!editModal)
    }

    const confirmEdit = () => {
        ticketsDataService.updateTicket(`/tickets/${id}`, { subject, ticketBody, departmentId: id, ticketStatus: 3 }).then((res: any) => {
            if (res.success) {
                props.fetchTickets()
                dispatch(globalPopupCreator.initglobalPopup("Ticket Closed successfully", "ADDON_DELETED", "success"))
            } else {
                dispatch(globalPopupCreator.initglobalPopup(res.data?.message, "ADDON_DELETED_FAIL", "error"))
            }
        }).catch((err: any) => {
            dispatch(globalPopupCreator.initglobalPopup(err?.message, "ADDON_DELETED_FAIL", "error"))
        })
    }

    return (
        <>
            {
                editModal && (
                    <>
                        <Backdrop handlePopupModal={onChangeEditModal} />
                        <Modal title="Are you sure you want to close the ticket ?" handlePopupModal={onChangeEditModal} >
                            <div style={{ display: "flex", width: 500 }}>
                                {/* <Grid container spacing={2}>
                               
                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.modaldeleteButton}  onClick={blockNumberDelete}>yes</Button>
                                            <Button size="medium" className={classes.deltecancelButton} onClick={onChangeDeleteModal}>
                                                No
                                        </Button>
                                        </div>
                                    </Grid>
                                </Grid> */}
                                <Grid container spacing={2}>
                                    <Grid item md={12} style={{ textAlign: 'center', padding: 20 }}>
                                        <Button variant='outlined' onClick={confirmEdit} className={classes.yesButton}>Yes</Button>
                                        <Button className={classes.noButton} variant="outlined" onClick={onChangeEditModal}>No</Button>
                                    </Grid>
                                </Grid>

                            </div>
                        </Modal>
                    </>
                )
            }
            <TableRow>
                <TableCell className={classes.tableData}>{subject ? subject : ""}</TableCell>
                {/* <TableCell className={classes.tableData}>{id ? id : ""}</TableCell> */}
                <TableCell className={classes.tableData}>{createdDate ? <div>{Moment(Number(createdDate)).format('YYYY-MM-DD HH:MM:SS')}</div> : ""}</TableCell>
                <TableCell className={classes.tableData}>
                    {ticketStatus === 1 &&
                        <>
                            <p style={{ color: 'red' }}>Pending</p>
                        </>
                    }
                    {ticketStatus === 2 &&
                        <>
                            <p style={{ color: 'rgb(38, 69, 173)' }}>Review</p>
                        </>
                    }
                    {ticketStatus === 3 &&
                        <>
                            <p style={{ color: 'green' }}>Closed</p>
                        </>
                    }
                </TableCell>

                <TableCell className={classes.tableData}>{departmentId ? departmentId?.departmentName : ""}</TableCell>
                <TableCell className={classes.tableData}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <IconButton onClick={() => history.push({ pathname: `comments/${id}`, state: { ticketType: 'open' } })}
                                style={{ width: 28, height: 28, borderRadius: 4, padding: 7, background: '#2645AD' }}
                            >
                                <BsFillChatFill style={{ color: '#FFFFFF' }} />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton onClick={onChangeEditModal}
                                style={{ width: 28, height: 28, borderRadius: 4, padding: 7, background: 'red', marginLeft: 5 }}
                            >
                                <RiChatOffFill color='#FFFFFF' />
                                {/* <MdOutlineCancelPresentation /> */}
                            </IconButton>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default TableBody
