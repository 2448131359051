import React from 'react'
import {useDispatch} from 'react-redux';
import Profile from './Profile/Profile'
import MyNumbers from './MyNumbers/MyNumbers'
import profileServices from '../../components-services/MyAccountPage/ProfileData';
import CallHandling from './CallHandling/CallHandling'
import AdvancedSettings from './AdvancedSettings/AdvancedSettings'
import SMSSettings from './SMSSettings/SMSSettings';
import * as authenticationCreator from '../../store/Actions/Authentication-creator'


function Index(props: any) {
    const [settingsLoading, setSettingsLoading] = React.useState<any>(false)

    const [mySettings, setMySettings] = React.useState<any>(null);

    const dispatch = useDispatch();
    
    const fecthMysettings = () => {
        setSettingsLoading(true)
        profileServices.fecthMysettings("/accounts/syncsettings").then((res: any) => {
            if(res.success){
                setMySettings(res.data.data)
                setSettingsLoading(false)
            }else{
                setSettingsLoading(false)
            }
        }).catch((err: any) => {
            //err
            setSettingsLoading(false)
        })
    }

    React.useEffect(() => {
        fecthMysettings()
        dispatch(authenticationCreator.authProfileData())
    }, [])

    React.useEffect(() => {
        window.scrollTo(0, 0)
    },[])


    return (
        <div>
            {/* <profile comoponenet */}
            <Profile settingsLoading={settingsLoading} fecthMysettings={fecthMysettings} settings={mySettings} />
            {/* <My Numbers*/}
            <MyNumbers settingsLoading={settingsLoading} fecthMysettings={fecthMysettings} settings={mySettings} />
            
            <CallHandling settingsLoading={settingsLoading} fecthMysettings={fecthMysettings} settings={mySettings} />

            <AdvancedSettings settingsLoading={settingsLoading} fecthMysettings={fecthMysettings} settings={mySettings} />

            <SMSSettings settingsLoading={settingsLoading} fecthMysettings={fecthMysettings} settings={mySettings} />
        </div>
    )
}

export default Index
