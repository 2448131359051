import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { useStyles } from '../styles'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import TableBodyData from './TableBody'

function Minutestable(props: any) {
    const [data, setData] = React.useState<any>([])
    React.useEffect(() => {
        setData(props.minutesData)
    }, [props])
    const classes = useStyles();
    
    return (
        <div>
            <Table>
                <TableHead style={{background: '#F3F6F9'}}>
                    <TableRow>
                        <TableCell className={classes.tableHead}>Route & Country Name</TableCell>
                        <TableCell className={classes.tableHead}>Addon Name</TableCell>
                        <TableCell className={classes.tableHead}>Free Minutes</TableCell>
                        <TableCell className={classes.tableHead}>Remaining Minutes</TableCell>
                        <TableCell className={classes.tableHead}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.length > 0 && data.map((dts: any, i: any) => <TableBodyData rowData={dts} key={i}/>) 
                    }    
                </TableBody>
            </Table>
            {
                data.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
            }
        </div>
    )
}

export default Minutestable
