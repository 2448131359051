import { orderNumberConstants, status } from '../Constants';

export function orderNumber(state = {}, action: any) {
    switch (action.type) {
        case orderNumberConstants.ORDER_NUMBER_REQUEST:
            return {
                ...state,
                order_number_status: status.IN_PROGRESS,
                orderNumber: action.data
            };
        case orderNumberConstants.ORDER_NUMBER_SUCCESS:
            return {
                ...state,
                order_number_status: status.SUCCESS,
                orderNumber: action.data
            };
        case orderNumberConstants.ORDER_NUMBER_FAILURE:
            return {
                ...state,
                order_number_status: status.FAILURE,
                orderNumber: action.data
            };
        default:
            return state;
    }
}
