import {  status,CallSimulationConstants } from '../Constants';

export function callSimulationUsers(state = {}, action: any) {
    switch (action.type) {
        case CallSimulationConstants.SEARCH_CALLSIMULATION_REQUEST:
            return {
                ...state,
                search_callSimulation_status: status.IN_PROGRESS,
                searchcallSimuationList: action.data
            };
        case CallSimulationConstants.SEARCH_CALLSIMULATION_SUCCESS:
            return {
                ...state,
                search_callSimulation_status: status.SUCCESS,
                searchcallSimuationList: action.data
            };
        case CallSimulationConstants.SEARCH_CALLSIMULATION_FAILURE:
            return {
                ...state,
                search_callSimulation_status: status.FAILURE,
                searchcallSimuationList: null
            };
        default:
            return state;
    }
}